import { createMuiTheme } from "@material-ui/core/styles"
const theme = createMuiTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      light: "#ed1c2e",
      main: "#cb0020",
      dark: "#b20d27",
    },
    secondary: {
      light: "#101010",
      main: "#101010",
      dark: "#101010",
    },
  },
})
export default theme
