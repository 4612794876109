import { useStaticQuery, graphql } from "gatsby"
import { useState } from "react"
import { InsuranceCommune } from "../../../types"

type QueryResult = {
  allInsurance: {
    nodes: [
      {
        communes: InsuranceCommune[]
      }
    ]
  }
}

export const useInsuranceCommunesOptions = (): InsuranceCommune[] => {
  // Fetch static data
  const data = useStaticQuery<QueryResult>(graphql`
    query INSURANCE_COMMUNES {
      allInsurance {
        nodes {
          communes {
            id
            name
            regionId
          }
        }
      }
    }
  `)
  // Save data sorted
  const [allCommunes] = useState<InsuranceCommune[]>(() =>
    data.allInsurance.nodes[0].communes.sort((a, b) =>
      new Intl.Collator("es").compare(a.name, b.name)
    )
  )
  return allCommunes
}
