// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-checkout-accessories-tsx": () => import("./../../../src/pages/checkout/accessories.tsx" /* webpackChunkName: "component---src-pages-checkout-accessories-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-info-js": () => import("./../../../src/pages/checkout/info.js" /* webpackChunkName: "component---src-pages-checkout-info-js" */),
  "component---src-pages-checkout-step-2-js": () => import("./../../../src/pages/checkout/step2.js" /* webpackChunkName: "component---src-pages-checkout-step-2-js" */),
  "component---src-pages-checkout-tax-js": () => import("./../../../src/pages/checkout/tax.js" /* webpackChunkName: "component---src-pages-checkout-tax-js" */),
  "component---src-pages-checkout-thanks-js": () => import("./../../../src/pages/checkout/thanks.js" /* webpackChunkName: "component---src-pages-checkout-thanks-js" */),
  "component---src-pages-comparador-js": () => import("./../../../src/pages/comparador.js" /* webpackChunkName: "component---src-pages-comparador-js" */),
  "component---src-pages-en-construccion-js": () => import("./../../../src/pages/en-construccion.js" /* webpackChunkName: "component---src-pages-en-construccion-js" */),
  "component---src-pages-error-payment-js": () => import("./../../../src/pages/error-payment.js" /* webpackChunkName: "component---src-pages-error-payment-js" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-how-does-it-work-js": () => import("./../../../src/pages/how-does-it-work.js" /* webpackChunkName: "component---src-pages-how-does-it-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-unused-index-2-js": () => import("./../../../src/pages/unused/index2.js" /* webpackChunkName: "component---src-pages-unused-index-2-js" */),
  "component---src-templates-config-js": () => import("./../../../src/templates/config.js" /* webpackChunkName: "component---src-templates-config-js" */),
  "component---src-templates-model-js": () => import("./../../../src/templates/model.js" /* webpackChunkName: "component---src-templates-model-js" */)
}

