import React, { useContext, useCallback } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { WhatsAppIcon, fromDercoLogo } from "../assets"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import LoyaltyIcon from "@material-ui/icons/Loyalty"
import CreditCardIcon from "@material-ui/icons/CreditCard"
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar"
import CompareArrowsIcon from "@material-ui/icons/CompareArrows"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import SecurityRoundedIcon from "@material-ui/icons/SecurityRounded"
import BookmarkBorderRoundedIcon from "@material-ui/icons/BookmarkBorderRounded"
import UserIcon from "@material-ui/icons/Person"
import { CarContext, SearchContext, UIContext } from "../context"
import { checkUrl, urlWithSession, capitalize, shareWhatsapp } from "../utils"
import { Model } from "../types"

export const MobileMenu: React.FC = () => {
  const { selectedVersion, offers, selectedOffer } = useContext(CarContext)

  const {
    closeMobileMenu,
    openFinancingDrawer,
    openAppraiseDrawer,
    openInsuranceDrawer,
    openSessionMenu,
    openSellerMenu,
  } = useContext(UIContext)
  const { resetFilters } = useContext(SearchContext)

  const allModels = useStaticQuery(query).allModels.nodes

  const getClassName = useCallback(
    page => (checkUrl([page]) ? "selected" : ""),
    []
  )

  const handleClickNavigate = useCallback(
    page => () => {
      checkUrl([""]) && resetFilters()
      !checkUrl([page]) && navigate(urlWithSession("/" + page))
      closeMobileMenu()
    },
    [resetFilters, closeMobileMenu]
  )

  const handleFinancing = useCallback(() => {
    openFinancingDrawer()
    if (checkUrl([""])) {
      resetFilters()
      navigate(urlWithSession("/home"))
    }
    closeMobileMenu()
  }, [openFinancingDrawer, closeMobileMenu, resetFilters])

  const handleAppraise = useCallback(() => {
    openAppraiseDrawer()
    if (checkUrl([""])) {
      resetFilters()
      navigate(urlWithSession("/home"))
    }
    closeMobileMenu()
  }, [openAppraiseDrawer, closeMobileMenu, resetFilters])

  const handleInsurance = useCallback(() => {
    openInsuranceDrawer()
    closeMobileMenu()
  }, [openInsuranceDrawer, closeMobileMenu, resetFilters])

  const handleShare = useCallback(() => {
    shareWhatsapp({
      modelName: deductModelName(
        selectedVersion,
        selectedOffer,
        allModels,
        offers
      ),
    })
    closeMobileMenu()
  }, [selectedVersion, selectedOffer, allModels, offers, closeMobileMenu])

  const handleSessionMenu = useCallback(() => {
    openSessionMenu()
    closeMobileMenu()
  }, [openSessionMenu, closeMobileMenu])

  const handleSellerMenu = useCallback(() => {
    openSellerMenu()
    closeMobileMenu()
  }, [openSellerMenu, closeMobileMenu])

  return (
    <div className="mobile-menu">
      <List component="nav">
        <ListItem
          className={getClassName("home")}
          id="navigate-home-mobilemenu"
          button
          onClick={handleClickNavigate("home")}
        >
          <ListItemIcon>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Autos nuevos" />
        </ListItem>

        <ListItem
          className={getClassName("how-does-it-work")}
          id="navigate-howdoesitworks-mobilemenu"
          button
          onClick={handleClickNavigate("how-does-it-work")}
        >
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Cómo funciona" />
        </ListItem>

        {false && (
          <ListItem
            className={getClassName("offers")}
            id="navigate-outlet-mobilemenu"
            button
            onClick={handleClickNavigate("offers")}
          >
            <ListItemIcon>
              <LoyaltyIcon />
            </ListItemIcon>
            <ListItemText primary="Outlet" />
          </ListItem>
        )}

        <ListItem
          id="open-financing-drawer-button-mobilemenu"
          button
          onClick={handleFinancing}
        >
          <ListItemIcon>
            <CreditCardIcon />
          </ListItemIcon>
          <ListItemText primary="Financiamiento" />
        </ListItem>

        <ListItem
          id="open-appraise-drawer-button-mobilemenu"
          button
          onClick={handleAppraise}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Auto parte de pago" />
        </ListItem>

        <ListItem
          id="open-insurance-drawer-button-mobilemenu"
          button
          onClick={handleInsurance}
        >
          <ListItemIcon>
            <SecurityRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Cotizar seguro" />
        </ListItem>

        <ListItem
          className={getClassName("comparador")}
          id="compare-button-mobilemenu"
          button
          onClick={handleClickNavigate("comparador")}
        >
          <ListItemIcon>
            <CompareArrowsIcon />
          </ListItemIcon>
          <ListItemText primary="Comparador" />
        </ListItem>

        <ListItem
          id="session-button-mobilemenu"
          button
          onClick={handleSessionMenu}
        >
          <ListItemIcon>
            <BookmarkBorderRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Guardado" />
        </ListItem>

        <ListItem
          id="session-button-mobilemenu"
          button
          onClick={handleSellerMenu}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary="Vendedor" />
        </ListItem>

        <ListItem id="share-mobilemenu" button onClick={handleShare}>
          <ListItemIcon>
            <WhatsAppIcon />
          </ListItemIcon>
          <ListItemText primary="Compartir" />
        </ListItem>
      </List>
      <div className="menu-footer">
        <img
          className="from-derco"
          src={fromDercoLogo}
          alt="Una empresa de Derco"
        ></img>
        <span className="title">CONTACTO</span>
        <span className="info">contacto@dercocenterx.cl</span>
        <span className="title">DERCO RESPONDE</span>
        <span className="info">600 600 600</span>
      </div>
    </div>
  )
}

const deductModelName = (
  selectedVersion,
  selectedOffer,
  allModels: Model[],
  offers
): string => {
  // Search if there is any selected offer
  const offer = offers?.find(of => of.internalId === selectedOffer)
  if (offer) return capitalize(offer.brand) + " " + offer.modelName

  // Then, search if there is any selectedVersion
  if (!selectedVersion) return ""
  return (
    allModels.find(model =>
      model.versions.map(v => v.id).includes(selectedVersion.versionId)
    )?.name || ""
  )
}

const query = graphql`
  query BM_BV_QUERY_2 {
    allModels {
      nodes {
        ...ModelBasic
        versions {
          ...VersionBasic
        }
      }
    }
  }
`
