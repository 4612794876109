import React from "react"
import { DCXButton, InputForEmail, InputForPhone, InputForFullname } from ".."
import { saveAppraise } from "../../api"
import { StepProps, ErrorKey } from "."

export const AppraiseThanks1: React.FC<StepProps> = ({
  form,
  updateForm,
  handleChange,
}) => {
  const previousStep = () => {
    updateForm(prev => ({
      ...prev,
      info: {
        ...prev.info,
        stepHistory: prev.info.stepHistory.slice(0, -1),
        step: prev.info.stepHistory.slice(-1)[0],
      },
    }))
  }
  const finishStep = () => {
    saveAppraise(form, false)
    form.actions.nextStep()
  }

  // Disable if there is any error or there is any null/empty value
  const shouldDisableButton =
    (["name", "phone", "email"] as ErrorKey[]).reduce(
      (acum: boolean, key: ErrorKey) => acum || form.errors[key],
      false
    ) || ![form.name, form.phone, form.email].every(Boolean)

  return (
    <>
      <div className="info">
        <div className="car-name">¡Necesitamos saber más!</div>
        <div className="car-desc">
          Dadas las condiciones de tu auto necesitamos hacerte algunas preguntas
          adicionales.
        </div>
      </div>
      <div className="title">Completa tus datos</div>
      <div className="subtitle"></div>
      <div className="component">
        <InputForFullname
          name="name"
          onChange={handleChange}
          value={form.name}
        />
        <InputForEmail
          name="email"
          onChange={handleChange}
          value={form.email}
        />
        <InputForPhone
          name="phone"
          onChange={handleChange}
          value={form.phone}
        />
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-8"
          type="secondary"
          text="ATRÁS"
          onClick={previousStep}
        />
        <DCXButton
          className="button-next ask-appraise"
          id="request-appraise"
          type="primary"
          text="SOLICITAR"
          disabled={shouldDisableButton}
          onClick={finishStep}
        />
      </div>
    </>
  )
}
