import React from "react"
import Rating from "@material-ui/lab/Rating"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."

export const AppraiseStep3: React.FC<StepProps> = ({ form, updateForm }) => {
  const finishStep = () => {
    if (form.stars > 2) {
      form.actions.nextStep()
    } else {
      form.actions.thanksPage("Cantidad de estrellas igual o menor a 2")
    }
  }

  return (
    <>
      <CarInfo data={form} />
      <div className="title">¿Cuál es el estado de tu auto?</div>
      <div className="subtitle">Elige el que más se parezca</div>
      <div className="component">
        <Rating
          id="select-stars"
          name="simple-controlled"
          size="large"
          value={form.stars}
          onChange={(_, newValue) =>
            newValue && updateForm(prev => ({ ...prev, stars: newValue }))
          }
        />
        <div className="star-description">
          {getStarsDescription(form.stars)}
        </div>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-3"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next select-car-condition"
          id="finish-step-3"
          type="primary"
          text="SIGUIENTE"
          onClick={finishStep}
        />
      </div>
    </>
  )
}

const getStarsDescription = (stars: number) => (
  <>
    <strong>{TEXTS[stars as 1 | 2 | 3 | 4 | 5][0]}</strong>
    <br></br>
    {TEXTS[stars as 1 | 2 | 3 | 4 | 5][1]}
  </>
)

const TEXTS = {
  1: [
    "Mi auto está carreteado",
    "No está muy bien mecánicamente, podría tener un choque mal reparado, alguna luz que muestre problemas en el motor o fallas en su caja de cambios. ¡Por eso necesito que me ayuden a cambiarlo!",
  ],
  2: [
    "Mi auto está más o menos",
    "Tiene algunos desperfectos mecánicos y detalles exteriores. Podría tener pequeñas pérdidas de aceite o desgaste en el embrague.",
  ],
  3: [
    "Mi auto está ok",
    "En buenas condiciones mecánicas, pero con algunos detalles en el exterior. Tiene un par de topones o pequeñas abolladuras o rayas. En el interior, el tapiz puede estar un poco desgastado.",
  ],
  4: [
    "Mi auto se ve bien, está bien cuidado",
    "Si miras bien puedes encontrar algún detalle o raya, tuvo un pequeño topón pero está reparado. El interior está en buenas condiciones.",
  ],
  5: [
    "¡Mi auto está como nuevo!",
    "Sin ningún topón ni raya. Además, por dentro está impecable, el tapiz no tiene ninguna mancha.",
  ],
}
