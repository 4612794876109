import React, { useContext } from "react"
import { IconButton } from "@material-ui/core"
import { CloseIcon, NavigateBeforeIcon } from "../../assets"
import { CarContext } from "../../context"

export function DrawerHeader({ className, title, drawerName, closeDrawer }) {
  const appContext = useContext(CarContext)
  const { windowWidth } = appContext
  return (
    <div className={`drawer-header ${className || ""}`}>
      <IconButton
        className={`back ${windowWidth >= 520 ? "hide" : ""}`}
        id={`close-${drawerName}-1`}
        role="button"
        tabIndex={0}
        onClick={closeDrawer}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <span className="title">{title}</span>
      <IconButton
        className={`close ${windowWidth < 520 ? "hide" : ""}`}
        id={`close-${drawerName}-2`}
        role="button"
        tabIndex={0}
        onClick={closeDrawer}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}
