import React from "react"
import { ShortcutCard, WrapperProps } from "./ShortcutCard"
import { ShortcutCardAppraiseItem } from "../../assets"

export const AppraiseShortcutCard: React.FC<WrapperProps> = ({
  className,
  handleClick,
}) => {
  return (
    <div className={`shortcut-cards appraise-card ${className}`}>
      <ShortcutCard
        icon={ShortcutCardAppraiseItem}
        title="¿Aún con tu viejo?"
        text="Tasa tu auto actual y entrégalo en parte de pago"
        buttonText="TASA TU AUTO"
        handleClick={handleClick}
        color="secondary"
      />
    </div>
  )
}
