import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { DCXButton, Select, ShareButton, SaveMenuButton } from "."
import { urlWithSession, getModelShortNameOrDefault, checkUrl } from "../utils"
import { getStandardAnimationProps } from "../constants"

export const HeaderCar = ({ page, model }) => {
  const [hideSaveMenu, setHideSaveMenu] = useState(true)

  useEffect(() => {
    document.addEventListener("sticky-change", e => {
      const header = e.detail.target // header became sticky or stopped sticking.
      const sticking = e.detail.stuck // true when header is sticky.
      header.classList.toggle("sticky-active", sticking) // add drop shadow when sticking.
    })
  }, [])
  useEffect(() => {
    setHideSaveMenu(!checkUrl(["config"]))
  }, [])

  const allModels = useStaticQuery(query).allModels.nodes

  return (
    <div className="header-car">
      <div className="model-select">
        <Select
          group="brand"
          props={{
            values: allModels,
            propertyValue: "slug",
            propertyShow: "name",
            actual: model.slug,
          }}
          onChange={value => {
            navigate(urlWithSession(`/${page}/${value}`))
          }}
        />
      </div>
      <div className={`menu ${page}-page`} {...getStandardAnimationProps()}>
        <DCXButton
          className="link-button"
          type="tertiary"
          text="Datos Prácticos"
          onClick={() => {
            const element = document.getElementById("practicos")
            window.scrollTo({
              top: element.offsetTop,
              behavior: "smooth",
            })
          }}
        />
        <DCXButton
          className="link-button"
          type="tertiary"
          text="Ficha Técnica"
          onClick={() => {
            const element = document.getElementById("tecnica")
            window.scrollTo({
              top: element.offsetTop,
              behavior: "smooth",
            })
          }}
        />
        <SaveMenuButton hide={hideSaveMenu} />
        {/* <ShareButton
          className="share"
          messageType={page === "model" ? "model" : "config"}
          modelName={model.name}
        /> */}

        <DCXButton
          className="button-buy"
          type="primary"
          text={`ARMA TU ${getModelShortNameOrDefault(model)}`}
          onClick={() => navigate(urlWithSession(`/config/${model.slug}`))}
        />
      </div>
    </div>
  )
}

const query = graphql`
  query BM_BV_QUERY_1 {
    allModels {
      nodes {
        ...ModelBasic
        versions {
          ...VersionBasic
        }
      }
    }
  }
`
