import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react"
import { infoToSessionString } from "../utils"
import { Payment } from "../types"

export const CarContext = createContext()

export const CarProvider = ({ children }) => {
  const [appLoading, setAppLoading] = useState(true)

  /* SELECTIONS */
  const [transmission, setTransmission] = new useState(null)
  const [equipment, setEquipment] = new useState(null)
  const [engine, setEngine] = new useState(null)
  const [traction, setTraction] = new useState(null)
  const [color, setColor] = new useState(null)

  /* PAYMENT PARAMS */
  const [payment, setPayment] = new useState(Payment.credit)
  const [dues, setDues] = new useState(36)
  const [percentage, setPercentage] = new useState(20)
  const [carPaymentInfo, SetCarPaymentInfo] = new useState(null)
  const [maxDues, setMaxDues] = new useState(60)
  const [minDues, setMinDues] = new useState(12)

  /* GLOBAL VARIABLES */
  const [modelsPricesStocks, setModelsPricesStocks] = new useState(null)
  const [pricesInfo, setPricesInfo] = new useState({
    fetching: false,
    expired: true,
  })
  const [selectedVersion, setSelectedVersion] = new useState(null)
  const [selectedOffer, setSelectedOffer] = new useState(null)
  const [terms, setTerms] = new useState(false)
  const [comparatorSelections, setComparatorSelections] = new useState([])
  const [loading, setLoading] = new useState(true)
  const [offers, setOffers] = new useState(null)
  const [financeData, setFinanceData] = new useState(null)
  const [appFirstStart, setAppFirstStart] = new useState(true)
  const [windowWidth, setWindowWidth] = new useState(
    typeof window !== "undefined" && window.innerWidth
  )

  /* HOME SORTING */
  const [priceOrder, setPriceOrder] = useState(null)

  /* CHECKOUT VARIABLES */
  const [checkoutData, setCheckoutData] = useState({
    insurance: null,
    selectedAccessoriesModelId: null,
    selectedAccessories: [],
    carPrice: null,
    firstPaymentPrice: null,
    includePlatePack: true,
    platePackUnderstanding: false,
    platePackPageVisited: false,
    client: {
      name: null,
      lastname: null,
      rut: null,
      phone: null,
      email: null,
      income: null,
    },
    agreeTC: false,
    withCredit: null,
    preApproved: null,
    leadUUID: null,
    transbankUrl: null,
    transbankToken: null,
    sellerData: null,
  })

  const savePlatePlack = useCallback(
    ({ includePlatePack }) => {
      setCheckoutData(prev => ({
        ...prev,
        includePlatePack,
      }))
      infoToSessionString({ includePlatePack })
    },
    [setCheckoutData]
  )

  const saveAccessories = useCallback(
    ({ selectedAccessoriesModelId, selectedAccessories, accessoryId }) => {
      // Update context
      setCheckoutData(prev => ({
        ...prev,
        selectedAccessoriesModelId:
          selectedAccessoriesModelId ?? prev.selectedAccessoriesModelId,
        selectedAccessories:
          selectedAccessories ||
          prev.selectedAccessories.map(acc => {
            return acc.code === accessoryId
              ? { ...acc, selected: !acc.selected }
              : acc
          }),
      }))
    },
    [setCheckoutData]
  )

  const prevAccessoriesValue = useRef(0)
  // Update session string
  useEffect(() => {
    const opt = checkoutData.selectedAccessories
      .map(acc => acc.selected)
      .reduce((prev, actual, idx) => {
        return prev + Number(actual) * Math.pow(2, idx)
      }, 0)
    if (opt != prevAccessoriesValue.current)
      infoToSessionString({ selectedAccessories: opt })
    prevAccessoriesValue.current = opt
  }, [checkoutData.selectedAccessories])

  const resetContext = useCallback(() => {
    //Selection
    setTransmission(null)
    setEquipment(null)
    setEngine(null)
    setTraction(null)
    setColor(null)
    //Payment
    setPayment(Payment.credit)
    setDues(36)
    setPercentage(20)
    SetCarPaymentInfo(null)
    setMaxDues(60)
    setMinDues(12)
    //Global
    setSelectedVersion(null)
    setSelectedOffer(null)
    setTerms(null)
    setComparatorSelections([])
    //Home
    setPriceOrder(null)
    //Checkout
    setCheckoutData(prev => ({
      ...prev,
      insurance: null,
      selectedAccessoriesModelId: null,
      selectedAccessories: [],
      carPrice: null,
      firstPaymentPrice: null,
      includePlatePack: true,
      platePackUnderstanding: false,
      platePackPageVisited: false,
      client: {
        name: null,
        lastname: null,
        rut: null,
        phone: null,
        email: null,
        income: null,
      },
      agreeTC: false,
      withCredit: null,
      preApproved: null,
      leadUUID: null,
      transbankUrl: null,
      transbankToken: null,
    }))
  }, [])

  return (
    <CarContext.Provider
      value={{
        resetContext,
        transmission,
        setTransmission: (v, s = true) => {
          setTransmission(v)
          s && infoToSessionString({ transmission: v })
        },
        equipment,
        setEquipment: (v, s = true) => {
          setEquipment(v)
          s && infoToSessionString({ equipment: v })
        },
        engine,
        setEngine: (v, s = true) => {
          setEngine(v)
          s && infoToSessionString({ engine: v })
        },
        traction,
        setTraction: (v, s = true) => {
          setTraction(v)
          s && infoToSessionString({ traction: v })
        },
        color,
        setColor: (v, s = true) => {
          setColor(v)
          s && infoToSessionString({ color: v })
        },
        setAllSelections: (
          { transmission, equipment, engine, traction, color },
          saveSession = true,
          considerNulls = false
        ) => {
          if (considerNulls || transmission) {
            setTransmission(transmission)
            saveSession && infoToSessionString({ transmission })
          }
          if (considerNulls || equipment) {
            setEquipment(equipment)
            saveSession && infoToSessionString({ equipment })
          }
          if (considerNulls || engine) {
            setEngine(engine)
            saveSession && infoToSessionString({ engine })
          }
          if (considerNulls || traction) {
            setTraction(traction)
            saveSession && infoToSessionString({ traction })
          }
          if (considerNulls || color) {
            setColor(color)
            saveSession && infoToSessionString({ color })
          }
        },
        payment,
        setPayment: (v, s = true) => {
          setPayment(v)
          if (v !== Payment.cash && !checkoutData.includePlatePack) {
            setCheckoutData(prev => ({ ...prev, includePlatePack: true }))
            infoToSessionString({ includePlatePack: true })
          }
          s && infoToSessionString({ payment: v })
        },
        setPaymentToCash: (s = true, d) => {
          setTimeout(() => setPayment(Payment.cash), d || 0)
          s && infoToSessionString({ payment: Payment.cash })
        },
        setPaymentToCredit: (s = true, d) => {
          setTimeout(() => setPayment(Payment.credit), d || 0)
          s && infoToSessionString({ payment: Payment.credit })
        },
        dues,
        setDues: (v, s = true) => {
          if (v !== dues) {
            setDues(v)
            s && infoToSessionString({ dues: v })
          }
        },
        percentage,
        setPercentage: (v, s = true) => {
          if (v !== percentage) {
            setPercentage(v)
            s && infoToSessionString({ percentage: v })
          }
        },
        carPaymentInfo,
        SetCarPaymentInfo: (v, s = true) => {
          SetCarPaymentInfo(v)
          s && infoToSessionString({ carPaymentInfo: v })
        },
        maxDues,
        setMaxDues,
        minDues,
        setMinDues,
        selectedVersion,
        setSelectedVersion: val => {
          setSelectedVersion(val)
          setSelectedOffer(null)
        },
        selectedOffer,
        setSelectedOffer: val => {
          setSelectedOffer(val)
          setSelectedVersion(null)
        },
        comparatorSelections,
        setComparatorSelections,
        terms,
        setTerms,
        loading,
        setLoading,
        modelsPricesStocks,
        setModelsPricesStocks,
        pricesInfo,
        setPricesInfo,
        appFirstStart,
        setAppFirstStart,
        windowWidth,
        setWindowWidth,
        financeData,
        setFinanceData,
        offers,
        setOffers,
        checkoutData,
        setCheckoutData,
        savePlatePlack,
        saveAccessories,
        priceOrder,
        setPriceOrder,
        appLoading,
        setAppLoading,
      }}
    >
      {children}
    </CarContext.Provider>
  )
}
