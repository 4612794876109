import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { Disclaimer } from "."
import { parseNumber } from "../utils"
import { useSelectedAccessoriesTotalPrice } from "../hooks"
import { Payment } from "../types"

export function PaymentSummaryTable({
  appContext,
  prices,
  PaymentParams,
  dense,
}) {
  const [
    accessoriesTotalPrice,
    selectedAccessories,
  ] = useSelectedAccessoriesTotalPrice()

  const paymentSummaryRows = getCheckoutPaymentSummaryRows({
    appContext,
    prices,
    PaymentParams,
    totalTaxes: PaymentParams.totalAdditionals - accessoriesTotalPrice,
    selectedAccessories,
  })

  return (
    <div className="payment-summary-table">
      <Table
        size={!dense && appContext.windowWidth >= 810 ? "medium" : "small"}
        aria-label="a table"
      >
        <TableBody>
          {paymentSummaryRows.map(row => (
            <TableRow
              className={row.highlighted ? "highlighted" : ""}
              key={row.name}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {getCellValue(row)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Disclaimer />
    </div>
  )
}

const getCellValue = row => {
  if (row.name === "Pack patente" && row.value === 0)
    return (
      <TableCell align="right">
        <span style={{ fontStyle: "italic" }}>no incluído</span>
      </TableCell>
    )
  if (row.name.includes("CAE"))
    return (
      <TableCell align="right">{parseNumber(row.value, null, 2)}%</TableCell>
    )
  if (row.name.includes("meses"))
    return <TableCell align="right">{parseNumber(row.value)}</TableCell>
  return (
    <TableCell align="right">
      {row.negative ? "- " : ""}${parseNumber(row.value)}
    </TableCell>
  )
}

const getCheckoutPaymentSummaryRows = ({
  appContext: { payment, dues, carPaymentInfo, checkoutData },
  prices,
  isOffer,
  PaymentParams,
  totalTaxes,
  selectedAccessories,
}) => {
  if (!prices) return []
  const variant = Math.floor(PaymentParams.variant)
  // brand discounts is the difference between priceList and priceCash
  const brandDiscount = prices.priceList - prices.priceCash
  // financing discount for this case is the difference between priceCash and selectedPrice
  const financingDiscount =
    prices.priceCash -
    (PaymentParams.selectedCarPrice - (PaymentParams.colorSurcharge || 0))
  const platePackValue = checkoutData.includePlatePack ? totalTaxes : 0
  const carInPaymentValue = carPaymentInfo?.finalPrice || 0
  const finalFirstPaymentPrice = [2, 5].includes(variant)
    ? 0
    : Math.max(PaymentParams.firstPaymentPrice, 0)

  const finalLineName = [1, 4].includes(variant)
    ? "Valor total a financiar"
    : [2, 5, 7].includes(variant)
    ? "Valor final"
    : "Te devolvemos"

  const finalLineValue = [1, 4].includes(variant)
    ? PaymentParams.baseToFinance
    : [2, 5, 7].includes(variant)
    ? PaymentParams.firstPaymentPrice
    : Math.abs(PaymentParams.onFavor)

  return [
    {
      name: "Precio lista",
      value: prices.priceList,
    },
    ...(PaymentParams.colorSurcharge
      ? [
          {
            name: "Adicional color premium",
            value: PaymentParams.colorSurcharge,
          },
        ]
      : []),
    {
      name: "Descuento auto",
      value: brandDiscount,
      negative: true,
    },
    {
      name: "Descuento financiamiento",
      value: financingDiscount,
      negative: true,
    },
    ...(isOffer || PaymentParams.totalDiscounts === 0
      ? []
      : [
          {
            name: "Descuento online",
            value: PaymentParams.totalDiscounts,
            negative: true,
          },
        ]),
    {
      name: "Valor auto",
      value: PaymentParams.finalCarPrice,
      highlighted: true,
    },
    ...selectedAccessories.map(acc => ({
      name: "Accesorio " + acc.name,
      value: acc.price,
    })),
    {
      name: "Pack patente",
      value: platePackValue,
    },
    {
      name: "Auto en parte de pago",
      value: carInPaymentValue,
      negative: true,
    },
    ...([1, 4].includes(variant)
      ? [
          {
            name: "Monto de pie a abonar",
            value: finalFirstPaymentPrice,
            negative: true,
          },
        ]
      : []),
    {
      name: finalLineName,
      value: finalLineValue,
      highlighted: true,
    },
    ...(PaymentParams.withCredit
      ? [
          {
            name: "N° de meses",
            value: dues,
          },
          {
            name: "Valor cuota mensual",
            value: PaymentParams.monthlyPrice,
            highlighted: true,
          },
          ...([1, 3].includes(variant)
            ? [
                {
                  name: "Pago final opcional",
                  value: PaymentParams.VFMG,
                  highlighted: true,
                },
              ]
            : []),
          {
            name: "Carga anual equiv. (CAE)",
            value: PaymentParams.CAE,
          },
          {
            name: "Costo total del crédito",
            value: PaymentParams.CTC,
          },
        ]
      : []),
  ]
}
