import { InsuranceSimulationOption } from "./"

export enum CarType {
  new = "new",
  used = "used",
}

export enum Owner {
  me = "me",
  business = "business",
  other = "other",
}

export enum Gender {
  male = "male",
  female = "female",
}

export enum ContactChannel {
  email = "email",
  phone = "phone",
  whatsapp = "whatsapp",
}

export enum Insurance {
  simulationId = "simulationId",
  carType = "carType", // Options: used, new
  brandId = "brandId",
  modelId = "modelId",
  year = "year",
  plate = "plate",
  owner = "owner", // Options: me, business, other
  rut = "rut",
  rz = "rz",
  gender = "gender", // Options: male, female
  name = "name",
  lastname = "lastname",
  birthdate = "birthdate",
  addressId = "addressId",
  dues = "dues",
  deductible = "deductible",
  companyId = "companyId", // (insurance company)
  contactChannel = "contactChannel",
  email = "email",
  phone = "phone",
}

export interface InsuranceData {
  simulationId: number
  carType: CarType
  brandId: string
  modelId: string
  year: string
  plate: string
  owner: Owner
  rut: string
  rz: string
  gender: Gender
  name: string
  lastname: string
  birthdate: string
  addressId: string
  dues: number
  deductible: number
  companyId: string
  contactChannel: ContactChannel
  email: string
  phone: string
}

export type InsuranceAdditionalData = {
  selectedPlan?: InsuranceSimulationOption
  simulationId?: number
  UFvalue?: number
  dcxModelId?: number
}

export interface InsuranceContextType {
  insuranceData: InsuranceData
  setInsuranceData: React.Dispatch<React.SetStateAction<InsuranceData>>
  additionalData: InsuranceAdditionalData | null
  setAdditionalData: React.Dispatch<
    React.SetStateAction<InsuranceAdditionalData | null>
  >
  cleanData: () => void
}

export type OnChangeParams = {
  name: Insurance
  value: number | string | null
  hasError?: boolean
}
export type OnChange = ({ name, value, hasError }: OnChangeParams) => void

export type SetStepInputValid = (valid: boolean) => void
