import React, { useState, useEffect } from "react"
import MomentUtils from "@date-io/moment"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import moment from "moment"
import "moment/locale/es"

moment.locale("es")

type Props = {
  date: string | null
  setDate: (date: string | null) => void
}

export const DatePicker: React.FC<Props> = ({ date, setDate }) => {
  const [isValideAge, setIsValidAge] = useState(true)
  useEffect(() => {
    if (moment(date).isValid()) {
      setIsValidAge(moment().diff(date, "years", true) >= 18)
    }
  }, [setIsValidAge, date])
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
      <KeyboardDatePicker
        variant="inline"
        format="DD/MM/yyyy"
        margin="normal"
        label="Fecha de nacimiento"
        value={date ? moment(date, "DD/MM/yyyy", true) : null}
        onChange={date => setDate(date?.format("DD/MM/yyyy") || "")}
        autoOk
        invalidDateMessage={"La fecha ingresada no es válida"}
        helperText={!isValideAge ? "No puedes ser menor de edad" : ""}
        disableFuture
        error={!isValideAge}
      />
      {/*<form className="native-datepicker" noValidate>
        <TextField
          id="date"
          label="Fecha de nacimiento"
          type="date"
          className="datepicker"
          fullWidth
          value={
            date
              ? moment(date, "DD/MM/yyyy", true).format("yyyy-MM-DD")
              : moment().format("yyyy-MM-DD")
          }
          onChange={e =>
            setDate(moment(e.target.value, "yyyy-MM-DD").format("DD/MM/yyyy"))
          }
          variant="outlined"
        />
        </form>*/}
    </MuiPickersUtilsProvider>
  )
}
