import React from "react"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."
import { parseNumber } from "../../utils"

export const AppraiseStep7: React.FC<StepProps> = ({ form }) => {
  const finishStep = () => {
    form.actions.SetCarPaymentInfo({
      finalPrice: form.info.carInfo.finalPrice,
      plate: form.info.carInfo.plate as string,
      date: new Date().getTime(),
    })
    form.actions.closeAppraiseDrawer(true)
  }
  return (
    <>
      <CarInfo data={form} />
      <div className="title">Recibimos tu auto en:</div>
      <div className="subtitle"></div>
      <div className="component">
        <div className="amount">
          ${parseNumber(form.info.carInfo.finalPrice)}
        </div>
        <div className="disclaimer">
          Precio referencial sujeto a la inspección de tu auto. Simula para
          descontar este valor en el precio total de tu nuevo auto.
        </div>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-7"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next save-appraise"
          id="accept-final-value"
          type="primary"
          text="SIMULAR"
          onClick={finishStep}
        />
      </div>
    </>
  )
}
