/* MATERIAL-UI ICONS */
import CloseIcon from "@material-ui/icons/Close"
import HelpIcon from "@material-ui/icons/Help"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import ShareIcon from "@material-ui/icons/Share"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined"
import InfoIcon from "@material-ui/icons/Info"
/* HOW DOES IT WORK ASSETS */
import HowDoesItWork1Image from "./how-does-it-work/1.svg"
import HowDoesItWork2Image from "./how-does-it-work/2.svg"
import HowDoesItWork3Image from "./how-does-it-work/3.svg"
/* APPRAISE ASSETS */
import appraiseStep0Image from "./appraise/appraise-step0.svg"
import appraiseFinalStepImage from "./appraise/appraise-final-step.png"
import carkeyBlackIcon from "./appraise/car-keys-black.svg"
import carkeyRedIcon from "./appraise/car-keys-red.svg"
/* BRAND LOGOS FOR OFFERS */
import changanLogo from "./brand-logos/changan.svg"
import greatwallLogo from "./brand-logos/great_wall.svg"
import havalLogo from "./brand-logos/haval.svg"
import jacLogo from "./brand-logos/jac.svg"
import mazdaLogo from "./brand-logos/mazda.svg"
import renaultLogo from "./brand-logos/renault.svg"
import suzukiLogo from "./brand-logos/suzuki.svg"
/* BUTTONS ICONS (TO-BE REFACTORED) */
import compareIconBlack from "./buttons/compare-black.svg"
import compareIconPink from "./buttons/compare-black.svg"
import backIconPink from "./buttons/back-pink.svg"
/* CHECKOUT ASSETS */
import checkoutFinalImage from "./checkout/thanks.svg"
import checkoutPlateImage from "./checkout/plate.svg"
import checkoutFormImage from "./checkout/form.svg"
import checkoutApprovedImage from "./checkout/approved.svg"
import checkoutRejectedImage from "./checkout/rejected.svg"
import checkoutMoneyImage from "./checkout/money.svg"
import checkoutDeliveryImage from "./checkout/delivery.svg"
import checkoutAccessoriesImage from "./checkout/accessories.svg"
import big1Image from "./checkout/steps-numbers/big1.svg"
import big2Image from "./checkout/steps-numbers/big2.svg"
import big3Image from "./checkout/steps-numbers/big3.svg"
import big4Image from "./checkout/steps-numbers/big4.svg"
import big5Image from "./checkout/steps-numbers/big5.svg"
import big6Image from "./checkout/steps-numbers/big6.svg"
import big7Image from "./checkout/steps-numbers/big7.svg"
import completedIcon from "./checkout/steps-numbers/completed.svg"
import pendingIcon from "./checkout/steps-numbers/pending.svg"
/* MATERIAL-UI ICONS */
import fromDercoLogo from "./derco/from-derco.svg"
import dcxLogo from "./derco/dcx-logo.svg"
import dcxWhiteLogo from "./derco/dcx-logo-white.svg"
/* SEO ASSETS */
import seoImage from "./meta/seo.jpg"
/* MISC ICONS */
import linkIcon from "./misc/link.svg"
import footPinkIcon from "./misc/foot-pink.svg"
import footBlackIcon from "./misc/foot-black.svg"
import FaceStarsIcon from "./misc/face-star-icon.png"
import FacePartyIcon from "./misc/party-face-icon.png"
import ShareDialogImage1 from "./misc/share-dialog-image1.png"
import ShareDialogImage2 from "./misc/share-dialog-image2.png"
import whatsappLogo from "./misc/whatsapp.svg"
import CoolEmoji from "./misc/emoji-cool.svg"
import FilterIcon from "./misc/filter-icon.svg"
import SuccessIcon from "./checkout/success.svg"
import SmileStarEmoji from "./checkout/smile-star-face.png"
/* FEATS ICONS */
import absIconBlack from "./model/feats/abs-black.svg"
import absIconWhite from "./model/feats/abs-white.svg"
import acIconWhite from "./model/feats/ac-white.svg"
import acIconBlack from "./model/feats/ac-black.svg"
import airbagIconWhite from "./model/feats/airbag-white.svg"
import airbagIconBlack from "./model/feats/airbag-black.svg"
import babySeatIconBlack from "./model/feats/babySeat-black.svg"
import babySeatIconWhite from "./model/feats/babySeat-white.svg"
import carCheckIconBlack from "./model/feats/carCheck-black.svg"
import carMaintenanceIconBlack from "./model/feats/carMaintenance-black.svg"
import carplayIconBlack from "./model/feats/carplay-black.svg"
import carplayIconWhite from "./model/feats/carplay-white.svg"
import carWarrantyIconBlack from "./model/feats/carWarranty-black.svg"
import climatizerIconWhite from "./model/feats/climatizer-white.svg"
import climatizerIconBlack from "./model/feats/climatizer-black.svg"
import doubleSeatsIconWhite from "./model/feats/doubleSeats-white.svg"
import doubleSeatsIconBlack from "./model/feats/doubleSeats-black.svg"
import engineIconWhite from "./model/feats/engine-white.svg"
import engineIconBlack from "./model/feats/engine-black.svg"
import engineTurboIconBlack from "./model/feats/engineTurbo-black.svg"
import engineTurboIconWhite from "./model/feats/engineTurbo-white.svg"
import gasIconBlack from "./model/feats/gas-black.svg"
import gasIconWhite from "./model/feats/gas-white.svg"
import heightIconBlack from "./model/feats/height-black.svg"
import keylessIconWhite from "./model/feats/keyless-white.svg"
import lengthIconBlack from "./model/feats/length-black.svg"
import mirrorIconBlack from "./model/feats/mirror-black.svg"
import mirrorIconWhite from "./model/feats/mirror-white.svg"
import radioCommandIconWhite from "./model/feats/radioCommand-white.svg"
import safetyIconBlack from "./model/feats/safety-black.svg"
import safetyIconWhite from "./model/feats/safety-white.svg"
import screenIconBlack from "./model/feats/screen-black.svg"
import screenIconWhite from "./model/feats/screen-white.svg"
import seatsIconWhite from "./model/feats/seats-white.svg"
import seatsIconBlack from "./model/feats/seats-black.svg"
import sensorIconWhite from "./model/feats/sensor-white.svg"
import sensorIconBlack from "./model/feats/sensor-black.svg"
import speakerIconBlack from "./model/feats/speaker-black.svg"
import speakersIconWhite from "./model/feats/speakers-white.svg"
import sunroofIconWhite from "./model/feats/sunroof-white.svg"
import tapestryIconWhite from "./model/feats/tapestry-white.svg"
import trunkIconWhite from "./model/feats/trunk-white.svg"
import trunkIconBlack from "./model/feats/trunk-black.svg"
import usbIconWhite from "./model/feats/usb-white.svg"
import usbIconBlack from "./model/feats/usb-black.svg"
import weightIconBlack from "./model/feats/weight-black.svg"
import wheelIconBlack from "./model/feats/wheel-black.svg"
import wheelIconWhite from "./model/feats/wheel-white.svg"
import widthIconBlack from "./model/feats/width-black.svg"
import DHAIconWhite from "./model/feats/DHA-white.svg"
import ESPIconWhite from "./model/feats/ESP-white.svg"
import ESPIconBlack from "./model/feats/ESP-black.svg"
import HASIconWhite from "./model/feats/HAS-white.svg"
import HASIconBlack from "./model/feats/HAS-black.svg"
/* PAYMENT TYPES ICONS */
import paymentCashBlackIcon from "./payment-options/cash-black.svg"
import paymentCreditBlackIcon from "./payment-options/credit-black.svg"
import paymentSmartBlackIcon from "./payment-options/smart-black.svg"
import paymentCashBlueIcon from "./payment-options/cash-blue.svg"
import paymentCreditBlueIcon from "./payment-options/credit-blue.svg"
import paymentSmartBlueIcon from "./payment-options/smart-blue.svg"
/* THANKS PAGE ASSETS */
import thanksBadPaymentImage from "./thanks/bad-payment.svg"
import thanksSuccessPaymentImage from "../assets/thanks/success-payment.svg"
import thanksCallCenterImage from "../assets/thanks/callcenter.svg"
/* LANDING PAGE ASSETS */
import FrameCarImage from "./landing-page/frame-car.svg"
import FrameLensImage from "./landing-page/frame-lens.svg"
import FrameSCrossImage from "./landing-page/frame-scross.png"
import FrameSwiftImage from "./landing-page/frame-swift.png"
import FrameWingleImage from "./landing-page/frame-wingle.png"
import SmarthponeImage from "./landing-page/smartphone.png"
import CloudImageMobile1 from "./landing-page/clouds/mobile/cloud-1.svg"
import CloudImageMobile2 from "./landing-page/clouds/mobile/cloud-2.svg"
import CloudImageDesktop from "./landing-page/clouds/desktop/cloud.svg"
import CloudImageDesktopBlurry1 from "./landing-page/clouds/desktop/cloud-blurry-1.svg"
import CloudImageDesktopBlurry2 from "./landing-page/clouds/desktop/cloud-blurry-2.svg"
/* CAREGORIES ICONS*/
import FurgonIcon from "./categories/furgon.svg"
import HatchbackIcon from "./categories/hatchback.svg"
import SedanIcon from "./categories/sedan.svg"
import SportIcon from "./categories/sport.svg"
import SuvIcon from "./categories/suv.svg"
import TruckIcon from "./categories/truck.svg"
import VanIcon from "./categories/van.svg"
/* HOME ICONS */
import ShortcutCardCashItem from "./home/cash.svg"
import ShortcutCardCreditItem from "./home/credit.svg"
import ShortcutCardAppraiseItem from "./home/appraise.svg"
import ShortcutCardCompareItem from "./home/compare.svg"
import ShortcutCardInsuranceItem from "./insurance/steps/car-type-step.svg"
import NoProductsImage from "./home/no-products.png"
/* INSURANCE ASSETS */
import BciLogo from "./insurance/logos/bci.png"
import ChilenaConsolidadaLogo from "./insurance/logos/chilena-consolidada.png"
import ConsorcioLogo from "./insurance/logos/consorcio.png"
import HdiLogo from "./insurance/logos/hdi.png"
import LibertyLogo from "./insurance/logos/liberty.png"
import CarTypeStepImage from "./insurance/steps/car-type-step.svg"
import EmailIcon from "./insurance/steps/email-channel.svg"
import CallIcon from "./insurance/steps/call-channel.svg"
import WhatsappIcon from "./insurance/steps/whatsapp-channel.svg"
import ContactChannelStepImage from "./insurance/steps/contact-channel-step.svg"
import UserSummaryStepImage from "./insurance/steps/user-summary-step.svg"
import CarSummaryStepImage from "./insurance/steps/car-summary-step.svg"
import IntroImage1 from "./insurance/intro/intro-1.svg"
import IntroImage2 from "./insurance/intro/intro-2.png"
import IntroImage3 from "./insurance/intro/intro-3.svg"
import IntroImage4 from "./insurance/intro/intro-4.png"
import IntroImage5 from "./insurance/intro/intro-5.png"

/* FUNCTION TO RETRIEVE ICONS PROGRAMATICALLY */
const getIcon = (name, color) => {
  const icon = MODEL_ICONS[name][color]
  if (!icon) console.log("ICON NOT FOUND:", name)
  return icon
}

export {
  /* MATERIAL-UI ICONS */
  CloseIcon,
  HelpIcon,
  NavigateBeforeIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  WhatsAppIcon,
  ShareIcon,
  CalendarTodayIcon,
  EmojiObjectsOutlinedIcon,
  InfoIcon,
  /* HOW DOES IT WORK ASSETS */
  HowDoesItWork1Image,
  HowDoesItWork2Image,
  HowDoesItWork3Image,
  /* APPRAISE ASSETS */
  appraiseStep0Image,
  appraiseFinalStepImage,
  carkeyBlackIcon,
  carkeyRedIcon,
  /* BRAND LOGOS FOR OFFERS */
  changanLogo,
  greatwallLogo,
  havalLogo,
  jacLogo,
  mazdaLogo,
  renaultLogo,
  suzukiLogo,
  /* BUTTONS ICONS (TO-BE REFACTORED) */
  compareIconBlack,
  compareIconPink,
  backIconPink,
  /* CHECKOUT ASSETS */
  checkoutFinalImage,
  checkoutPlateImage,
  checkoutFormImage,
  checkoutApprovedImage,
  checkoutRejectedImage,
  checkoutMoneyImage,
  checkoutDeliveryImage,
  checkoutAccessoriesImage,
  big1Image,
  big2Image,
  big3Image,
  big4Image,
  big5Image,
  big6Image,
  big7Image,
  completedIcon,
  pendingIcon,
  /* */
  fromDercoLogo,
  dcxLogo,
  dcxWhiteLogo,
  /* SEO ASSTES */
  seoImage,
  /* MISC ICONS */
  linkIcon,
  footPinkIcon,
  footBlackIcon,
  FaceStarsIcon,
  FacePartyIcon,
  ShareDialogImage1,
  ShareDialogImage2,
  whatsappLogo,
  FilterIcon,
  SuccessIcon,
  SmileStarEmoji,
  CoolEmoji,
  /* FEATS ICONS */
  absIconBlack,
  absIconWhite,
  acIconWhite,
  acIconBlack,
  airbagIconWhite,
  airbagIconBlack,
  babySeatIconBlack,
  babySeatIconWhite,
  carCheckIconBlack,
  carMaintenanceIconBlack,
  carplayIconBlack,
  carplayIconWhite,
  carWarrantyIconBlack,
  climatizerIconWhite,
  climatizerIconBlack,
  doubleSeatsIconWhite,
  doubleSeatsIconBlack,
  engineIconWhite,
  engineIconBlack,
  engineTurboIconBlack,
  engineTurboIconWhite,
  gasIconBlack,
  gasIconWhite,
  heightIconBlack,
  keylessIconWhite,
  lengthIconBlack,
  mirrorIconBlack,
  mirrorIconWhite,
  radioCommandIconWhite,
  safetyIconBlack,
  safetyIconWhite,
  screenIconBlack,
  screenIconWhite,
  seatsIconBlack,
  seatsIconWhite,
  sensorIconWhite,
  sensorIconBlack,
  speakerIconBlack,
  speakersIconWhite,
  sunroofIconWhite,
  tapestryIconWhite,
  trunkIconBlack,
  trunkIconWhite,
  usbIconWhite,
  usbIconBlack,
  weightIconBlack,
  wheelIconBlack,
  wheelIconWhite,
  widthIconBlack,
  DHAIconWhite,
  ESPIconWhite,
  ESPIconBlack,
  HASIconWhite,
  HASIconBlack,
  /* PAYMENT TYPES ICONS */
  paymentSmartBlueIcon,
  paymentSmartBlackIcon,
  paymentCreditBlueIcon,
  paymentCreditBlackIcon,
  paymentCashBlueIcon,
  paymentCashBlackIcon,
  /* THANKS PAGE ASSETS */
  thanksBadPaymentImage,
  thanksSuccessPaymentImage,
  thanksCallCenterImage,
  /* LANDING PAGE ASSETS */
  FrameCarImage,
  FrameLensImage,
  FrameSCrossImage,
  FrameSwiftImage,
  FrameWingleImage,
  SmarthponeImage,
  CloudImageMobile1,
  CloudImageMobile2,
  CloudImageDesktop,
  CloudImageDesktopBlurry1,
  CloudImageDesktopBlurry2,
  /* CATEGORIES ICONS*/
  FurgonIcon,
  HatchbackIcon,
  SedanIcon,
  SportIcon,
  SuvIcon,
  TruckIcon,
  VanIcon,
  /* HOME ICONS */
  ShortcutCardCashItem,
  ShortcutCardCreditItem,
  ShortcutCardAppraiseItem,
  ShortcutCardCompareItem,
  ShortcutCardInsuranceItem,
  NoProductsImage,
  /* INSURANCE ASSETS */
  BciLogo,
  ChilenaConsolidadaLogo,
  ConsorcioLogo,
  HdiLogo,
  LibertyLogo,
  CarTypeStepImage,
  EmailIcon,
  CallIcon,
  WhatsappIcon,
  ContactChannelStepImage,
  UserSummaryStepImage,
  CarSummaryStepImage,
  IntroImage1,
  IntroImage2,
  IntroImage3,
  IntroImage4,
  IntroImage5,
  /* FUNCTION TO RETRIEVE ICONS PROGRAMATICALLY */
  getIcon,
}

const MODEL_ICONS = {
  ac: {
    white: acIconWhite,
    black: acIconBlack,
  },
  absBrakes: {
    white: absIconWhite,
    black: absIconBlack,
  },
  airbags: {
    white: airbagIconWhite,
    black: airbagIconBlack,
  },
  babySeat: {
    white: babySeatIconWhite,
    black: babySeatIconBlack,
  },
  carplay: {
    white: carplayIconWhite,
    black: carplayIconBlack,
  },
  carMaintenance: {
    white: null,
    black: carMaintenanceIconBlack,
  },
  carCheck: {
    white: null,
    black: carCheckIconBlack,
  },
  carWarranty: {
    white: null,
    black: carWarrantyIconBlack,
  },
  climatizer: {
    white: climatizerIconWhite,
    black: climatizerIconBlack,
  },
  dobleSeat: {
    white: doubleSeatsIconWhite,
    black: doubleSeatsIconBlack,
  },
  engine: {
    white: engineIconWhite,
    black: engineIconBlack,
  },
  engineTurbo: {
    white: engineTurboIconWhite,
    black: engineTurboIconBlack,
  },
  gas: {
    white: gasIconWhite,
    black: gasIconBlack,
  },
  height: {
    white: null,
    black: heightIconBlack,
  },
  keyless: {
    white: keylessIconWhite,
    black: null,
  },
  length: {
    white: null,
    black: lengthIconBlack,
  },
  mirror: {
    white: mirrorIconWhite,
    black: mirrorIconBlack,
  },
  radioCommand: {
    white: radioCommandIconWhite,
    black: null,
  },
  safety: {
    white: safetyIconWhite,
    black: safetyIconBlack,
  },
  seats: {
    white: seatsIconWhite,
    black: seatsIconBlack,
  },
  screen: {
    white: screenIconWhite,
    black: screenIconBlack,
  },
  speaker: {
    white: speakersIconWhite,
    black: speakerIconBlack,
  },
  sensor: {
    white: sensorIconWhite,
    black: sensorIconBlack,
  },
  sunroof: {
    white: sunroofIconWhite,
    black: null,
  },
  tapestry: {
    white: tapestryIconWhite,
    black: null,
  },
  trunk: {
    white: trunkIconWhite,
    black: trunkIconBlack,
  },
  usb: {
    white: usbIconWhite,
    black: usbIconBlack,
  },
  wheelRadio: {
    white: wheelIconWhite,
    black: wheelIconBlack,
  },
  width: {
    white: null,
    black: widthIconBlack,
  },
  weight: {
    white: null,
    black: weightIconBlack,
  },
  DHA: {
    white: DHAIconWhite,
    black: null,
  },
  ESP: {
    white: ESPIconWhite,
    black: ESPIconBlack,
  },
  HAS: {
    white: HASIconWhite,
    black: HASIconBlack,
  },
}
