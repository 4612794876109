import React from "react"
import { IconButton } from "@material-ui/core"

type BrandOptionProps = {
  className?: string
  img: string
  onClick: () => void
}

export const BrandOption: React.FC<BrandOptionProps> = ({
  className,
  img,
  onClick,
}) => {
  return (
    <IconButton className={`brand-option ${className}`} onClick={onClick}>
      <img src={img} alt="Logo de la marca" />
    </IconButton>
  )
}
