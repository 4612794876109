import React, { useState, useCallback, useContext, useEffect } from "react"
import Popover from "@material-ui/core/Popover"
import Divider from "@material-ui/core/Divider"
import { InputForRut, InputForEmail, DCXButton, Frame } from ".."
import { saveSession } from "../../api"
import { CarContext } from "../../context"
import { useSpecialDevice, useAllModels } from "../../hooks"
import { getCurrentUrl, getSelectedModel } from "../../utils"
import { SuccessIcon, SmileStarEmoji } from "../../assets"

type SaveMenuProps = {
  anchorEl: HTMLElement | null
  onClose: () => void
}

export const SaveMenu: React.FC<SaveMenuProps> = ({
  anchorEl,
  onClose: handleClose,
}) => {
  const [allModels] = useAllModels()
  const { selectedVersion } = useContext(CarContext)

  const [loading, setLoading] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [rut, setRut] = useState("")
  const [rutHasError, setRutHasError] = useState(true)
  const [email, setEmail] = useState("")
  const [emailHasError, setEmailHasError] = useState(true)

  const [, specialDevice] = useSpecialDevice()

  const handleRutChange = useCallback(({ value, hasError }) => {
    setRut(value)
    setRutHasError(hasError)
  }, [])
  const handleEmailChange = useCallback(({ value, hasError }) => {
    setEmail(value)
    setEmailHasError(hasError)
  }, [])
  const handleSubmit = useCallback(async () => {
    setLoading(true)
    const response = await saveSession({
      rut,
      email,
      url: getCurrentUrl({ withSearch: true }),
      modelName: getSelectedModel(allModels, selectedVersion)?.name || "",
      specialDevice,
    })
    if (response) setRequestSuccess(true)
    else window.alert("Ocurrió un error al guardar")
    setLoading(false)
  }, [rut, email, specialDevice])

  const reset = useCallback(() => {
    setRequestSuccess(false)
  }, [])

  useEffect(() => {
    if (!requestSuccess) return
    const timer = setTimeout(() => {
      handleClose()
    }, 3000)

    return () => timer && clearTimeout(timer)
  }, [requestSuccess, handleClose])

  return (
    <Popover
      className="save-menu"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      onExited={reset}
    >
      <div className="save-menu__container">
        <div className={`save-menu__form ${requestSuccess ? "hidden" : ""}`}>
          <span role="img" aria-label="">
            <img src={SmileStarEmoji} alt="smile-icon" />
          </span>
          <h3>Guarda tus selecciones</h3>
          <Divider />
          <InputForRut
            className="save-menu__rut"
            name="savemenu-rut"
            onChange={handleRutChange}
            fullWidth
            validateEmptyString={false}
            label="RUT *"
          />
          <h6>Además, podemos enviártelo</h6>
          <InputForEmail
            helperText="Ups, parece no estar bien escrito 🧐"
            name={`savemenu-email`}
            className="save-menu__email"
            onChange={handleEmailChange}
            fullWidth
            validateEmptyString={false}
          />
          <DCXButton
            className="save-menu__send"
            text="ENVIAR"
            onClick={handleSubmit}
            type="primary"
            disabled={rutHasError || rut === "" || emailHasError || loading}
          />
        </div>
        <div
          className={`save-menu__success ${!requestSuccess ? "hidden" : ""}`}
        >
          <Frame image={SuccessIcon} desc="Configuración guardada con éxito" />
        </div>
      </div>
    </Popover>
  )
}
