import { getSelectedOptions, getVersionIdsBySelections } from "."

export const getColorCircleStyle = hexaString =>
  hexaString.includes(" ")
    ? {
        background: `linear-gradient(to bottom, 
          ${hexaString.split(" ")[1]} 0%, 
          ${hexaString.split(" ")[1]} 50%, 
          ${hexaString.split(" ")[0]} 50%, 
          ${hexaString.split(" ")[0]} 100%)`,
      }
    : { background: hexaString }

export const getSelectedColorName = (model, selections) => {
  if (!model) return null
  // Get model colornames
  const modelColorNames = model.colorGallery.map(item => item.colorName.name)
  const selectedColorName = selections?.color
    ? getSelectedOptions(model, selections)?.color?.name
    : modelColorNames[0]
  return selectedColorName
}

export const getAvailableColorNames = (versionIds, pricesStocks) => {
  // Only display color that have stock on any model's version
  const availableColorNames = []
  for (let vId of versionIds) {
    for (let cs of pricesStocks[String(vId)]?.colorStocks || []) {
      availableColorNames.push(cs.colorName)
    }
  }
  return Array.from(new Set(availableColorNames))
}

export const buildConfiguratorOptionsColors = (
  _validVersionIds,
  model,
  selections,
  pricesStocks
) => {
  if (!pricesStocks) return []
  const validVersionIds = getVersionIdsBySelections(
    _validVersionIds,
    model,
    { ...selections, color: null },
    pricesStocks
  )
  const availableColorNames = getAvailableColorNames(
    validVersionIds,
    pricesStocks
  )
  return availableColorNames
    .map(name => {
      const item = model.configOptions.colors.find(col => col.name === name)
      if (!item) {
        // console.log(
        //   "COLOR NOT MATCH:",
        //   name,
        //   "(from stocks gsheet)",
        //   model.configOptions.colors
        // )
        return null
      }
      return {
        style: getColorCircleStyle(item.hexa),
        displayName: item.colorSpanish,
      }
    })
    .filter(Boolean)
}

export const buildColorOptions = (model, selections, pricesStocks, context) => {
  if (!model || !context.selectedVersion || !pricesStocks) return null
  const modelVersionIds = model.versions.map(v => v.id)
  const modelAvailableVersionIds = Object.keys(pricesStocks).filter(vId =>
    modelVersionIds.includes(Number(vId))
  )

  // Only display color that have stock on any model's version
  const availableColorNames = getAvailableColorNames(
    modelAvailableVersionIds,
    pricesStocks
  )

  const validVersionIds = getVersionIdsBySelections(
    pricesStocks ? Object.keys(pricesStocks).map(Number) : [],
    model,
    { ...selections, color: null },
    pricesStocks
  )

  //console.log("validVersionIds", validVersionIds)
  //console.log("pricesStocks:", pricesStocks)
  return model.configOptions.colors
    .filter(item => availableColorNames.includes(item.name))
    .map(item => {
      // Find color on stocks
      const PS = Object.entries(pricesStocks)
        .filter(entry => validVersionIds.includes(Number(entry[0])))
        .find(entry =>
          entry[1].colorStocks.map(y => y.colorName).includes(item.name)
        )
      const colorStock =
        PS && PS[1].colorStocks.find(cs => cs.colorName === item.name)

      return {
        info: {
          id: item.id,
          ...colorStock,
          hexa: item.hexa,
          displayName: item.colorSpanish,
          versionIds: [Number(PS ? PS[0] : 0)],
        },
        selected: selections.color === item.id,
        style: getColorCircleStyle(item.hexa),
        disabled: !colorStock,
      }
    })
}
