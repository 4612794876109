/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect } from "react"
import { Dialog } from "@material-ui/core"
import { Payment } from "../types"
// import React, { useCallback, useContext, useState } from "react"
// import { Dialog, IconButton } from "@material-ui/core"
// import { CloseIcon } from "../assets"
import { CarContext } from "../context"
import { useUpdateStats, UpdateStatType } from "../hooks"

type Props = {
  payment: string
  open: boolean
  closeCreditVideo: () => void // TODO: set useState type
}

enum VideoResolution {
  "small" = 480,
  "medium" = 720,
  "large" = 1080,
}

enum WindowWidthBoundary {
  "small" = 610,
  "medium" = 1080,
}

// enum ExitBehavior {
//   "play",
//   "mouse",
// }

const URL_BASE = "https://dercocenterx.s3.us-east-2.amazonaws.com/Videos"

const getVideoUrl = (creditType: string, res: VideoResolution): string => {
  return `${URL_BASE}/credito-${creditType}_${String(res)}.mp4`
}

export const CreditVideoModal: React.FC<Props> = ({
  payment,
  open,
  closeCreditVideo,
}) => {
  const { windowWidth } = useContext(CarContext)
  const [updateCreditVideo] = useUpdateStats(
    "creditVideo-traditionalCredit",
    UpdateStatType.CALLBACK
  )
  const [updateFlexibleCreditVideo] = useUpdateStats(
    "creditVideo-flexibleCredit",
    UpdateStatType.CALLBACK
  )
  // const isMobile = windowWidth < 610
  // const [showExit, setShowExit] = useState<boolean>(isMobile ? true : false)

  // const handleShowExitButton = useCallback(
  //   (behavior: ExitBehavior) => () => {
  //     if (behavior === ExitBehavior.play && isMobile) {
  //       setShowExit(prev => !prev)
  //     }
  //     if (behavior === ExitBehavior.mouse && !isMobile) {
  //       setShowExit(prev => !prev)
  //     }
  //   },
  //   [setShowExit]
  // )

  const creditType = payment === Payment.credit ? "convencional" : "flexible"
  useEffect(() => {
    if (!open) return

    if (payment === Payment.credit) updateCreditVideo()
    else updateFlexibleCreditVideo()
  }, [payment, open])

  return (
    <Dialog
      className="credit-video-modal"
      open={open}
      onClose={closeCreditVideo}
    >
      {/* <IconButton
        className={`close-video-button ${!showExit ? "hidden" : ""}`}
        size="medium"
        onClick={closeCreditVideo}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton> */}
      <video
        className="credit-video small-res"
        controls
        autoPlay={windowWidth < WindowWidthBoundary.small ? true : false}
        // onPlay={handleShowExitButton(ExitBehavior.play)}
        // onPause={handleShowExitButton(ExitBehavior.play)}
        // onMouseLeave={handleShowExitButton(ExitBehavior.mouse)}
        // onMouseEnter={handleShowExitButton(ExitBehavior.mouse)}
      >
        <source
          src={getVideoUrl(creditType, VideoResolution.small)}
          type="video/mp4"
        ></source>
      </video>
      <video
        className="credit-video medium-res"
        controls
        autoPlay={
          windowWidth >= WindowWidthBoundary.small &&
          windowWidth < WindowWidthBoundary.medium
            ? true
            : false
        }
        // onPlay={handleShowExitButton(ExitBehavior.play)}
        // onPause={handleShowExitButton(ExitBehavior.play)}
        // onMouseLeave={handleShowExitButton(ExitBehavior.mouse)}
        // onMouseEnter={handleShowExitButton(ExitBehavior.mouse)}
      >
        <source
          src={getVideoUrl(creditType, VideoResolution.medium)}
          type="video/mp4"
        ></source>
      </video>
      <video
        className="credit-video large-res"
        controls
        autoPlay={windowWidth >= WindowWidthBoundary.medium ? true : false}
        // onPlay={handleShowExitButton(ExitBehavior.play)}
        // onPause={handleShowExitButton(ExitBehavior.play)}
        // onMouseLeave={handleShowExitButton(ExitBehavior.mouse)}
        // onMouseEnter={handleShowExitButton(ExitBehavior.mouse)}
      >
        <source
          src={getVideoUrl(creditType, VideoResolution.large)}
          type="video/mp4"
        ></source>
      </video>
    </Dialog>
  )
}
