import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForRut } from "../../reusables"
import { Insurance, OnChange } from "../../../types"

type Props = {
  rut: string
  onChange: OnChange
}

const BusinessRutStepComponent: React.FC<Props> = ({ rut, onChange }) => {
  return (
    <QuestionTemplate title="¿Cuál es el RUT de la empresa?">
      <div className="insurance-drawer-step-content">
        <InputForRut
          name={Insurance.rut}
          value={rut}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}

export const BusinessRutStep = memo(BusinessRutStepComponent)
