import React, { useRef, useState, useEffect } from "react"

type SwipableScrollResults = {
  swipableViewRef: React.MutableRefObject<null>
  haveScroll: boolean
  hasScrolled: boolean
}

type Props = {
  step: number
  threshold?: number
}

export const useSwipableScroll = ({
  step,
  threshold = 0,
}: Props): SwipableScrollResults => {
  const swipableViewRef = useRef(null)
  const [haveScroll, setHaveScroll] = useState<boolean>(false)
  const [hasScrolled, setHasScrolled] = useState<boolean>(false)

  useEffect(() => {
    if (!swipableViewRef.current) return
    //Check if step view have scroll
    const stepView =
      swipableViewRef.current?.rootNode?.firstChild?.childNodes[step]
    if (!stepView) return
    setHaveScroll(stepView.scrollHeight - stepView.clientHeight > 0)

    //Set initial scroll position
    setHasScrolled(stepView.scrollTop > threshold)

    //----------------Scroll listener-----------------
    const scrollCallback = e => {
      setHasScrolled(e.target.scrollTop > threshold)
    }
    stepView.addEventListener("scroll", scrollCallback)
    //------------------------------------------------
    //-------------Window resize listener-------------
    const resizeCallback = e => {
      const view =
        swipableViewRef.current?.rootNode?.firstChild?.childNodes[step]
      if (!view) return
      setHaveScroll(view.scrollHeight - view.clientHeight > 0)
    }
    window.addEventListener("resize", resizeCallback)
    //-----------------------------------------------

    return () => {
      stepView.removeEventListener("scroll", scrollCallback)
      window.removeEventListener("resize", resizeCallback)
    }
  }, [swipableViewRef.current, step, setHasScrolled, threshold])

  return { swipableViewRef, haveScroll, hasScrolled }
}
