import React, { useState, useCallback } from "react"
import ClickAwayListener from "react-click-away-listener"
import { IconButton } from "@material-ui/core"
import { DCXButton, ShareDialog } from "."
import { useSpecialDevice } from "../../hooks"
import { shareWhatsapp, getCurrentUrlOnMainSiteForSharing } from "../../utils"
import { WhatsAppIcon, ShareIcon, linkIcon, FaceStarsIcon } from "../../assets"

export function ShareButton({
  className,
  mobile,
  url,
  messageType,
  modelName,
}) {
  const [open, setOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [shareDialogOpened, setShareDialogOpened] = useState(false)
  const [forStore] = useSpecialDevice()

  const URL = url || (typeof window !== "undefined" && window.location.href)

  const copyClipboard = () => {
    try {
      navigator.clipboard
        .writeText(URL)
        .then(() => {
          setCopied(true)
          setTimeout(() => {
            setCopied(false)
            setOpen(false)
          }, 2000)
        })
        .catch(err => {
          // This can happen if the user denies clipboard permissions:
          alert("Error al copiar")
        })
    } catch (error) {
      alert("Opcion no habilitada por el momento")
    }
  }

  const displayDialog =
    forStore || (typeof window !== "undefined" && window.innerWidth > 810)

  const onClickCallback = useCallback(() => {
    if (displayDialog) setShareDialogOpened(s => !s)
    else setOpen(s => !s)
  }, [displayDialog, setShareDialogOpened, setOpen])

  const ButtonComponent = mobile ? (
    <IconButton className="share-button" onClick={onClickCallback}>
      <ShareIcon />
    </IconButton>
  ) : (
    <DCXButton
      className="share-button"
      type="tertiary"
      icon="share"
      text="COMPARTIR"
      onClick={onClickCallback}
    />
  )

  return displayDialog ? (
    <>
      <div className={`share-button-component ${className || ""}`}>
        <div className="share-header">{ButtonComponent}</div>
        <ShareDialog
          disableBackdrop
          emojiSrc={FaceStarsIcon}
          mainText="Comparte tu auto soñado"
          qrUrl={getCurrentUrlOnMainSiteForSharing({ withSearch: true })}
          mailingTemplate="model"
          open={shareDialogOpened}
          setState={setShareDialogOpened}
          PaperProps={{
            style: {
              position: "fixed",
              top: "85px",
              right: "110px",
              // height: "auto",
              width: "700px",
            },
          }}
        />
      </div>
    </>
  ) : (
    <ClickAwayListener
      className={`share-button-component ${className || ""}`}
      onClickAway={() => setOpen(false)}
    >
      <div className="share-header">
        {ButtonComponent}

        <div className={`share-bubble ${open ? "show" : ""}`}>
          <div
            className="share-list"
            role="button"
            tabIndex={0}
            onClick={() =>
              shareWhatsapp({
                URL,
                messageType,
                modelName,
                callback: () => setOpen(false),
              })
            }
            onKeyPress={e =>
              e.key === "Enter" &&
              shareWhatsapp({
                URL,
                messageType,
                modelName,
                callback: () => setOpen(false),
              })
            }
          >
            <div className="icon">
              <WhatsAppIcon />
            </div>
            <div className="text">Compartir por Whatsapp</div>
          </div>
          <div
            className="share-list"
            role="button"
            tabIndex={0}
            onClick={copyClipboard}
            onKeyPress={e => e.key === "Enter" && copyClipboard()}
          >
            <img className="icon" src={linkIcon} alt="ícono"></img>
            <div className="text">
              {copied ? "Link copiado" : "Copiar enlace para compartir"}
            </div>
          </div>
        </div>
      </div>
    </ClickAwayListener>
  )
}
