import React, { memo } from "react"
import { RutGenderStep, BusinessRutStep } from "../steps"
import { Insurance, OnChange, Owner, InsuranceData } from "../../../types"

type Props = {
  insuranceData: InsuranceData
  onChange: OnChange
}

export const RutStepWrapper: React.FC<Props> = memo(
  ({ insuranceData, onChange }) => {
    return insuranceData[Insurance.owner] !== Owner.business ? (
      <RutGenderStep
        gender={insuranceData[Insurance.gender]}
        rut={insuranceData[Insurance.rut]}
        owner={insuranceData[Insurance.owner]}
        onChange={onChange}
      />
    ) : (
      <BusinessRutStep rut={insuranceData[Insurance.rut]} onChange={onChange} />
    )
  },
  (prev, next) => {
    const change = prev.onChange === next.onChange
    const gender = prev.insuranceData.gender === next.insuranceData.gender
    const rut = prev.insuranceData.rut === next.insuranceData.rut
    const owner = prev.insuranceData.owner === next.insuranceData.owner
    return change && gender && rut && owner
  }
)
