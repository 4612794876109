import { useContext, useEffect, useState } from "react"
import { CarContext } from "../context"
import { checkUrl } from "../utils"

export const useSurchargeAmount = (): [number] => {
  const { financeData } = useContext(CarContext)

  const [surchargeAmount, setSurchargeAmount] = useState(0)

  useEffect(() => {
    if (financeData && !checkUrl(["home", "model", "comparador"])) {
      setSurchargeAmount(financeData.otherParams.mazdaColorsSurcharge)
    }
  }, [financeData])

  return [surchargeAmount]
}
