import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { StepProps } from "."
import { InputForPlate, DCXButton } from ".."
import { getDataPlate, getVersions } from "../../api"
import { appraiseStep0Image } from "../../assets"

const recaptchaRef = React.createRef()

export const AppraiseStep0: React.FC<StepProps> = ({
  form,
  updateForm,
  toggleLoading,
  handleChange,
}) => {
  const finishStep = async () => {
    toggleLoading()
    try {
      const cleanPlate = form.plate.replace(/·/g, "")
      if (cleanPlate.length !== 6) return
      // Decide if its necessary to fetch the info
      if (!form.info.carInfo || form.info.carInfo.plate !== cleanPlate) {
        const carInfo = await getDataPlate(cleanPlate)
        const versions = await getVersions(carInfo?.model_id, carInfo?.year)
        if (versions.length <= 2)
          throw new Error("Versiones no encontradas para esta patente")
        updateForm(prev => ({
          ...prev,
          brand: undefined,
          model: undefined,
          year: undefined,
          info: {
            ...prev.info,
            carInfo: { ...(carInfo || {}), plate: cleanPlate },
            versions: versions
              .slice(0, -2)
              .map(v => ({ value: v.id, label: v.name })),
            plateFallback: false,
          },
        }))
      }
      form.actions.nextStep()
    } catch (e) {
      updateForm(prev => ({
        ...prev,
        info: {
          ...prev.info,
          plateFallback: true,
        },
      }))
      form.actions.nextStep(false)
    }
    toggleLoading()
  }

  // Disable if there is any error or there is any null/empty value
  const shouldDisableButton = form.errors.plate || !form.plate

  return (
    <>
      <div className="info">
        <img src={appraiseStep0Image} alt=""></img>
      </div>
      <div className="title">¿Tienes un auto en parte de pago?</div>
      <div className="subtitle">Necesitamos tu patente</div>
      <div className="component">
        <InputForPlate
          name="plate"
          value={form.plate}
          onChange={handleChange}
          onEnterPress={() => !shouldDisableButton && finishStep()}
        />
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
        size="invisible"
        onChange={null}
      />

      <div className="buttons">
        <DCXButton
          id="finish-step-0-search-plate"
          type="primary"
          text="BUSCAR PATENTE"
          disabled={shouldDisableButton}
          onClick={async () => {
            await finishStep()
            //async () => {const token = await recaptchaRef.current.execute()}
          }}
        />
      </div>
    </>
  )
}
