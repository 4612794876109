import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForNames, InputForLastnames } from "../../reusables/inputs"
import { Insurance, OnChange, Owner } from "../../../types"

type Props = {
  name: string
  lastname: string
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿Cómo te llamas?",
  [Owner.other]: "¿Cómo se llama esa persona?",
  [Owner.business]: "",
}

const NameStepComponent: React.FC<Props> = ({
  name,
  lastname,
  owner,
  onChange,
}) => {
  // Validation not needed. Handled by custom inputs.
  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content insurance-name-step">
        <InputForNames
          name={Insurance.name}
          value={name}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
        <InputForLastnames
          name={Insurance.lastname}
          value={lastname}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}

export const NameStep = memo(NameStepComponent)
