import React, { useContext } from "react"
import { AppBar, Toolbar } from "@material-ui/core"
import { PaymentSelector, DuesSelector, PercentageSelector } from "."
import { CarContext, SearchContext } from "../context"
import { Payment } from "../types"

export const FinancingHeader = React.forwardRef((_, ref) => {
  const {
    payment,
    setPayment,
    dues,
    setDues,
    percentage,
    setPercentage,
  } = useContext(CarContext)
  const { activeModelSearch } = useContext(SearchContext)

  return (
    <AppBar
      ref={ref}
      className={`financing-header ${activeModelSearch ? "active-search" : ""}`}
      position="sticky"
    >
      <Toolbar variant="dense">
        <PaymentSelector
          payment={payment}
          setPayment={setPayment}
          dues={dues}
          setDues={setDues}
          disableUnderline
        />

        <DuesSelector
          className={payment === Payment.cash ? "hide" : ""}
          payment={payment}
          dues={dues}
          setDues={setDues}
          disableUnderline
        />

        <PercentageSelector
          className={payment === Payment.cash ? "hide" : ""}
          payment={payment}
          percentage={percentage}
          setPercentage={setPercentage}
          disableUnderline
        />
      </Toolbar>
    </AppBar>
  )
})
