import React from "react"
import { FormData } from "."
import { carkeyRedIcon } from "../../assets"
import { parseNumber } from "../../utils"

type Props = {
  data: FormData
}

export const CarInfo: React.FC<Props> = ({ data }) => {
  const { plate, kms } = data
  const vers = getVersionName(data)
  const year = getYear(data)
  return (
    <div className="info">
      <div className="car-keys">
        <img src={carkeyRedIcon} alt="" />
      </div>
      <div className="upper-text">Tu auto es un</div>
      <div className="car-name">
        {getBrandName(data) + " " + getModelName(data)}
      </div>
      <div className="car-desc">
        {plate} | {year ? year + " | " : null}
        {vers ? vers + " | " : null}
        {kms ? parseNumber(kms) + " Kms" : null}
      </div>
      <div
        className="error"
        id="its-not-my-car"
        role="button"
        tabIndex={0}
        onClick={data.actions.notMyCar}
        onKeyDown={e => e.key === "Enter" && data.actions.notMyCar()}
      >
        Cambiar modelo
      </div>
    </div>
  )
}

const getBrandName = (data: FormData) => {
  return (
    (data.brand
      ? data.info.brands?.find(v => v.value === data.brand)?.label
      : data.info.carInfo?.brand_name) || ""
  )
}

const getModelName = (data: FormData) => {
  return (
    (data.model
      ? data.info.models?.find(v => v.value === data.model)?.label
      : data.info.carInfo?.model_name) || ""
  )
}

const getVersionName = (data: FormData) => {
  if (!data.version) return null
  return data.info.versions?.find(v => v.value === data.version)?.label
}

const getYear = (data: FormData) => {
  if (!data) return null
  return data.year || data.info.carInfo?.year
}
