import React, { useState, useContext, useEffect, useCallback } from "react"
import { navigate } from "gatsby"
import { DCXButton } from ".."
import { OptionEquipment, OptionTriplet, OptionColor } from "../options"
import { CarContext, UIContext } from "../../context"
import {
  CalculatePriceFull,
  urlWithSession,
  getMinimunPrices,
  buildColorOptions,
  infoToSessionString,
  capitalize,
  getSelectedOptions,
  getVersionName,
  buildConfiguratorOptionsColors,
  getVersionIdsBySelections,
} from "../../utils"
import { useSurchargeAmount } from "../../hooks"

const isColorPremium = ({
  model,
  colorId,
  modelsPricesStocks,
  selectedVersionId,
}) => {
  const colorName = model?.configOptions.colors.find(opt => opt.id === colorId)
    .name
  return Boolean(
    modelsPricesStocks[selectedVersionId]?.colorStocks.find(
      color => color.colorName === colorName
    )?.colorSurcharge
  )
}
const getSurchargedPrices = ({
  model,
  prices,
  surchargeAmount,
  colorId,
  modelsPricesStocks,
  selectedVersionId,
}) => {
  const isPremium = isColorPremium({
    model,
    colorId,
    modelsPricesStocks,
    selectedVersionId,
  })
  return isPremium
    ? {
        ...prices,
        priceList: prices.priceList + surchargeAmount,
        priceCash: prices.priceCash + surchargeAmount,
        priceCredit: prices.priceCredit + surchargeAmount,
      }
    : prices
}

export function OptionsViewConfig({
  model,
  selections,
  pricesStocks,
  appContext,
}) {
  // console.log("_______OPTIONS VIEW COMPONENT_________")
  // console.log("MODEL:", model)
  // console.log("BUILDED MODEL:", model)
  // console.log("SELECTIONS:", selections)
  // console.log("OPTIONS:", model.configOptions)
  // console.log("PRICES STOCKS:", pricesStocks)
  // console.log("SELECTED VERSION:", context.selectedVersion)
  // console.log("CONTEXT:", context)
  // console.log("________________")

  const { selectedVersion } = useContext(CarContext)
  const { openDetailsDrawer } = useContext(UIContext)

  const isMobile = appContext.windowWidth < 810

  /* Need to know which section has default values, to scroll properly */
  const [defaultSelections, setDefaultSelections] = useState({
    transmission: false,
    equipment: false,
    engine: false,
    traction: false,
    color: false,
  })

  const [options, setOptions] = useState({
    transmissionOptions: [],
    equipmentOptions: [],
    engineOptions: [],
    tractionOptions: [],
    colorOptions: [],
  })

  const [surchargeAmount] = useSurchargeAmount()

  useEffect(() => {
    const {
      transmissionOptions,
      equipmentOptions,
      engineOptions,
      tractionOptions,
      colorOptions,
    } = buildOptions(
      model,
      selections,
      pricesStocks,
      appContext,
      openDetailsDrawer,
      isMobile,
      defaultSelections,
      setDefaultSelections,
      surchargeAmount
    )
    setOptions({
      transmissionOptions,
      equipmentOptions,
      engineOptions,
      tractionOptions,
      colorOptions,
    })
  }, [
    model,
    selections,
    pricesStocks,
    appContext,
    openDetailsDrawer,
    isMobile,
    defaultSelections,
    setDefaultSelections,
    setOptions,
    surchargeAmount,
  ])

  const {
    transmission,
    equipment,
    engine,
    traction,
    color,
  } = getSelectedOptions(model, selections)

  /* Get final button props, that depends on selections
    (disabled when there are sections unselected or an out-of-stock option was selected) 
  */
  const { outOfStockSelected, disabledButton, buttonText } = getButtonProps({
    transmission,
    equipment,
    engine,
    traction,
    color,
    options,
  })
  const versionName = getVersionName(model, selectedVersion?.versionId)

  // Generator for option click callbacks
  const onClickOptionCallbackGenerator = useCallback(
    (section, option) => () =>
      onClickOption(
        section,
        option,
        selections,
        appContext,
        isMobile,
        defaultSelections
      ),
    [selections, appContext, isMobile, defaultSelections]
  )

  return (
    <div className="config-options-model">
      <div className="title-config-model">
        <h2>
          Arma tu <br></br>
          {model.name}
        </h2>
      </div>
      <div className="section" id="transmission">
        <div className="header-section-config-option">
          <div className="title-section">
            <h3>Elige tu caja de cambio</h3>
          </div>
        </div>
        <div className="options">
          {options.transmissionOptions.map((opt, idx) => (
            <OptionTriplet
              key={idx}
              option={opt}
              onClick={onClickOptionCallbackGenerator("transmission", opt)}
            />
          ))}
        </div>
      </div>

      {options.equipmentOptions && (
        <div
          className={`section ${transmission ? "" : "disable"}`}
          id="equipment"
        >
          <div className="header-section-config-option">
            <div className="title-section">
              <h3>Elige tu equipamiento adicional</h3>
            </div>
          </div>
          <div className="options">
            {options.equipmentOptions.map((opt, idx) => (
              <OptionEquipment
                key={idx}
                option={opt}
                onClick={onClickOptionCallbackGenerator("equipment", opt)}
                openDetailsDrawer={openDetailsDrawer}
                alwaysExpanded={options?.equipmentOptions?.length === 1}
              />
            ))}
          </div>
        </div>
      )}

      {options.engineOptions && (
        <div
          className={`section ${transmission && equipment ? "" : "disable"}`}
          id="engine"
        >
          <div className="header-section-config-option">
            <div className="title-section">
              <h3>Elige tu motor</h3>
            </div>
          </div>
          <div className="options">
            {options.engineOptions.map((opt, idx) => (
              <OptionTriplet
                key={idx}
                option={opt}
                onClick={onClickOptionCallbackGenerator("engine", opt)}
              />
            ))}
          </div>
        </div>
      )}

      {options.tractionOptions && (
        <div
          className={`section ${
            transmission && equipment && engine ? "" : "disable"
          }`}
          id="traction"
        >
          <div className="header-section-config-option">
            <div className="title-section">
              <h3>Elige la tracción</h3>
            </div>
          </div>
          <div className="options">
            {options.tractionOptions.map((opt, idx) => (
              <OptionTriplet
                key={idx}
                option={opt}
                onClick={onClickOptionCallbackGenerator("traction", opt)}
              />
            ))}
          </div>
        </div>
      )}

      <div
        className={`section ${
          transmission && equipment && engine && traction ? "" : "disable"
        }`}
        id="color"
      >
        <div className="header-section-config-option">
          <div className="title-section">
            <h3>Elige tu color</h3>
          </div>
        </div>
        <div
          className={`options options-color ${
            outOfStockSelected ? "no-options" : ""
          }`}
        >
          <div
            className={`no-color-message ${!outOfStockSelected ? "hide" : ""}`}
          >
            Los colores de esta configuración están agotados
          </div>
          {!outOfStockSelected &&
            options.colorOptions?.map((opt, idx) => (
              <OptionColor
                key={idx}
                option={opt}
                onClick={onClickOptionCallbackGenerator("color", opt)}
                displayPriceSection={opt.shouldDisplayPricesOnOptions}
              />
            ))}
        </div>
      </div>

      <div className="section centered">
        <div className="header-section-config-option">
          <div className="title-section">
            <h3>Esta selección corresponde a</h3>
          </div>
        </div>
        <div className="model-version">
          <div className="text">
            {model.name} {versionName}
            {color ? ", " + color.colorSpanish : ""}
          </div>
          {equipment?.origin && (
            <div className="origin">Origen: {equipment.origin.value}</div>
          )}
        </div>
        {!model.partialUpload && (
          <DCXButton
            id="open-details-drawer-button-1"
            type="tertiary"
            text="CARACTERÍSTICAS DEL AUTO"
            arrow="right"
            onClick={openDetailsDrawer}
          />
        )}
      </div>

      <div className="buttons-section">
        <DCXButton
          className={`next-button ${!disabledButton ? "enabled" : ""}`}
          text={buttonText}
          type="primary"
          disabled={disabledButton}
          onClick={() => navigate(urlWithSession("/checkout/accessories"))}
        />
      </div>
    </div>
  )
}

const getButtonProps = ({
  transmission,
  equipment,
  engine,
  traction,
  color,
  options,
}) => {
  // Determine if any out of stock option was selected, in order to change final button props
  const outOfStockSelected =
    Boolean(
      options.transmissionOptions?.find(opt => opt.selected && opt.outOfStock)
    ) ||
    Boolean(
      options.equipmentOptions?.find(opt => opt.selected && opt.outOfStock)
    ) ||
    Boolean(
      options.engineOptions?.find(opt => opt.selected && opt.outOfStock)
    ) ||
    Boolean(
      options.tractionOptions?.find(opt => opt.selected && opt.outOfStock)
    )

  const missingSelectedSections = !(
    transmission &&
    equipment &&
    engine &&
    traction &&
    color
  )
  return {
    outOfStockSelected,
    buttonText: outOfStockSelected ? "AGOTADO" : "SIGUIENTE",
    disabledButton: outOfStockSelected || missingSelectedSections,
    tooltipText:
      !outOfStockSelected && missingSelectedSections
        ? "Debe escoger " +
          (!transmission
            ? "una transmisión"
            : !equipment
            ? "un equipamiento"
            : !engine
            ? "un motor"
            : !traction
            ? "una tracción"
            : !color
            ? "un color"
            : "")
        : null,
  }
}

const buildTransmissionOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  isMobile,
  defaultSelections
) => {
  const processedOptions = model.configOptions.transmissions.map(item => {
    // Find valid versions ids based on stocks
    const validVersionIds = item.versionIds.filter(
      vId => pricesStocks && Object.keys(pricesStocks).includes(String(vId))
    )
    // Find chepeast version and prices
    const prices = getMinimunPrices(pricesStocks, validVersionIds, {
      allowOutOfStock: true,
    })

    const unavailable = validVersionIds.length === 0
    const outOfStock =
      !unavailable &&
      validVersionIds
        .map(vId =>
          getMinimunPrices(pricesStocks, [vId], {
            allowOutOfStock: true,
          })
        )
        .every(price => price.outOfStock)

    return {
      info: item,
      selected: selections.transmission === item.id,
      unavailable,
      outOfStock,
      colors:
        !unavailable && !outOfStock
          ? buildConfiguratorOptionsColors(
              validVersionIds,
              model,
              {},
              pricesStocks
            )
          : null,
      prices,
      minFin: !unavailable ? CalculatePriceFull({ appContext, prices }) : null,
      validVersionIds,
    }
  })
  // console.log("TRANSMISSION OPTIONS:", processedOptions)

  // Save unique option and hide section
  if (processedOptions.length === 1) {
    !selections.transmission &&
      saveDefaultOption(
        "transmission",
        processedOptions[0],
        selections,
        appContext
      )
    //return null
  }
  return processedOptions.sort(
    (a, b) => a.minFin?.monthlyPrice - b.minFin?.monthlyPrice
  )
}

const buildPackOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  openDetailsDrawer,
  isMobile,
  defaultSelections
) => {
  const processedOptions = model.configOptions.packs.map(item => {
    // Find valid versions ids based on previous selections AND STOCKS
    const { transmission } = getSelectedOptions(model, selections)
    const validVersionIds = item.versionIds.filter(
      vId =>
        pricesStocks &&
        Object.keys(pricesStocks).includes(String(vId)) &&
        transmission?.versionIds.includes(vId)
    )
    const prices = getMinimunPrices(pricesStocks, validVersionIds, {
      allowOutOfStock: true,
    })
    const unavailable = validVersionIds.length === 0
    const outOfStock =
      !unavailable &&
      validVersionIds
        .map(vId =>
          getMinimunPrices(pricesStocks, [vId], {
            allowOutOfStock: true,
          })
        )
        .every(price => price.outOfStock)

    return {
      info: item,
      isFirstPack: !Boolean(item.previousEquip),
      selected: selections.equipment === item.id,
      unavailable,
      outOfStock,
      colors:
        !unavailable && !outOfStock
          ? buildConfiguratorOptionsColors(
              validVersionIds,
              model,
              {},
              pricesStocks
            )
          : null,
      prices,
      minFin: !unavailable ? CalculatePriceFull({ appContext, prices }) : null,
      validVersionIds,
    }
  })
  // Save unique option and hide section
  if (processedOptions.length === 1) {
    !selections.equipment &&
      saveDefaultOption(
        "equipment",
        processedOptions[0],
        selections,
        appContext
      )
    // return null
  }
  // console.log("PACK OPTIONS:", processedOptions)
  return processedOptions
}

const buildEngineOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  isMobile,
  defaultSelections
) => {
  const processedOptions = model.configOptions.engines.map(item => {
    // Find valid versions ids based on previous selections AND STOCKS
    const { transmission, equipment } = getSelectedOptions(model, selections)
    const validVersionIds = item.versionIds.filter(
      vId =>
        pricesStocks &&
        Object.keys(pricesStocks).includes(String(vId)) &&
        transmission?.versionIds.includes(vId) &&
        equipment?.versionIds.includes(vId)
    )
    const prices = getMinimunPrices(pricesStocks, validVersionIds, {
      allowOutOfStock: true,
    })

    const unavailable = validVersionIds.length === 0
    const outOfStock =
      !unavailable &&
      validVersionIds
        .map(vId =>
          getMinimunPrices(pricesStocks, [vId], {
            allowOutOfStock: true,
          })
        )
        .every(price => price.outOfStock)

    return {
      info: item,
      selected: selections.engine === item.id,
      unavailable,
      outOfStock,
      colors:
        !unavailable && !outOfStock
          ? buildConfiguratorOptionsColors(
              validVersionIds,
              model,
              {},
              pricesStocks
            )
          : null,
      prices,
      minFin: !unavailable ? CalculatePriceFull({ appContext, prices }) : null,
      validVersionIds,
    }
  })

  // Save unique option
  if (processedOptions.length === 1) {
    !selections.engine &&
      saveDefaultOption("engine", processedOptions[0], selections, appContext)
    //return null
  }
  //console.log("ENGINE OPTIONS:", processedOptions)
  return processedOptions.sort(
    (a, b) => a.minFin?.monthlyPrice - b.minFin?.monthlyPrice
  )
}

const buildTractionOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  isMobile,
  defaultSelections
) => {
  const processedOptions = model.configOptions.tractions.map(item => {
    // Find valid versions ids based on previous selections and stocks
    const { transmission, equipment, engine } = getSelectedOptions(
      model,
      selections
    )
    const validVersionIds = item.versionIds.filter(
      vId =>
        pricesStocks &&
        Object.keys(pricesStocks).includes(String(vId)) &&
        transmission?.versionIds.includes(vId) &&
        equipment?.versionIds.includes(vId) &&
        engine?.versionIds.includes(vId)
    )
    const prices = getMinimunPrices(pricesStocks, validVersionIds, {
      allowOutOfStock: true,
    })

    const unavailable = validVersionIds.length === 0
    const outOfStock =
      !unavailable &&
      validVersionIds
        .map(vId =>
          getMinimunPrices(pricesStocks, [vId], {
            allowOutOfStock: true,
          })
        )
        .every(price => price.outOfStock)

    return {
      info: item,
      selected: selections.traction === item.id,
      unavailable,
      outOfStock,
      colors:
        !unavailable && !outOfStock
          ? buildConfiguratorOptionsColors(
              validVersionIds,
              model,
              {},
              pricesStocks
            )
          : null,
      prices,
      minFin: !unavailable ? CalculatePriceFull({ appContext, prices }) : null,
      validVersionIds,
    }
  })
  // Save unique option and hide section
  if (processedOptions.length === 1) {
    !selections.traction &&
      saveDefaultOption("traction", processedOptions[0], selections, appContext)
    return null
  }
  //console.log("TRACTION OPTIONS:", processedOptions)
  return processedOptions.sort(
    (a, b) => a.minFin?.monthlyPrice - b.minFin?.monthlyPrice
  )
}

const generateColorOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  surchargeAmount
) => {
  const options = buildColorOptions(
    model,
    selections,
    pricesStocks,
    appContext
  )?.filter(opt => !opt.disabled)

  const allPreviousOptionsSelected =
    Boolean(selections.transmission) &&
    Boolean(selections.equipment) &&
    Boolean(selections.engine) &&
    Boolean(selections.traction)

  const anyColorWithSurcharge = options
    ?.filter(opt => !opt.disabled)
    .some(opt => !opt.disabled && opt.info.colorSurcharge)

  const validVersionIdsBasedOnSelections = getVersionIdsBySelections(
    model.versions.map(v => v.id),
    model,
    { ...selections, color: null },
    pricesStocks
  ).filter(
    id =>
      !getMinimunPrices(pricesStocks, [id], { allowOutOfStock: true })
        ?.outOfStock
  )

  /* Display prices when
      a) the color is the last section to be selected
      b) there is any color not disabled with surcharge
      c) there are more than 1 version available for the previous selections (JAC S2 normal & "bicolor")
  */
  const shouldDisplayPricesOnOptions =
    allPreviousOptionsSelected &&
    (anyColorWithSurcharge || validVersionIdsBasedOnSelections.length > 1)

  return options
    ?.map(opt => {
      const validVersionIds = getVersionIdsBySelections(
        opt.info.versionIds,
        model,
        { ...selections, color: null },
        pricesStocks
      )
      const basePrices = getMinimunPrices(pricesStocks, validVersionIds)
      const prices = getSurchargedPrices({
        model,
        prices: basePrices,
        surchargeAmount,
        colorId: opt.info.id,
        modelsPricesStocks: appContext.modelsPricesStocks,
        selectedVersionId: opt.info.versionIds[0],
      })

      return {
        ...opt,
        minFin:
          shouldDisplayPricesOnOptions &&
          !opt.disabled &&
          CalculatePriceFull({
            appContext,
            prices,
          }),
        prices: basePrices,
        shouldDisplayPricesOnOptions,
      }
    })
    .sort((a, b) =>
      a.disabled === b.disabled
        ? new Intl.Collator("es").compare(
            a.info.displayName,
            b.info.displayName
          )
        : Number(a.disabled) - Number(b.disabled)
    )
}

const scrollToSection = (sectionId, isMobile) => {
  if (!sectionId) return
  // Get CarView coordinates to be considered when scrolling on mobile (bc this container uses screen height)
  // const carViewDims = document
  //   .getElementById("car-view-config-container")
  //   ?.getBoundingClientRect()

  // Get target element vertical position
  const elementYPosition = document.getElementById(sectionId)?.offsetTop

  // Calculate final position, adjusting if Mobile
  const finalYPosition = elementYPosition + (isMobile ? 40 : 60)
  // Finally, scroll to desired position
  typeof window !== "undefined" &&
    window.scrollTo({
      top: finalYPosition,
      behavior: "smooth",
    })
}

const updateSessionString = (context, newSelections) =>
  infoToSessionString({
    dues: context.dues,
    percentage: context.percentage,
    payment: context.payment,
    carPaymentInfo: context.carPaymentInfo,
    ...newSelections,
  })

const SECTIONS = ["transmission", "equipment", "engine", "traction", "color"]

const getSetterName = section => "set" + capitalize(section)

/**
  Save option for current section and discard next sections 
  **/
const updateSelections = (section, option, selections, context, cleanNext) => {
  // Save option for current section
  selections[getSetterName(section)](option.info.id)
  // Discard selections for next sections
  cleanNext &&
    SECTIONS.slice(
      SECTIONS.findIndex(sec => sec === section) + 1
    ).forEach(sec => selections[getSetterName(sec)](null))
  // Save chepeast version selected at this point
  cleanNext && context.setSelectedVersion(option.prices)
}

const getNextSectionToScroll = (section, selections, defaultSelections) =>
  SECTIONS.slice(SECTIONS.findIndex(sec => sec === section) + 1).find(
    sec => !defaultSelections[sec]
  )

/**
  Callback when option is clicked. Updates session and selections and finally scrolls.
  **/
const onClickOption = (
  section,
  option,
  selections,
  context,
  isMobile,
  defaultSelections
) => {
  if (option.disabled /*|| option.selected*/) return
  // Keep session string updated //TODO SHOULD SAVE ONLY OPTION IDS
  updateSessionString(
    context,
    Object.assign(
      {},
      ...SECTIONS.slice(
        0,
        SECTIONS.findIndex(sec => sec === section)
      ).map(sec => ({ [sec]: selections[sec] })),
      { [section]: option.info.id }
    )
  )
  // Update selections
  updateSelections(section, option, selections, context, true)
  // Scroll to next section
  isMobile !== null &&
    scrollToSection(
      getNextSectionToScroll(section, selections, defaultSelections),
      isMobile
    )
}

const saveDefaultOption = (section, option, selections, context) => {
  // Keep session string updated //TODO SHOULD SAVE ONLY OPTION IDS
  updateSessionString(context, Object.assign({}, { [section]: option.info.id }))
  // Update selections
  updateSelections(section, option, selections, context, false)
}

const buildOptions = (
  model,
  selections,
  pricesStocks,
  appContext,
  openDetailsDrawer,
  isMobile,
  defaultSelections,
  setDefaultSelections,
  surchargeAmount
) => {
  const transmissionOptions = buildTransmissionOptions(
    model,
    selections,
    pricesStocks,
    appContext,
    isMobile,
    defaultSelections
  )

  const equipmentOptions = buildPackOptions(
    model,
    selections,
    pricesStocks,
    appContext,
    openDetailsDrawer,
    isMobile,
    defaultSelections
  )
  if (!equipmentOptions && !defaultSelections.equipment)
    setDefaultSelections(prev => ({ ...prev, equipment: true }))

  const engineOptions = buildEngineOptions(
    model,
    selections,
    pricesStocks,
    appContext,
    isMobile,
    defaultSelections
  )
  if (!engineOptions && !defaultSelections.engine)
    setDefaultSelections(prev => ({ ...prev, engine: true }))

  const tractionOptions = buildTractionOptions(
    model,
    selections,
    pricesStocks,
    appContext,
    isMobile,
    defaultSelections
  )
  if (!tractionOptions && !defaultSelections.traction)
    setDefaultSelections(prev => ({ ...prev, traction: true }))

  const colorOptions = generateColorOptions(
    model,
    selections,
    pricesStocks,
    appContext,
    surchargeAmount
  )

  return {
    transmissionOptions,
    equipmentOptions,
    engineOptions,
    tractionOptions,
    colorOptions,
  }
}
