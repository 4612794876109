import { useContext } from "react"
import { CarContext } from "../context"
import {
  useSurchargedPrices,
  useIncludeTaxes,
  useSelectedAccessoriesTotalPrice,
} from "../hooks"
import { CalculatePriceFull } from "../utils"
import { AppContextType } from "../types"

export const useWithCredit = (): [boolean] => {
  const appContext = useContext<AppContextType>(CarContext)
  const { offers, selectedOffer, selectedVersion, color } = appContext
  const offer = offers?.find(of => of.internalId === selectedOffer)
  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId: color,
  })
  const [includeTaxes] = useIncludeTaxes()
  const [accessoriesTotalPrice] = useSelectedAccessoriesTotalPrice()

  const paymentParams = CalculatePriceFull({
    appContext,
    offer,
    prices,
    includeTaxes: includeTaxes,
    aditionals: [accessoriesTotalPrice],
  })
  return [paymentParams.withCredit]
}
