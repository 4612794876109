export * from "./InsuranceEnum"
export * from "./insuranceData"
export * from "./brand"
export * from "./model"
export * from "./events"
export * from "./options"
export * from "./specialDevice"
export * from "./payment"
export * from "./accessory"
export * from "./appContext"
export * from "./seller"
export * from "./plate"
export * from "./session"

export type StandardOnChangeParams = {
  name: string
  value: number | string | null
  hasError: boolean
}
