import React from "react"
import { Image } from ".."

const buildEngineDetails = engines =>
  engines.map((engine, idx) => (
    <div className="detail-spec" key={idx}>
      <div className="value">
        {engine.name}
        <div className="sub">{engine.turbo}</div>
      </div>
      <div className="details">
        {engine.features.map((feat, idx) => (
          <div key={idx} className="detail">
            {feat}
          </div>
        ))}
      </div>
    </div>
  ))

export function Motor({ model, isMobile }) {
  const imageWidth =
    (isMobile ? 10 : 90) + (isMobile ? 90 : 130) * model.enginesSpecs.length
  const gallery = model.gallery.find(g => g.category === "engine")
  return (
    <div className="model-motor">
      <div className="section-container">
        <div className="specs">
          <div className="title">
            <div className="black">
              {model.modelStrings.engineSection.mainTitle}
            </div>
            <div className="gray">
              {model.modelStrings.engineSection.secondaryTitle}
            </div>
          </div>
          <div className="bottom-container" id="engine-data-container">
            <div className="description">
              {model.modelStrings.engineSection.description}
            </div>
            <div className="details-container" id="engine-details-container">
              {buildEngineDetails(model.enginesSpecs)}
            </div>
            <div className="photo" style={{ width: `${imageWidth}px` }}>
              <Image filename={gallery.images[0].url.split("/").pop()}></Image>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
