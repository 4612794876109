import React from "react"
import { Select } from "@material-ui/core"
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded"
import { FINANCING_MINS, FINANCING_MAXS } from "../../constants"
import { useUpdateStats, UpdateStatType } from "../../hooks"
import { Payment, CreditText } from "../../types"

type Props = {
  className?: string
  disableUnderline?: boolean
  payment: Payment
  dues: unknown
  setPayment: (value: unknown) => void
  setDues: (value: unknown) => void
  allowedOptions: string[]
}

export const PaymentSelector: React.FC<Props> = ({
  className,
  disableUnderline,
  payment,
  dues,
  setPayment,
  setDues,
  allowedOptions,
}) => {
  const [updateStat] = useUpdateStats("", UpdateStatType.CALLBACK)

  return (
    <Select
      className={`selector payment-selector ${className || ""}`}
      native
      value={payment}
      disableUnderline={disableUnderline}
      onChange={({ target: { value } }) => {
        if (value) {
          setPayment(value)
          // Adjust dues according to payment change
          if (value !== Payment.cash) {
            updateStat(
              `paymentConfig-payment-selectorTo${
                value === Payment.credit
                  ? "TraditionalCredit"
                  : "FlexibleCredit"
              }`
            )
            if (dues < FINANCING_MINS.dues[value])
              setDues(FINANCING_MINS.dues[value])
            else if (dues > FINANCING_MAXS.dues[value])
              setDues(FINANCING_MAXS.dues[value])
          } else {
            updateStat("paymentConfig-payment-selectorToCash")
          }
        }
      }}
      IconComponent={KeyboardArrowDownRoundedIcon}
    >
      {PAYMENT_OPTIONS.filter(
        opt => !allowedOptions || allowedOptions.includes(opt.value)
      ).map((e, idx) => (
        <option key={idx} value={e.value}>
          {e.label}
        </option>
      ))}
    </Select>
  )
}

const PAYMENT_OPTIONS = [
  {
    value: Payment.credit,
    label: CreditText.credit,
  },
  {
    value: Payment.flexCredit,
    label: CreditText.flexCredit,
  },
  {
    value: Payment.cash,
    label: "Contado",
  },
]
