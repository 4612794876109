import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { Insurance, OnChange, OnChangeParams } from "../../../types"
import { Selector, GroupedSelector } from "../subcomponents"

type Props = {
  brandId: string
  brandOptions: { value: string; label: string }[]
  modelId: string
  modelOptions: { value: string; label: string }[]
  year: string
  yearOptions: { value: string; label: string }[]
  onChange: OnChange
}

const PlateFallbackStepComponent: React.FC<Props> = ({
  brandId,
  brandOptions,
  modelId,
  modelOptions,
  year,
  yearOptions,
  onChange,
}) => {
  // Validation not needed. Handled by custom input.
  const onBrandChange = useCallback(
    (x: OnChangeParams) => {
      onChange(x)
      onChange({ name: Insurance.modelId, value: null })
    },
    [onChange]
  )
  return (
    <QuestionTemplate title="¿Cuéntanos del auto?">
      <div className="insurance-drawer-step-content insurance-plate-fallback-step">
        <GroupedSelector
          id="insurance-plate-fallback-brand-input"
          label="Marca"
          name={Insurance.brandId}
          value={brandId}
          groups={["Derco", "Otros"]}
          options={brandOptions}
          onChange={onBrandChange}
        />
        <Selector
          id="insurance-plate-fallback-model-input"
          label="Modelo"
          name={Insurance.modelId}
          value={modelId}
          options={modelOptions}
          onChange={onChange}
          disabled={!brandId}
        />
        <Selector
          id="insurance-plate-fallback-year-input"
          label="Año"
          name={Insurance.year}
          value={year}
          options={yearOptions}
          onChange={onChange}
        />
      </div>
    </QuestionTemplate>
  )
}

export const PlateFallbackStep = memo(PlateFallbackStepComponent)
