export const parseNumber = (number, min = null, max = null) => {
  if (!number) return 0
  let parsenEnglish
  if (min === null && max === null)
    parsenEnglish = number.toLocaleString("en", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  else {
    parsenEnglish = number.toLocaleString("en", {
      ...(max !== null ? { minimumFractionDigits: min } : {}),
      ...(max !== null ? { maximumFractionDigits: max } : {}),
    })
  }
  return parsenEnglish.replace(/\./g, "_").replace(/,/g, ".").replace(/_/g, ",")
}

export const getModelNameWithoutShortname = (modelName, shortName) =>
  modelName.toLowerCase().replace(shortName.toLowerCase(), "").trim()

export const capitalize = string =>
  string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : ""

export const capitalizeWords = string =>
  string ? string.split(" ").map(capitalize).join(" ") : ""

export const getModelShortNameOrDefault = (
  model,
  maxLen = 6,
  defaultText = "auto"
) =>
  model ? (model.shortName.length > maxLen ? defaultText : model.shortName) : ""

export const getMaxStringLengthByField = (array, field) =>
  Math.max(...array.map(element => element[field].length))
