import React, { useCallback, useContext } from "react"
import Slider from "@material-ui/core/Slider"
import {
  Disclaimer,
  DCXButton,
  DrawerHeader,
  PaymentSelector,
  DuesSelector,
  PaymentToggle,
} from "."
import { CarContext, UIContext } from "../context"
import { checkUrl, CalculatePriceFull, parseNumber } from "../utils"
import {
  useSelectedAccessoriesTotalPrice,
  useSurchargedPrices,
  useIncludeTaxes,
  useUpdateStats,
  UpdateStatType,
} from "../hooks"
import { FINANCING_MINS, FINANCING_MAXS } from "../constants"
import IconButton from "@material-ui/core/IconButton"
import { HelpIcon } from "../assets"
import { Payment, AppContextType } from "../types"

export const DrawerFinancing: React.FC = () => {
  const appContext = useContext<AppContextType>(CarContext)
  const {
    payment,
    setPayment,
    dues,
    setDues,
    percentage,
    setPercentage,
    carPaymentInfo,
    SetCarPaymentInfo,
    selectedVersion,
    offers,
    selectedOffer,
    color: colorId,
  } = appContext

  const {
    closeFinancingDrawer,
    openAppraiseDrawer,
    openPaymentDrawer,
    openCreditVideo,
  } = useContext(UIContext)

  // Check context to determine if sale is present
  const offer = offers?.find(of => of.internalId === selectedOffer)

  // Calculate selected accessories total price, if needed depending on the current page
  const [accessoriesTotalPrice] = useSelectedAccessoriesTotalPrice()
  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId,
  })
  const [includeTaxes] = useIncludeTaxes()

  const PaymentInfo = CalculatePriceFull({
    appContext,
    offer,
    prices,
    includeTaxes: includeTaxes,
    aditionals: [accessoriesTotalPrice],
  })
  const {
    firstPaymentPrice,
    monthlyPrice,
    CAE,
    baseAmount,
    variant: _variant,
    VFMG,
  } = PaymentInfo
  // Car in Payment isn't relevant for displaying purposes here
  const variant = Math.floor(_variant)

  const withCredit = [1, 4].includes(variant)

  const isHomeOrOffers = checkUrl(["home", "offers", "comparador"])

  const disableSliders =
    typeof window !== "undefined" &&
    (isHomeOrOffers ? payment === Payment.cash : !withCredit)

  // Evaluate if any payment produces a variant that does not belong to [2,5,7], so it belogs to [1,4] and has an extra line
  const shouldAddEmptyLineToVariants257 = evaluateEmptyLine257({
    appContext,
    offer,
    prices,
  })

  const [updateStat] = useUpdateStats("", UpdateStatType.CALLBACK)

  const removeAppraise = useCallback(() => {
    SetCarPaymentInfo(null)
    updateStat("appraise-other-removeCIP")
  }, [SetCarPaymentInfo, updateStat])

  return (
    <div className="drawer-layout drawer-financing">
      <DrawerHeader
        // className="financing-drawer-header"
        title="FINANCIAMIENTO"
        drawerName="financing-drawer"
        closeDrawer={closeFinancingDrawer}
      />
      <div className="content">
        <div className={`summary ${isHomeOrOffers ? "hide" : ""}`}>
          {[1, 4].includes(variant) && (
            <>
              <div className="summary-line bold">
                <span>{dues} cuotas de</span>
                <span>${parseNumber(monthlyPrice)}</span>
              </div>
              <div className="summary-line bold">
                <span>Pie</span>
                <span>${parseNumber(firstPaymentPrice)}</span>
              </div>
              <div className="summary-line">
                <span>Carga anual equiv. (CAE)</span>
                <span>${parseNumber(CAE, null, 2)}</span>
              </div>
            </>
          )}
          {[1, 3].includes(variant) && (
            <div className="summary-line bold">
              <span>Pago Final Opcional</span>
              <span>${parseNumber(VFMG)}</span>
            </div>
          )}
          {[2, 5, 7].includes(variant) && (
            <>
              <div className="summary-line bold">
                <span>Valor final</span>
                <span>${parseNumber(firstPaymentPrice)}</span>
              </div>
              <div className="summary-line">
                <span>Precio Lista</span>
                <span style={{ textDecoration: "line-through" }}>
                  ${parseNumber(prices.priceList)}
                </span>
              </div>
              {shouldAddEmptyLineToVariants257 && (
                <div className="summary-line empty"></div>
              )}
            </>
          )}
          {[3, 6, 8].includes(variant) && (
            <>
              <div className="summary-line bold">
                <span>Te devolvemos</span>
                <span>${parseNumber(Math.abs(baseAmount))}</span>
              </div>
            </>
          )}
          <DCXButton
            className="more-info"
            id="payment-details-button"
            type="tertiary"
            text="Ver Detalles"
            arrow="right"
            onClick={openPaymentDrawer}
          />
        </div>

        <div className={`title ${isHomeOrOffers ? "first-title" : ""}`}>
          <div className="text">
            ¿Cómo quieres pagar tu nuevo auto{" "}
            <span role="img" aria-label="ícono de dinero">
              💵
            </span>
            ?
          </div>
        </div>

        <PaymentToggle
          value={payment === Payment.flexCredit ? Payment.credit : payment}
          onChange={(_: unknown, val: Payment) => {
            if (val) {
              setPayment(val)
              // Adjust dues according to payment change
              if (val !== Payment.cash) {
                updateStat("paymentConfig-payment-toggleToTraditionalCredit")
                if (dues < FINANCING_MINS.dues[val])
                  setDues(FINANCING_MINS.dues[val])
                else if (dues > FINANCING_MAXS.dues[val])
                  setDues(FINANCING_MAXS.dues[val])
              } else {
                updateStat("paymentConfig-payment-toggleToCash")
              }
            }
          }}
        />

        <div className="selector-container">
          <IconButton
            className={`modal-open-button ${
              payment === Payment.cash ? "hide" : ""
            }`}
            size="small"
            onClick={openCreditVideo}
          >
            <HelpIcon
              color="secondary"
              fontSize="inherit"
              className="info-icon"
            />
          </IconButton>
          <PaymentSelector
            payment={payment}
            dues={dues}
            setPayment={setPayment}
            setDues={setDues}
          />
        </div>

        <div
          className={`selector-container ${
            payment === Payment.cash ? "hide" : ""
          }`}
        >
          <DuesSelector payment={payment} dues={dues} setDues={setDues} />
        </div>

        <div
          className={`title subtitle ${payment === Payment.cash ? "hide" : ""}`}
        >
          <div className="text">¿Cuánto pie quieres poner?</div>
          <div className="value">
            {disableSliders ? null : `${percentage}%`}
          </div>
        </div>

        <div
          className={`slider-container last ${
            payment === Payment.cash ? "hide" : ""
          }`}
        >
          <Slider
            key="percentage-slider-home"
            id="select-money-foot-slider"
            aria-labelledby="continuous-slider"
            value={disableSliders ? 0 : percentage}
            getAriaValueText={val => val + "%"}
            min={FINANCING_MINS.percentage[payment]}
            max={FINANCING_MAXS.percentage[payment]}
            marks={getSliderMarks("percentage", payment, disableSliders)}
            onChange={(_, value: number) => setPercentage(value)}
            onChangeCommitted={() =>
              updateStat("paymentConfig-percentage-slider")
            }
            disabled={disableSliders}
          />
        </div>
        <div className="appraise">
          <div className={`empty-appraise ${carPaymentInfo ? "hide" : ""}`}>
            <div className="title">
              <div className="text">
                ¿Auto en parte de pago{" "}
                <span role="img" aria-label="ícono de automóvil">
                  🚘
                </span>
                ?
              </div>
            </div>
            <div className="description">
              Entrega tu auto actual en parte de pago de tu nuevo modelo
            </div>
            <DCXButton
              className="button button-desktop"
              id="open-appraise-drawer-button"
              type="primary"
              text="TASA TU AUTO"
              onClick={openAppraiseDrawer}
            />
            <DCXButton
              className="button button-mobile"
              id="open-appraise-drawer-button"
              type="secondary"
              text="TASA TU AUTO"
              onClick={openAppraiseDrawer}
            />
          </div>
          <div className={`current-appraise ${!carPaymentInfo ? "hide" : ""}`}>
            <div className="title">
              <div className="text">Tu auto en parte de pago</div>
            </div>
            <div className="amount">
              ${parseNumber(carPaymentInfo?.finalPrice, 0)}
            </div>
            <DCXButton
              className="button"
              id="remove-car-in-payment-button"
              type="tertiary"
              text="LIMPIAR"
              onClick={removeAppraise}
            />
          </div>
        </div>
        <Disclaimer className="disclaimer" />
      </div>
      <div className="footer-apply">
        <DCXButton
          className="button"
          id="save-financing-changes-button"
          type="primary"
          text="APLICAR"
          onClick={closeFinancingDrawer}
        />
      </div>
    </div>
  )
}

const getSliderMarks = (
  type: "dues" | "percentage",
  payment: Payment,
  disableSliders: boolean
) =>
  !disableSliders &&
  [FINANCING_MINS[type][payment], FINANCING_MAXS[type][payment]].map(n => ({
    value: n,
    label: type === "dues" ? n : n + "%",
  }))

const evaluateEmptyLine257 = ({ appContext, offer, prices }) => {
  const { variant: flexCreditVariant } = CalculatePriceFull({
    appContext: { ...appContext, payment: Payment.flexCredit },
    offer,
    prices,
  })
  const { variant: creditVariant } = CalculatePriceFull({
    appContext: { ...appContext, payment: Payment.credit },
    offer,
    prices,
  })

  const { variant: cashVariant } = CalculatePriceFull({
    appContext: { ...appContext, payment: Payment.cash },
    offer,
    prices,
  })

  return ![flexCreditVariant, creditVariant, cashVariant].every(v =>
    [2, 5, 7].includes(Math.floor(v))
  )
}
