import React, { useContext } from "react"
import Drawer from "@material-ui/core/Drawer"
import { KeyboardContext } from "../context"

type Props = {
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
}

export const DrawerBase: React.FC<Props> = ({ children, isOpen, onClose }) => {
  const { isKeyboardOpen } = useContext(KeyboardContext)

  return (
    <Drawer
      className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
      open={isOpen}
      anchor="right"
      onClose={onClose}
    >
      {children}
    </Drawer>
  )
}
