import React, { useContext, useCallback } from "react"
import { navigate } from "gatsby"
import {
  Backdrop,
  Fade,
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import CallMadeRoundedIcon from "@material-ui/icons/CallMadeRounded"
import { CarContext, SearchContext } from "../context"
import { getFilteredModels, checkUrl, urlWithSession } from "../utils"

export function SearchSuggestions({ allModels }) {
  const { payment, modelsPricesStocks } = useContext(CarContext)
  const {
    filters,
    modelSearch,
    searchSuggestions,
    showSearchSuggestions,
    clickSearchSuggestion,
    closeSearchSuggestions,
    setSearchResults,
  } = useContext(SearchContext)

  const handleClick = useCallback(
    (result, i) => {
      if (checkUrl(["/"])) navigate(urlWithSession("/home"))
      clickSearchSuggestion(result)
      const filteredModels = getFilteredModels(
        allModels,
        result,
        filters,
        modelsPricesStocks,
        payment,
        i > 0
      )
      setSearchResults(filteredModels)
    },
    [
      clickSearchSuggestion,
      allModels,
      filters,
      payment,
      modelsPricesStocks,
      setSearchResults,
    ]
  )

  return (
    <>
      <Backdrop
        className="search-results-backdrop"
        open={showSearchSuggestions}
        onClick={closeSearchSuggestions}
      ></Backdrop>
      <Fade direction="down" in={showSearchSuggestions}>
        <Paper className={"search-results"} elevation={12}>
          {[modelSearch]
            .concat(searchSuggestions)
            .filter(Boolean)
            .map((result, i) => (
              <ListItem
                key={i + result}
                role={undefined}
                button={true}
                divider
                onClick={() => handleClick(result, i)}
                ContainerComponent="div"
              >
                <ListItemIcon>
                  <SearchRoundedIcon />
                </ListItemIcon>
                <ListItemText primary={result} />
                <ListItemSecondaryAction>
                  {i > 0 && <CallMadeRoundedIcon />}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </Paper>
      </Fade>
    </>
  )
}
