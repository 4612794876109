import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForRut } from "../../reusables"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {
  Insurance,
  OnChange,
  Gender,
  RadioGroupOnChangeType,
  Owner,
} from "../../../types"

type Props = {
  rut: string
  gender: Gender
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿Cuál es tu RUT?",
  [Owner.other]: "¿Cuál es su RUT?",
  [Owner.business]: "",
}

const RutGenderStepComponent: React.FC<Props> = ({
  rut,
  gender,
  owner,
  onChange,
}) => {
  const onGenderChange = useCallback(
    (_, value: Gender) => {
      onChange({ name: Insurance.gender, value })
    },
    [onChange]
  )
  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content insurance-rut-gender">
        <InputForRut
          name={Insurance.rut}
          onChange={onChange}
          fullWidth
          value={rut}
          margin="normal"
        />
        <RadioGroup
          value={gender}
          onChange={onGenderChange as RadioGroupOnChangeType}
        >
          <FormControlLabel
            value={Gender.male}
            control={<Radio />}
            label="Hombre"
          />
          <FormControlLabel
            value={Gender.female}
            control={<Radio />}
            label="Mujer"
          />
        </RadioGroup>
      </div>
    </QuestionTemplate>
  )
}

export const RutGenderStep = memo(RutGenderStepComponent)
