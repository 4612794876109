import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core/"
import { DCXButton } from ".."

type Props = {
  title: string
  primaryButtonText: string
  primaryButtonHandle: () => void
  secondaryButtonText?: string
  secondaryButtonHandle?: () => void
  open: boolean
  handleClose: () => void
  children?: React.ReactNode
  dialogClass?: string
}

export const InfoDialog: React.FC<Props> = ({
  title,
  primaryButtonText,
  primaryButtonHandle,
  secondaryButtonText,
  secondaryButtonHandle,
  open,
  handleClose,
  children,
  dialogClass,
}) => {
  return (
    <Dialog
      className={`info-dialog ${dialogClass}`}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <DCXButton
          className={!secondaryButtonText && "hidden"}
          type="secondary"
          text={secondaryButtonText}
          onClick={secondaryButtonHandle}
        />
        <DCXButton
          type="primary"
          text={primaryButtonText}
          onClick={primaryButtonHandle}
        />
      </DialogActions>
    </Dialog>
  )
}
