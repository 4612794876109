import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { Insurance, OnChange } from "../../../types"
import { BrandOption } from "../subcomponents"
import isEqual from "lodash/isEqual"

type BrandStepProps = {
  brandId: string
  options: { value: string; img: string }[]
  onChange: OnChange
}

const BrandStepComponent: React.FC<BrandStepProps> = ({
  brandId,
  options,
  onChange,
}) => {
  const onClick = useCallback(
    (value: string) => () => onChange({ name: Insurance.brandId, value }),
    [onChange]
  )
  return (
    <QuestionTemplate title="¿Qué marca?">
      <div className="insurance-drawer-step-content insurance-brand-step-content">
        {options.map(({ value, img }) => (
          <BrandOption
            className={brandId === value ? "selected" : ""}
            key={value}
            img={img}
            onClick={onClick(value)}
          />
        ))}
      </div>
    </QuestionTemplate>
  )
}

export const BrandStep = memo(BrandStepComponent, (prev, next) => {
  const brand = prev.brandId === next.brandId
  let options = prev.options.length === next.options.length
  if (options) {
    for (let i = 0; i < prev.options.length && options; i++) {
      options = isEqual(prev.options[i], next.options[i])
    }
  }
  return brand && options
})
