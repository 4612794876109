import React from "react"
import { parseNumber } from "../../utils"

export const AccessoryPrice = ({
  className,
  monthlyPrice,
  cashPrice,
  firstPaymentPrice,
}) => {
  return (
    <div
      className={`accessory-financed-price ${className || ""} ${
        !monthlyPrice ? "cash-price" : ""
      }`}
    >
      <span className="add plus-symbol">+</span>
      <span className="add">${parseNumber(monthlyPrice || cashPrice)}</span>
      <span className="month">/ mes</span>
      <span className="extra">+Pie ${parseNumber(firstPaymentPrice)} </span>
    </div>
  )
}
