const getVersionTaxes = ({ greenTaxRate, financeData, carPrice }) => {
  if (!greenTaxRate || !financeData || !carPrice) return null
  const utm = financeData.utm[new Date().getMonth() + 1]

  return {
    inscription: financeData.inscription.AUTOMOVIL,
    greenTax: Math.round((carPrice / 10000000) * greenTaxRate * utm),
    circulationPermit: getCirculationPermitValue(carPrice, utm, financeData),
    soap: financeData.soap.AUTOMOVIL,
  }
}

const getCirculationPermitValue = (carPrice, utm, financeData) => {
  const carPriceNetValue = carPrice / 1.19
  let annualCirculationPermit = 0
  for (let range of financeData.circulationPermit) {
    if (carPriceNetValue <= range.upperLimit * utm) {
      annualCirculationPermit +=
        (carPriceNetValue - range.lowerLimit * utm) * range.rate
      break
    } else {
      annualCirculationPermit +=
        (range.upperLimit - range.lowerLimit) * utm * range.rate
    }
  }
  const month = new Date().getMonth() + 1
  const finalCirculationPermit = Math.round(
    (annualCirculationPermit / 12) * (12 - month + 1)
  )
  return finalCirculationPermit
}

export { getVersionTaxes }
