import React, { useState, useCallback, useEffect, useContext } from "react"
import Keyboard from "react-simple-keyboard"
import "react-simple-keyboard/build/css/index.css"
import { KeyboardContext } from "../context"

const DEFAULT_LAYOUT = [
  "{tab} q w e r t y u i o p {accent} {bksp} 7 8 9 {close}",
  "{lock} a s d f g h j k l ñ {enter} 4 5 6",
  "{shift} z x c v b n m , . - 1 2 3",
  "* {space} @ .com .cl 0",
]
const abc = "abcdefghijklmnñopqrstuvwxyzáéíóú"
const vocals = "aeiou"

const uppercasify = layout =>
  layout.map(row =>
    row
      .split(" ")
      .map(key => (abc.includes(key) ? key.toUpperCase() : key))
      .join(" ")
  )
const accentifyLetter = letter =>
  vocals.includes(letter)
    ? letter
        .replace("a", "á")
        .replace("e", "é")
        .replace("i", "í")
        .replace("o", "ó")
        .replace("u", "ú")
    : letter
const accentify = layout =>
  layout.map(row => row.split(" ").map(accentifyLetter).join(" "))

const LAYOUTS = {
  default: DEFAULT_LAYOUT,
  uppercase: uppercasify(DEFAULT_LAYOUT),
  accent_lowercase: accentify(DEFAULT_LAYOUT),
  accent_uppercase: uppercasify(accentify(DEFAULT_LAYOUT)),
}

const TRANSITIONS = {
  // key: { fromLayout: toLayout }
  accent: {
    default: "accent_lowercase",
    accent_lowercase: "default",
    uppercase_lock: "accent_uppercase_lock",
    uppercase_shift: "accent_uppercase_shift",
    accent_uppercase_lock: "uppercase_lock",
    accent_uppercase_shift: "uppercase_shift",
  },
  lock: {
    default: "uppercase_lock",
    uppercase_lock: "default",
    uppercase_shift: "uppercase_lock",
    accent_lowercase: "accent_uppercase_lock",
    accent_uppercase_lock: "accent_lowercase",
    accent_uppercase_shift: "accent_uppercase_lock",
  },
  shift: {
    default: "uppercase_shift",
    uppercase_lock: "default",
    uppercase_shift: "default",
    accent_lowercase: "accent_uppercase_shift",
    accent_uppercase_lock: "accent_lowercase",
    accent_uppercase_shift: "accent_lowercase",
  },
}

const getLayoutOnKeyPressed = state => {
  let layout = state.layout
  // Deactivate accent if ´ was pressed
  if (layout.includes("accent")) layout = TRANSITIONS.accent[layout]
  // Deactivate uppercase if Shift was pressed.
  if (!state.capsLocked && layout.includes("uppercase"))
    layout = TRANSITIONS.shift[layout]
  return layout
}

const getLayoutOnSpecialKeyPress = (specialKey, previousLayout, options) =>
  options.capsAlwaysLocked
    ? previousLayout
    : TRANSITIONS[specialKey][previousLayout]

export function CustomKeyboard({
  keyboardRef,
  inputRef,
  options,
  callbacks: { onTabPress, onEnterPress, onDeletePress, onChange },
  // value,
}) {
  const { hideKeyboard } = useContext(KeyboardContext)
  const [state, setState] = useState({
    shiftLocked: false,
    capsLocked: options.capsAlwaysLocked || false,
    layout: options.capsAlwaysLocked ? "uppercase_lock" : "default",
  })

  const onKeyboardChange = useCallback(
    input => {
      // Handle tab pressing, removing that char
      let processed = input
        .split("")
        .filter(char => char.charCodeAt(0) !== 9)
        .join("")
      // Update keyboard internal state if needed
      if (input !== processed) {
        keyboardRef.current.setInput(processed)
      }
      if (onChange) onChange(processed)
    },
    [onChange, keyboardRef]
  )

  const onKeyPress = useCallback(
    button => {
      if (button === "{close}") {
        setTimeout(() => hideKeyboard(true), 200)
      } else if (button === "{bksp}") {
        if (onDeletePress) {
          onDeletePress()
          keyboardRef.current.setInput("")
        }
      } else if (button === "{tab}") {
        onTabPress && onTabPress()
      } else if (button === "{enter}") {
        onEnterPress && onEnterPress()
      } else if (button === "{shift}") {
        setState(prev => ({
          ...prev,
          layout: getLayoutOnSpecialKeyPress("shift", prev.layout, options),
          capsLocked: false || options.capsAlwaysLocked,
          shiftLocked:
            prev.capsLocked && options.capsAlwaysLocked
              ? false
              : !prev.shiftLocked,
        }))
      } else if (button === "{lock}") {
        setState(prev => ({
          ...prev,
          layout: getLayoutOnSpecialKeyPress("lock", prev.layout, options),
          capsLocked:
            options.capsAlwaysLocked || prev.shiftLocked
              ? true
              : !prev.capsLocked,
          shiftLocked: false,
        }))
      } else if (button === "{accent}") {
        !options.disableAccent &&
          setState(prev => ({
            ...prev,
            layout: TRANSITIONS.accent[prev.layout],
          }))
      } else {
        // Deactivate uppercase if Shift was pressed. Deactivate accent if ´ was pressed
        setState(prev => ({
          ...prev,
          layout: getLayoutOnKeyPressed(prev),
        }))
      }
    },
    [
      setState,
      hideKeyboard,
      onTabPress,
      onEnterPress,
      onDeletePress,
      keyboardRef,
      options,
    ]
  )

  // Update keyboard internal value with input initial value
  useEffect(() => {
    if (keyboardRef.current && inputRef.current)
      keyboardRef.current.setInput(
        inputRef.current.value.slice(options.prefixLen || 0)
      )
  }, [keyboardRef, inputRef, options.prefixLen])

  //TODO ENTER de color
  return (
    <Keyboard
      keyboardRef={r => (keyboardRef.current = r)}
      onChange={onKeyboardChange}
      onKeyPress={onKeyPress}
      preventMouseDownDefault
      useButtonTag
      // debug
      layoutName={state.layout}
      layout={{
        default: LAYOUTS.default,
        uppercase_lock: LAYOUTS.uppercase,
        uppercase_shift: LAYOUTS.uppercase,
        accent_lowercase: LAYOUTS.accent_lowercase,
        accent_uppercase_lock: LAYOUTS.accent_uppercase,
        accent_uppercase_shift: LAYOUTS.accent_uppercase,
      }}
      display={{
        "{bksp}": "Borrar ⌫",
        "{enter}": "<div>Enter</div><div>↵</div>",
        "{tab}": "Tab ⇥",
        "{lock}": "Mayús. ⇪",
        "{shift}": "Shift ⇧",
        "{space}": "Espacio",
        "{accent}": "´",
        "{close}": "Cerrar",
      }}
    />
  )
}
