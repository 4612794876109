import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Chip from "@material-ui/core/Chip"
import { DCXButton } from "."
import {
  CalendarTodayIcon,
  EmojiObjectsOutlinedIcon,
  footBlackIcon,
} from "../assets"
import { Payment, CreditText } from "../types"

export function PaymentHeader({
  data: {
    payment: rawPayment,
    dues,
    percentage,
    onFavor,
    firstPaymentPrice,
    variant: _variant,
  },
  onClick,
  id,
}) {
  const variant = Math.floor(_variant)
  const thereIsReturnOrCashPayOnly = [2, 3, 5, 6].includes(variant)
  const payment = thereIsReturnOrCashPayOnly
    ? Payment.cash
    : rawPayment || Payment.credit

  return (
    <Tooltip
      className="payment-header"
      title="Ver opciones de financiamiento"
      arrow={true}
    >
      <div
        className="payment-header"
        id={id}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={e => e.key === "Enter" && onClick()}
        role="button"
      >
        <div className="chips">
          <Chip
            icon={
              <EmojiObjectsOutlinedIcon
                style={{ marginLeft: "5px", width: "19px", color: "#2B303D" }}
              />
            }
            label={PAYMENT_DICT[payment]}
            variant="outlined"
          />

          <Chip
            style={payment === Payment.cash ? { display: "none" } : {}}
            icon={
              <CalendarTodayIcon
                style={{
                  marginLeft: "7px",
                  marginRight: "-3px",
                  width: "14px",
                  color: "#2B303D",
                }}
              />
            }
            label={dues}
            variant="outlined"
          />

          <Chip
            className="last"
            style={payment === Payment.cash ? { display: "none" } : {}}
            icon={
              <img
                style={{ paddingLeft: "5px", width: "18px" }}
                src={footBlackIcon}
                alt=""
              />
            }
            label={`${percentage}%`}
            variant="outlined"
          />
        </div>
        {!thereIsReturnOrCashPayOnly && (
          <DCXButton type="tertiary" text={TEXT_DICT[payment]} arrow="right" />
        )}
      </div>
    </Tooltip>
  )
}

const PAYMENT_DICT = {
  flexCredit: CreditText.flexCredit,
  credit: CreditText.credit,
  cash: "Pago al contado",
}

const TEXT_DICT = {
  flexCredit: "ver precio al contado",
  credit: "ver precio al contado",
  cash: "ver precio en cuotas",
}
