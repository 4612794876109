import React, { memo } from "react"
import { FrameTemplate } from "../templates"
import { CarTypeStepImage } from "../../../assets"
import { Owner } from "../../../types"

type Props = {
  owner: Owner
}

const WORDS = {
  [Owner.me]: ["Te", "entregarte"],
  [Owner.other]: ["Le", "entregarle"],
  [Owner.business]: ["Te", "entregarte"],
}

const ThanksStepComponent: React.FC<Props> = ({ owner }) => {
  return (
    <FrameTemplate
      image={CarTypeStepImage}
      title="¡Gracias por cotizar!"
      desc={`${WORDS[owner][0]} contactaremos para ${WORDS[owner][1]} más detalles`}
    />
  )
}

export const ThanksStep = memo(ThanksStepComponent)
