import React from "react"

const options = (
  values,
  propertyValue,
  propertyShow,
  actual,
  defaultValue,
  group
) => {
  let groupList = []

  if (group) {
    groupList = values.map(value => value[group])
    groupList = [...new Set(groupList)]

    return groupList.map((list, index) => {
      return (
        <optgroup key={index} label={list.toUpperCase()}>
          {values.map(
            (item, i) =>
              item[group] === list && (
                <option key={i} value={item[propertyValue]}>
                  {item[propertyShow]}
                </option>
              )
          )}
        </optgroup>
      )
    })
  } else {
    return values.map(item => {
      return <option value={item[propertyValue]}>{item[propertyShow]}</option>
    })
  }
}

export function Select({
  props,
  onChange,
  placeholder,
  className,
  group = false,
  id,
}) {
  const { values, propertyValue, propertyShow, actual, defaultValue } = props
  return (
    <select
      className={`select-design-dercocenterx ${className}`}
      id={id}
      onChange={value => {
        onChange(value.target.value)
      }}
      onBlur={null}
      placeholder={placeholder}
      value={actual}
    >
      {values &&
        options(
          values,
          propertyValue,
          propertyShow,
          actual,
          defaultValue,
          group
        )}
    </select>
  )
}
