import React from "react"
import FormGroup from "@material-ui/core/FormGroup"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."

export const AppraiseStep5: React.FC<StepProps> = ({ form, updateForm }) => {
  return (
    <>
      <CarInfo data={form} />
      <div className="title">Queremos saber un poco más</div>
      <div className="subtitle"></div>
      <div className="component">
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              id="select-2-keys"
              control={
                <Checkbox
                  checked={form.carKeys}
                  onChange={event =>
                    updateForm(prev => ({
                      ...prev,
                      carKeys: event.target.checked,
                    }))
                  }
                  name="2-car-keys"
                />
              }
              label="Tienes 2 llaves del auto"
            />

            <div className="between-options-desc">
              No te imaginas lo caras que son las llaves. Si tienes dos llaves
              podemos ofrecerte un mejor precio por tu auto.
            </div>

            <FormControlLabel
              id="select-one-owner"
              control={
                <Checkbox
                  checked={form.oneOwner}
                  onChange={event =>
                    updateForm(prev => ({
                      ...prev,
                      oneOwner: event.target.checked,
                    }))
                  }
                  name="one-owner"
                />
              }
              label="Único dueño"
            />
          </FormGroup>
        </FormControl>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-5"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next select-car-keys"
          id="finish-step-5"
          type="primary"
          text="SIGUIENTE"
          onClick={form.actions.nextStep}
        />
      </div>
    </>
  )
}
