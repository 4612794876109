import api from "./api"
import { SPECIAL_DEVICE } from "../types"

type SaveSessionParams = {
  rut: string
  email: string
  url: string
  modelName: string
  specialDevice: SPECIAL_DEVICE | null
}
export const saveSession = async ({
  rut,
  email,
  url,
  modelName,
  specialDevice,
}: SaveSessionParams): Promise<boolean> => {
  try {
    await api.post("/sessions", {
      rut,
      email,
      url,
      modelName,
      specialDevice: specialDevice || "WEB",
    })
    return true
  } catch (e) {
    return false
  }
}

export const getSessionData = async (rut: string): Promise<string | null> => {
  try {
    const response = await api.get(`/sessions?rut=${rut}`)
    return response?.data?.url
  } catch (e) {
    return null
  }
}
