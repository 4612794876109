import React, { useEffect, useContext, useRef } from "react"
import { Input } from "@inventures/react-lib/components"
import { InputStatus, useInput } from "@inventures/react-lib/hooks"
import {
  RequiredValidator,
  RutFormatValidator,
  RutValidator,
  EmailValidator,
  LengthValidator,
  Validator,
} from "@inventures/react-lib/validators"
import {
  MaskFormatter,
  Formatter,
  RutFormatter,
} from "@inventures/react-lib/formatters"
import { KeyboardContext } from "../../context"
import { useSpecialDevice } from "../../hooks"

const debounceTime = 800

const InputStyleProps = {
  variant: "outlined",
  color: "primary",
}

export const InputForPlate = ({
  elementId = "input-for-plate",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new MaskFormatter([
        "A",
        "A",
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        "9",
        "9",
      ]),
      validators: [
        new RequiredValidator("Patente requerida"),
        new LengthValidator("¡Ojo! Esta patente está incompleta 🤷‍♀️", 6),
      ],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  // Update parent component when value or error changes
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      id={elementId}
      {...InputStyleProps}
      {...props}
      inputRef={inputRef}
      value={value}
      onChange={e => setValue(String(e.target.value).toUpperCase())} // Update internal state
      onFocus={() =>
        showKeyboard(
          setValue,
          inputRef,
          {
            onEnterPress,
            onTabPress,
          },
          { capsAlwaysLocked: true, disableAccent: true }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      label="Patente"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

export const InputForRut = ({
  elementId = "input-for-rut",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  validateEmptyString = true,
  requiredValidatorError,
  asyncValidators,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new RutFormatter(),
      validators: [
        new RequiredValidator(requiredValidatorError ?? "RUT requerido"),
        new LengthValidator("Ups, parece no estar bien escrito 🧐", {
          min: 11,
          max: 12,
        }),
        new RutFormatValidator("Ups, parece no estar bien escrito 🧐"),
        new RutValidator("Ups, parece no estar bien escrito 🧐"),
      ],
      asyncValidators: asyncValidators || [],
      debounceTime,
    }
  )
  const hasError =
    Boolean(validateEmptyString || value) && status === InputStatus.ERROR

  useEffect(() => {
    onChange({
      name,
      value,
      hasError,
    })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id={elementId}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(
          setValue,
          { elementId: "input-for-rut" },
          { onEnterPress, onTabPress }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={hasError && errors[0]}
      label={props.label || "RUT"}
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

class CustomNamesFormatter extends Formatter {
  format(input) {
    const words = input.split(" ").filter(Boolean).length
    const trimmedInput = words > 0 ? input.trimLeft() : input.trim()
    return trimmedInput.replace(/[\d]/, "").replace(/\s+/g, " ")
  }
}

export const InputForNames = ({
  elementId = "input-for-name",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new CustomNamesFormatter(),
      validators: [new RequiredValidator("Nombres requeridos")],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id={elementId}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(
          setValue,
          { elementId: "input-for-name" },
          { onEnterPress, onTabPress }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      label="Nombres"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

class CustomLastnameValidator extends Validator {
  validate(input) {
    return input.split(" ").filter(Boolean).length >= 2
  }
}

export const InputForLastnames = ({
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new CustomNamesFormatter(),
      validators: [new RequiredValidator("Apellidos requeridos")],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id="input-for-lastname"
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(
          setValue,
          { elementId: "input-for-lastname" },
          { onEnterPress, onTabPress }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      label="Apellidos"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

export const InputForFullname = ({
  elementId = "input-for-fullname",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new CustomNamesFormatter(),
      validators: [
        new RequiredValidator("Nombre completo requerido"),
        new CustomLastnameValidator("Por favor ingrese su nombre completo."),
      ],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id={elementId}
      inputRef={inputRef}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(setValue, inputRef, {
          onEnterPress,
          onTabPress,
        })
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      label="Nombre completo"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

export const InputForPhone = ({
  elementId = "input-for-phone",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new MaskFormatter("+56 9 9999 9999"),
      validators: [
        new RequiredValidator("Teléfono requerido"),
        new LengthValidator("¡Ojo! Este teléfono está incompleto 🤷‍♀️", 15),
      ],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id={elementId}
      inputRef={inputRef}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(
          setValue,
          inputRef,
          {
            onEnterPress,
            onTabPress,
          },
          { prefixLen: 4 }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      label="Teléfono"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

export const InputForEmail = ({
  elementId = "input-for-email",
  name,
  helperText,
  onChange,
  onEnterPress,
  onTabPress,
  validateEmptyString = true,
  requiredValidatorError,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      validators: [
        new RequiredValidator(requiredValidatorError ?? "Email requerido"),
        new EmailValidator(
          helperText || "Este email parece no estar bien escrito 🧐"
        ),
      ],
      debounceTime,
    }
  )
  const hasError =
    Boolean(validateEmptyString || value) && status === InputStatus.ERROR

  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      {...props}
      id={elementId}
      inputRef={inputRef}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(setValue, inputRef, {
          onEnterPress,
          onTabPress,
        })
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={hasError && errors[0]}
      label="Email"
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}

class CustomIncomesFormatter extends Formatter {
  // To support suffix we need to control TextField with inputRef -> selectionStart
  prefix = "$ "
  //suffix = " USD"
  milesSep = "."
  format(input) {
    const userInput = input.startsWith(this.prefix)
      ? input.slice(this.prefix.length)
      : input
    const userInputOnlyNumbers = userInput.replace(/[^0-9]/g, "")
    const userInputWithSeparator = userInputOnlyNumbers.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      `$1${this.milesSep}`
    )
    return userInput.length
      ? this.prefix + userInputWithSeparator // + this.suffix
      : ""
  }
  unformat(input) {
    return Number(input.replace(this.prefix, "").replace(/\./g, ""))
  }
}

export const IncomesFormatter = new CustomIncomesFormatter()

export const InputForIncome = ({
  elementId = "input-for-income",
  lengthValidatorText = "Ingresos mensuales requeridos",
  extraValidators = [],
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new CustomIncomesFormatter(),
      validators: [
        new LengthValidator(lengthValidatorText, {
          min: 3,
          max: Infinity,
        }),
        ...extraValidators,
      ],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      {...InputStyleProps}
      label="Ingreso líquido mensual"
      {...props}
      id={elementId}
      value={value}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(
          setValue,
          { elementId: "input-for-income", prefixLen: 2 },
          { onEnterPress, onTabPress }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      autoComplete={forStore ? "off" : "on"}
      margin={props.margin ? props.margin : "dense"}
    />
  )
}
