import React from "react"
import { ShowPrice } from ".."
import { CalculatePriceFull } from "../../utils"

export function CarPrice({
  prices,
  appContext,
  center,
  setMoreInfo,
  chips = true,
}) {
  const PaymentInfo = CalculatePriceFull({ appContext, prices })

  return (
    <div className={`price-show`}>
      <ShowPrice
        appContext={appContext}
        PaymentInfo={PaymentInfo}
        data={{
          terms: appContext.setTerms,
          setMoreInfo,
          priceList: prices?.priceList,
        }}
        big
        center={center}
        fullClickeable
        chips={chips}
      ></ShowPrice>
    </div>
  )
}
