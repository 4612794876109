import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."
import { getModels, getVersions } from "../../api"

export const AppraiseStep1: React.FC<StepProps> = ({
  form,
  updateForm,
  toggleLoading,
  availableAppraiseYears,
}) => {
  const finishStep = async () => {
    toggleLoading()

    const cleanPlate = form.plate.replace(/·/g, "")
    const carInfo = {
      brand_id: form.brand,
      brand_name: form.info.brands?.find(b => b.value === form.brand)?.label,
      model_id: form.model,
      model_name: form.info.models?.find(b => b.value === form.model)?.label,
      year: form.year,
    }
    updateForm(prev => ({
      ...prev,
      info: {
        ...prev.info,
        carInfo: { ...carInfo, plate: cleanPlate },
      },
    }))
    // Cars older than 2012 are not appraised automatically
    if ((form.year || 0) <= 2012) {
      toggleLoading()
      return form.actions.thanksPage("Año del vehículo anterior a 2012")
    }
    // Decide if its necessary to refetch the info
    if (
      !form.info.carInfo ||
      form.info.carInfo.model_id !== carInfo.model_id ||
      form.info.carInfo.year !== carInfo.year ||
      !form.info.versions
    ) {
      const versions = await getVersions(carInfo.model_id, carInfo.year)
      if (versions.length > 2) {
        updateForm(prev => ({
          ...prev,
          info: {
            ...prev.info,
            versions: versions
              .slice(0, -2)
              .map(v => ({ value: v.id, label: v.name })),
          },
        }))
        form.actions.nextStep()
      } else {
        form.actions.thanksPage(
          "Patente no encontrada. Luego no se encontraron versiones para el modelo y año ingresados manualmente"
        )
      }
    } else {
      form.actions.nextStep()
    }

    toggleLoading()
  }

  const shouldDisableButton = !form.brand || !form.model || !form.year

  return (
    <>
      <CarInfo data={form} />
      <div className="title">¿Quieres recibir una oferta?</div>
      <div className="subtitle">Necesitamos el detalle de tu auto</div>
      <div className="component">
        <FormControl id="select-brand" variant="outlined">
          <InputLabel htmlFor="outlined-native-simple-4">Marca</InputLabel>
          <Select
            native
            value={form.brand || ""}
            onChange={async ({ target: { value } }) => {
              toggleLoading()
              const modelList = await getModels(value as number)
              updateForm(prev => ({
                ...prev,
                brand: value ? Number(value) : undefined,
                model: undefined,
                info: {
                  ...prev.info,
                  models: modelList.map(e => ({ value: e.id, label: e.name })),
                },
              }))
              toggleLoading()
            }}
            label="Marca"
            inputProps={{
              name: "brand",
              id: "outlined-native-simple-4",
            }}
          >
            <option aria-label="None" value="" />
            {form.info.brands.map((e, idx) => (
              <option key={idx} value={e.value}>
                {e.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl id="select-model" variant="outlined">
          <InputLabel htmlFor="outlined-native-simple-5">Modelo</InputLabel>
          <Select
            native
            value={form.model || ""}
            onChange={({ target: { value } }) => {
              updateForm(prev => ({
                ...prev,
                model: value ? Number(value) : undefined,
              }))
            }}
            label="Modelo"
            inputProps={{
              name: "model",
              id: "outlined-native-simple-5",
            }}
          >
            <option aria-label="None" value="" />
            {form.info.models ? (
              form.info.models.map((e, idx) => (
                <option key={idx} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <option aria-label="None" value="">
                Seleccione marca primero
              </option>
            )}
          </Select>
        </FormControl>

        <FormControl id="select-year" variant="outlined">
          <InputLabel htmlFor="outlined-native-simple-6">Año</InputLabel>
          <Select
            native
            value={form.year || ""}
            onChange={({ target: { value } }) => {
              updateForm(prev => ({
                ...prev,
                year: value ? Number(value) : undefined,
              }))
            }}
            label="Año"
            inputProps={{
              name: "year",
              id: "outlined-native-simple-6",
            }}
          >
            <option aria-label="None" value="" />
            {availableAppraiseYears.map((e, idx) => (
              <option key={idx} value={e.value}>
                {e.label}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-1"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next select-full-model"
          id="finish-step-1"
          type="primary"
          text="SIGUIENTE"
          disabled={shouldDisableButton}
          onClick={finishStep}
        />
      </div>
    </>
  )
}
