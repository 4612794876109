import React, { useCallback } from "react"
import { DCXButton } from ".."
import { getStandardAnimationProps } from "../../constants"

type FrameProps = {
  idx?: number
  image: string
  title?: string
  desc: string
  value?: unknown
  buttonText?: string
  buttonType?: string
  handleClick?: (value: unknown) => void
  animate?: boolean
}

export const Frame: React.FC<FrameProps> = ({
  idx = 0,
  image,
  title,
  desc,
  value,
  buttonText,
  buttonType,
  handleClick,
  animate,
}) => {
  const onClick = useCallback(() => handleClick && handleClick(value), [
    value,
    handleClick,
  ])
  return (
    <div className="frame" {...(animate ? getStandardAnimationProps(idx) : {})}>
      <img className="frame-image" src={image} alt="" />
      <div className="frame-title">{title}</div>
      <div className={`frame-desc ${!desc ? "hide" : ""}`}>{desc}</div>
      <DCXButton
        className={`button long-button ${!buttonText ? "hide" : ""}`}
        id="open-appraise-drawer-button"
        type={buttonType}
        text={buttonText}
        onClick={onClick}
      />
    </div>
  )
}
