import React, { memo } from "react"
import { FrameTemplate } from "../templates/FrameTemplate"
import { DCXButton } from "../../reusables/dcx-button"
import { CarSummaryStepImage } from "../../../assets"
import { CarType } from "../../../types"

type Props = {
  brand: string
  model: string
  year: string
  plate: string
  carType: CarType
  onChangeInfo: () => void
}

const CarSummaryStepComponent: React.FC<Props> = ({
  brand,
  model,
  year,
  plate,
  carType,
  onChangeInfo,
}) => {
  // const processedModel = model.replace(brand, "").trim()
  return (
    <FrameTemplate
      image={CarSummaryStepImage}
      title="¿Están bien los datos?"
      desc="Revísalos y si están correctos continúa"
    >
      <div className="insurance-drawer-step-content car-summary-step-content">
        <div className="summary">
          <span className="brand">{brand}</span>
          <span className="model">{model}</span>
          <span className="year">{year}</span>
          <span className={`plate ${carType === CarType.new ? "hidden" : ""}`}>
            {plate}
          </span>
        </div>
        <DCXButton
          type="tertiary"
          text="CAMBIAR INFORMACIÓN"
          arrow="right"
          onClick={onChangeInfo}
        />
      </div>
    </FrameTemplate>
  )
}

export const CarSummaryStep = memo(CarSummaryStepComponent)
