import React from "react"
import { DCXButton } from ".."
import { getStandardAnimationProps } from "../../constants"

export function ActionButton({ className, ...props }) {
  return (
    <div className={`action-button-container ${className || ""}`}>
      <div {...getStandardAnimationProps()}>
        <DCXButton
          className="button-buy"
          {...props}
          // type={type}
          // text={text}
          // onClick={onClick}
          // disabled={disabled}
        />
      </div>
    </div>
  )
}
