import React, { createContext, useState, useMemo, useCallback } from "react"
import {
  Insurance,
  Owner,
  InsuranceData,
  InsuranceContextType,
  InsuranceAdditionalData,
} from "../types/InsuranceEnum"
// import { useSpecialDevice } from "../hooks"

type Props = {
  children: React.ReactNode
}

const initialValues = {
  // [Insurance.carType]: CarType.new,
  [Insurance.owner]: Owner.me,
  [Insurance.dues]: 24,
  [Insurance.deductible]: 10,
}

export const InsuranceContext = createContext({} as InsuranceContextType)

export const InsuranceProvider: React.FC<Props> = ({ children }) => {
  const [insuranceData, setInsuranceData] = useState<InsuranceData>(() =>
    Object.assign(
      {},
      ...Object.values(Insurance).map(field => ({ [field]: null })),
      initialValues
    )
  )
  const [
    additionalData,
    setAdditionalData,
  ] = useState<InsuranceAdditionalData | null>(null)

  const cleanData = useCallback(() => {
    setInsuranceData(
      Object.assign(
        {},
        ...Object.values(Insurance).map(field => ({ [field]: null })),
        initialValues
      )
    )
    setAdditionalData(null)
  }, [setInsuranceData, setAdditionalData])

  const ctx = useMemo<InsuranceContextType>(
    () => ({
      insuranceData,
      setInsuranceData,
      additionalData,
      setAdditionalData,
      cleanData,
    }),
    [insuranceData, additionalData, cleanData]
  )

  return (
    <InsuranceContext.Provider value={ctx}>
      {children}
    </InsuranceContext.Provider>
  )
}
