import React from "react"
import { Link } from "gatsby"
import { ShowPrice, DCXButton, ShareButton } from "."
import {
  changanLogo,
  greatwallLogo,
  havalLogo,
  jacLogo,
  mazdaLogo,
  renaultLogo,
  suzukiLogo,
} from "../assets"
import {
  CalculatePriceFull,
  urlWithOffer,
  getDiscountPercentage,
  capitalize,
} from "../utils"

export function CardOffer({ offer, appContext, terms, ms }) {
  const {
    internalId,
    brand,
    modelName,
    versionName,
    year,
    imageUrl,
    colorName,
    colorHexa,
    priceList,
    priceCash,
    priceCredit,
    isFlash,
    isSold,
  } = offer

  const { setSelectedOffer } = appContext

  const PaymentInfo = CalculatePriceFull({
    appContext,
    offer,
    prices: { priceList, priceCash, priceCredit },
  })

  const discount = getDiscountPercentage(PaymentInfo.finalCarPrice, priceList)

  return (
    <div className={`common-card offer-card ${isSold ? "sold" : ""}`}>
      <div style={{ width: "100%" }}>
        <div className="card-content">
          <div className="card-top">
            <img className="brand-icon" src={getBrandLogo(brand)} alt="marca" />
            <div className="offer-names">
              <div className="model-name">{modelName}</div>
              <div className="version-name">{versionName}</div>
            </div>
          </div>

          <img className="photo" src={imageUrl} alt="" />

          <div className="model-color">
            <div className="circle" style={{ backgroundColor: colorHexa }} />
            <div className="color-name">{colorName}</div>
          </div>
          <div className="model-year">AUTO NUEVO - AÑO {year}</div>

          <div className="price">
            <ShowPrice
              appContext={appContext}
              PaymentInfo={PaymentInfo}
              data={{
                terms,
                setMoreInfo: () => {
                  setSelectedOffer(internalId)
                },
                priceList,
              }}
              center={true}
              from={false}
              discount={false}
              chips={false}
            ></ShowPrice>
          </div>
        </div>
        <div className={`card-bottom ${isSold ? "sold" : ""}`}>
          {isSold ? (
            <>
              <div className="sold-text">VENDIDO</div>
              <div className="sold-icon">
                <span role="img" aria-label="aplausos">
                  👏
                </span>
              </div>
            </>
          ) : (
            <>
              <Link to={urlWithOffer("/checkout/tax", internalId, false)}>
                <DCXButton
                  className="button-buy"
                  type="primary"
                  text="LO QUIERO"
                  onClick={() => setSelectedOffer(internalId)}
                />
              </Link>

              <ShareButton
                className="button-share"
                mobile={true}
                url={urlWithOffer("/checkout", internalId)}
                messageType="single-offer"
                modelName={capitalize(brand) + " " + modelName}
              />
            </>
          )}
        </div>
        <div className={`discount ${discount === 0 ? "hidden" : ""}`}>
          {getDiscountPercentage(PaymentInfo.finalCarPrice, priceList)}% DCTO{" "}
          <span role="img" aria-label="descuento">
            💰
          </span>
        </div>
        {isFlash && (
          <div className="flash">
            FLASH{" "}
            <span role="img" aria-label="rayo">
              ⚡
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

const getBrandLogo = brand => {
  switch (brand) {
    case "mazda":
      return mazdaLogo
    case "suzuki":
      return suzukiLogo
    case "renault":
      return renaultLogo
    case "changan":
      return changanLogo
    case "great_wall":
      return greatwallLogo
    case "haval":
      return havalLogo
    case "jac":
      return jacLogo
    default:
      return null
  }
}
