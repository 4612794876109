import React, { useCallback } from "react"
import { ShortcutCard, WrapperProps } from "./ShortcutCard"
import { ShortcutCardCreditItem } from "../../assets"
import { useUpdateStats, UpdateStatType } from "../../hooks"

export const CreditShortcutCard: React.FC<WrapperProps> = ({
  className,
  handleClick,
}) => {
  const [updateToCash] = useUpdateStats(
    "paymentConfig-payment-toggleToTraditionalCredit",
    UpdateStatType.CALLBACK
  )

  const onClick = useCallback(() => {
    updateToCash()
    handleClick()
  }, [handleClick])

  return (
    <div className={`shortcut-cards payment-card ${className}`}>
      <ShortcutCard
        icon={ShortcutCardCreditItem}
        title="EN CUOTAS"
        text="Revisa el precio en cuotas de los autos cambiándolo aquí"
        buttonText="VER PRECIO EN CUOTAS"
        handleClick={onClick}
      />
    </div>
  )
}
