import CryptoJS from "crypto-js"
import queryString from "query-string"
import { getInsuranceData, getSellerData, getLeadByUUID } from "../api"
import { Payment, Model, Session } from "../types"
import {
  checkValidSelections,
  getModelAccessories,
  getAccessoriesSelectionState,
  Base64ToNumber,
  NumberToBase64,
  deductSelectedVersion,
} from "."

const fillZero = (str: string, len: number): string =>
  "0".repeat(len - str.length) + str

const MINI_PAYMENT: {
  flexCredit: string
  credit: string
  cash: string
  0: string
  1: string
  2: string
} = {
  flexCredit: "0",
  credit: "1",
  cash: "2",
  0: Payment.flexCredit,
  1: Payment.credit,
  2: Payment.cash,
}

/**
 * El objeto de sessión es reducido en un string alfanumérico de 45 caracteres, para luego ser encriptado
 * Las posiciones de este string determinan las variables de la sessión, según la siguiente conversión:
 * (la notación [] incluye el borde derecho)
 *
 * [0] payment (que además es reducido según el diccionario MINI_PAYMENT)
 * [1:2] dues
 * [3:4] percentage
 * [5:12] CIP price
 * [13:18] CIP plate
 * [19:28] CIP date / 1000 (es decir, epoch time en segundos, 10 dígitos)
 * [29] transmission (Para las selecciones del configurador, un 0 significa ausencia de selección, null)
 * [30] equipment
 * [31] engine
 * [32] traction
 * [33:34] color
 * [35:36] model ID (for validating selections when rendering config page for first time)
 * [37] Pack patente (0 ó 1)
 * [38:39] Si seleccionó cada uno de sus accesorios ordenados por precio: [1,0,1,1,1]. (Supuestos: max 5 y precio cte)
 * [40:43] Insurance Simulation ID
 * [44] Seller ID
 * [45:81] Lead UUID
 **/
export const minifySession = (session: Session): string => {
  const {
    transmission,
    equipment,
    engine,
    traction,
    color,
    modelId,
    includePlatePack,
    selectedAccessories,
    insuranceSimulationId,
    sellerId,
    leadUUID,
  } = session
  const string =
    MINI_PAYMENT[session.payment] +
    (session.dues ? fillZero(String(session.dues), 2) : "00") +
    (session.percentage ? fillZero(String(session.percentage), 2) : "00") +
    (session.carPaymentInfo
      ? fillZero(String(session.carPaymentInfo.finalPrice), 8) +
        session.carPaymentInfo.plate +
        String(Math.round(session.carPaymentInfo.date / 1000))
      : "0".repeat(24)) +
    (transmission ? String(transmission) : "0") +
    (equipment ? String(equipment) : "0") +
    (engine ? String(engine) : "0") +
    (traction ? String(traction) : "0") +
    (color ? fillZero(String(color), 2) : "00") +
    (modelId ? fillZero(String(modelId), 2) : "00") +
    (includePlatePack !== undefined ? String(Number(includePlatePack)) : "1") +
    (selectedAccessories ? fillZero(String(selectedAccessories), 2) : "00") +
    (insuranceSimulationId
      ? fillZero(String(NumberToBase64(insuranceSimulationId)), 4)
      : "0000") +
    (sellerId ? NumberToBase64(sellerId) : "0") +
    (leadUUID ? leadUUID : "")
  return string
}

export const unminifySession = (string: string): Session => ({
  payment: MINI_PAYMENT[string.slice(0, 1)],
  dues: Number(string.slice(1, 3)),
  percentage: Number(string.slice(3, 5)),
  carPaymentInfo:
    string.slice(5, 29) === "0".repeat(24)
      ? null
      : {
          finalPrice: Number(string.slice(5, 13)),
          plate: string.slice(13, 19),
          date: Number(string.slice(19, 29)) * 1000,
        },
  transmission: Number(string.slice(29, 30)) || null,
  equipment: Number(string.slice(30, 31)) || null,
  engine: Number(string.slice(31, 32)) || null,
  traction: Number(string.slice(32, 33)) || null,
  color: Number(string.slice(33, 35)) || null,
  modelId: Number(string.slice(35, 37)) || null,
  includePlatePack: Boolean(Number(string.slice(37, 38))),
  selectedAccessories: Number(string.slice(38, 40)) || null,
  insuranceSimulationId: Base64ToNumber(string.slice(40, 44)) || null,
  sellerId: Base64ToNumber(string.slice(44, 45)) || null,
  leadUUID:
    string.slice(45, 82) === "0".repeat(36) ? null : string.slice(45, 82),
})

export const getQueryParams = (): queryString.ParsedQuery => {
  return typeof window !== "undefined"
    ? queryString.parse(window.location.search)
    : {}
}

export const infoToSessionString = (
  info: Record<string, unknown>,
  onlyReturn = false
): string | void => {
  // Get all query params
  const params = getQueryParams()
  // Decode session info, if exists
  let sessionInfo: Session = {}
  if (params.session) {
    try {
      sessionInfo = unminifySession(
        CryptoJS.AES.decrypt(
          params.session,
          process.env.GATSBY_SESSION_SECRET
        ).toString(CryptoJS.enc.Utf8)
      )
    } catch {
      // Remove session string from query params if corrupted
      // console.log("SESSION STRING NOT VALID. REMOVING IT FROM QUERY STRING")
      delete params.session
    }
  }

  // Update session info
  sessionInfo = {
    ...sessionInfo,
    ...info,
  }
  // Remove null values
  Object.keys(sessionInfo).forEach(
    key => sessionInfo[key] === null && delete sessionInfo[key]
  )
  // Update query string
  params.session = CryptoJS.AES.encrypt(
    minifySession(sessionInfo),
    process.env.GATSBY_SESSION_SECRET
  ).toString()

  const newQueryString = Object.keys(params).length
    ? "?" + queryString.stringify(params)
    : ""

  if (onlyReturn) return newQueryString

  typeof window !== "undefined" &&
    window.history.replaceState(
      {},
      "",
      window.location.origin + window.location.pathname + newQueryString
    )
}

export const decodeSessionString = (string: string): Session | null => {
  if (!string) return null
  let params = null
  try {
    params = unminifySession(
      CryptoJS.AES.decrypt(string, process.env.GATSBY_SESSION_SECRET).toString(
        CryptoJS.enc.Utf8
      )
    )
  } catch {
    throw { error: "Can't unminify session" }
  }
  return params
}

export const sessionStringToContext = async (
  model: any,
  queries: any,
  {
    setAllSelections,
    setDues,
    setMinDues,
    setMaxDues,
    setPercentage,
    setPayment,
    SetCarPaymentInfo,
    payment,
    dues,
    percentage,
    setCheckoutData,
  }: {
    setAllSelections: () => void
    setDues: (val: number, b: boolean) => void
    setMinDues: (val: number) => void
    setMaxDues: (val: number) => void
    setPercentage: (val: number, b: boolean) => void
    setPayment: (val: string, b: boolean) => void
    SetCarPaymentInfo: () => void
    payment: string
    dues: number
    percentage: number
    setCheckoutData: () => void
  },
  allAccessories: any,
  allModels: Model[],
  modelsPricesStocks: any,
  setSelectedVersion: (value: any) => void
): Promise<void> => {
  const params = decodeSessionString(queries.session)
  if (!params) {
    // Remove session string from query params if corrupted
    // console.log("SESSION STRING NOT VALID. REMOVING IT FROM QUERY STRING")
    delete queries.session
    const newQueries = Object.keys(queries).length
      ? "?" + queryString.stringify(queries)
      : ""
    typeof window !== "undefined" &&
      window.history.replaceState(
        {},
        "",
        window.location.origin + window.location.pathname + newQueries
      )
    return
  }

  const sessionModel = allModels?.find(model => model.ID === params.modelId)
  const selectedVersion = sessionModel
    ? deductSelectedVersion(sessionModel, params, modelsPricesStocks)
    : null
  if (selectedVersion) setSelectedVersion(selectedVersion)

  // If there is any option selected on session string, check them. Else, reset selections
  if (checkValidSelections(model, params) && selectedVersion) {
    setAllSelections(params, false, true)
  } else {
    setAllSelections({}, true, true)
  }

  // Set Payment params and Payment Info
  params.dues ? setDues(params.dues, false) : infoToSessionString({ dues })
  params.percentage
    ? setPercentage(params.percentage, false)
    : infoToSessionString({ percentage })
  params.payment
    ? setPayment(params.payment, false)
    : infoToSessionString({ payment })

  if (params.payment === Payment.cash) {
    setMinDues(0)
    setMaxDues(0)
  } else {
    setMaxDues(params.payment === Payment.flexCredit ? 36 : 60)
    setMinDues(params.payment === Payment.flexCredit ? 24 : 12)
  }

  // 2592000000 Miliseconds in a month
  if (
    params.carPaymentInfo &&
    params.carPaymentInfo.date > new Date().getTime() - 2592000000
  ) {
    SetCarPaymentInfo(params.carPaymentInfo, false)
  }

  //Set If Pack plate is included and set selected accessories model id
  const sessionSelectedAccessories = getAccessoriesSelectionState(
    params.selectedAccessories || 0
  )
  setCheckoutData(prev => ({
    ...prev,
    includePlatePack: params.includePlatePack,
    selectedAccessoriesModelId: params.modelId, // OJO, se va a setear siempre
    selectedAccessories: !allAccessories
      ? []
      : getModelAccessories(sessionModel, allAccessories).map((acc, idx) => ({
          ...acc,
          selected: sessionSelectedAccessories[idx],
        })),
  }))

  if (params.insuranceSimulationId) {
    const insuranceData = await getInsuranceData(params.insuranceSimulationId)
    if (insuranceData) {
      setCheckoutData((prev: any) => ({
        ...prev,
        insurance: {
          simulationId: params.insuranceSimulationId,
          selectedPlan: insuranceData.selectedPlan,
        },
      }))
    }
  }

  if (params.sellerId) {
    const sellerData = await getSellerData(params.sellerId)
    if (sellerData) {
      setCheckoutData((prev: any) => ({
        ...prev,
        sellerData,
      }))
    }
  }

  if (params.leadUUID) {
    const lead = await getLeadByUUID(params.leadUUID)
    if (lead) {
      const leadData = {
        leadUUID: params.leadUUID,
        client: lead.clientData,
        preApproved: lead.creditPreApproved,
      }
      setCheckoutData(prev => ({
        ...prev,
        ...leadData,
      }))
    }
  }
}
