import React from "react"
import App from "./src/App"
import LogRocket from "logrocket"

/*export const onClientEntry = () => {
  if (process.env.NODE_ENV !== "production") {
    const whyDidYouRender = require("@welldone-software/why-did-you-render")
    whyDidYouRender(React, {
      trackAllPureComponents: true,
      logOnDifferentValues: true,
    })
  }
}*/

export function wrapRootElement({ element }) {
  if (process.env.NODE_ENV === "production") {
    LogRocket.init("4obnxq/dercox")
  }

  return <App>{element}</App>
}
