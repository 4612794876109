import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  memo,
} from "react"
import { DrawerHeader, DrawerLoader } from ".."
import { Stepper, DoubleButton, SimpleButton } from "./subcomponents"
import {
  insuranceSimulate,
  insuranceSimulationFinish,
} from "../../api/insurance"
import { getDataPlate } from "../../api/appraise"
import SwipableViews from "react-swipeable-views"
import {
  CarouselStep,
  CarTypeStep,
  BrandStep,
  ModelStep,
  CarSummaryStep,
  OwnerStep,
  BirthdateStep,
  AddressStep,
  UserSummaryStep,
  InsuranceSelectionStep,
  InsuranceSummaryStep,
  ContactChannelStep,
  EmailStep,
  ThanksStep,
  PhoneStep,
  PlateStep,
  PlateFallbackStep,
} from "./steps"
import {
  useSwipableScroll,
  useInsuranceCommunesOptions,
  useBrandOptions,
  useModelOptions,
  useFlowSelection,
  FlowType,
} from "./hooks"
import {
  CarContext,
  InsuranceContext,
  UIContext,
  KeyboardContext,
} from "../../context"
import {
  InsuranceData,
  Insurance,
  ContactChannel,
  InsuranceSimulationOption,
  InsuranceContextType,
  BrandOption,
  ModelOption,
  CarType,
  Owner,
  DataType,
} from "../../types"
import { capitalizeWords, infoToSessionString } from "../../utils"
import { NameStepWrapper, RutStepWrapper } from "./stepWrappers"
import { useUpdateStats, UpdateStatType } from "../../hooks"

// type DrawerProps = {
//   models: Model[]
// }

enum InsuranceSteps {
  CarouselStep,
  CarType,
  PlateOrBrand,
  PlateFallbackOrModel,
  CarSummary,
  Owner,
  RutWrapper,
  NameWrapper,
  Birthdate,
  Addresss,
  UserSummary,
  InsuranceSelection,
  InsuranceSummary,
  ContactChannelSelection,
  EmailOrPhone,
  Thanks,
}

const getNextButtonText = (step: number): string => {
  if (step === InsuranceSteps.CarouselStep) return "COTIZA TU SEGURO"
  if (step === InsuranceSteps.InsuranceSummary) return "ME INTERESA"
  if (step === InsuranceSteps.Thanks) return "FINALIZAR"
  return "SIGUIENTE"
}
/* 
Se tuvo que harcodear y validar con un useEffect en este Drawer porque:
  a) No funcionaba validar en el Callback, porque éste no se ejecuta al entrar al componente. Una vista se mostraría como válida si la anterior lo era.
  b) No funcionaba validar en un Effect en cada Step monitoreando las variables, porque ReactSwipeableViews monta todos los componentes al principio, lo que era un caos para el booleano de validación.
*/
const STEPS_VALIDATIONS = (insuranceData: InsuranceData) => [
  [],
  /* 0*/ [Insurance.carType],
  /* 1*/ insuranceData[Insurance.carType] === CarType.used
    ? [Insurance.plate]
    : [Insurance.brandId],
  /* 2*/ insuranceData[Insurance.carType] === CarType.used
    ? [Insurance.brandId, Insurance.modelId, Insurance.year]
    : [Insurance.modelId],
  /* 3*/ [],
  /* 4*/ [Insurance.owner],
  /* 5*/ insuranceData[Insurance.owner] === Owner.business
    ? [Insurance.rut]
    : [Insurance.rut, Insurance.gender],
  /* 6*/ insuranceData[Insurance.owner] === Owner.business
    ? [Insurance.rz]
    : [Insurance.name, Insurance.lastname],
  /* 7*/ [Insurance.birthdate],
  /* 8*/ [Insurance.addressId],
  /* 9*/ [],
  /*10*/ [Insurance.dues, Insurance.companyId, Insurance.deductible],
  /*11*/ [],
  /*12*/ [Insurance.contactChannel],
  /*13*/ insuranceData[Insurance.contactChannel] === ContactChannel.email
    ? [Insurance.email]
    : [Insurance.phone],
  /*14*/ [],
]

const _DrawerInsurance: React.FC = () => {
  //TODO: Mejorar tipado
  const {
    insuranceData,
    additionalData,
    setInsuranceData,
    setAdditionalData,
  } = useContext<InsuranceContextType>(InsuranceContext)

  const { closeInsuranceDrawer, openDemoAlert } = useContext(UIContext)
  const { financeData, setCheckoutData } = useContext(CarContext)
  const { isKeyboardOpen } = useContext(KeyboardContext)

  const [flow, maxSteps, maxStepperValue, beginProgressOn] = useFlowSelection()
  const [step, setStep] = useState<number>(0)

  const [updateStat] = useUpdateStats("", UpdateStatType.CALLBACK)

  const addressOptions = useInsuranceCommunesOptions()
  const [brandOptions] = useBrandOptions({
    carType: insuranceData[Insurance.carType],
  })
  const [modelOptions] = useModelOptions({
    carType: insuranceData[Insurance.carType],
  })
  const { swipableViewRef, haveScroll, hasScrolled } = useSwipableScroll({
    step,
    threshold: 26,
  })

  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})
  const [isStepInputValid, setStepInputValid] = useState<boolean>(false)

  // Save selected brand, filtered model options & selected model objects, for being used on new NEW CAR flow
  const [selectedBrandOption, setSelectedBrandOption] = useState<
    BrandOption | undefined
  >()
  const [filteredModelOptions, setFilteredModelOptions] = useState<
    ModelOption[]
  >(modelOptions)
  const [selectedModelOption, setSelectedModelOption] = useState<
    ModelOption | undefined
  >()

  // Save last 13 years options, for being used on USED CAR flow
  const [yearsOptions, setYearsOptions] = useState<
    { label: string; value: string }[]
  >([])
  useEffect(
    () =>
      setYearsOptions(
        Array.from(
          Array(financeData?.otherParams?.availableInsuranceYears || 0).keys()
        ).map(val => ({
          value: String(new Date().getFullYear() - val),
          label: String(new Date().getFullYear() - val),
        }))
      ),
    [financeData?.otherParams?.availableInsuranceYears]
  )

  // Save insurance company options comming from backend api request
  const [insuranceOptions, setInsuranceOptions] = useState<
    InsuranceSimulationOption[]
  >([])

  const stepNavigate = useCallback(
    (dir: number) => () => {
      if (step + dir > maxSteps) {
        closeInsuranceDrawer()
        return
      }
      setStep(prev => Math.max(Math.min(prev + dir, maxSteps), 0))
    },
    [setStep, maxSteps, step]
  )

  const requestPlateData = useCallback(() => {
    setLoading(true)
    getDataPlate(insuranceData[Insurance.plate])
      .then(({ brand_name, model_name, year }) => {
        setLoading(false)
        const brandInsurance = brandOptions.find(
          b =>
            brand_name.includes(b.insuranceName) ||
            b.insuranceName.includes(brand_name)
        )
        const modelInsuranceCandidates = modelOptions.filter(
          m =>
            m.insuranceBrandId === brandInsurance?.insuranceId &&
            (model_name.includes(m.insuranceName) ||
              m.insuranceName.includes(model_name))
        )
        const modelInsurance = modelInsuranceCandidates.sort(
          (a, b) => a.insuranceName.length - b.insuranceName.length
        )[0]

        const yearInsurance = yearsOptions.find(y => y.value == year)

        if (brandInsurance && modelInsurance && yearInsurance) {
          setInsuranceData(prev => ({
            ...prev,
            modelId: modelInsurance.insuranceId,
            brandId: brandInsurance.insuranceId,
            year: yearInsurance.value,
          }))
          stepNavigate(2)()
        } else stepNavigate(1)()
      })
      .catch(() => {
        setLoading(false)
        stepNavigate(1)()
      })
  }, [
    insuranceData,
    stepNavigate,
    setInsuranceData,
    brandOptions,
    modelOptions,
  ])

  const requestSimulation = useCallback(() => {
    setLoading(true)
    insuranceSimulate(
      insuranceData,
      brandOptions,
      addressOptions,
      selectedModelOption
    )
      .then(({ simulationId, options }) => {
        setInsuranceData(prev => ({
          ...prev,
          [Insurance.simulationId]: simulationId,
        }))
        setAdditionalData(prev => ({
          ...prev,
          simulationId,
          dcxModelId: selectedModelOption?.dcxId,
        }))
        setInsuranceOptions(options)
        infoToSessionString({ insuranceSimulationId: simulationId })
        setLoading(false)
        setStep(prev => Math.max(Math.min(prev + 1, maxSteps), 0))
      })
      .catch(() => {
        // TODO: Redirect to error step
        setLoading(false)
        window.alert(
          "Lo sentimos. Hubo un problema simulando tu seguro. Por favor contáctanos para solucionarlo."
        )
      })
  }, [
    selectedModelOption,
    insuranceData,
    maxSteps,
    addressOptions,
    brandOptions,
    setInsuranceData,
    setAdditionalData,
    setInsuranceOptions,
    setStep,
    setLoading,
  ])

  const saveSimulation = useCallback(
    (dataType: DataType) => () => {
      setLoading(true)
      insuranceSimulationFinish(insuranceData, dataType)
        .then(() => {
          setLoading(false)
          const selectedPlan = findSelectedInsuranceOption(
            insuranceOptions,
            insuranceData
          )
          if (dataType === DataType.selectedPlan) {
            setAdditionalData(prev => ({
              ...prev,
              selectedPlan,
              UFvalue: financeData?.todayUF,
            }))
          }
          if (
            dataType === DataType.selectedPlan &&
            flow === FlowType.Checkout
          ) {
            infoToSessionString({
              insuranceSimulationId: additionalData?.simulationId,
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setCheckoutData((prev: any) => ({
              ...prev,
              insurance: {
                simulationId: additionalData?.simulationId,
                selectedPlan,
              },
              client: {
                ...prev.client,
                rut: insuranceData[Insurance.rut],
                name: insuranceData[Insurance.name],
                lastname: insuranceData[Insurance.lastname],
              },
            }))
            return closeInsuranceDrawer()
          }
          stepNavigate(1)()
        })
        .catch(() => {
          setLoading(false)
          window.alert(
            "Lo sentimos. Hubo un problema guardando tus datos. Por favor contáctanos para solucionarlo."
          )
        })
    },
    [
      flow,
      additionalData?.simulationId,
      insuranceData,
      insuranceOptions,
      maxSteps,
      financeData?.todayUF,
      setLoading,
      setStep,
      setAdditionalData,
      stepNavigate,
    ]
  )

  const onChange = useCallback(
    ({ name, value, hasError }) => {
      setInsuranceData(prev => ({ ...prev, [name]: value }))
      typeof hasError !== "undefined" &&
        setErrors(prev => ({ ...prev, [name]: hasError }))
    },
    [setInsuranceData, setStepInputValid, setErrors]
  )

  const onCarSummaryChangeInfo = useCallback(() => {
    if (insuranceData[Insurance.carType] === CarType.used)
      return stepNavigate(InsuranceSteps.PlateFallbackOrModel - step)()
    else return stepNavigate(InsuranceSteps.PlateOrBrand - step)()
  }, [step, insuranceData[Insurance.carType], stepNavigate])

  const getNextButtonCallback = useCallback(() => {
    if (
      step === InsuranceSteps.PlateOrBrand &&
      insuranceData[Insurance.carType] === CarType.used
    )
      return requestPlateData

    if (
      step === InsuranceSteps.NameWrapper &&
      insuranceData[Insurance.owner] === Owner.business
    )
      return stepNavigate(2)

    if (step === InsuranceSteps.UserSummary) return requestSimulation

    if (step === InsuranceSteps.InsuranceSummary)
      return saveSimulation(DataType.selectedPlan)

    if (step === InsuranceSteps.EmailOrPhone)
      return saveSimulation(DataType.contactData)

    if (step === InsuranceSteps.Thanks)
      return () => {
        stepNavigate(1)()
        openDemoAlert()
      }

    return stepNavigate(1)
  }, [
    step,
    insuranceData[Insurance.carType],
    insuranceData[Insurance.owner],
    requestPlateData,
    requestSimulation,
    saveSimulation,
    stepNavigate,
    openDemoAlert,
  ])

  const getBackButtonCallback = useCallback(() => {
    if (
      step === InsuranceSteps.Addresss &&
      insuranceData[Insurance.owner] === Owner.business
    )
      return stepNavigate(-2)
    return stepNavigate(-1)
  }, [
    step,
    // insuranceData[Insurance.carType],
    insuranceData[Insurance.owner],
    stepNavigate,
  ])

  // Input validations
  useEffect(() => {
    const varNames = STEPS_VALIDATIONS(insuranceData)[step]
    if (step < STEPS_VALIDATIONS(insuranceData).length && varNames.length) {
      const allDataExists = varNames.every(
        variable =>
          insuranceData[variable] !== null && insuranceData[variable] !== ""
      )
      const formErrors = varNames.some(variable => errors[variable])
      setStepInputValid(allDataExists && !formErrors)
    } else {
      setStepInputValid(true)
    }
  }, [step, insuranceData, errors, setStepInputValid])

  // Update selected brand based on selected brandId
  // Update models options based on selected brand
  useEffect(() => {
    if (!brandOptions.length) return
    const brandOption = brandOptions.find(
      brand => brand.insuranceId === insuranceData[Insurance.brandId]
    )
    setSelectedBrandOption(brandOption)
    setFilteredModelOptions(
      modelOptions
        .filter(
          model => model.insuranceBrandId === insuranceData[Insurance.brandId]
        )
        .sort(
          insuranceData[Insurance.carType] === CarType.new
            ? (a, b) => new Intl.Collator("es").compare(a.dcxName, b.dcxName)
            : (a, b) =>
                new Intl.Collator("es").compare(
                  a.insuranceName,
                  b.insuranceName
                )
        )
    )
  }, [
    brandOptions,
    modelOptions,
    insuranceData[Insurance.carType],
    insuranceData[Insurance.brandId],
    setSelectedBrandOption,
    setFilteredModelOptions,
  ])

  // Update selected model based on selected modelId
  useEffect(() => {
    filteredModelOptions.length &&
      setSelectedModelOption(
        filteredModelOptions.find(
          model => model.insuranceId === insuranceData[Insurance.modelId]
        )
      )
  }, [
    filteredModelOptions,
    insuranceData[Insurance.modelId],
    setSelectedModelOption,
  ])

  useEffect(() => {
    if (flow !== FlowType.Checkout) return
    stepNavigate(InsuranceSteps.Owner)()
  }, [flow])
  useEffect(() => {
    if (flow === FlowType.None) return

    const actualStep = step - (flow === FlowType.Checkout ? 5 : 0)
    if (actualStep >= 0)
      updateStat(
        `insurance-${
          flow === FlowType.Checkout ? "checkout-" : "general-"
        }step-${step}`
      )
  }, [step, flow])

  const simpleButton =
    flow === FlowType.General ? step === 0 : step === InsuranceSteps.Owner

  return (
    <div className="drawer-layout insurance-drawer">
      <DrawerLoader isOpen={loading} />
      <DrawerHeader
        title="COTIZA TU SEGURO"
        drawerName="insurance-drawer"
        closeDrawer={closeInsuranceDrawer}
      />
      <div className={`content ${isKeyboardOpen ? "keyboard-open" : ""}`}>
        <Stepper
          className={`stepper ${step - beginProgressOn <= 0 ? "hide" : ""}`}
          maxSteps={maxStepperValue}
          step={step - beginProgressOn}
          hide={hasScrolled}
        />
        <SwipableViews
          className={`swipable ${isKeyboardOpen ? "keyboard-open" : ""}`}
          axis="x"
          index={step}
          disabled
          ref={swipableViewRef}
        >
          <CarouselStep />
          <CarTypeStep
            carType={insuranceData[Insurance.carType]}
            newCarsYear={financeData?.otherParams?.newCarsYear}
            onChange={onChange}
          />
          {insuranceData[Insurance.carType] === CarType.used
            ? [
                <PlateStep
                  key="plate-step"
                  plate={insuranceData[Insurance.plate]}
                  onChange={onChange}
                />,
                <PlateFallbackStep
                  key="plate-fallback-step"
                  brandId={insuranceData[Insurance.brandId]}
                  brandOptions={brandOptions.map(brand => ({
                    value: brand.insuranceId,
                    label: brand.insuranceName,
                    group: brand.dcxId > 0 ? "Derco" : "Otros",
                  }))}
                  modelId={insuranceData[Insurance.modelId]}
                  modelOptions={filteredModelOptions.map(model => ({
                    label: model.insuranceName,
                    value: model.insuranceId,
                  }))}
                  year={insuranceData[Insurance.year]}
                  yearOptions={yearsOptions}
                  onChange={onChange}
                />,
              ]
            : [
                <BrandStep
                  key="brand-step"
                  brandId={insuranceData[Insurance.brandId]}
                  options={brandOptions.map(brand => ({
                    value: brand.insuranceId,
                    img: brand.logo as string,
                  }))}
                  onChange={onChange}
                />,
                <ModelStep
                  key="model-step"
                  modelId={insuranceData[Insurance.modelId]}
                  options={filteredModelOptions.map(model => ({
                    label: model.dcxName,
                    value: model.insuranceId,
                  }))}
                  onChange={onChange}
                />,
              ]}
          <CarSummaryStep
            brand={
              (insuranceData[Insurance.carType] === CarType.new
                ? selectedBrandOption?.dcxName
                : selectedBrandOption?.insuranceName) || ""
            }
            model={
              (insuranceData[Insurance.carType] === CarType.new
                ? selectedModelOption?.dcxName
                : selectedModelOption?.insuranceName) || ""
            }
            year={insuranceData[Insurance.year]}
            plate={insuranceData[Insurance.plate]}
            carType={insuranceData[Insurance.carType]}
            onChangeInfo={onCarSummaryChangeInfo}
          />
          <OwnerStep
            carType={insuranceData[Insurance.carType]}
            owner={insuranceData[Insurance.owner]}
            onChange={onChange}
          />
          <RutStepWrapper insuranceData={insuranceData} onChange={onChange} />
          <NameStepWrapper insuranceData={insuranceData} onChange={onChange} />
          <BirthdateStep
            birthdate={insuranceData[Insurance.birthdate]}
            name={insuranceData[Insurance.name]}
            owner={insuranceData[Insurance.owner]}
            onChange={onChange}
          />
          <AddressStep
            addressId={insuranceData[Insurance.addressId]}
            options={addressOptions.map(opt => ({
              value: `${opt.regionId}${opt.id}`,
              label: capitalizeWords(opt.name),
            }))}
            owner={insuranceData[Insurance.owner]}
            onChange={onChange}
          />
          <UserSummaryStep
            owner={insuranceData[Insurance.owner]}
            name={insuranceData[Insurance.name]}
            lastname={insuranceData[Insurance.lastname]}
            rut={insuranceData[Insurance.rut]}
            address={
              addressOptions.find(
                opt =>
                  `${opt.regionId}${opt.id}` ===
                  insuranceData[Insurance.addressId]
              )?.name || ""
            }
            rz={insuranceData[Insurance.rz]}
            birthdate={insuranceData[Insurance.birthdate]}
            onChangeInfo={stepNavigate(InsuranceSteps.RutWrapper - step)}
          />
          <InsuranceSelectionStep
            options={insuranceOptions}
            onChange={onChange}
            dues={insuranceData[Insurance.dues]}
            companyId={insuranceData[Insurance.companyId]}
            deductible={insuranceData[Insurance.deductible]}
            owner={insuranceData[Insurance.owner]}
          />
          <InsuranceSummaryStep
            option={findSelectedInsuranceOption(
              insuranceOptions,
              insuranceData
            )}
            brand={
              (insuranceData[Insurance.carType] === CarType.new
                ? selectedBrandOption?.dcxName
                : selectedBrandOption?.insuranceName) || ""
            }
            model={
              (insuranceData[Insurance.carType] === CarType.new
                ? selectedModelOption?.dcxName
                : selectedModelOption?.insuranceName) || ""
            }
            name={insuranceData[Insurance.name]}
            lastname={insuranceData[Insurance.lastname]}
            year={insuranceData[Insurance.year]}
            owner={insuranceData[Insurance.owner]}
          />
          <ContactChannelStep
            contactChannel={insuranceData[Insurance.contactChannel]}
            owner={insuranceData[Insurance.owner]}
            onChange={onChange}
          />
          {insuranceData[Insurance.contactChannel] === ContactChannel.email ? (
            <EmailStep
              email={insuranceData[Insurance.email]}
              owner={insuranceData[Insurance.owner]}
              onChange={onChange}
            />
          ) : (
            <PhoneStep
              phone={insuranceData[Insurance.phone]}
              owner={insuranceData[Insurance.owner]}
              onChange={onChange}
            />
          )}
          <ThanksStep owner={insuranceData[Insurance.owner]} />
        </SwipableViews>
      </div>
      <div className={`actions ${haveScroll ? "elevation" : ""}`}>
        <SimpleButton
          className={!simpleButton ? "hide" : ""}
          text={getNextButtonText(step)}
          onClick={stepNavigate(1)}
          disabled={!isStepInputValid}
        />
        <DoubleButton
          className={simpleButton ? "hide" : ""}
          onBackClick={getBackButtonCallback()}
          onNextClick={getNextButtonCallback()}
          disableNextButton={!isStepInputValid}
          nextText={getNextButtonText(step)}
        />
      </div>
    </div>
  )
}

const findSelectedInsuranceOption = (
  options: InsuranceSimulationOption[],
  insuranceData: InsuranceData
): InsuranceSimulationOption | undefined =>
  options.find(
    opt =>
      opt.dues === insuranceData[Insurance.dues] &&
      opt.deductible === insuranceData[Insurance.deductible] &&
      opt.companyId === insuranceData[Insurance.companyId]
  )

_DrawerInsurance.whyDidYouRender = true
export const DrawerInsurance = memo(_DrawerInsurance)
