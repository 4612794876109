import { useEffect, useState, useContext } from "react"
import { CarContext } from "../context"
import { checkUrl, checkComposedUrl } from "../utils"

export const useIncludeTaxes = (): [boolean] => {
  const { checkoutData } = useContext(CarContext)
  const { includePlatePack, platePackPageVisited } = checkoutData

  const [includeTaxes, setIncludeTaxes] = useState(false)

  useEffect(() => {
    if (
      checkUrl(["checkout"]) &&
      includePlatePack &&
      !(checkComposedUrl(["/checkout/accessories"]) && !platePackPageVisited)
    ) {
      setIncludeTaxes(true)
    }
  }, [includePlatePack, platePackPageVisited, setIncludeTaxes])

  return [includeTaxes]
}
