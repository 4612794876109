import { capitalize, buildColorOptions } from "."
import { Model, Session } from "../types"

export const getSelectionsSeparatedString = (
  model: Model,
  selections
): unknown => {
  if (!model || !selections) return null
  const { transmission, equipment, engine, traction, color } = selections
  const colorName = color?.colorSpanish
  return (
    (transmission ? transmission.main + " | " : "") +
    (equipment ? "Pack " + capitalize(equipment.name) + " | " : "") +
    (engine ? engine.main + " | " : "") +
    (traction ? traction.main + " | " : "") +
    (colorName || "")
  )
}

export const getVersionSeparatedString = (
  model: Model,
  vId: number,
  pricesStocks,
  selections,
  context
): unknown => {
  const { configOptions } = model

  const colorOptions = buildColorOptions(
    model,
    selections,
    pricesStocks,
    context
  )
  const selectedColor = colorOptions?.find(opt => opt.selected)
  return getSelectionsSeparatedString(model, {
    transmission: configOptions.transmissions.find(t =>
      t.versionIds.includes(vId)
    ),
    equipment: configOptions.packs.find(e => e.versionIds.includes(vId)),
    engine: configOptions.engines.find(e => e.versionIds.includes(vId)),
    traction: configOptions.tractions.find(t => t.versionIds.includes(vId)),
    color: selectedColor?.info,
  })
}

export const checkValidSelections = (
  model,
  { transmission, equipment, engine, traction, color, modelId }
): unknown => {
  if (!model) return true
  if (
    (transmission || equipment || engine || traction || color) &&
    model.ID !== modelId
  )
    return false
  return true
}

export const getSelectedOptions = (model: Model, selections): unknown => {
  if (!model) return {}
  // Find each option object based on selected ID
  return {
    transmission: model.configOptions.transmissions?.find(
      t => t.id === selections.transmission
    ),
    equipment: model.configOptions.packs?.find(
      pk => pk.id === selections.equipment
    ),
    engine: model.configOptions.engines?.find(
      eng => eng.id === selections.engine
    ),
    traction: model.configOptions.tractions?.find(
      tr => tr.id === selections.traction
    ),
    color: model.configOptions.colors?.find(c => c.id === selections.color),
  }
}

export const getSelectedModel = (
  allModels: Model[],
  selectedVersion: any
): Model | null =>
  allModels.find(model =>
    model.versions.map(v => v.id).includes(selectedVersion?.versionId)
  ) || null

export const getVersionIdsBySelections = (
  initialVersionIds: number[],
  model,
  selections,
  pricesStocks
): number[] => {
  if (!initialVersionIds || !model || !selections || !pricesStocks) return []
  let validVersionIds = initialVersionIds
  const {
    transmission,
    equipment,
    engine,
    traction,
    color,
  } = getSelectedOptions(model, selections)
  if (transmission)
    validVersionIds = validVersionIds.filter(vId =>
      transmission.versionIds.includes(vId)
    )
  if (equipment)
    validVersionIds = validVersionIds.filter(vId =>
      equipment.versionIds.includes(vId)
    )
  if (engine)
    validVersionIds = validVersionIds.filter(vId =>
      engine.versionIds.includes(vId)
    )
  if (traction)
    validVersionIds = validVersionIds.filter(vId =>
      traction.versionIds.includes(vId)
    )

  if (color)
    validVersionIds = validVersionIds.filter(vId => {
      const PS = pricesStocks[String(vId)]
      if (!PS) return false
      return pricesStocks[String(vId)].colorStocks.some(
        cs => cs.colorName === color.name
      )
    })
  return validVersionIds
}

export const deductSelectedVersion = (
  model: Model,
  session: Session,
  pricesStocks
): unknown => {
  let validVersionIds = model.versions.map(v => v.id)

  if (session.transmission) {
    const option = model.configOptions.transmissions.find(
      opt => opt.id === session.transmission
    )
    validVersionIds = validVersionIds.filter(id =>
      option.versionIds.includes(id)
    )
  }
  if (session.equipment) {
    const option = model.configOptions.packs.find(
      opt => opt.id === session.equipment
    )
    validVersionIds = validVersionIds.filter(id =>
      option.versionIds.includes(id)
    )
  }
  if (session.engine) {
    const option = model.configOptions.engines.find(
      opt => opt.id === session.engine
    )
    validVersionIds = validVersionIds.filter(id =>
      option.versionIds.includes(id)
    )
  }
  if (session.traction) {
    const option = model.configOptions.tractions.find(
      opt => opt.id === session.traction
    )
    validVersionIds = validVersionIds.filter(id =>
      option.versionIds.includes(id)
    )
  }
  // Check if we get a versionId
  const versionId = validVersionIds[0]
  if (!versionId) return null

  // Check if this versionId has an entry on pricesStocks
  const PS = pricesStocks[String(versionId)]
  if (!PS) return null

  // Check if the selected color has stock
  if (session.color) {
    const { color } = getSelectedOptions(model, { color: session.color })
    if (!PS.colorStocks.find(cs => cs.colorName === color.name)) return null
  }

  // Finally, return versionId and prices
  return {
    ...PS,
    versionId,
  }
}

export const cleanConfigSelections = (context, updateSession = true) => {
  context.setTransmission(null, updateSession)
  context.setEquipment(null, updateSession)
  context.setEngine(null, updateSession)
  context.setTraction(null, updateSession)
  context.setColor(null, updateSession)
}
