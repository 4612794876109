import React, {
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import HasUserIcon from "@material-ui/icons/Person"
import UserIcon from "@material-ui/icons/PersonOutlined"
import { SearchBar } from "@inventures/react-lib/components"
import { SearchSuggestions } from ".."
import { dcxWhiteLogo } from "../../assets"
import { CarContext, SearchContext, UIContext } from "../../context"
import { SessionMenu, MobileSellerMenu } from "./SessionMenu"
import { useSpecialDevice } from "../../hooks"
import {
  checkUrl,
  urlWithSession,
  removeSaleFromUrl,
  getFilteredModels,
  getBestSellingModels,
} from "../../utils"

import { Model } from "../../types"

type HeaderProps = {
  page: string
}

export const Header: React.FC<HeaderProps> = ({ page }) => {
  const { windowWidth, payment, modelsPricesStocks, checkoutData } = useContext(
    CarContext
  )
  const {
    openMobileMenu,
    openInsuranceDrawer,
    sessionMenuOpen,
    openSessionMenu,
    closeSessionMenu,
    sellerMenuOpen,
    closeSellerMenu,
  } = useContext(UIContext)
  const {
    isSearcherOpened,
    openSearcher,
    closeSearcher,
    modelSearch,
    setModelSearch,
    handleSearchEnter,
    activeModelSearch,
    showSearchSuggestions,
    setSearchSuggestions,
    openSearchSuggestions,
    closeSearchSuggestions,
    resetFilters,
    filters,
  } = useContext(SearchContext)

  const inputRef = useRef(null)
  const [isSpecialDevice] = useSpecialDevice()

  const {
    allModels: { nodes: allModels },
  } = useStaticQuery<{ allModels: { nodes: Model[] } }>(query)

  const stickyness = activeModelSearch || page === "comparator"

  // const [searchValue, handleSetSearchValue, searchResult] = useSearchBar(
  //   "",
  //   [{ name: "abcabc" }, { name: "defdef" }, { name: "ghighi" }],
  //   {
  //     isCaseSensitive: false,
  //     keys: ["name"],
  //     debounceTime: 2000,
  //   }
  // )
  // console.log({ searchValue, searchResult })

  const handleWrite = useCallback(
    e => {
      setModelSearch(String(e.target.value))
    },
    [setModelSearch]
  )

  const handleClickClearSearch = useCallback(
    value => {
      setModelSearch(String(value))
      closeSearcher()
    },
    [setModelSearch, closeSearcher]
  )

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      if (page === "index") navigate(urlWithSession("/home"))
      handleSearchEnter()
      inputRef.current.blur()
    },
    [page, handleSearchEnter, inputRef]
  )

  const handleClickNavigate = useCallback(
    page => () => {
      checkUrl([""]) && resetFilters()
      !checkUrl([page]) &&
        navigate(removeSaleFromUrl(urlWithSession(`/${page}`)))
    },
    [resetFilters]
  )

  const handleBlur = useCallback(() => {
    closeSearchSuggestions()
    if (!activeModelSearch) closeSearcher()
  }, [activeModelSearch, closeSearchSuggestions, closeSearcher])

  /* SEARCH SUGGESTIONS HANDLING */
  useEffect(() => {
    if (modelSearch) {
      // Build suggestions
      const filteredModels = getFilteredModels(
        allModels,
        modelSearch,
        filters,
        modelsPricesStocks,
        payment
      )
      // Set found models if found, best selling models if not
      if (filteredModels.length) {
        setSearchSuggestions(
          filteredModels.map(model => model.name).slice(0, 5)
        )
      } else {
        setSearchSuggestions(
          getBestSellingModels(allModels)
            .map(model => model.name)
            .slice(0, 5)
        )
      }
      if (!showSearchSuggestions) {
        openSearchSuggestions()
      }
    } else {
      setSearchSuggestions([])
      // if (showSearchSuggestions) {
      //   closeSearchSuggestions()
      // }
    }
  }, [
    allModels,
    modelSearch,
    filters,
    payment,
    modelsPricesStocks,
    showSearchSuggestions,
    setSearchSuggestions,
    openSearchSuggestions,
    closeSearchSuggestions,
  ])

  const [isMobile, setIsMobile] = useState(false)
  const searchIconRef = useRef(null)
  const sessionIconRef = useRef(null)
  const handleClickSessionMenu = useCallback(() => {
    openSessionMenu()
  }, [openSessionMenu])
  const handleCloseSessionMenu = useCallback(() => {
    closeSessionMenu()
  }, [closeSessionMenu])
  const handleCloseSellerMenu = useCallback(() => {
    closeSellerMenu()
  }, [closeSellerMenu])

  useEffect(() => {
    const onResize = () => {
      const query = window.matchMedia("(max-width: 1080px)").matches
      setIsMobile(query)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [setIsMobile])

  return (
    <>
      <header className={`header-page ${stickyness ? "sticky" : ""}`}>
        <IconButton
          className="menu"
          id="open-mobilemenu-header"
          aria-label="open drawer"
          onClick={openMobileMenu}
          edge="start"
        >
          <MenuIcon />
        </IconButton>

        <Link
          className={`logo-link ${isSearcherOpened ? "hide" : ""}`}
          id="header-logo"
          to={isSpecialDevice ? "/" : removeSaleFromUrl(urlWithSession("/"))}
        >
          <img src={dcxWhiteLogo} alt=""></img>
        </Link>

        <form
          className={`search-form ${isSearcherOpened ? "opened" : ""}`}
          onSubmit={handleSubmit}
          autoComplete="off"
          ref={searchIconRef}
        >
          <SearchBar
            className="search-bar"
            id="header-search-bar"
            showInput={isSearcherOpened}
            onCollapsedIconClick={openSearcher}
            onExpandedIconClick={handleClickClearSearch}
            value={modelSearch}
            onChange={handleWrite}
            // eslint-disable-next-line
            autoFocus={!modelSearch}
            size="small"
            iconColor="#FFFFFF"
            barColor="#FFFFFF"
            placeholder={`Busca por ${
              windowWidth > 420 ? "marca, " : ""
            }modelo o categoría`}
            inputRef={inputRef}
            onFocus={openSearchSuggestions}
            onBlur={handleBlur}
          />
        </form>

        <div className="links">
          <Button
            className="option"
            id="navigate-home-header"
            onClick={handleClickNavigate("home")}
          >
            AUTOS NUEVOS
          </Button>
          <Button
            className="option"
            id="navigate-howdoesitworks-header"
            onClick={handleClickNavigate("how-does-it-work")}
          >
            CÓMO FUNCIONA
          </Button>
          <Button
            className="option"
            id="open-insurance-drawer"
            onClick={openInsuranceDrawer}
          >
            COTIZAR SEGURO
          </Button>
          <IconButton
            className="option"
            onClick={handleClickSessionMenu}
            ref={sessionIconRef}
          >
            {checkoutData.sellerData ? <HasUserIcon /> : <UserIcon />}
          </IconButton>
          <SessionMenu
            open={sessionMenuOpen}
            anchorEl={isMobile ? searchIconRef.current : sessionIconRef.current}
            handleClose={handleCloseSessionMenu}
          />
          <MobileSellerMenu
            open={sellerMenuOpen}
            handleClose={handleCloseSellerMenu}
          />
          {false && (
            <Link
              className={`option special-offers ${
                page !== "offers" ? "blink" : ""
              }`}
              id="navigate-outlet-header"
              to={removeSaleFromUrl(urlWithSession("/offers"))}
            >
              <span>OUTLET</span>
              <span
                className={`icon ${page !== "offers" ? "blink" : ""}`}
                role="img"
                aria-label="auto"
              >
                🚗
              </span>
            </Link>
          )}
        </div>
      </header>
      <SearchSuggestions allModels={allModels} />
    </>
  )
}

export const query = graphql`
  query FULL_QUERY_8 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
  }
`
