import Fuse from "fuse.js"
import { getMinimunPrices } from "."
import { Payment } from "../types"

// MODELS
export const getFilteredModels = (
  allModels,
  modelSearch,
  filters,
  pricesStocks,
  payment,
  exactSearch
) => {
  if (!allModels || !filters || !pricesStocks) return allModels
  /* Filtering models by filters */
  const filteredModels = allModels.filter(model => {
    const matchBrands =
      filters.brand.length === 0 || filters.brand.includes(model.brand)

    const matchCategory =
      filters.category.length === 0 || filters.category.includes(model.category)

    const modelPrice = getMinimunPrices(
      pricesStocks,
      model.versions.map(v => v.id),
      { allowOutOfStock: true }
    )?.[payment === Payment.cash ? "priceCash" : "priceCredit"]

    const matchBudget =
      filters.budget[0] < modelPrice && modelPrice < filters.budget[1]

    return matchBrands && matchCategory && matchBudget
  })

  /* Early return when not active search */
  if (!modelSearch) return filteredModels

  /* Exact search by clicking on a suggested model */
  if (exactSearch) {
    return filteredModels.filter(model => model.name === modelSearch)
  }

  /* Exact search by writing an exact category */
  const modelsByExactCategory = filteredModels.filter(
    model => model.category.toLowerCase() === modelSearch.trim().toLowerCase()
  )
  if (modelsByExactCategory.length) {
    return modelsByExactCategory
  }

  // Initialize fuse searcher
  const fuseSearcher = new Fuse(filteredModels, {
    keys: [
      { name: "brand", weight: 2 },
      { name: "name", weight: 2 },
      { name: "category", weight: 2 },
      { name: "keywords", weight: 2 },
    ],
    useExtendedSearch: true,
    threshold: 0.4,
    distance: 1000,
  })
  //Allow searching by multiple models and single number library bug
  const parsedSearch = modelSearch.replace(/,/g, " | ").replace(/\d+/g, " $& ")
  return fuseSearcher.search(parsedSearch).map(e => e.item)
}

export const getBestSellingModels = allModels =>
  allModels.filter(model => model.mostSold)

// ACCESSORIES
export const getModelAccessories = (model, accessories) =>
  model
    ? accessories
        .filter(acc => acc.stock)
        .filter(acc => !acc.brand || acc.brand === model.brand)
        .filter(acc => !acc.model || acc.model === model.name)
        .sort((acc1, acc2) =>
          acc1.price === acc2.price
            ? new Intl.Collator("es").compare(acc1.name, acc2.name)
            : acc1.price - acc2.price
        )
    : []
