import React, { useCallback, useContext, memo } from "react"
import moment from "moment"
import { QuestionTemplate } from "../templates"
import { parseNumber } from "../../../utils"
import { InsuranceCompanyOption } from "../subcomponents"
import { DCXButtonGroup } from "../../reusables/DCXButtonGroup"
import { DCXToggleButtonGroup } from "../../reusables/DCXToggleButtonGroup"
import { URLS } from "../../../constants"
import {
  NumberOption,
  Insurance,
  OnChange,
  InsuranceSimulationOption,
  Owner,
} from "../../../types"
import { CarContext } from "../../../context"

type Props = {
  options: InsuranceSimulationOption[]
  companyId: string
  dues: number
  deductible: number
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "Elige tu seguro",
  [Owner.other]: "Elige el seguro",
  [Owner.business]: "Elige el seguro",
}

const InsuranceSelectionStepComponent: React.FC<Props> = ({
  options,
  dues,
  deductible,
  companyId,
  owner,
  onChange,
}) => {
  const { financeData } = useContext(CarContext)
  const todayUF = financeData?.todayUF
  const validity = 15
  const expirationDate = moment().add(validity, "days").format("DD/MM/YYYY")

  const onDuesChange = useCallback(
    (value: number) => onChange({ name: Insurance.dues, value }),
    [onChange]
  )

  const onDeductibleClick = useCallback(
    (value: number) => onChange({ name: Insurance.deductible, value }),
    [onChange]
  )

  const onCompanyClick = useCallback(
    (value: string) => () => onChange({ name: Insurance.companyId, value }),
    [onChange]
  )

  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content insurance-selection-step">
        <div className="dues-container">
          <span className="dues-text">Plazo</span>
          <DCXToggleButtonGroup
            value={dues}
            options={duesOptions}
            onChange={onDuesChange}
          />
        </div>
        <div className="deductible-container">
          <div className="text-container">
            <span>¿Qué deducible quieres?</span>
            <span>{`$${parseNumber(Number(deductible) * todayUF)}*`}</span>
          </div>
          <DCXButtonGroup
            buttons={deductibleOptions}
            selectedValue={deductible}
            onClick={onDeductibleClick}
          />
        </div>
        <div className="companies-container">
          {options
            .filter(
              option => option.dues === dues && option.deductible === deductible
            )
            .sort((o1, o2) => o1.monthlyCostUf - o2.monthlyCostUf)
            .map(option => (
              <InsuranceCompanyOption
                key={option.companyId}
                selected={companyId === option.companyId}
                onClick={onCompanyClick(option.companyId)}
                content={{
                  ...option,
                  deductible: undefined,
                  monthlyCost: option.monthlyCostUf * todayUF,
                }}
              />
            ))}
        </div>
        <div className="insurance-disclaimer">
          * Los valores en pesos variarán de acuerdo al valor de la UF del día.
          Cotización válida hasta el {expirationDate}. Medio de pago Tarjeta de
          Crédito (puede no ser del dueño del auto). Ver{" "}
          <a
            id="view-tac-link"
            href={URLS.termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Términos y condiciones
          </a>
          .
        </div>
      </div>
    </QuestionTemplate>
  )
}

export const InsuranceSelectionStep = memo(InsuranceSelectionStepComponent)

const duesOptions = [12, 24].map(value => ({
  name: value + " MESES",
  value,
})) as [NumberOption, NumberOption]

const deductibleOptions = [0, 3, 5, 10].map(value => ({
  name: value + " UF",
  value,
})) as NumberOption[]
