import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { Insurance, OnChange, Owner } from "../../../types"
import isEqual from "lodash/isEqual"

type Props = {
  addressId: string
  options: { value: string; label: string }[]
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿En qué comuna vives?",
  [Owner.other]: "¿En qué comuna vive?",
  [Owner.business]: "¿En qué comuna se encuentra?",
}
const AddressStepComponent: React.FC<Props> = ({
  addressId,
  options,
  owner,
  onChange,
}) => {
  const _onChange = useCallback(
    (_, option) => {
      onChange({
        name: Insurance.addressId,
        value: option?.value || "",
      })
    },
    [onChange]
  )
  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content">
        <Autocomplete
          id="insurance-outlined-address-input"
          options={[{ label: "", value: "" }, ...options]}
          getOptionLabel={option => option.label}
          openOnFocus
          value={
            options.find(a => a.value === addressId) || {
              label: "",
              value: "",
            }
          }
          onChange={_onChange}
          renderInput={params => (
            <TextField {...params} label="Comuna" variant="outlined" />
          )}
          getOptionSelected={(option, selected) =>
            option.value === selected.value
          }
          noOptionsText="Sin resultados"
        />
      </div>
    </QuestionTemplate>
  )
}

export const AddressStep = memo(AddressStepComponent, (prev, next) => {
  const address = prev.addressId === next.addressId
  let options = prev.options.length === next.options.length
  if (options) {
    for (let i = 0; i < prev.options.length && options; i++) {
      options = isEqual(prev.options[i], next.options[i])
    }
  }
  return address && options
})
