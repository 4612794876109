import React from "react"
import { DCXButton } from ".."
import { getStandardAnimationProps } from "../../constants"

export function DownloadSheet({ model }) {
  return (
    <div className="download-technical-sheet">
      <div className="description">
        {`Para detalle de especificaciones y verificación de esta información resumida revise la ficha técnica del ${model.name} en el siguiente botón. Frente a cualquier duda puede dirigirse a nuestros locales Dercocenter X o chatear con nosotros en `}
        <a href="https://dercocenterx.cl">www.dercocenterx.cl</a>
      </div>
      <div {...getStandardAnimationProps()}>
        <DCXButton
          className="download-button"
          type="secondary"
          text="DESCARGA LA FICHA TÉCNICA"
          onClick={() => window.open(model.technicalSheetUrl, "_blank")}
        />
      </div>
    </div>
  )
}
