import React from "react"
import { DrawerBase } from "../../components/DrawerBase"
import { DrawerInsurance } from "../../components/insurance/DrawerInsurance"

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const DrawerInsuranceWrapper: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  return (
    <DrawerBase isOpen={isOpen} onClose={onClose}>
      <DrawerInsurance />
    </DrawerBase>
  )
}
