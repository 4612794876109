import { checkUrl } from "."

const shareWhatsapp = ({ URL, messageType, modelName, callback }) => {
  const text = buildShareMessage(
    URL || window.location.href,
    messageType || deductMessageType(),
    modelName || "auto"
  )
  window.open("https://api.whatsapp.com/send?text=" + encodeURIComponent(text))
  callback && callback()
}

const deductMessageType = () => {
  const types = ["", "model", "config", "offers"]
  const currPath = window.location.pathname
  const currSearch = window.location.search

  // First, test for types that are called the same that the pathname
  for (let type of types) {
    if (currPath.includes(type)) return type
  }
  // Then, when viewing the checkout, deduct if its an offer
  if (checkUrl(["checkout"]))
    return currSearch.includes("sale=") ? "checkout-offer" : "checkout-new"

  return null
}

const buildShareMessage = (url, type, modelName) => {
  if (type === "home")
    return `Hola! 👋\nMira estos autos nuevos que encontré 🚘:\n${url}`
  if (type === "model")
    return `Hola! 👋\nMira este ${modelName} nuevo que encontré 🚘:\n${url}`
  if (type === "config")
    return `Hola! 👋\nMira este ${modelName} nuevo que diseñé 🚘:\n${url}`
  if (type === "offers")
    return `Hola! 👋\nMira estos autos nuevos en oferta que encontré 🚘:\n${url}`
  if (type === "single-offer")
    return `Hola! 👋\nMira este ${modelName} nuevo en oferta que encontré 🚘:\n${url}`
  if (type === "checkout-new")
    return `Hola! 👋\nMira este ${modelName} nuevo que estoy a punto de comprar 🚘:\n${url}`
  if (type === "checkout-offer")
    return `Hola! 👋\nMira este ${modelName} nuevo en oferta que estoy a punto de comprar 🚘:\n${url}`

  return `Hola! 👋\nMira este increíble sitio web que vende autos nuevos! 🚘\n${url}`
}

export { shareWhatsapp, buildShareMessage, deductMessageType }
