import { useContext, useEffect, useState } from "react"
import { CarContext } from "../context"
import { getSelectedModel, checkUrl } from "../utils"
import { useSurchargeAmount, usePremiumColor } from "."
import { useAllModels } from "./useAllModels"
import { Model } from "../types"

type Prices = { priceList: number; priceCash: number; priceCredit: number }

type Params = {
  model?: Model
  prices: Prices
  colorId?: number
}
type Results = [Prices]
export const useSurchargedPrices = ({
  model,
  prices,
  colorId,
}: Params): Results => {
  const { selectedVersion, color: contextColor } = useContext(CarContext)
  const [allModels] = useAllModels()

  const [selectedModel, setSelectedModel] = useState<Model | null>(null)
  const [shouldSurcharge, setShouldSurcharge] = useState(false)
  const finalColorId = colorId || contextColor

  const [surchargeAmount] = useSurchargeAmount()
  const [isColorPremium] = usePremiumColor({
    colorId: finalColorId,
    selectedModel,
    selectedVersionId: selectedVersion?.versionId,
  })

  useEffect(() => {
    setSelectedModel(model || getSelectedModel(allModels, selectedVersion))
  }, [model, allModels, selectedVersion])

  // Save color from context only when needed
  useEffect(() => {
    // console.log("executing useEffect")
    if (!checkUrl(["home", "model", "comparador"])) {
      setShouldSurcharge(true)
    }
  }, [])

  return [
    isColorPremium && shouldSurcharge
      ? {
          ...prices,
          priceList: prices.priceList + surchargeAmount,
          priceCash: prices.priceCash + surchargeAmount,
          priceCredit: prices.priceCredit + surchargeAmount,
        }
      : prices,
  ]
}
