import React from "react"
import { Tooltip } from "@material-ui/core"

export function OptionColorsSection({ colors }) {
  return colors ? (
    <div className="option-colors-container">
      <span className="text">Colores</span>
      <div className="colors">
        {colors?.map((item, idx) => (
          <Tooltip
            key={idx}
            title={item.displayName}
            leaveDelay={100}
            aria-label={item.displayName}
          >
            <div className="color" style={item.style}></div>
          </Tooltip>
        ))}
      </div>
    </div>
  ) : null
}
