import React from "react"
import { Carousel2 } from "../../reusables"
import { Frame } from "../.."
import { insuranceInfoFramesData } from "../../../constants"

export const CarouselStep: React.FC = () => {
  return (
    <div className="insurance-drawer-step-content carousel-step-content">
      <Carousel2
        breakpoints={{ every: { breakpoint: { min: 0, max: 4000 }, items: 1 } }}
        autoPlay={5}
      >
        {insuranceInfoFramesData.map((frame, idx) => (
          <Frame key={idx} {...frame} />
        ))}
      </Carousel2>
    </div>
  )
}
