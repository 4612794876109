import { useState } from "react"
import { SPECIAL_DEVICE } from "../types"

type Results = [boolean, SPECIAL_DEVICE | null]

export const useSpecialDevice = (): Results => {
  const [storePage] = useState<SPECIAL_DEVICE | null>(
    () =>
      (process.env.GATSBY_SPECIAL_DEVICE?.trim().toUpperCase() as SPECIAL_DEVICE) ||
      null
  )
  return [!!storePage, storePage]
}

/* Usage: */
// const [isStorePage, storePage] = useSpecialDevice()
