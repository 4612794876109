import { useCallback, useEffect } from "react"
import { updateStat } from "../api"

export enum UpdateStatType {
  EFFECT = "effect",
  CALLBACK = "callback",
}

export const useUpdateStats = (
  key: string,
  type: UpdateStatType = UpdateStatType.EFFECT
): [(v?: string) => void] => {
  useEffect(() => {
    if (type !== UpdateStatType.EFFECT) return
    updateStat(key)
  }, [key, type])

  const cb = useCallback(
    v => {
      if (type !== UpdateStatType.CALLBACK) return
      updateStat(v || key)
    },
    [key, type]
  )

  return [cb]
}
