import React from "react"
import { Button } from "@material-ui/core"

type Props = {
  icon: string
  text: string
  selected?: boolean
  onClick: () => void
}

export const ContactChannelOption: React.FC<Props> = ({
  icon,
  text,
  selected,
  onClick,
}) => {
  return (
    <Button
      className={`insurance-contact-option ${selected ? "selected" : ""}`}
      onClick={onClick}
      startIcon={<img src={icon} alt="Ícono de medio de contacto" />}
    >
      {text}
    </Button>
  )
}
