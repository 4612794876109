import React, { useState, useContext } from "react"
import { Image, OptionEquipment, DrawerHeader } from "."
import { CarContext, UIContext } from "../context"
import {
  KeyboardArrowDownIcon,
  KeyboardArrowRightIcon,
  getIcon,
} from "../assets"
import {
  getVersionSeparatedString,
  getVersionName,
  getSelectedColorName,
  getMostExpensiveVersionName,
} from "../utils"

export function DrawerVersionDetails({ model, selections, context }) {
  const { modelsPricesStocks } = useContext(CarContext)
  const { closeDetailsDrawer } = useContext(UIContext)
  //console.log("MODEL:", model)
  //console.log("SELECTED VERSION:", context.selectedVersion)

  const vId = context.selectedVersion?.versionId || 0
  const dimensionSpecs = getDimensionSpecs(model)
  const specsCategories = getSpecsCategories(model)

  // Use array of booleans to control opened categories. Position 0 goes for dimensions
  const [openStates, setOpenStates] = useState(
    [false].concat(specsCategories.map(() => false))
  )

  return (
    <div className="drawer-layout drawer-details">
      <DrawerHeader
        // className="details-drawer-header"
        title="DETALLES"
        drawerName="details-drawer"
        closeDrawer={closeDetailsDrawer}
      />
      <div className="content">
        <div className="presentation">
          <Image
            filename={model.colorGallery
              .find(
                item =>
                  item.colorName.name ===
                  getSelectedColorName(model, selections)
              )
              .image.url.split("/")
              .pop()}
          ></Image>
          <div className="info">
            <div className="disclaimer">
              Foto corresponde a versión{" "}
              {getMostExpensiveVersionName(model, modelsPricesStocks)}
            </div>
            <div className="model-name">{model.name}</div>
            <div className="version-name">
              {getVersionName(model, context.selectedVersion?.versionId)}
            </div>
            <div className="selections">
              {getVersionSeparatedString(
                model,
                vId,
                context.modelsPricesStocks,
                selections,
                context
              )}
            </div>
          </div>
        </div>
        <div className="categories">
          <div
            className="category-selector"
            id="details-drawer-category-0"
            role="button"
            tabIndex={0}
            aria-label="Abrir categoría Dimensiones"
            onKeyDown={e =>
              e.key === "Enter" &&
              setOpenStates(
                openStates.map((_, idx) =>
                  idx === 0 ? !openStates[idx] : false
                )
              )
            }
            onClick={() =>
              setOpenStates(
                openStates.map((_, idx) =>
                  idx === 0 ? !openStates[idx] : false
                )
              )
            }
          >
            <span>Dimensiones</span>
            <KeyboardArrowDownIcon
              className={`arrow ${openStates[0] ? "active" : ""}`}
            />
          </div>
          <div className={`category-content ${openStates[0] ? "active" : ""}`}>
            <div className="icons-list">
              {dimensionSpecs.map((spec, i) => (
                <div className="detail" key={i}>
                  <img
                    className="icon"
                    src={getIcon(spec.icon.name, "black")}
                    alt=""
                  ></img>
                  <div className="title-detail">{spec.name}</div>
                  <div className="explain">{spec.value}</div>
                </div>
              ))}
            </div>
          </div>

          {specsCategories.map((cat, index) => (
            <>
              <div
                className="category-selector"
                id={`details-drawer-category-${index + 1}`}
                role="button"
                tabIndex={0}
                aria-label={`Abrir categoría ${cat.name}`}
                onClick={() =>
                  setOpenStates(
                    openStates.map((_, idx) =>
                      idx === index + 1 ? !openStates[idx] : false
                    )
                  )
                }
                onKeyDown={e =>
                  e.key === "Enter" &&
                  setOpenStates(
                    openStates.map((_, idx) =>
                      idx === index + 1 ? !openStates[idx] : false
                    )
                  )
                }
              >
                <span>{cat.name}</span>
                <KeyboardArrowDownIcon
                  className={`arrow ${openStates[index + 1] ? "active" : ""}`}
                />
              </div>
              <div
                className={`category-content ${
                  openStates[index + 1] ? "active" : ""
                }`}
              >
                {index === 1 ? (
                  getEquipmentOption(model, vId)
                ) : (
                  <div className="text-list">
                    {getCategorySpec(
                      cat.options.find(opt => opt.versionIds.includes(vId))
                    )}
                  </div>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  )
}

const getDimensionSpecs = model => {
  return model.dimensionSpecs.overall.concat(model.dimensionSpecs.lengths)
}

const getEquipmentOption = (model, vId) => {
  const equipment = model.configOptions.packs.find(pack =>
    pack.versionIds.includes(vId)
  )
  return (
    <OptionEquipment
      option={{
        info: equipment,
        isFirstPack: !Boolean(equipment.previousEquip),
      }}
      alwaysExpanded
      withoutPriceSection
    />
  )
}

const getCategorySpec = option => {
  return (
    <div className="item">
      <KeyboardArrowRightIcon className="icon" color="primary" />
      <div className="text">{option.name}</div>
    </div>
  )
}

const getSpecsCategories = (model, vId) =>
  model.technicalData.sections.map(section => ({
    ...section,
    option: section.options?.find(opt => opt.versionIds.includes(vId)),
  }))
