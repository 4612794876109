import React, { useEffect, useState, useCallback, useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { DCXButton, DrawerHeader, BudgetSlider } from "."
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  Switch,
  Divider,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { CarContext, SearchContext, UIContext } from "../context"
import { getFilteredModels } from "../utils"
import { BUDGET_FILTER } from "../constants"

export function DrawerFilters() {
  const { payment, modelsPricesStocks } = useContext(CarContext)
  const { filters, setFilters, resetFilters, areFiltersActive } = useContext(
    SearchContext
  )
  const { closeFiltersDrawer } = useContext(UIContext)
  // Get all car models
  const data = useStaticQuery(query)
  const allModels = data.allModels.nodes

  const [allOptions] = useState({
    brand: Array.from(new Set(allModels.map(model => model.brand))),
    category: Array.from(new Set(allModels.map(model => model.category))),
  })

  const handleSliderChange = useCallback(
    (_, newValue) =>
      setFilters(prev => ({
        ...prev,
        budget: newValue,
      })),
    [setFilters]
  )

  const handleChange = useCallback(
    ({ target: { name, checked } }) => {
      const [type, property, value] = name.split("-")
      if (type === "switch") {
        setFilters(prev => ({
          ...prev,
          [property]: checked ? allOptions[property] : [],
        }))
      }
      if (type === "checkbox") {
        setFilters(prev => ({
          ...prev,
          [property]: checked
            ? prev[property].concat([value])
            : prev[property].filter(opt => opt !== value),
        }))
      }
    },
    [setFilters, allOptions]
  )

  // State to store filtered models and avoid recalculating on every rerender
  const [filteredModels, setFilteredModels] = useState(0)
  useEffect(() => {
    const numberFiltered = getFilteredModels(
      allModels,
      "",
      filters,
      modelsPricesStocks,
      payment
    ).length
    setFilteredModels(numberFiltered)
  }, [allModels, filters, modelsPricesStocks, payment])

  return (
    <div className="drawer-layout drawer-filters">
      <DrawerHeader
        // className="filters-drawer-header"
        title="FILTROS"
        drawerName="filters-drawer"
        closeDrawer={closeFiltersDrawer}
      />
      <div className="content">
        {["brand", "category"].map((property, idx) => (
          <React.Fragment key={property}>
            <Accordion key={property}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <span className="title">{PROPERTY_DATA[property].name}</span>
                <span className="selected">
                  {parseSelectedOptions(filters, allOptions, property)}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="option switch">
                  <span className="option-text">
                    Todas las {PROPERTY_DATA[property].name}s
                  </span>
                  <Switch
                    name={`switch-${property}`}
                    checked={allOptions[property].every(opt =>
                      filters[property].includes(opt)
                    )}
                    onChange={handleChange}
                    inputProps={{
                      "aria-label": "seleccionar todas las marcas",
                    }}
                  />
                </div>
                {getOptions(allModels, property)
                  .sort((a, b) => b.count - a.count)
                  .map(opt => (
                    <div className="option" key={opt.name}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={`checkbox-${property}-${opt.name}`}
                            checked={filters[property].includes(opt.name)}
                            onChange={handleChange}
                          />
                        }
                        label={opt.name}
                      />
                      <span className="option-text">{opt.count}</span>
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
            <Divider className={idx !== 0 ? "hide" : ""} />
          </React.Fragment>
        ))}
        <Divider />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <span className="title">presupuesto</span>
            <span className="selected">{parseSelectedBudget(filters)}</span>
          </AccordionSummary>
          <AccordionDetails>
            <BudgetSlider
              value={filters.budget}
              onChange={handleSliderChange}
            />
          </AccordionDetails>
        </Accordion>
        <Divider />
      </div>
      <div className="sticky-footer">
        <div className="remaining-models">
          <span role="img" aria-label="ícono de automóvil">
            🚗
          </span>{" "}
          Tenemos {filteredModels} modelos
          {areFiltersActive ? " en esa selección" : ""}
        </div>
        <div className="buttons-container">
          <DCXButton type="tertiary" text="LIMPIAR" onClick={resetFilters} />
          <DCXButton
            type="primary"
            text="APLICAR"
            onClick={closeFiltersDrawer}
          />
        </div>
      </div>
    </div>
  )
}

const getOptions = (allModels, property) => {
  return allModels.reduce((summary, model) => {
    const sum = summary.find(s => s.name === model[property])
    if (sum) sum.count += 1
    else summary.push({ name: model[property], count: 1 })
    return summary
  }, [])
}

const parseSelectedOptions = (filters, allOptions, property) => {
  const selected = filters[property]
  const n = selected.length
  if (n === allOptions[property].length) return "Todas"
  if (n > PROPERTY_DATA[property].max) return n + " seleccionadas"
  else return selected.join(", ")
}

const parseSelectedBudget = filters => {
  if (filters.budget[0] === 0 && filters.budget[1] === BUDGET_FILTER.max)
    return ""
  if (filters.budget[0] > 0 && filters.budget[1] === BUDGET_FILTER.max)
    return `desde ${filters.budget[0] / 1000000} millones`
  if (filters.budget[0] === 0 && filters.budget[1] < BUDGET_FILTER.max)
    return `hasta ${filters.budget[1] / 1000000} millones`
  return `${filters.budget[0] / 1000000} a ${
    filters.budget[1] / 1000000
  } millones`
}

const PROPERTY_DATA = {
  brand: {
    name: "marca",
    max: 3,
  },
  category: {
    name: "categoría",
    max: 2,
  },
}

const query = graphql`
  query BM_QUERY_1 {
    allModels {
      nodes {
        ...ModelBasic
        ...Versions
      }
    }
  }
`
