import React from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import Slide from "@material-ui/core/Slide"

type Props = {
  className?: string
  maxSteps: number
  step: number
  hide: boolean
}

export const Stepper: React.FC<Props> = ({
  className,
  maxSteps,
  step,
  hide,
}) => {
  return (
    <Slide appear={false} direction="down" in={!hide}>
      <div className={className || ""}>
        <LinearProgress
          className="insurance-stepper"
          variant="determinate"
          value={Math.max(Math.min(100, (step / maxSteps) * 100), 0)}
        />
      </div>
    </Slide>
  )
}
