const base = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz*/"

export const NumberToBase64 = (number: number): string => {
  if (number < 0) throw "Can't represent negative numbers"
  let rixit;
  let residual = Math.floor(number)
  let result = ""
  while (residual > 0) {
    rixit = residual % 64
    result = base.charAt(rixit) + result
    residual = Math.floor(residual / 64)
  }
  return result
}

export const Base64ToNumber = (base64: string): number => {
  let result = 0
  const rixits = base64.split('')
  for (let i = 0; i < rixits.length; i++) {
    result = (result * 64) + base.indexOf(rixits[i])
  }
  return result
}