import api from "./api"

export const updateStat = async (key: string): Promise<null> => {
  try {
    api.post("/statistics", { key })
    return null
  } catch (e) {
    return null
  }
}
