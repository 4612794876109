import React, { useContext, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import AOS from "aos"
import { ThemeProvider } from "@material-ui/core/styles"
import { Drawer } from "@material-ui/core"
import theme from "../styles/theme"
import {
  DrawerFinancing,
  DrawerAppraise,
  DrawerVersionDetails,
  DrawerPaymentDetails,
  MobileMenu,
  DrawerFilters,
  CreditVideoModal,
  DemoAlertModal,
} from "../components"
import {
  CarContext,
  KeyboardContext,
  SearchContext,
  UIContext,
} from "../context"
import {
  getQueryParams,
  sessionStringToContext,
  infoToSessionString,
  getSelectedModel,
} from "../utils"
import { getFinanceData, getOffers, getPrices } from "../api"
import { whatsappLogo } from "../assets"
import { DrawerInsuranceWrapper as DrawerInsurance } from "../components/insurance/DrawerInsuranceWrapper"

export const Layout = ({ children }) => {
  const { isKeyboardOpen } = useContext(KeyboardContext)
  const { showSearchSuggestions } = useContext(SearchContext)
  const appContext = useContext(CarContext)
  const {
    appLoading,
    resetContext,
    appFirstStart,
    setAppFirstStart,
    selectedOffer,
    setSelectedOffer,
    offers,
    setFinanceData,
    setOffers,
    setWindowWidth,
    setPricesInfo,
    setLoading,
    setModelsPricesStocks,
    pricesInfo: { expired, fetching },
    payment,
    selectedVersion,
    setSelectedVersion,
    modelsPricesStocks,
    setAppLoading,
  } = appContext

  const {
    isFinancingDrawerOpen,
    closeFinancingDrawer,
    closeCreditVideo,
    closeMobileMenu,
    isAppraiseDrawerOpen,
    closeAppraiseDrawer,
    isDetailsDrawerOpen,
    closeDetailsDrawer,
    isPaymentDrawerOpen,
    closePaymentDrawer,
    isFiltersDrawerOpen,
    closeFiltersDrawer,
    isInsuranceDrawerOpen,
    closeInsuranceDrawer,
    areDrawersOpened,
    isMobileMenuOpen,
    creditVideoOpen,
    demoAlertOpen,
    openDemoAlert,
    closeDemoAlert,
  } = useContext(UIContext)

  const data = useStaticQuery(query)
  const allModels = data.allModels.nodes
  const allAccessories = data.allAccessories.nodes

  const actualCar = getSelectedModel(allModels, appContext.selectedVersion)

  const selections = {
    transmission: appContext.transmission,
    equipment: appContext.equipment,
    engine: appContext.engine,
    traction: appContext.traction,
    color: appContext.color,
  }

  const queryParams = getQueryParams()

  useEffect(() => {
    if (appFirstStart && modelsPricesStocks) {
      openDemoAlert()
      getFinanceData()
        .then(data => {
          setFinanceData(data)
        })
        .catch(() => console.error("FAILED TO GET AMICAR PARAMS FROM STRAPI"))

      getOffers()
        .then(data => {
          setOffers(data)
        })
        .catch(() => console.error("FAILED TO GET CAR OFFERS FROM STRAPI"))
      // Save on context variables on session string
      if (queryParams.session) {
        sessionStringToContext(
          null,
          queryParams,
          appContext,
          allAccessories,
          allModels,
          modelsPricesStocks,
          setSelectedVersion
        ).then(() => setAppLoading(false))
      } else {
        infoToSessionString({
          payment: appContext.payment,
          dues: appContext.dues,
          percentage: appContext.percentage,
          sellerId: appContext.checkoutData.sellerData?.id,
        })
        setAppLoading(false)
      }
      setAppFirstStart(false)
    }
  }, [
    appFirstStart,
    setAppFirstStart,
    setOffers,
    setFinanceData,
    queryParams,
    appContext,
    allAccessories,
    allModels,
    modelsPricesStocks,
    openDemoAlert,
  ])

  useEffect(() => {
    if (expired && !fetching) {
      // Set fetching variable as "true" to avoid re-fetching on next rerender
      setPricesInfo(prev => ({
        ...prev,
        fetching: true,
      }))
      // Fetch the prices from API and build object
      getPrices().then(data => {
        // Save object of prices+stocks
        setModelsPricesStocks(data)
        // Cancel global loading
        setLoading(false)
        // Set expired as false to avoid refetching on next 5 minutes
        setPricesInfo({
          expired: false,
          fetching: false,
        })
        // Set timeout to refetch
        setTimeout(
          () =>
            setPricesInfo(prev => ({
              ...prev,
              expired: true,
            })),
          300000
        )
      })
    }
  }, [expired, fetching, setPricesInfo, setLoading, setModelsPricesStocks])

  useEffect(() => {
    if (queryParams.sale && !selectedOffer) {
      setSelectedOffer(queryParams.sale)
    }
  }, [queryParams.sale, offers, selectedOffer, setSelectedOffer])

  useEffect(() => {
    AOS.init({ once: true })
    function handleResize() {
      typeof window !== "undefined" && setWindowWidth(window.innerWidth)
    }
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", handleResize)
    }
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [setWindowWidth])

  useEffect(() => {
    if (queryParams.session) return
    resetContext()
    setAppFirstStart(true)
  }, [queryParams.session])

  return (
    <ThemeProvider theme={theme}>
      <>
        <link rel="stylesheet" href="https://unpkg.com/aos@next/dist/aos.css" />
        <main>
          <div
            className={`layout ${
              showSearchSuggestions ? "fullpage-search-results" : ""
            }`}
            style={{
              margin: `0 auto`,
            }}
            role="presentation"
            onContextMenu={e => {
              e.preventDefault()
              return false
            }}
          >
            {children}
          </div>
        </main>

        <button
          className={`whatsapp-floating-button ${
            areDrawersOpened ? "hide" : ""
          }`}
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=56233048940")
          }
        >
          <img src={whatsappLogo} alt="WhatsApp" />
        </button>

        <button
          className="whatsapp-floating-button"
          onClick={() =>
            window.open("https://api.whatsapp.com/send?phone=56233048940")
          }
        >
          <img src={whatsappLogo} alt="WhatsApp" />
        </button>

        <Drawer
          className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
          anchor="right"
          open={isFinancingDrawerOpen}
          onClose={closeFinancingDrawer}
        >
          <DrawerFinancing buttonGroupOrientation="horizontal" />
        </Drawer>

        <Drawer
          className="menu-drawer"
          anchor="left"
          open={isMobileMenuOpen}
          onClose={closeMobileMenu}
        >
          <MobileMenu />
        </Drawer>

        <Drawer
          className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
          anchor="right"
          open={isAppraiseDrawerOpen}
          onClose={closeAppraiseDrawer}
        >
          <DrawerAppraise />
        </Drawer>

        <Drawer
          className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
          anchor="right"
          open={isDetailsDrawerOpen}
          onClose={closeDetailsDrawer}
        >
          {actualCar && (
            <DrawerVersionDetails
              model={actualCar}
              selections={selections}
              context={{
                selectedVersion: selectedVersion,
                modelsPricesStocks: modelsPricesStocks,
              }}
            />
          )}
        </Drawer>

        <Drawer
          className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
          anchor="right"
          open={isPaymentDrawerOpen}
          onClose={closePaymentDrawer}
        >
          <DrawerPaymentDetails model={actualCar} />
        </Drawer>

        <Drawer
          className={`drawer-right-360 ${isKeyboardOpen ? "smaller" : ""}`}
          anchor="right"
          open={isFiltersDrawerOpen}
          onClose={closeFiltersDrawer}
        >
          <DrawerFilters />
        </Drawer>

        <DrawerInsurance
          isOpen={isInsuranceDrawerOpen}
          onClose={closeInsuranceDrawer}
        />

        <CreditVideoModal
          payment={payment}
          open={creditVideoOpen}
          closeCreditVideo={closeCreditVideo}
        />

        <DemoAlertModal open={demoAlertOpen} onClose={closeDemoAlert} />
      </>
    </ThemeProvider>
  )
}

const query = graphql`
  query FULL_QUERY_1 {
    allModels {
      nodes {
        ...ModelBasic
        ...Highlights
        ...Gallery
        ...HeroVideo
        ...RecommendVideo
        ...ColorGallery
        ...ModelStrings
        ...EngineSpecs
        ...DimensionSpecs
        ...PracticalData
        ...TechnicalData
        ...Versions
        ...ConfigOptions
      }
    }
    allAccessories {
      nodes {
        ...AccessoriesBasic
      }
    }
  }
`
