import React from "react"
import { ShowPrice, Image } from ".."
import {
  CalculatePriceFull,
  getSelectedColorName,
  getVersionName,
  getMostExpensiveVersionName,
  searchMinimunVersion,
} from "../../utils"
import { useSurchargedPrices } from "../../hooks"

export function CarViewConfig({
  model,
  selections,
  pricesStocks,
  appContext,
  terms,
}) {
  const [prices] = useSurchargedPrices({
    prices:
      appContext.selectedVersion ||
      searchMinimunVersion(
        model,
        selections,
        pricesStocks,
        model.versions.map(v => v.id),
        { allowOutOfStock: true }
      ),
    colorId: appContext.color,
  })

  const PaymentInfo = CalculatePriceFull({
    appContext,
    prices,
  })

  return (
    <div className="car-view-config" id="car-view-config-container">
      <div className="card-front">
        <div className="title">
          <div className="principal">
            <h1>{model.name}</h1>
          </div>
          <div className="secondary">
            <h2>
              {getVersionName(model, appContext.selectedVersion?.versionId)}
            </h2>
          </div>
        </div>
        <div className="photo-price">
          <div className="photo-car">
            <Image
              filename={model.colorGallery
                .find(
                  item =>
                    item.colorName.name ===
                    getSelectedColorName(model, selections)
                )
                .image.url.split("/")
                .pop()}
            ></Image>
            <div className="disclaimer">
              Foto corresponde a versión{" "}
              {getMostExpensiveVersionName(model, pricesStocks)}
            </div>
          </div>
          <div className="price">
            <ShowPrice
              appContext={appContext}
              PaymentInfo={PaymentInfo}
              data={{
                terms,
                priceList: prices?.priceList,
              }}
              big
              from={false}
              fullClickeable
            ></ShowPrice>
          </div>
        </div>
      </div>
    </div>
  )
}
