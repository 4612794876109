import React from "react"
import { getStandardAnimationProps } from "../../constants"

type CarouselElementProps = {
  idx: number
  text?: string
  image?: string
  handleClick: () => void
}
const CarouselElement: React.FC<CarouselElementProps> = ({
  idx,
  text,
  image,
  handleClick,
}) => {
  return (
    <div
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={e => e.key === "Enter" && handleClick()}
      role="button"
      className={`carousel-native-element ${image ? "" : "no-image-container"}`}
      {...getStandardAnimationProps(idx, 200, 100)}
    >
      <img
        src={image}
        alt=""
        className={`carousel-native-image ${text ? "" : "no-text"}`}
      />
      <span className={`carousel-native-text ${image ? "" : "no-image"}`}>
        {text}
      </span>
    </div>
  )
}

type CarouselNativeProps = {
  elements: {
    text?: string
    image?: string
    value: string
  }[]
  handleClick: (value: string[]) => void
}
export const CarouselNative: React.FC<CarouselNativeProps> = ({
  elements,
  handleClick,
}) => {
  return (
    <div className="carousel-native">
      <div className="carousel-native-container">
        {elements.map((element, idx) => (
          <CarouselElement
            key={idx}
            idx={idx}
            text={element.text}
            image={element.image}
            handleClick={() => handleClick([element.value])}
          />
        ))}
      </div>
    </div>
  )
}
