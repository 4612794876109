import React, { useCallback, useContext, useEffect, useRef } from "react"
import { AppBar, Toolbar, Divider } from "@material-ui/core"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import { HeaderSortButton, HeaderFilterButton, HeaderCompareButton } from "."
import { SearchBar } from "@inventures/react-lib/components"
import { CarContext, KeyboardContext, SearchContext } from "../context"
import { getFilteredModels } from "../utils"

export const HomeDesktopHeader = ({ allModels, topPosition }) => {
  const {
    windowWidth,
    payment,
    modelsPricesStocks,
    setPriceOrder,
  } = useContext(CarContext)
  const { showKeyboard, hideKeyboard, resetKeyboard } = useContext(
    KeyboardContext
  )
  const {
    modelSearch,
    userSearch,
    disableSearchResults,
    setSearchResults,
    filters,
    activeModelSearch,
    setModelSearch,
    resetSearch,
    handleSearchEnter,
  } = useContext(SearchContext)

  const inputRef = useRef(null)

  const handleKeyboardEnter = useCallback(() => {
    handleSearchEnter()
    inputRef.current.blur()
  }, [handleSearchEnter, inputRef])

  const handleClear = useCallback(() => {
    resetSearch()
    resetKeyboard()
  }, [resetSearch, resetKeyboard])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      handleSearchEnter()
    },
    [handleSearchEnter]
  )

  const handleWrite = useCallback(
    e => {
      setModelSearch(String(e.target.value))
    },
    [setModelSearch]
  )

  // Get and set models that match filters and text search
  useEffect(() => {
    if (!disableSearchResults) {
      const filteredModels = getFilteredModels(
        allModels,
        userSearch,
        filters,
        modelsPricesStocks,
        payment
      )
      setSearchResults(filteredModels)
    }
  }, [
    allModels,
    userSearch,
    filters,
    payment,
    modelsPricesStocks,
    disableSearchResults,
    setSearchResults,
    setPriceOrder,
  ])

  return (
    <AppBar className={`home-desktop-header ${topPosition}`} position="sticky">
      <Toolbar variant="dense">
        <form
          className="search-form"
          role="presentation"
          onSubmit={handleSubmit}
          onFocus={() =>
            showKeyboard(setModelSearch, inputRef, {
              onTabPress: handleKeyboardEnter,
              onEnterPress: handleKeyboardEnter,
            })
          }
          onBlur={hideKeyboard}
        >
          <SearchBar
            className="search-bar"
            showInput={true}
            onCollapsedIconClick={() => {}}
            onExpandedIconClick={handleClear}
            ExpandedIcon={!activeModelSearch && SearchRoundedIcon}
            value={modelSearch}
            onChange={handleWrite}
            // eslint-disable-next-line
            autoFocus={false}
            size="small"
            iconColor="#FFFFFF"
            barColor="#FFFFFF"
            placeholder={`Busca por ${
              windowWidth > 1200 ? "marca, " : ""
            }modelo o categoría`}
            inputRef={inputRef}
          />
        </form>
        <HeaderSortButton />
        <Divider orientation="vertical" />
        <HeaderFilterButton />
        <Divider orientation="vertical" />
        <HeaderCompareButton />
      </Toolbar>
    </AppBar>
  )
}
