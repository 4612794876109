import React from "react"
import { navigate } from "gatsby"
import { HeaderModel } from ".."
import { getIcon } from "../../assets"
import { urlWithSession } from "../../utils"
import { Model, PracticalDataSection } from "../../types"

type Props = {
  data: {
    model: Model
    comparatorSelections: Model[]
    setComparatorSelections: (models: Model[]) => void
  }
}
export const PracticalData: React.FC<Props> = ({
  data: { model, comparatorSelections, setComparatorSelections },
}) => {
  const parkingGallery = model.gallery.find(g => g.category === "parking")
  const hasParkingImages =
    parkingGallery?.images?.length === 2 &&
    parkingGallery?.images?.every(image => Boolean(image?.url))
  return (
    <div className="model-practical-data" id="practicos">
      <HeaderModel
        upperTitle="DATOS"
        lowerTitle="PRÁCTICOS"
        buttonText="COMPARA LOS MODELOS"
        clickHandler={() => {
          if (
            !comparatorSelections.length ||
            comparatorSelections[0].name !== model.name
          )
            setComparatorSelections([model])
          navigate(urlWithSession("/comparador"))
        }}
      />
      {model.practicalData.sections.slice(0, 1).map(getSection)}

      <div className={`spec parking-spec ${!hasParkingImages ? "hide" : ""}`}>
        <div className="parking-desktop">
          <img
            className="car"
            src={parkingGallery?.images[0]?.url}
            alt="parking"
          ></img>
          <div className="title-parking">
            El {model.name} en un estacionamiento pequeño (medidas mínimas
            permitidas)
          </div>
        </div>
        <div className="parking-mobile">
          <img src={parkingGallery?.images[1]?.url} alt="parking"></img>
          <div className="title-parking">
            El {model.name} en un estacionamiento pequeño (medidas mínimas
            permitidas)
          </div>
        </div>
      </div>

      {model.practicalData.sections.slice(1).map(getSection)}
    </div>
  )
}

const getSection = (section: PracticalDataSection, idx: number) =>
  section.specs.length > 0 && (
    <div className="spec" key={idx}>
      <div className="spec-container">
        <div className="title">{section.name}</div>
        <div className="spec-list">
          {section.specs.map((spec, idx: number) => (
            <div className="detail" key={idx}>
              <img
                className="icon"
                src={getIcon(spec.icon.name, "black")}
                alt=""
              ></img>
              <div className="title-detail">{spec.name}</div>
              <div className="explain">
                {spec.value}
                {spec.explanation && (
                  <>
                    <br />
                    <span className="explanation">{spec.explanation}</span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
