import React, { useEffect } from "react"
import { Slide, useScrollTrigger } from "@material-ui/core"

export function HideOnScroll({ children, setState }) {
  const trigger = useScrollTrigger()

  useEffect(() => setState(!trigger), [trigger, setState])

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}
