import React, { useCallback, useState } from "react"
import { parseNumber } from "../../utils"
import {
  DCXButton,
  DuesSelector,
  PaymentToggle,
  InputForIncome,
  BudgetSlider,
  InfoDialog,
} from "../"
import { URLS } from "../../constants"
import { IncomesFormatter } from "../reusables/inputs"
import IconButton from "@material-ui/core/IconButton"
import { InfoIcon } from "../../assets"
import { BUDGET_FILTER, FINANCING_MINS } from "../../constants"
import { Payment } from "../../types"

export function BudgetCard({
  payment,
  setPayment,
  dues,
  setDues,
  monthly,
  setMonthly,
  firstPayment,
  setFirstPayment,
  budgetedPrice,
  firstPaymentBottleneck,
  sliderValues,
  setSliderValues,
  modelsLength,
  onSubmit,
}) {
  const [formError, setFormError] = useState(false)
  const [firstPaymentDialogOpen, setFirstPaymentDialogOpen] = useState(false)
  const [budgetDialogOpen, setBudgetDialogOpen] = useState(false)

  const handleChange = useCallback(
    ({ name, value, hasError }) => {
      const val = IncomesFormatter.unformat(value)
      if (name === "monthly") {
        setMonthly(val)
      } else if (name === "firstPayment") {
        setFirstPayment(val)
      }
      setFormError(hasError)
    },
    [setMonthly, setFirstPayment]
  )

  // Update budget filter on budgetedPrice slider changes
  const handleSliderChange = useCallback(
    (_, newValue) => setSliderValues(newValue),
    [setSliderValues]
  )

  const handleFirstPaymentDialogOpen = useCallback(() => {
    setFirstPaymentDialogOpen(true)
  }, [setFirstPaymentDialogOpen])

  const handleBudgetDialogOpen = useCallback(() => {
    setBudgetDialogOpen(true)
  }, [setBudgetDialogOpen])

  const handleDialogClose = useCallback(() => {
    setFirstPaymentDialogOpen(false)
    setBudgetDialogOpen(false)
  }, [setFirstPaymentDialogOpen, setBudgetDialogOpen])

  return (
    <>
      <div className="budget-card">
        <div className="left-section">
          <div className="title">Por presupuesto </div>
          <PaymentToggle
            value={payment === Payment.flexCredit ? Payment.credit : payment}
            onChange={(_, val) => {
              if (val) {
                setPayment(val)
              }
            }}
          />
          <div
            className={`text-input-container ${
              payment === Payment.cash ? "hide" : ""
            }`}
          >
            <InputForIncome
              className="budget-input"
              name="monthly"
              value={IncomesFormatter.format(String(monthly))}
              onChange={handleChange}
              label="¿Cuánto quieres pagar al mes?"
              variant="outlined"
              lengthValidatorText="Por favor ingrese un valor"
              extraValidators={[
                {
                  validate: value =>
                    IncomesFormatter.unformat(value) >=
                    BUDGET_FILTER.minMonthlyInput,
                  errorMsg: `La cuota mínima es $${parseNumber(
                    BUDGET_FILTER.minMonthlyInput
                  )}`,
                },
              ]}
            />
            <InputForIncome
              className="budget-input"
              elementId="budget-card-input-for-income"
              name="firstPayment"
              value={IncomesFormatter.format(String(firstPayment))}
              onChange={handleChange}
              label="¿Cuánto pie quieres poner?"
              variant="outlined"
              lengthValidatorText="Por favor ingrese un valor"
              extraValidators={[
                {
                  validate: value =>
                    IncomesFormatter.unformat(value) >=
                    BUDGET_FILTER.minFirstPaymentInput,
                  errorMsg: `El pie mínimo es $${parseNumber(
                    BUDGET_FILTER.minFirstPaymentInput
                  )}`,
                },
              ]}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className="info-button"
                    size="small"
                    onClick={handleFirstPaymentDialogOpen}
                  >
                    <InfoIcon
                      color="secondary"
                      fontSize="inherit"
                      className="info-icon"
                    />
                  </IconButton>
                ),
              }}
            />
          </div>
          <div
            className={`selector-input-container ${
              payment === Payment.cash ? "hide" : ""
            }`}
          >
            <DuesSelector
              className={payment === Payment.cash ? "hide" : ""}
              payment={payment}
              dues={dues}
              setDues={setDues}
              label="Plazo"
              variant="outlined"
              disableUnderline
            />
          </div>
          <BudgetSlider
            className={payment !== Payment.cash ? "hide" : ""}
            value={sliderValues}
            onChange={handleSliderChange}
          />
        </div>
        <div className={`divider ${payment === Payment.cash ? "hide" : ""}`} />
        <div className="right-section">
          <span
            className={`budget-desc cash ${
              payment === Payment.cash ? "hide" : ""
            }`}
          >
            Tienes un presupuesto aproximado de
          </span>
          <span
            className={`budget-desc credit ${
              payment !== Payment.cash ? "hide" : ""
            }`}
          >
            Autos dentro de tu presupuesto
          </span>
          <span
            className={`budget-ammount ${
              payment === Payment.cash ? "hide" : ""
            }`}
          >
            {`$${parseNumber(budgetedPrice)}`}
            <IconButton
              className="info-button"
              size="small"
              onClick={handleBudgetDialogOpen}
            >
              <InfoIcon
                className="info-icon"
                color="secondary"
                fontSize="inherit"
              />
            </IconButton>
          </span>

          <div className="gray-area">
            <span>
              {!modelsLength
                ? "🚗 No encontramos modelos "
                : `🚗 Tenemos ${modelsLength} modelo${
                    modelsLength > 1 ? "s " : " "
                  } en esa selección`}
            </span>
          </div>
          <DCXButton
            className="button long-button"
            type="primary"
            text="VER AUTOS HASTA ESE PRECIO"
            disabled={
              (payment === Payment.credit && formError) ||
              (payment === Payment.cash && !modelsLength)
            }
            onClick={onSubmit}
          />
        </div>
      </div>
      <InfoDialog
        title="Acerca del pie de un crédito"
        primaryButtonText="OK"
        primaryButtonHandle={handleDialogClose}
        open={firstPaymentDialogOpen}
        handleClose={handleDialogClose}
        dialogClass="landing-info-dialog"
      >
        <span>
          Necesitas entregar un pie mínimo de {FINANCING_MINS.percentage.credit}
          % del valor del auto que quieras comprar para facilitar la aprobación
          de un crédito.
        </span>
      </InfoDialog>
      <InfoDialog
        title="Acerca de tu presupuesto aproximado"
        primaryButtonText="OK"
        primaryButtonHandle={handleDialogClose}
        open={budgetDialogOpen}
        handleClose={handleDialogClose}
        dialogClass="landing-info-dialog"
      >
        <div
          className={!firstPaymentBottleneck || !budgetedPrice ? "hide" : ""}
        >
          <span>
            El <b>pie</b> que ingresaste está limitando tu presupuesto
            <span className="emoji" role="img" aria-label="ícono de tristeza">
              {" "}
              😢
            </span>
          </span>
          <span>
            ¡Puedes aumentarlo para encontrar mejores autos!
            <span className="emoji" role="img" aria-label="ícono de automóvil">
              {" "}
              🚗
            </span>
          </span>
        </div>

        <div className={firstPaymentBottleneck || !budgetedPrice ? "hide" : ""}>
          <span>
            La <b>cuota</b> que ingresaste está limitando tu presupuesto
            <span className="emoji" role="img" aria-label="ícono de tristeza">
              {" "}
              😢
            </span>
          </span>
          <span>
            ¡Puedes aumentarla para encontrar mejores autos!
            <span className="emoji" role="img" aria-label="ícono de automóvil">
              {" "}
              🚗
            </span>
          </span>
        </div>
        <span className={budgetedPrice > 0 ? "hide" : ""}>
          Por favor ingresa una <b>cuota</b> mayor a $
          {parseNumber(BUDGET_FILTER.minMonthlyInput)} y un <b>pie</b> mayor a $
          {parseNumber(BUDGET_FILTER.minFirstPaymentInput)}
        </span>
        <span>
          Para más información, ver los{" "}
          <a
            href={URLS.termsAndConditions}
            target="_blank"
            rel="noopener noreferrer"
          >
            Términos y Condiciones
          </a>
          .
        </span>
      </InfoDialog>
    </>
  )
}
