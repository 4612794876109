import React, { useCallback, memo } from "react"
import { FrameTemplate } from "../templates"
import { CarTypeStepImage } from "../../../assets"
import { Insurance, OnChange, CarType } from "../../../types"
import { Button } from "@material-ui/core"

type CarTypeOptionProps = {
  className?: string
  text: string
  onClick: () => void
  selected: boolean
  disabled?: boolean
}

const CarTypeOption: React.FC<CarTypeOptionProps> = ({
  className,
  text,
  onClick,
  selected,
  disabled,
}) => {
  return (
    <Button
      className={`car-type-option ${className || ""} ${
        selected ? "selected" : ""
      } ${disabled ? "disabled" : ""}`}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}

type CarTypeStepProps = {
  carType: string
  newCarsYear: number | null
  onChange: OnChange
}

const CarTypeStepComponent: React.FC<CarTypeStepProps> = ({
  carType,
  newCarsYear,
  onChange,
}) => {
  const onClick = useCallback(
    value => () => {
      onChange({ name: Insurance.carType, value })
      onChange({
        name: Insurance.year,
        value: value === CarType.new ? newCarsYear : null,
      })
    },
    [newCarsYear, onChange]
  )
  return (
    <FrameTemplate
      image={CarTypeStepImage}
      title="¿Qué auto quieres asegurar?"
      desc="Compara en línea y elige tu mejor opción para ti y tu auto"
    >
      <div className="insurance-drawer-step-content insurance-car-type-step-content">
        <CarTypeOption
          text="Mi próximo auto"
          onClick={onClick(CarType.new)}
          selected={carType === CarType.new}
        />
        <CarTypeOption
          text="Mi auto actual"
          onClick={onClick(CarType.used)}
          selected={carType === CarType.used}
        />
      </div>
    </FrameTemplate>
  )
}

export const CarTypeStep = memo(CarTypeStepComponent)
