import React from "react"

export function Intro({ image, title, subtitle }) {
  return (
    <div className="page-intro">
      <img src={image} alt="" />
      <div className="texts">
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
      </div>
    </div>
  )
}
