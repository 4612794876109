import React from "react"
import { Button, Divider, Chip } from "@material-ui/core"
import { parseNumber } from "../../../utils"
import {
  BciLogo,
  ChilenaConsolidadaLogo,
  LibertyLogo,
  HdiLogo,
  ConsorcioLogo,
} from "../../../assets"

type OptionProps = {
  selected?: boolean
  onClick?: () => void
  disabled?: boolean
  content: {
    companyId?: string
    monthlyCost?: number
    monthlyCostUf?: number
    dues?: number
    deductible?: number
  }
}

export const InsuranceCompanyOption: React.FC<OptionProps> = ({
  selected,
  onClick,
  disabled,
  content: { companyId, monthlyCost, monthlyCostUf, dues, deductible },
}) => {
  return (
    <Button
      className={`insurance-option ${selected ? "selected" : ""}`}
      onClick={onClick}
      disabled={disabled}
      fullWidth
    >
      <div className="image-container">
        <img
          src={COMPANY_LOGO[companyId || ""]}
          alt="Logo de la compañía aseguradora"
        />
      </div>
      <Divider orientation="vertical" flexItem variant="middle" />
      <div className="details-container">
        <span>{`${dues} cuotas de`}</span>
        <span className="amount">{`$${parseNumber(monthlyCost)}*`}</span>
        <span>{`UF ${monthlyCostUf}`}</span>
        <Chip
          className={!deductible ? "hidden" : ""}
          size="small"
          label={`${deductible} UF deducible`}
        />
      </div>
    </Button>
  )
}

const COMPANY_LOGO = {
  "00003": ChilenaConsolidadaLogo,
  "00016": BciLogo,
  "00024": LibertyLogo,
  "00004": HdiLogo,
  "00017": ConsorcioLogo,
} as { [key: string]: string }
