import { useEffect, useState, useContext } from "react"
import { checkUrl, getSelectedModel } from "../../../utils"
import { useModelOptions } from "."
import { CarType, Insurance } from "../../../types"
import { InsuranceContext } from "../../../context/InsuranceContext"
import { CarContext } from "../../../context"
import { useAllModels } from "../../../hooks"

export enum FlowType {
  None = -1,
  General = 0,
  Checkout = 1,
}
type FlowSelectionResults = [FlowType, number, number, number]

export const useFlowSelection = (): FlowSelectionResults => {
  const { setInsuranceData } = useContext(InsuranceContext)
  const { selectedVersion, financeData } = useContext(CarContext)
  const [flow, setFlow] = useState<FlowType>(FlowType.None)
  const [maxSteps] = useState<number>(15)
  const [maxStepperValue] = useState<number>(12)
  const [beginProgressOn, setBeginProgressOn] = useState<number>(0)
  const [modelOptions] = useModelOptions({ carType: CarType.new })
  const [allModels] = useAllModels()

  useEffect(() => {
    if (checkUrl(["checkout"]) && financeData && selectedVersion) {
      // Set missing insurance data
      const selectedModel = getSelectedModel(allModels, selectedVersion)
      const selectedInsuranceModelOption = modelOptions.find(
        option => option.dcxId === selectedModel.ID
      )
      setInsuranceData(prev => ({
        ...prev,
        [Insurance.carType]: CarType.new,
        [Insurance.brandId]:
          selectedInsuranceModelOption?.insuranceBrandId || "",
        [Insurance.modelId]: selectedInsuranceModelOption?.insuranceId || "",
        [Insurance.year]: String(financeData?.otherParams.newCarsYear),
      }))
      // Checkout flow setter
      setFlow(FlowType.Checkout)
      setBeginProgressOn(3)
    } else {
      if (flow === FlowType.None) setFlow(FlowType.General)
    }
  }, [
    setInsuranceData,
    financeData,
    selectedVersion,
    allModels,
    modelOptions,
    flow,
  ])

  return [flow, maxSteps, maxStepperValue, beginProgressOn]
}
