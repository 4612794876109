import {
  changanLogo,
  greatwallLogo,
  havalLogo,
  jacLogo,
  mazdaLogo,
  renaultLogo,
  suzukiLogo,
  IntroImage1,
  IntroImage2,
  IntroImage3,
  IntroImage4,
  IntroImage5,
} from "./assets"

export const FINANCING_MINS = {
  dues: {
    flexCredit: 24,
    credit: 12,
    cash: 0,
  },
  percentage: {
    flexCredit: 20,
    credit: 20,
    cash: 0,
  },
}

export const FINANCING_MAXS = {
  dues: {
    flexCredit: 36,
    credit: 60,
    cash: 1,
  },
  percentage: {
    flexCredit: 50,
    credit: 50,
    cash: 1,
  },
}

export const BRANDS_LOGOS = {
  1: suzukiLogo,
  2: mazdaLogo,
  3: changanLogo,
  4: renaultLogo,
  5: jacLogo,
  6: havalLogo,
  9: greatwallLogo,
} as Record<number, string>

export const CATEGORIES = {
  all: "all",
  suv: "SUV",
  hatchback: "Hatchback",
  sedan: "Sedán",
  van: "VAN",
  sport: "Deportivo",
  truck: "Camioneta",
  furgon: "Furgón",
  citycar: "Citycar",
}

export const CLASSIFICATIONS = {
  family: [CATEGORIES.suv, CATEGORIES.van, CATEGORIES.sedan],
  city: [CATEGORIES.hatchback, CATEGORIES.sport, CATEGORIES.citycar],
  work: [CATEGORIES.truck, CATEGORIES.furgon],
}

export const BUDGET_FILTER = {
  max: 32000000,
  step: 500000,
  minMonthlyInput: 100000,
  minFirstPaymentInput: 1000000,
}

type AnimationResults = {
  "data-aos": string
  "data-aos-anchor-placement": string
  "data-aos-delay": number
  "data-aos-duration": number
}
export const getStandardAnimationProps = (
  index = 0,
  baseDelay = 200,
  variableDelay = 200,
  duration = 350
): AnimationResults => ({
  "data-aos": "fade-up",
  "data-aos-anchor-placement": "center-bottom",
  "data-aos-delay": baseDelay + variableDelay * index,
  "data-aos-duration": duration,
})

export const URLS = {
  termsAndConditions:
    "https://dercocenterx.s3.us-east-2.amazonaws.com/Terminos_y_Condiciones_Dercocenter_X_23173fd6f6.pdf",
  privacyPolicy:
    "https://dercocenterx.s3.us-east-2.amazonaws.com/684c5bb328bf4df9843d2a3eab35ceac.pdf",
  termsAndConditionsOutlet:
    "https://dercocenterx.s3.us-east-2.amazonaws.com/Terminos_y_Condiciones_Ofertas_Flash_Dercocenter_X.pdf",
}

export const insuranceInfoFramesData: {
  image: string
  title: string
  desc: string
}[] = [
  {
    image: IntroImage1,
    title: "DERCO Seguros",
    desc:
      "Protege tu auto de accidentes, robo o daños que puedan sufrir tú y tu auto",
  },
  {
    image: IntroImage2,
    title: "Auto 0 km de reposición",
    desc:
      "En caso de pérdida total o robo, hasta 2 años desde la compra de tu auto",
  },
  {
    image: IntroImage3,
    title: "Repuestos 100% originales",
    desc: "Para reparaciones de marcas Derco en nuestros talleres",
  },
  {
    image: IntroImage4,
    title: "Garantía de reparación",
    desc: "Reparación exclusiva en talleres de la red Dercocenter",
  },
  {
    image: IntroImage5,
    title: "Asistencia todo el año",
    desc: "Servicio de grúa y asistencia los 365 días del año",
  },
]
