import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { InsuranceModel, ModelOption, CarType } from "../../../types"

type QueryResult = {
  allInsurance: {
    nodes: [
      {
        models: InsuranceModel[]
      }
    ]
  }
  allModels: {
    nodes: {
      ID: number
      name: string
    }[]
  }
}

type Props = {
  carType: CarType
}

export const useModelOptions = ({ carType }: Props): [ModelOption[]] => {
  // Fetch static data
  const data = useStaticQuery<QueryResult>(graphql`
    query MODELS {
      allModels {
        nodes {
          ID
          name
        }
      }
      allInsurance {
        nodes {
          models {
            id
            name
            brandId
            dcxModelIds
          }
        }
      }
    }
  `)

  const [ourModels] = useState<ModelOption[]>(() =>
    data.allModels.nodes
      .map(model => {
        const insuranceModel = data.allInsurance.nodes[0].models.find(im =>
          im.dcxModelIds?.includes(model.ID)
        )
        return {
          dcxId: model.ID,
          dcxName: model.name,
          insuranceId: insuranceModel?.id || "",
          insuranceName: insuranceModel?.name || "",
          insuranceBrandId: insuranceModel?.brandId || "",
        }
      })
      .sort((a, b) => new Intl.Collator("es").compare(a.dcxName, b.dcxName))
  )

  // Save data sorted
  const [allInsuranceModels] = useState<ModelOption[]>(() =>
    data.allInsurance.nodes[0].models
      .map(model => ({
        dcxId: 0,
        dcxName: "",
        insuranceId: model.id,
        insuranceName: model.name,
        insuranceBrandId: model.brandId,
      }))
      .sort((a, b) =>
        new Intl.Collator("es").compare(a.insuranceName, b.insuranceName)
      )
  )

  return carType === CarType.new ? [ourModels] : [allInsuranceModels]
}
