import React from "react"
import { Select } from "@material-ui/core"
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded"
import { getDuesOrPercentageOptions } from "../../utils"
import { useUpdateStats, UpdateStatType } from "../../hooks"

type Props = {
  className?: string
  disableUnderline?: boolean
  payment: unknown
  percentage: number
  setPercentage: (value: number) => void
}

export const PercentageSelector: React.FC<Props> = ({
  className,
  disableUnderline,
  payment,
  percentage,
  setPercentage,
}) => {
  const [updateStat] = useUpdateStats("", UpdateStatType.CALLBACK)
  return (
    <Select
      className={`selector percentage-selector ${className || ""}`}
      native
      value={percentage}
      disableUnderline={disableUnderline}
      onChange={({ target: { value } }) => {
        value && setPercentage(value as number)
        updateStat("paymentConfig-percentage-selector")
      }}
      IconComponent={KeyboardArrowDownRoundedIcon}
    >
      {getDuesOrPercentageOptions(payment, "percentage").map((e, idx) => (
        <option key={idx} value={e.value}>
          {e.label}
        </option>
      ))}
    </Select>
  )
}
