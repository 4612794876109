import React from "react"
import { Spinner } from "."

export function Loader({ className }) {
  return (
    <div className={`loader-background ${className || ""}`}>
      <Spinner
        className="loader"
        size={100}
        c={19} //Depend indirectly of size
        color="#b20d27"
        strokes={{ line: 4, circle: 8 }}
      />
    </div>
  )
}
