import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {
  Insurance,
  OnChange,
  Owner,
  CarType,
  RadioGroupOnChangeType,
} from "../../../types"

type Props = {
  owner: Owner
  carType: CarType
  onChange: OnChange
}

const TITLES = {
  [CarType.used]: "¿Quién es el dueño del auto?",
  [CarType.new]: "¿Quién será el dueño del auto?",
}

const LABELS = {
  [Owner.me]: {
    [CarType.used]: "Yo soy el dueño",
    [CarType.new]: "Yo seré el dueño",
  },
  [Owner.other]: {
    [CarType.used]: "Otra persona",
    [CarType.new]: "Otra persona",
  },
  [Owner.business]: {
    [CarType.used]: "La empresa",
    [CarType.new]: "La empresa",
  },
}

const OwnerStepComponent: React.FC<Props> = ({ owner, carType, onChange }) => {
  const onOwnerChange = useCallback(
    (_, value: Owner) => onChange({ name: Insurance.owner, value }),
    [onChange]
  )
  return (
    <QuestionTemplate title={TITLES[carType]}>
      <div className="insurance-drawer-step-content insurance-owner-step">
        <RadioGroup
          value={owner}
          onChange={onOwnerChange as RadioGroupOnChangeType}
        >
          <FormControlLabel
            value={Owner.me}
            control={<Radio />}
            label={LABELS[Owner.me][carType]}
          />
          <FormControlLabel
            value={Owner.other}
            control={<Radio />}
            label={LABELS[Owner.other][carType]}
          />
          {/* <FormControlLabel
            value={Owner.business}
            control={<Radio />}
            label={LABELS[Owner.business][carType]}
            disabled={true}
          /> */}
        </RadioGroup>
      </div>
    </QuestionTemplate>
  )
}

export const OwnerStep = memo(OwnerStepComponent)
