import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForPlate2 } from "../../reusables/InputForPlate"
import { Insurance, OnChange } from "../../../types"

type Props = {
  plate: string
  onChange: OnChange
}

const PlateStepComponent: React.FC<Props> = ({ plate, onChange }) => {
  // Validation not needed. Handled by custom input.
  return (
    <QuestionTemplate title="¿Cuál es la patente?">
      <div className="insurance-drawer-step-content">
        <InputForPlate2
          name={Insurance.plate}
          value={plate}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}

export const PlateStep = memo(PlateStepComponent)
