import api from "./api"
import { getSelectedOptions, getVersionName } from "../utils"

// Only updates lead attributes, not nested info
export const updateLead = (uuid: string, updates: unknown): any => {
  return api.post(`/leads/update/${uuid}`, { updates, requestPreapprove: true })
}

// Create or Update lead, depending if uuid is provided
export const processLead = (uuid: string | null, info: unknown): any => {
  const {
    clientData,
    selectedAccessories,
    accessoriesTotalPrice,
    PaymentParams,
    selectedModel,
    selections,
    deliveryTime,
    offer,
    prices,
    surchargeAmmount,
    insuranceSimulationId,
    sellerId,
  } = info
  delete prices.colorStocks

  // Get selections based on selected options IDs
  const { transmission, equipment, engine, traction } = getSelectedOptions(
    selectedModel,
    selections
  )

  const lead = {
    name: clientData.name,
    lastname: clientData.lastname,
    rut: clientData.rut,
    phone: clientData.phone,
    email: clientData.email,
    income: clientData.income,
    carInfo: {
      accessories: {
        totalPrice: accessoriesTotalPrice,
        selected: selectedAccessories.map(acc => ({
          id: acc.ID,
          code: acc.code,
          name: acc.name,
          price: acc.price,
        })),
      },
      insuranceSimulationId: insuranceSimulationId || null,
      sellerId: sellerId || null,
      prices: {
        finalCarPrice: PaymentParams.finalCarPrice,
        ...prices,
        colorSurcharge: surchargeAmmount,
      },
      dataCar: !offer
        ? {
            modelId: selectedModel.ID,
            brandId: selectedModel.brandId,
            model: selectedModel.name,
            version: getVersionName(selectedModel, prices.versionId),
            deliveryTime,
            configs: {
              // Selections names
              transmission: transmission.main + " " + transmission.secondary,
              equipment: equipment.name, // + " " + equipment.code,
              engine: engine.main, // + " " + engine.secondary,
              traction: traction.main + " " + traction.secondary,
              color: info.selectedColor.info.displayName,
              colorName: info.selectedColor.info.colorName,
              // Selections ID's
              transmissionId: selections.transmission,
              equipmentId: selections.equipment,
              engineId: selections.engine,
              tractionId: selections.traction,
              colorId: selections.color,
            },
            photoCar: info.photoCar,
          }
        : null,
      offer: offer || null,
      taxes: {
        ...PaymentParams.taxes,
        inscription: PaymentParams.withCredit
          ? "N/A"
          : PaymentParams.taxes.inscription,
        STATUS: info.includePlatePack ? "INCLUIDO" : "NO INCLUÍDO",
      },
      dataPayment: {
        payment: info.payment,
        dues: info.dues,
        carInPayment: info.carPaymentInfo,
        ...PaymentParams,
        taxes: undefined,
      },
      url: `https://${window.location.host}`,
    },
  }
  return uuid
    ? api.post(`/leads/update/${uuid}`, {
        updates: lead,
        requestPreapprove: false,
      })
    : api.post("/leads", lead)
}

export const createTransbank = async (uuid: string): Promise<any> => {
  const response = await api.post("/leads/create-payment", {
    uuid,
  })
  return response.data
}

export const getLeadByUUID = async (uuid: string): Promise<any> => {
  try {
    const response = await api.get(`/leads/token/${uuid}`)
    return response.data
  } catch (e) {
    return null
  }
}
