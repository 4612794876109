import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import LogRocket from "logrocket"
import { Layout } from "./components/index"
import {
  CarProvider,
  KeyboardProvider,
  SearchProvider,
  InsuranceProvider,
  UIProvider,
} from "./context"
import { useSpecialDevice } from "./hooks"

import "./styles/default.scss"

import "./pages/checkout/common.scss"
import "./pages/checkout/accessories.scss"
import "./pages/checkout/tax.scss"
import "./pages/checkout/index.scss"
import "./pages/checkout/step2.scss"
import "./pages/checkout/info.scss"
import "./pages/checkout/thanks.scss"
import "./pages/comparador.scss"
import "./pages/en-construccion.scss"
import "./pages/error-payment.scss"
import "./pages/how-does-it-work.scss"
import "./pages/home.scss"
import "./pages/offers.scss"
import "./pages/home-cards-background.scss"
import "./pages/index.scss"

import "./components/CustomKeyboard.scss"
import "./components/card-common.scss"
import "./components/card-model.scss"
import "./components/card-offer.scss"
import "./components/disclaimer.scss"
import "./components/DrawerCommon.scss"
import "./components/DrawerAppraise.scss"
import "./components/DrawerFinancing.scss"
import "./components/DrawerPaymentDetails.scss"
import "./components/DrawerVersionDetails.scss"
import "./components/DrawerFilters.scss"
import "./components/footer.scss"
import "./components/header-car.scss"
import "./components/header/header.scss"
import "./components/header/SessionMenu.scss"
import "./components/layout.scss"
import "./components/loader.scss"
import "./components/DrawerLoader.scss"
import "./components/mobile-menu.scss"
import "./components/payment-header.scss"
import "./components/payment-summary-table.scss"
import "./components/plate-pack-table.scss"
import "./components/FinancingHeader.scss"
import "./components/FilterSortHeader.scss"
import "./components/HomeDesktopHeader.scss"
import "./components/show-price.scss"
import "./components/SearchSuggestions.scss"
import "./components/CreditVideoModal.scss"
import "./components/DemoAlertModal.scss"
import "./components/home/ShortcutCard.scss"
import "./components/accessories/AccessoryPrice.scss"
import "./components/accessories/AccessoryCard.scss"
import "./components/insurance/DrawerInsurance.scss"
import "./components/insurance/subcomponents/Stepper.scss"
import "./components/insurance/subcomponents/SimpleButton.scss"
import "./components/insurance/subcomponents/DoubleButton.scss"
import "./components/insurance/subcomponents/ContactChannelOption.scss"
import "./components/insurance/steps/ContactChannelStep.scss"
import "./components/insurance/steps/NameStep.scss"
import "./components/insurance/steps/RutGenderStep.scss"
import "./components/insurance/steps/BirthdateStep.scss"
import "./components/insurance/steps/UserSummaryStep.scss"

import "./components/checkout/intro.scss"
import "./components/checkout/steps.scss"
import "./components/checkout/SaveMenu.scss"
import "./components/checkout/CheckoutHeader.scss"

import "./components/config-car/car-view.scss"
import "./components/config-car/options-view.scss"

import "./components/options/option-color.scss"
import "./components/options/option-flat.scss"
import "./components/options/option-equipment.scss"
import "./components/options/option-triplet.scss"
import "./components/options/subcomponents/OptionColorsSection.scss"
import "./components/options/subcomponents/OptionPriceSection.scss"
import "./components/options/subcomponents/OptionOutOfStockBanner.scss"

import "./components/model/car-price.scss"
import "./components/model/color-car.scss"
import "./components/model/gallery-exterior.scss"
import "./components/model/gallery-interior.scss"
import "./components/model/motor.scss"
import "./components/model/PracticalData.scss"
import "./components/model/recommends.scss"
import "./components/model/technical-data.scss"
import "./components/model/video-background.scss"
import "./components/model/download-sheet.scss"
import "./components/model/action-button.scss"
import "./components/model/Accessories.scss"
import "./components/model/HeaderModel.scss"
import "./components/model/Insurance.scss"

import "./components/landing/BudgetCard.scss"
import "./components/landing/Frame.scss"
import "./components/landing/CarouselNative.scss"
import "./components/landing/LandingSearch.scss"
import "./components/landing/InfoDialog.scss"

import "./components/insurance/templates/FrameTemplate.scss"
import "./components/insurance/templates/QuestionTemplate.scss"
import "./components/insurance/steps/InsuranceSelectionStep.scss"
import "./components/insurance/steps/OwnerStep.scss"
import "./components/insurance/steps/BrandStep.scss"
import "./components/insurance/steps/InsuranceSummaryStep.scss"
import "./components/insurance/steps/CarTypeStep.scss"
import "./components/insurance/steps/CarSummaryStep.scss"
import "./components/insurance/steps/PlateFallbackStep.scss"
import "./components/insurance/steps/CarouselStep.scss"
import "./components/insurance/subcomponents/InsuranceCompanyOption.scss"

import "./components/reusables/dcx-button.scss"
import "./components/reusables/select.scss"
import "./components/reusables/share-button.scss"
import "./components/reusables/ShareDialog.scss"
import "./components/reusables/Selector.scss"
import "./components/reusables/HeaderSortButton.scss"
import "./components/reusables/HeaderFilterButton.scss"
import "./components/reusables/HeaderCompareButton.scss"
import "./components/reusables/InfoCard.scss"
import "./components/reusables/PaymentToggle.scss"
import "./components/reusables/BudgetSlider.scss"
import "./components/reusables/DCXButtonGroup.scss"
import "./components/reusables/DCXToggleButtonGroup.scss"
import "./components/reusables/Carousel.scss"

import "./templates/model.scss"
import "./templates/config.scss"
import { SPECIAL_DEVICE } from "./types"

export default function App({ children }) {
  const [, storePage] = useSpecialDevice()
  const [CRISP_COOKIE_EXPIRE_VAR] = useState(
    storePage === SPECIAL_DEVICE.ipad ? "window.CRISP_COOKIE_EXPIRE=600;" : ""
  )

  useEffect(() => {
    if (process.env.NODE_ENV === "production") LogRocket.init("4obnxq/dercox")
  }, [])

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`window.$crisp=[];window.CRISP_WEBSITE_ID='b7d9ef99-fdb1-4b06-a100-9e74fceccdef';${CRISP_COOKIE_EXPIRE_VAR}(function(){d=document;s=d.createElement('script');s.src='https://client.crisp.chat/l.js';s.async=1;d.getElementsByTagName('head')[0].appendChild(s);})();`}
        </script>
      </Helmet>
      <KeyboardProvider>
        <SearchProvider>
          <CarProvider>
            <InsuranceProvider>
              <UIProvider>
                <Layout>{children}</Layout>
              </UIProvider>
            </InsuranceProvider>
          </CarProvider>
        </SearchProvider>
      </KeyboardProvider>
    </>
  )
}

export const ModelsBasicFragment = graphql`
  fragment ModelBasic on Models {
    ID
    category
    mostSold
    brand
    brandId
    brandSlug
    modelSlug
    name
    slug
    shortName
    slogan
    keywords
    technicalSheetUrl
    position
    partialUpload
    similarModels
  }
`
export const VersionBasicFragment = graphql`
  fragment VersionBasic on ModelsVersions {
    id
    versionSlug
    equipment
    name
  }
`
export const ModelsColorGalleryFragment = graphql`
  fragment ColorGallery on Models {
    colorGallery {
      colorName {
        name
      }
      image {
        url
      }
    }
  }
`
export const ModelsStringsFragment = graphql`
  fragment ModelStrings on Models {
    modelStrings {
      engineSection {
        mainTitle
        secondaryTitle
        description
      }
    }
  }
`
export const ModelsEngineSpecsFragment = graphql`
  fragment EngineSpecs on Models {
    enginesSpecs {
      name
      turbo
      features
    }
  }
`
export const ModelsDimensionSpecsFragment = graphql`
  fragment DimensionSpecs on Models {
    dimensionSpecs {
      name
      overall {
        name
        value
        icon {
          name
        }
      }
      lengths {
        name
        value
        icon {
          name
        }
      }
    }
  }
`
export const ModelsHighlightsFragment = graphql`
  fragment Highlights on Models {
    highlights {
      icon {
        name
      }
      value
    }
  }
`
export const ModelsGalleryFragment = graphql`
  fragment Gallery on Models {
    gallery {
      category
      images {
        url
      }
    }
  }
`
export const ModelsHeroVideoFragment = graphql`
  fragment HeroVideo on Models {
    heroVideo {
      url1080p
      url480p
      url720p
    }
  }
`
export const ModelsRecommendVideoFragment = graphql`
  fragment RecommendVideo on Models {
    recommendVideo {
      url
    }
  }
`
export const ModelsVersionsFragment = graphql`
  fragment Versions on Models {
    versions {
      ...VersionBasic
      specs {
        name
        value
      }
    }
  }
`
export const ModelsPracticalFragment = graphql`
  fragment PracticalData on Models {
    practicalData {
      sections {
        name
        specs {
          icon {
            name
          }
          name
          value
          explanation
        }
      }
    }
  }
`
export const ModelsTechnicalFragment = graphql`
  fragment TechnicalData on Models {
    technicalData {
      sections {
        name
        options {
          name
          versionIds
          features {
            name
            value
          }
        }
      }
    }
  }
`
export const ConfigOptionsFragment = graphql`
  fragment ConfigOptions on Models {
    configOptions {
      transmissions {
        id
        main
        secondary
        versionIds
      }
      packs {
        id
        name
        origin {
          name
          value
        }
        versionIds
        previousEquip
        categories {
          name
          features {
            name
            value
            newFeat
          }
        }
      }
      engines {
        id
        main
        secondary
        versionIds
      }
      tractions {
        id
        main
        secondary
        versionIds
      }
      colors {
        id
        hexa
        name
        colorSpanish
      }
    }
  }
`
export const AccessoriesBasicFragment = graphql`
  fragment AccessoriesBasic on Accessories {
    ID
    code
    name
    model
    price
    brand
    attributes
    photoURL
    stock
    description
  }
`
