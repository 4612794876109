import queryString from "query-string"

export const checkUrl = (options: string[], _testPage?: string): boolean => {
  const testPage =
    _testPage || (typeof window !== "undefined" ? window.location.pathname : "")
  return options.some(path => testPage.replace(/\//g, "").startsWith(path))
}

export const checkComposedUrl = (
  options: string[],
  _testPage?: string
): boolean => {
  const testPage =
    _testPage || (typeof window !== "undefined" ? window.location.pathname : "")
  return options.some(path => testPage.startsWith(path))
}

export const urlWithSession = (path: string): string =>
  path + (typeof window !== "undefined" ? window.location.search : "")

export const urlWithOffer = (
  path: string,
  offerId: string,
  withOrigin = true
): string => {
  const querys = queryString.parse(
    typeof window !== "undefined" ? window.location.search : ""
  )
  return typeof window !== "undefined"
    ? (withOrigin ? window.location.origin : "") +
        path +
        "?" +
        queryString.stringify(
          { ...querys, sale: offerId },
          { sort: (a, b) => (a === "sale" ? -1 : b === "sale" ? 1 : 0) }
        )
    : ""
}

export const removeSaleFromUrl = (url: string): string => {
  const parsedUrl = queryString.parseUrl(url)
  const newUrl = queryString.stringifyUrl(
    { ...parsedUrl, query: { ...parsedUrl.query, sale: null } },
    { skipNull: true }
  )
  return newUrl
}

export const getCurrentUrlOnMainSiteForSharing = ({
  withSearch,
}: {
  withSearch: boolean
}): string =>
  "https://dercocenterx.cl" +
  (typeof window !== "undefined"
    ? window.location.pathname + (withSearch ? window.location.search : "")
    : "")

export const getCurrentUrl = ({
  withSearch,
}: {
  withSearch: boolean
}): string =>
  typeof window !== "undefined"
    ? window.location.pathname + (withSearch ? window.location.search : "")
    : ""
