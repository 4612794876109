import React, { useState } from "react"
import { Link } from "@reach/router"
// import { navigate } from "gatsby"
import { CarPrice } from "."
import { Image } from ".."
import { DCXButton, Spinner } from ".."
import {
  urlWithSession,
  getColorCircleStyle,
  getMinimunPrices,
} from "../../utils"
import { getStandardAnimationProps } from "../../constants"

const buildColorList = (newData, setColor, selectedColor) =>
  newData.configOptions.colors.map((item, idx) => (
    <div
      className={`color-container ${
        selectedColor.name === item.name ? "selected" : ""
      }`}
      key={idx}
      role="button"
      aria-label="elegir color"
      tabIndex={0}
      onClick={() => setColor(item)}
      onKeyDown={e => e.key === "Enter" && setColor(item.name)}
    >
      <div className="color" style={getColorCircleStyle(item.hexa)}></div>
    </div>
  ))

export function ColorCar({ model, appContext, setMoreInfo }) {
  const { colorGallery, configOptions } = model
  const [color, setColor] = useState(configOptions.colors[0])

  return (
    <div className="color-car">
      <div className="image-car">
        <div className="section-container">
          <div className="info-text">
            <div className="model-version">
              <h1>{model.name.toUpperCase()}</h1>
            </div>
            <div className="phrase">
              <h2>{model.slogan}</h2>
            </div>
            <div {...getStandardAnimationProps()}>
              <Link to={urlWithSession(`/config/${model.slug}`)}>
                <DCXButton
                  className="button-buy"
                  type="primary"
                  text="PERSONALÍZALO"
                />
              </Link>
            </div>
          </div>
          <div className="car-photo">
            <div className="model">
              <h2>{model.shortName.toUpperCase()}</h2>
            </div>
            <div className="image">
              <Image
                filename={colorGallery
                  .find(item => item.colorName.name === color.name)
                  .image.url.split("/")
                  .pop()}
              ></Image>
            </div>
            <div>{color.colorSpanish}</div>
          </div>
        </div>
      </div>
      <div className="colors">
        <div className="section-container">
          {appContext.loading ? (
            <Spinner
              className="loader"
              size={60}
              c={12} //Depend indirectly of size
              color="#b20d27"
              strokes={{ line: 3, circle: 6 }}
            />
          ) : (
            <CarPrice
              prices={getMinimunPrices(
                appContext.modelsPricesStocks,
                model?.versions.map(v => v.id),
                {
                  allowOutOfStock: true,
                }
              )}
              appContext={appContext}
              setMoreInfo={setMoreInfo}
            />
          )}
          <div className="color-list" {...getStandardAnimationProps()}>
            {buildColorList(model, setColor, color)}
          </div>
        </div>
      </div>
    </div>
  )
}
