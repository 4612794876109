import React, { memo } from "react"
import { NameStep, BusinessNameStep } from "../steps"
import { Insurance, OnChange, Owner, InsuranceData } from "../../../types"

type Props = {
  insuranceData: InsuranceData
  onChange: OnChange
}

export const NameStepWrapper: React.FC<Props> = memo(
  ({ insuranceData, onChange }) => {
    return insuranceData[Insurance.owner] !== Owner.business ? (
      <NameStep
        key="name-step"
        onChange={onChange}
        name={insuranceData[Insurance.name]}
        lastname={insuranceData[Insurance.lastname]}
        owner={insuranceData[Insurance.owner]}
      />
    ) : (
      <BusinessNameStep
        key="business-name-step"
        rz={insuranceData[Insurance.rz]}
        onChange={onChange}
      />
    )
  },
  (prev, next) => {
    const change = prev.onChange === next.onChange
    const name = prev.insuranceData.name === next.insuranceData.name
    const lastname = prev.insuranceData.lastname === next.insuranceData.lastname
    const owner = prev.insuranceData.owner === next.insuranceData.owner
    const rz = prev.insuranceData.rz === next.insuranceData.rz
    return change && name && lastname && owner && rz
  }
)
