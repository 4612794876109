import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { Insurance, OnChange } from "../../../types"
import { Selector } from "../subcomponents"
import isEqual from "lodash/isEqual"

type Props = {
  modelId: string
  options: { value: string; label: string }[]
  onChange: OnChange
}

const ModelStepComponent: React.FC<Props> = ({
  modelId,
  options,
  onChange,
}) => {
  return (
    <QuestionTemplate title="¿Qué modelo">
      <div className="insurance-drawer-step-content">
        <Selector
          id="insurance-model-step-model-input"
          label="Modelo"
          name={Insurance.modelId}
          value={modelId}
          options={options}
          onChange={onChange}
        />
      </div>
    </QuestionTemplate>
  )
}

export const ModelStep = memo(ModelStepComponent, (prev, next) => {
  const model = prev.modelId === next.modelId
  let options = prev.options.length === next.options.length
  if (options) {
    for (let i = 0; i < prev.options.length && options; i++) {
      options = isEqual(prev.options[i], next.options[i])
    }
  }
  return model && options
})
