import React, { useContext } from "react"
import { Image, PlatePackTable, PaymentSummaryTable, DrawerHeader } from "."
import { CarContext, UIContext } from "../context"
import {
  CalculatePriceFull,
  getVersionName,
  getMostExpensiveVersionName,
  getSelectedOptions,
} from "../utils"
import {
  useSelectedAccessoriesTotalPrice,
  useSurchargedPrices,
  useIncludeTaxes,
} from "../hooks"

const getImageFileName = (model, appContext) => {
  // Get the selected options from the context
  const selectedOptions = getSelectedOptions(model, appContext)
  // Try to find any image with the same name of the selected color on the context
  let image = model?.colorGallery.find(
    item => item.colorName.name === selectedOptions?.color?.name
  )?.image
  // Else, use the first image.
  if (!image) {
    image = model.colorGallery[0].image
  }
  return image.url.split("/").pop()
}

export function DrawerPaymentDetails({ model }) {
  const appContext = useContext(CarContext)
  const {
    selectedVersion,
    modelsPricesStocks,
    offers,
    selectedOffer,
    color: colorId,
  } = appContext
  const { closePaymentDrawer } = useContext(UIContext)
  // Check context to determine if sale is present
  const offer = offers?.find(of => of.internalId === selectedOffer)

  // Calculate selected accessories total price, if needed depending on the current page
  const [accessoriesTotalPrice] = useSelectedAccessoriesTotalPrice()
  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId,
  })
  const [includeTaxes] = useIncludeTaxes()

  const PaymentParams = CalculatePriceFull({
    appContext,
    offer,
    prices,
    includeTaxes: includeTaxes,
    aditionals: [accessoriesTotalPrice],
  })

  return (
    <div className="drawer-layout drawer-details">
      <DrawerHeader
        // className="details-drawer-header"
        title="DETALLES PAGO"
        drawerName="details-drawer"
        closeDrawer={closePaymentDrawer}
      />
      <div className="content">
        <div className="presentation">
          {offer ? (
            <img
              src={offer.imageUrl}
              alt=""
              className={`car-image ${offer ? "offer" : ""}`}
            />
          ) : (
            <Image filename={getImageFileName(model, appContext)}></Image>
          )}

          <div className="info">
            <div className="disclaimer">
              {offer
                ? "Foto referencial"
                : `Foto corresponde a versión ${getMostExpensiveVersionName(
                    model,
                    modelsPricesStocks
                  )}`}
            </div>
            <div className="model-name">
              {offer ? offer.modelName : model.name}
            </div>
            <div className="version-name">
              {offer
                ? offer.versionName
                : getVersionName(model, selectedVersion?.versionId)}
            </div>
          </div>
        </div>
        <div className="title">Resumen de pago</div>
        <PaymentSummaryTable
          appContext={appContext}
          prices={prices}
          isOffer={Boolean(offer)}
          PaymentParams={PaymentParams}
          dense
        />
        <div className="title">Opcionales</div>
        <PlatePackTable dense prices={prices} />
      </div>
    </div>
  )
}
