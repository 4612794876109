import React, { useContext } from "react"
import { navigate } from "gatsby"
import { HeaderModel } from ".."
import { OptionEquipment } from "../options/option-equipment"
import { KeyboardArrowRightIcon, getIcon } from "../../assets"
import { urlWithSession } from "../../utils"
import { CarContext } from "../../context"

const buildOptions = options =>
  options.map((option, idx) => (
    <React.Fragment key={idx}>
      <div className="list-title">{option.name}</div>
      {option.features?.map((spec, i) => (
        <div key={i} className="item">
          <KeyboardArrowRightIcon className="icon" color="primary" />
          <div className="text">
            {spec.name}
            {spec.value && ": " + spec.value}
          </div>
        </div>
      ))}
    </React.Fragment>
  ))

const buildConfigOptions = (options, windowWidth) =>
  options.map((opt, idx) => (
    <OptionEquipment
      className={`${options.length === 1 ? "single" : ""}`}
      key={idx}
      option={{ info: opt, isFirstPack: !Boolean(opt.previousEquip) }}
      firstEquipOpened={true}
      alwaysExpanded={windowWidth > 810}
      withoutPriceSection
    />
  ))

export function TechnicalData({
  data: { model, comparatorSelections, setComparatorSelections },
}) {
  const { windowWidth } = useContext(CarContext)
  return (
    <div className="model-technical-data" id="tecnica">
      <HeaderModel
        upperTitle="FICHA"
        lowerTitle="TÉCNICA"
        buttonText="COMPARA LOS MODELOS"
        clickHandler={() => {
          if (
            !comparatorSelections.length ||
            comparatorSelections[0].name !== model.name
          )
            setComparatorSelections([model])
          navigate(urlWithSession("/comparador"))
        }}
      />

      {model.dimensionSpecs && (
        <div className="spec">
          <div className="spec-container">
            <div className="title">{model.dimensionSpecs.name}</div>
            <div className="spec-list">
              {model.dimensionSpecs.overall.map((spec, idx) => (
                <div className="detail" key={idx}>
                  <img
                    className="icon"
                    src={getIcon(spec.icon.name, "black")}
                    alt=""
                  ></img>
                  <div className="title-detail">{spec.name}</div>
                  <div className="explain">{spec.value}</div>
                </div>
              ))}
              {model.dimensionSpecs.lengths.map((spec, idx) => (
                <div className="detail" key={idx}>
                  <img
                    className="icon"
                    src={getIcon(spec.icon.name, "black")}
                    alt=""
                  ></img>
                  <div className="title-detail">{spec.name}</div>
                  <div className="explain">{spec.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {model.technicalData.sections.map((sc, idx) => (
        <div className="spec with-text-list" key={idx}>
          <div className="spec-container" id={`technicalData-${sc.name}`}>
            <div className="title">{sc.name}</div>
            {idx === 1 ? (
              <div className="equipment-options">
                {buildConfigOptions(model.configOptions.packs, windowWidth)}
              </div>
            ) : (
              <div className="text-list">{buildOptions(sc.options)}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
