import api from "./api"
import {
  InsuranceSimulationData,
  InsuranceData,
  Insurance,
  Owner,
  CarType,
  InsuranceCommune,
  BrandOption,
  ModelOption,
  DataType,
} from "../types"

const defaultValues = {
  rut: null,
  name: null,
  lastname: null,
  birthdate: null,
  gender: null,
  rz: null,
  commune: null,
  state: null,
  year: null,
  plate: null,
  brandCode: null,
  modelCode: null,
  brand: null,
  model: null,
}

type InsuranceSimulate = (
  insuranceData: InsuranceData,
  brandOptions: BrandOption[],
  addressOptions: InsuranceCommune[],
  selectedModelOption?: ModelOption
) => Promise<InsuranceSimulationData>

export const insuranceSimulate: InsuranceSimulate = async (
  insuranceData,
  brandOptions,
  addressOptions,
  selectedModelOption
) => {
  const personOwner = insuranceData[Insurance.owner] !== Owner.business
  const newCar = insuranceData[Insurance.carType] === CarType.new

  const requestBody = {
    ...defaultValues,
    // Send personal data or business data
    rut: insuranceData[Insurance.rut],
    ...(personOwner
      ? {
          name: insuranceData[Insurance.name],
          lastname: insuranceData[Insurance.lastname],
          birthdate: insuranceData[Insurance.birthdate],
          gender: insuranceData[Insurance.gender],
        }
      : { rz: insuranceData[Insurance.rz] }),

    // Send location
    commune: insuranceData[Insurance.addressId],
    communeName: addressOptions.find(
      opt => `${opt.regionId}${opt.id}` === insuranceData[Insurance.addressId]
    )?.name,

    // Send car info
    ...(!newCar ? { plate: insuranceData[Insurance.plate] } : {}), // Send plate only if used car
    state: newCar ? "1" : "2",
    year: insuranceData[Insurance.year],
    brandCode: insuranceData[Insurance.brandId],
    brand: brandOptions.find(
      option => option.insuranceId === insuranceData[Insurance.brandId]
    )?.insuranceName,
    modelCode: selectedModelOption?.insuranceId,
    model: selectedModelOption?.insuranceName,
  }

  const response = await api.post(`/insurances`, requestBody)
  return response.data as InsuranceSimulationData
}

type InsuranceSimulationFinish = (
  insuranceData: InsuranceData,
  data: DataType
) => Promise<void>

export const insuranceSimulationFinish: InsuranceSimulationFinish = async (
  insuranceData,
  data
) => {
  const requestBody =
    data === DataType.selectedPlan
      ? {
          selectedPlan: {
            companyId: insuranceData[Insurance.companyId],
            deductible: insuranceData[Insurance.deductible],
            dues: insuranceData[Insurance.dues],
          },
        }
      : {
          contactData: {
            contactChannel: insuranceData[Insurance.contactChannel],
            email: insuranceData[Insurance.email],
            phone: insuranceData[Insurance.phone],
          },
        }

  await api.put(
    `/insurances/${insuranceData[Insurance.simulationId]}`,
    requestBody
  )
}

type InsuranceSimulationType = {
  selectedPlan: {
    companyId: string
    deductible: number
    dues: number
    monthlyCostUf: number
  }
}
export const getInsuranceData = async (
  insuranceSimulationId: number
): Promise<InsuranceSimulationType | null> => {
  if (!insuranceSimulationId) return null
  try {
    const response = await api.get(`/insurances/${insuranceSimulationId}`)
    return response?.data || null
  } catch (e) {
    return null
  }
}
