import React from "react"
import Button from "@material-ui/core/Button"
import { KeyboardArrowLeftIcon, KeyboardArrowRightIcon } from "../../assets"

type Props = {
  className?: string
  id?: string
  type: "primary" | "secondary" | "tertiary"
  text: string
  arrow?: "left" | "right"
  onClick: () => void
  disabled?: boolean
  startIcon?: React.FC
  endIcon?: React.FC
}

export const DCXButton: React.FC<Props> = ({
  className,
  id,
  type,
  text,
  arrow,
  onClick,
  disabled,
  startIcon,
  endIcon,
}) => {
  const MuiButtonProps = {
    onClick,
    disabled,
    id,
  }
  const classNames = `${type}-button-dcx button-dcx ${className || ""}`
  return type === "primary" ? (
    <Button
      className={classNames}
      {...MuiButtonProps}
      variant="contained"
      color="primary"
    >
      {text}
    </Button>
  ) : type === "secondary" ? (
    <Button
      className={classNames}
      {...MuiButtonProps}
      variant="outlined"
      color="secondary"
    >
      {text}
    </Button>
  ) : type === "tertiary" ? (
    <Button
      className={classNames}
      {...MuiButtonProps}
      // color="primary"
      startIcon={startIcon || (arrow === "left" && <KeyboardArrowLeftIcon />)}
      endIcon={endIcon || (arrow === "right" && <KeyboardArrowRightIcon />)}
    >
      {text}
    </Button>
  ) : null
}
