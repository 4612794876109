import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Brand } from "../types"

export const useAllBrands = (): Brand[] => {
  const data = useStaticQuery(graphql`
    query ALL_BRANDS {
      allBrands {
        nodes {
          ID
          name
        }
      }
    }
  `)
  const [allBrands] = useState(data.allBrands.nodes)
  return [allBrands]
}
