import React, { useCallback, memo } from "react"
import { FrameTemplate } from "../templates/FrameTemplate"
import { ContactChannelOption } from "../subcomponents"
import {
  EmailIcon,
  CallIcon,
  // WhatsappIcon,
  ContactChannelStepImage,
} from "../../../assets"
import { Insurance, OnChange, ContactChannel, Owner } from "../../../types"

type Props = {
  contactChannel: ContactChannel
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿Cómo quieres que te contactemos?",
  [Owner.other]: "¿Cómo quieres que lo contactemos?",
  [Owner.business]: "¿Cómo quieres que te contactemos?",
}

const VERB = {
  [Owner.me]: "enviarte",
  [Owner.other]: "enviarle",
  [Owner.business]: "enviarte",
}

const ContactChannelStepComponent: React.FC<Props> = ({
  contactChannel,
  owner,
  onChange,
}) => {
  const onClick = useCallback(
    value => () =>
      onChange({
        name: Insurance.contactChannel,
        value,
      }),
    [onChange]
  )
  return (
    <FrameTemplate
      image={ContactChannelStepImage}
      title={TITLES[owner]}
      desc={`Para ${VERB[owner]} la póliza y la información`}
    >
      <div className="insurance-drawer-step-content">
        <ContactChannelOption
          selected={contactChannel === ContactChannel.email}
          icon={EmailIcon}
          text="Email"
          onClick={onClick(ContactChannel.email)}
        />
        <ContactChannelOption
          selected={contactChannel === ContactChannel.phone}
          icon={CallIcon}
          text="Teléfono"
          onClick={onClick(ContactChannel.phone)}
        />
        {/*<ContactChannelOption
        selected={contactChannel === ContactChannel.whatsapp}
        icon={WhatsappIcon}
        text="Whatsapp"
        onClick={onClick(ContactChannel.whatsapp)}
      />*/}
      </div>
    </FrameTemplate>
  )
}

export const ContactChannelStep = memo(ContactChannelStepComponent)
