import React, { useCallback } from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"
import { Insurance, OnChange, SelectEvent } from "../../../types"

type Props = {
  id: string
  label: string
  name: Insurance
  value?: string
  groups: string[]
  options: { value: string; label: string; group: string }[]
  onChange: OnChange
  disabled?: boolean
}

export const GroupedSelector: React.FC<Props> = ({
  id,
  label,
  name,
  value,
  groups,
  options,
  disabled,
  onChange,
}) => {
  const _onChange = useCallback(
    (e: SelectEvent) =>
      onChange({
        name: e.target.name as Insurance,
        value: String(e.target.value),
      }),
    [onChange]
  )
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="insurance-outlined-model-input">{label}</InputLabel>
      <Select
        native
        value={value || ""}
        onChange={_onChange}
        label={label}
        inputProps={{
          name,
          id,
        }}
        disabled={disabled}
      >
        <option aria-label="None" value="" />
        {groups.map(actualGroup => (
          <optgroup key={actualGroup} label={actualGroup}>
            {options
              .filter(({ group }) => group === actualGroup)
              .map(({ value, label }, i) => (
                <option key={i} value={value}>
                  {label}
                </option>
              ))}
          </optgroup>
        ))}
      </Select>
    </FormControl>
  )
}
