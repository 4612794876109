import React from "react"
import { Frame } from "../.."

type Props = {
  image: string
  title: string
  desc: string
  children?: React.ReactNode
}

export const FrameTemplate: React.FC<Props> = ({
  image,
  title,
  desc,
  children,
}) => {
  return (
    <div className="frame-template">
      <Frame image={image} title={title} desc={desc} />
      {children}
    </div>
  )
}
