import React from "react"
import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import CheckIcon from "@material-ui/icons/CheckRounded"
import { parseNumber } from "../../utils"
import { AccessoryPrice } from "."

const AccessoryCardContent = ({
  photoURL,
  imgRoundBorders,
  name,
  description,
  attributes = [],
  monthlyPrice,
  cashPrice,
  firstPaymentPrice,
}) => (
  <div className="accessory-card-content">
    <div className={`image-container ${imgRoundBorders ? "round" : ""}`}>
      <img src={photoURL} alt="" />
    </div>
    <div className="text-container">
      <h3>{name}</h3>
      <h4>{description}</h4>
      <ul>
        {attributes.map((attr, i) => (
          <li key={attr + i}>
            <CheckIcon className="list-decorator" size="small" />
            <p>{attr}</p>
          </li>
        ))}
      </ul>
    </div>
    <div className={`price-container ${!monthlyPrice ? "cash-price" : ""}`}>
      <Divider />
      <span className="accessory-price">Precio: ${parseNumber(cashPrice)}</span>
      <AccessoryPrice {...{ monthlyPrice, cashPrice, firstPaymentPrice }} />
    </div>
  </div>
)

export const AccessoryCard = ({ data = {} }) => {
  return (
    <div className="accessory-card-container">
      <div className="accessory-card">
        <AccessoryCardContent
          imgRoundBorders
          {...data}
          cashPrice={data.price}
        />
      </div>
    </div>
  )
}

export const AccessoryCardSelectable = ({
  data = {},
  selected,
  onClick,
  monthlyPrice,
  firstPaymentPrice,
  cashPrice,
  nameRows,
}) => {
  const { name } = data

  return (
    <div
      className={`accessory-card-container ${selected ? "selected" : ""}`}
      role="button"
      onMouseDown={onClick(false)}
      onKeyDown={onClick(true)}
      tabIndex={0}
    >
      <div className="accessory-card selectable">
        <div className={`title ${nameRows}`}>
          <Checkbox
            className={`checkbox ${selected ? "selected" : ""}`}
            checked={selected}
            tabIndex={-1}
          />
          <h3>{name}</h3>
        </div>
        <Divider />
        <AccessoryCardContent
          {...data}
          {...{ monthlyPrice, cashPrice, firstPaymentPrice }}
          name=""
        />
      </div>
    </div>
  )
}
