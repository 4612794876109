import React, { createContext, useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { BUDGET_FILTER } from "../constants"
import { checkUrl, urlWithSession } from "../utils"
import { navigate } from "gatsby"
import { saveSearch } from "../api"

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
  /* TEXT SEARCH */
  const [isSearcherOpened, setSearcherOpen] = useState(false)
  const openSearcher = useCallback(() => setSearcherOpen(true), [
    setSearcherOpen,
  ])
  const closeSearcher = useCallback(() => setSearcherOpen(false), [
    setSearcherOpen,
  ])

  // Value on the input field
  const [searchValue, setSearchValue] = useState("")
  // Callback to reset the infor form value
  const resetSearch = useCallback(() => {
    setSearchValue("")
    setUserSearch("")
    setDisableSearchResults(false)
  }, [setSearchValue])

  // String to be searched after the debounce time
  const [userSearch, setUserSearch] = useState("")
  // Debounced callback to update the search string
  const updateUserSearch = useCallback(
    debounce(text => setUserSearch(text), 800),
    [setSearchValue]
  )

  /* SEARCH RESULTS */
  const [searchResults, setSearchResults] = useState([])
  const [disableSearchResults, setDisableSearchResults] = useState(false)
  const handleSetSearchResults = useCallback(
    results => {
      if (!disableSearchResults) setSearchResults(results)
    },
    [disableSearchResults, setSearchResults]
  )

  /* SEARCH SUGGESTIONS */
  const [searchSuggestions, setSearchSuggestions] = useState([])
  const [showSearchSuggestions, setShowSearchSuggestions] = useState(false)
  const [disableSearchSuggestions, setDisableSearchSuggestions] = useState(
    false
  )

  // Callback to update de input field value and call de debounced function
  const handleWrite = useCallback(
    text => {
      setSearchValue(text)
      setDisableSearchSuggestions(false)
      setDisableSearchResults(false)
      if (!text) {
        setShowSearchSuggestions(false)
        setUserSearch("")
      } else {
        updateUserSearch(text)
      }
    },
    [setSearchValue, updateUserSearch, setShowSearchSuggestions]
  )

  const handleSearchEnter = useCallback(() => {
    // setUserSearch(searchValue)
    setDisableSearchSuggestions(true)
    setSearchSuggestions([])
    setShowSearchSuggestions(false)
    !checkUrl(["home"]) && navigate(urlWithSession("/home"))
  }, [
    // setUserSearch,
    setShowSearchSuggestions,
  ])

  const openSearchSuggestions = useCallback(() => {
    setShowSearchSuggestions(true)
  }, [setShowSearchSuggestions])

  const clickSearchSuggestion = useCallback(
    text => {
      setSearchValue(text)
      setUserSearch(text)
      setShowSearchSuggestions(false)
      setDisableSearchSuggestions(true)
      setDisableSearchResults(true)
      setSearchSuggestions([])
      !checkUrl(["home"]) && navigate(urlWithSession("/home"))
    },
    [setSearchValue, setUserSearch]
  )

  const closeSearchSuggestions = useCallback(() => {
    setShowSearchSuggestions(false)
  }, [setShowSearchSuggestions])

  /* FILTERS */
  const [filters, setFilters] = useState({
    brand: [],
    category: [],
    budget: [0, BUDGET_FILTER.max],
  })
  const setEstimatedBudgetFilter = useCallback(
    ([min, max]) => {
      setFilters(prev => ({
        ...prev,
        budget: [
          min,
          Math.min(
            Math.ceil((max * 1.05) / 500000) * 500000,
            BUDGET_FILTER.max
          ),
        ],
      }))
    },
    [setFilters]
  )
  const resetFilters = useCallback(
    () =>
      setFilters({ brand: [], category: [], budget: [0, BUDGET_FILTER.max] }),
    [setFilters]
  )

  const resetAll = useCallback(() => {
    resetSearch()
    resetFilters()
  }, [resetSearch, resetFilters])

  /* Save user searches on DB with debounce */
  const searchSaver = useCallback(
    debounce(
      (text, location) => text && text.length && saveSearch(text, location),
      1500
    ),
    []
  )

  useEffect(() => {
    const location = window.location.pathname.replace("/", "") || "landing"
    searchSaver(searchValue, location)
  }, [searchValue, searchSaver])

  return (
    <SearchContext.Provider
      value={{
        isSearcherOpened,
        setSearcherOpen,
        openSearcher,
        closeSearcher,
        modelSearch: searchValue,
        userSearch,
        setModelSearch: handleWrite,
        resetSearch,
        activeModelSearch: searchValue !== "",
        handleSearchEnter,
        searchSuggestions,
        setSearchSuggestions,
        showSearchSuggestions:
          !disableSearchSuggestions && showSearchSuggestions,
        openSearchSuggestions,
        clickSearchSuggestion,
        closeSearchSuggestions,
        filters,
        setFilters,
        setEstimatedBudgetFilter,
        resetFilters,
        areFiltersActive:
          filters.brand.length > 0 ||
          filters.category.length > 0 ||
          filters.budget[0] > 0 ||
          filters.budget[1] < BUDGET_FILTER.max,
        filtersNumber:
          (filters.brand.length > 0 ? 1 : 0) +
          (filters.category.length > 0 ? 1 : 0) +
          (filters.budget[0] > 0 || filters.budget[1] < BUDGET_FILTER.max
            ? 1
            : 0),
        resetAll,
        searchResults,
        setSearchResults: handleSetSearchResults,
        disableSearchResults,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}
