import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { parseNumber } from "../../utils"

export function OptionFlat({ option, onClick }) {
  const isDesktop = useMediaQuery("(min-width:810px)")
  return (
    <Tooltip
      title={option.disabled ? option.disabledTooltipText : "Opción"}
      arrow={true}
      placement={isDesktop ? "right" : "bottom"}
      open={
        Boolean(option.disabled) && Boolean(option.disabledTooltipText)
          ? undefined
          : false
      }
    >
      <div
        className={`option-flat ${option.selected ? "active " : ""}  ${
          option.disabled ? "disabled " : ""
        }`}
        role="button"
        tabIndex={0}
        onClick={!option.disabled ? onClick : undefined}
        onKeyDown={e => e.key === "Enter" && !option.disabled && onClick()}
      >
        <div className="left">
          <div className="title">{option.info.main}</div>
          <div className="subtitle">{option.info.secondary}</div>
        </div>
        <div className="right">
          {option.disabled ? (
            <div className="subtitle">No disponible</div>
          ) : (
            getOptionPricing(option.minFin)
          )}
        </div>
      </div>
    </Tooltip>
  )
}

const getOptionPricing = fin => (
  <>
    {[1, 4].includes(Math.floor(fin.variant)) && (
      <>
        <div className="title">${parseNumber(fin.monthlyPrice)} /mes</div>
        <div className="subtitle">
          Pie ${parseNumber(fin.firstPaymentPrice, 0)}
        </div>
      </>
    )}
    {[2, 5, 7].includes(Math.floor(fin.variant)) && (
      <>
        <div className="title">${parseNumber(fin.firstPaymentPrice)}</div>
        <div className="subtitle">al contado</div>
      </>
    )}
    {[3, 6, 8].includes(Math.floor(fin.variant)) && (
      <>
        <div className="subtitle">Te devolvemos</div>
        <div className="title">${parseNumber(fin.onFavor)}</div>
      </>
    )}
  </>
)
