import React from "react"
import { Slider } from "@material-ui/core"
import { parseNumber } from "../../utils"
import { BUDGET_FILTER } from "../../constants"

export function BudgetSlider({ className, value, onChange }) {
  return (
    <div className={`budget-slider ${className || ""}`}>
      <span className="question">¿Cuánto quieres pagar?</span>
      <div className="slider-container">
        <Slider
          value={value} //{filters.budget}
          marks
          min={0}
          max={BUDGET_FILTER.max}
          step={BUDGET_FILTER.step}
          onChange={onChange}
          aria-labelledby="Slider para acotar el precio del auto"
          valueLabelDisplay="off"
        />
      </div>
      <div className="words">
        <span>desde</span>
        <span>hasta</span>
      </div>
      <div className="numbers">
        <span className="price">${parseNumber(value[0])}</span>
        <span className="dash">-</span>
        <span className="price">${parseNumber(value[1])}</span>
      </div>
    </div>
  )
}
