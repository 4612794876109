import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForEmail } from "../../reusables/inputs.js"
import { Insurance, OnChange, Owner } from "../../../types"

type Props = {
  email: string
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿Cuál es tu email?",
  [Owner.other]: "¿Cuál es su email?",
  [Owner.business]: "¿Cuál es tu email?",
}

const EmailStepComponent: React.FC<Props> = ({ email, owner, onChange }) => {
  // Validation not needed. Handled by InputForEmail.
  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content">
        <InputForEmail
          name={Insurance.email}
          onChange={onChange}
          value={email}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}

export const EmailStep = memo(EmailStepComponent)
