import React from "react"

export function OptionOutOfStockBanner({ className, hide }) {
  return (
    <div
      className={`out-of-stock-equipment-banner ${className || ""} ${
        hide ? "hide" : ""
      }`}
    >
      AGOTADO
    </div>
  )
}
