import React from "react"
import { DCXButton } from "../../reusables/dcx-button"

type Props = {
  nextText?: string
  backText?: string
  onBackClick: () => void
  onNextClick: () => void
  disableNextButton: boolean
  className?: string
}

export const DoubleButton: React.FC<Props> = ({
  nextText = "Siguiente",
  backText = "Atras",
  onBackClick,
  onNextClick,
  disableNextButton = false,
  className,
}) => {
  return (
    <div className={`insurance-double-buttons ${className || ""}`}>
      <DCXButton type="secondary" text={backText} onClick={onBackClick} />
      <DCXButton
        type="primary"
        text={nextText}
        onClick={onNextClick}
        disabled={disableNextButton}
      />
    </div>
  )
}
