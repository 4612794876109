import React, { useCallback } from "react"
import { NumberOption, StringOption, HandleOptionChange } from "../../types"
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab"

type Option = NumberOption | StringOption

type Props = {
  className?: string
  value: React.ReactText
  options: [Option, Option]
  onChange: HandleOptionChange
}

export const DCXToggleButtonGroup: React.FC<Props> = ({
  className,
  value,
  options,
  onChange,
}) => {
  const _onChange = useCallback(
    (_: unknown, value: React.ReactText) => value && onChange(value), // TODO necesario chequear si thuthy?
    [onChange]
  )
  return (
    <ToggleButtonGroup
      className={`dcx-toggle ${className || ""}`}
      color="primary"
      value={value}
      onChange={_onChange}
      orientation="horizontal"
      exclusive
    >
      <ToggleButton className="first" value={options[0].value}>
        {options[0].name}
      </ToggleButton>
      <ToggleButton className="second" value={options[1].value}>
        {options[1].name}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
