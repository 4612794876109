import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { DCXInput } from "../../reusables/DCXInput"
import { Insurance, OnChange } from "../../../types"

type Props = {
  rz: string
  onChange: OnChange
}

const BusinessNameStepComponent: React.FC<Props> = ({ rz, onChange }) => {
  // Validation not needed. Handled by custom inputs.
  return (
    <QuestionTemplate title="¿Cúal es la razón social?">
      <div className="insurance-drawer-step-content">
        <DCXInput
          label="Razón social"
          requiredValidatorError="Este campo es requerido"
          name={Insurance.rz}
          value={rz}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}
export const BusinessNameStep = memo(BusinessNameStepComponent)
