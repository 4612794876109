import React from "react"
import { parseNumber } from "../../../utils"

export function OptionPriceSection({ className, minFin }) {
  return (
    <div className={`option-bottom ${className || ""}`}>
      {!minFin ? (
        <div className="first-payment disabled">
          Disponible para otra configuración
        </div>
      ) : (
        <>
          {[1, 4].includes(Math.floor(minFin.variant)) && (
            <>
              <div className="price">
                <span className="amount">
                  ${parseNumber(minFin.monthlyPrice)}
                </span>{" "}
                /mes
              </div>
              <div className="first-payment">
                Pie ${parseNumber(minFin.firstPaymentPrice)}
              </div>
            </>
          )}
          {[2, 5, 7].includes(Math.floor(minFin.variant)) && (
            <div className="price">
              <span className="amount">
                ${parseNumber(minFin.firstPaymentPrice)}
              </span>
            </div>
          )}
          {[3, 6, 8].includes(Math.floor(minFin.variant)) && (
            <div className="price">
              <span className="amount">
                Te devolvemos ${parseNumber(minFin.onFavor)}
              </span>
            </div>
          )}
        </>
      )}
    </div>
  )
}
