import React, { useState } from "react"
import { SaveMenu } from "."
import Button from "@material-ui/core/Button"

type SaveMenuButtonProps = {
  hide?: boolean
}

export const SaveMenuButton: React.FC<SaveMenuButtonProps> = ({ hide }) => {
  const [saveMenuAnchor, setSaveMenuAnchor] = useState<HTMLElement | null>(null)
  return (
    <>
      <Button
        className={`save-button ${hide ? "hide" : ""}`}
        onClick={e => setSaveMenuAnchor(e.currentTarget)}
      >
        GUARDAR
      </Button>
      <SaveMenu
        anchorEl={saveMenuAnchor}
        onClose={() => setSaveMenuAnchor(null)}
      />
    </>
  )
}

export const CheckoutHeader: React.FC = () => {
  return (
    <div className="save-button-container">
      <SaveMenuButton />
    </div>
  )
}
