import React from "react"
import { ShortcutCard, WrapperProps } from "./ShortcutCard"
import { ShortcutCardInsuranceItem } from "../../assets"

export const InsuranceShortcutCard: React.FC<WrapperProps> = ({
  className,
  handleClick,
}) => {
  return (
    <div className={`shortcut-cards insurance-card ${className}`}>
      <ShortcutCard
        icon={ShortcutCardInsuranceItem}
        title="ASEGURA TU AUTO"
        text="Cotiza en línea, compara y elige tu mejor opción"
        buttonText="COTIZA TU SEGURO"
        handleClick={handleClick}
      />
    </div>
  )
}
