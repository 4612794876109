import React, { useContext } from "react"
// import facebook from "../assets/landing/facebook.svg"
// import twitter from "../assets/landing/twitter.svg"
// import youtube from "../assets/landing/youtube.svg"
import { fromDercoLogo, whatsappLogo } from "../assets"
import arrow from "../assets/landing/arrow-down.svg"
import { CarContext } from "../context"
import { useSpecialDevice } from "../hooks"
import { URLS } from "../constants"
import LEGAL_TERMS from "../data/legal-terms.json"

export function Footer({ shortWidth }) {
  const [forStore] = useSpecialDevice()
  const { terms, setTerms } = useContext(CarContext)
  return (
    <div className={`footer-container ${shortWidth ? "shortWidth" : ""} `}>
      <div className="terms-conditions" id="terms">
        <div
          className="click"
          role="button"
          tabIndex={0}
          onClick={() => setTerms(!terms)}
          onKeyDown={e => e.key === "Enter" && setTerms(!terms)}
        >
          <div className="text">Ver Términos Legales</div>
          <img
            className={`icon ${terms ? "active" : ""}`}
            src={arrow}
            alt="flecha"
          ></img>
        </div>
        <div className={`open ${terms ? "active" : ""}`}>
          <ol>
            {LEGAL_TERMS.map((term, i) => (
              <li key={i}>{term}</li>
            ))}
            <li>
              Para más información revisar{" "}
              <a
                href={URLS.termsAndConditions}
                target="_blank"
                rel="noopener noreferrer"
              >
                Términos y condiciones
              </a>
            </li>
          </ol>
        </div>
      </div>
      <div className="footer from-derco">
        <div className="column from-derco">
          <img src={fromDercoLogo} alt="Una empresa de Derco"></img>
        </div>
        <div className="column hide"></div>
        <div className="column hide"></div>
      </div>
      <div className="footer">
        <div className="column">
          <div className="title">CONTACTO</div>
          <div className="text-line">contacto@dercocenterx.cl</div>
          <div className="text-line">
            DERCO RESPONDE<br></br>600 600 0080
          </div>
          <div className="text-line whatsapp">
            Contáctanos por{" "}
            <a
              className={`whatsapp-button ${forStore ? "hide" : ""}`}
              href="https://api.whatsapp.com/send?phone=56233048940"
              target="_blank"
              rel="noopener noreferrer"
            >
              Whatsapp
              <img className="logo" src={whatsappLogo} alt="WhatsApp" />
            </a>
            <span className={`whatsapp-button ${!forStore ? "hide" : ""}`}>
              Whatsapp
              <img className="logo" src={whatsappLogo} alt="WhatsApp" />
            </span>
            <br></br>+56 2 33048940
          </div>
          {/* <div className="social">
            <a
              className="whatsapp-button"
              href="https://api.whatsapp.com/send?phone=56233048940"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={whatsappLogo} alt="WhatsApp" />
            </a>
          </div> */}
          {/* <div className="text-line">Encuéntranos en:</div>
          <div className="social">
            <a
              href="https://www.facebook.com/DercoCenter/?fref=ts"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="Facebook"></img>
            </a>
            <a
              href="https://twitter.com/DercoCenter/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} alt="Twitter"></img>
            </a>
            <a
              href="https://www.youtube.com/channel/UC02s7CbIT5wpNeE-ekJq-Wg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={youtube} alt="YouTube"></img>
            </a>
          </div> */}
        </div>
        <div className="column">
          <div className="title">SERVICIO AL CLIENTE</div>
          <div className="text-line">
            <a
              href="https://www.dercocenter.cl/garantias/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Garantías
            </a>
          </div>
          <div className="text-line">
            <a
              href={URLS.privacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Políticas de Privacidad
            </a>
          </div>
          <div className="text-line">
            <a
              href={URLS.termsAndConditions}
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y condiciones
            </a>
          </div>
          {/* <div className="text-line">
            <a
              href={URLS.termsAndConditionsOutlet}
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y condiciones Outlet
            </a>
          </div> */}
        </div>
        <div className="column">
          <div className="title">SERVICIOS DERCO</div>
          <div className="text-line">
            <a
              href="http://serviciotecnico.dercocenter.cl/ReservaHora.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Servicios y Mantenciones
            </a>
          </div>
          <div className="text-line">
            <a
              href="https://www.dercoaccesorios.cl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Accesorios
            </a>
          </div>
          <div className="text-line">
            <a
              href="https://www.dercocenter.cl/seguros/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Derco Seguros
            </a>
          </div>
          <div className="text-line">
            <a
              href="https://www.dercocenter.cl/financiamiento/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Financiamiento
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
