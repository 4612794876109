import React, { useCallback } from "react"
import { NumberOption, StringOption } from "../../types"
import { Button, ButtonGroup } from "@material-ui/core"
import { HandleOptionChange } from "../../types"

type Props = {
  buttons: (NumberOption | StringOption)[]
  selectedValue: React.ReactText
  onClick: HandleOptionChange
}

export const DCXButtonGroup: React.FC<Props> = ({
  buttons,
  selectedValue,
  onClick,
}) => {
  const _onClick = useCallback(value => () => onClick(value), [onClick])
  return (
    <ButtonGroup
      className="dcx-button-group"
      aria-label="Grupo de botones seleccionables"
    >
      {buttons.map((button, idx) => (
        <Button
          className={selectedValue === button.value ? "selected" : ""}
          key={idx}
          onClick={_onClick(button.value)}
        >
          {button.name}
        </Button>
      ))}
    </ButtonGroup>
  )
}
