import React from "react"
import { navigate } from "gatsby"
import { Button } from "@material-ui/core"
import CompareArrowsIcon from "@material-ui/icons/CompareArrows"
import { urlWithSession } from "../../utils"

export function HeaderCompareButton({ text }) {
  return (
    <Button
      className={`header-button compare-header-button`}
      startIcon={<CompareArrowsIcon />}
      onClick={() => navigate(urlWithSession("/comparador"))}
    >
      {text || "Comparador"}
    </Button>
  )
}
