import React from "react"
import { URLS } from "../constants"

export function Disclaimer({ className }) {
  return (
    <div className={`${className} disclaimer`}>
      Esta es una simulación sujeta a la evaluación del crédito y del auto en
      parte de pago. Ver{" "}
      <a
        id="view-tac-link"
        href={URLS.termsAndConditions}
        target="_blank"
        rel="noopener noreferrer"
      >
        Términos y condiciones
      </a>
      .
    </div>
  )
}
