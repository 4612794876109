import {
  CalculatePrice as CP,
  getVersionTaxes,
  getVersionName,
  parseNumber,
  getVersionIdsBySelections,
} from "."

export function CalculatePriceFull({
  appContext,
  offer,
  prices,
  includeTaxes,
  aditionals = [],
}) {
  const {
    payment,
    dues,
    percentage,
    selectedVersion,
    carPaymentInfo,
    financeData,
  } = appContext

  /* BASE CALCULATION, WITHOUT ADDITIONALS */
  const PaymentInfoBase = CP(
    payment,
    dues,
    percentage,
    prices,
    carPaymentInfo,
    financeData,
    []
  )

  // Calculate taxes with available info
  const greenTaxRate = offer?.greenTaxRate || selectedVersion?.greenTaxRate
  const taxes = getVersionTaxes({
    greenTaxRate,
    carPrice: PaymentInfoBase.finalCarPrice,
    financeData,
  })
  const totalTaxes = taxes
    ? taxes.soap +
      taxes.greenTax +
      taxes.circulationPermit +
      (PaymentInfoBase.withCredit ? 0 : taxes.inscription)
    : 0

  /* FINAL CALCULATION: overrides withCredit with base value */
  const PaymentInfoFinal = {
    ...CP(payment, dues, percentage, prices, carPaymentInfo, financeData, [
      ...aditionals,
      includeTaxes ? totalTaxes : 0,
    ]),
    withCredit: PaymentInfoBase.withCredit,
    taxes: {
      ...taxes,
      total: totalTaxes,
    },
  }

  return {
    ...PaymentInfoFinal,
  }
}

export const getDiscountPercentage = (carPrice, priceList) => {
  if (!carPrice || !priceList || carPrice >= priceList) return 0
  return parseNumber(((priceList - carPrice) / priceList) * 100, 0, 0)
}

export const getMinimunPrices = (pricesStocks, versionIds, options = {}) => {
  if (!pricesStocks || !versionIds?.length) return null
  return Object.entries(pricesStocks)
    .filter(([vId, object]) => options.allowOutOfStock || !object.outOfStock)
    .reduce((minimun, entry) => {
      const [vId, object] = entry
      if (!versionIds.includes(Number(vId))) return minimun
      return !minimun ||
        (options.reverse
          ? object.priceList > minimun.priceList
          : object.priceList < minimun.priceList)
        ? {
            ...object,
            versionId: Number(vId),
          }
        : minimun
    }, null)
}

export const searchMinimunVersion = (
  model,
  selections,
  pricesStocks,
  versionIds = null,
  options = {}
) => {
  if (!pricesStocks || !model) return null
  const validVersionIds = getVersionIdsBySelections(
    versionIds ?? Object.keys(pricesStocks).map(v => Number(v)),
    model,
    selections,
    pricesStocks
  )
  return getMinimunPrices(pricesStocks, validVersionIds, options)
}

export const getMostExpensiveVersionName = (
  model,
  pricesStocks,
  options = { reverse: true, allowOutOfStock: true }
) => {
  if (!model || !pricesStocks) return ""
  const mostExpensive = getMinimunPrices(
    pricesStocks,
    model.versions.map(v => v.id),
    options
  )
  return getVersionName(model, mostExpensive?.versionId)
}
