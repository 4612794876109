import React, { useContext, useCallback, useRef } from "react"
import { navigate } from "gatsby"
import { SearchBar } from "@inventures/react-lib/components"
import { SearchContext, CarContext, KeyboardContext } from "../../context"
import { useSpecialDevice } from "../../hooks"
import { urlWithSession, checkUrl, getFilteredModels } from "../../utils"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded"
import CallMadeRoundedIcon from "@material-ui/icons/CallMadeRounded"

import {
  Paper,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core"

export const LandingSearch = ({ allModels }) => {
  const { payment, modelsPricesStocks } = useContext(CarContext)
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const {
    activeModelSearch,
    modelSearch,
    setModelSearch,
    searchSuggestions,
    resetSearch,
    resetFilters,
    handleSearchEnter,
    filters,
    clickSearchSuggestion,
    setSearchResults,
  } = useContext(SearchContext)
  const [forStore] = useSpecialDevice()

  const inputRef = useRef(null)

  const handleWrite = useCallback(
    e => {
      setModelSearch(String(e.target.value))
    },
    [setModelSearch]
  )

  const handleKeyboardEnter = useCallback(() => {
    resetFilters()
    navigate(urlWithSession("/home"))
    handleSearchEnter()
  }, [resetFilters, handleSearchEnter])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      resetFilters()
      navigate(urlWithSession("/home"))
      handleSearchEnter()
    },
    [resetFilters, handleSearchEnter]
  )

  const handleClick = useCallback(
    (result, i) => {
      if (checkUrl(["/"])) navigate(urlWithSession("/home"))
      clickSearchSuggestion(result)
      const filteredModels = getFilteredModels(
        allModels,
        result,
        filters,
        modelsPricesStocks,
        payment,
        i > 0
      )
      setSearchResults(filteredModels)
    },
    [
      clickSearchSuggestion,
      allModels,
      filters,
      payment,
      modelsPricesStocks,
      setSearchResults,
    ]
  )

  return (
    <div className="landing-search">
      <form
        className="search-form"
        onSubmit={handleSubmit}
        autoComplete={forStore ? "off" : "on"}
      >
        <SearchBar
          className={`search-bar ${searchSuggestions.length > 0 ? "open" : ""}`}
          id="header-search-bar"
          showInput={true}
          onCollapsedIconClick={() => {}}
          onExpandedIconClick={resetSearch}
          ExpandedIcon={!activeModelSearch && SearchRoundedIcon}
          value={modelSearch}
          onChange={handleWrite}
          size="small"
          iconColor="#FFFFFF"
          barColor="#FFFFFF"
          placeholder={"Busca tu auto nuevo"}
          // eslint-disable-next-line
          autoFocus={false}
          inputRef={inputRef}
          onFocus={() =>
            showKeyboard(setModelSearch, inputRef, {
              onTabPress: handleKeyboardEnter,
              onEnterPress: handleKeyboardEnter,
            })
          }
          onBlur={hideKeyboard}
        />
      </form>
      <div
        className={`divider ${searchSuggestions.length <= 0 ? "hide" : ""}`}
      />
      <Paper
        className={`suggestions ${searchSuggestions.length > 0 ? "open" : ""}`}
        elevation={12}
      >
        {[]
          .concat(searchSuggestions)
          .filter(Boolean)
          .slice(0, 5)
          .map((result, i) => (
            <ListItem
              key={i + result}
              role={undefined}
              button={true}
              divider
              onClick={() => handleClick(result, i)}
              ContainerComponent="div"
            >
              <ListItemIcon>
                <SearchRoundedIcon />
              </ListItemIcon>
              <ListItemText primary={result} />
              <ListItemSecondaryAction className="suggestions___secondary-icon">
                <CallMadeRoundedIcon />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </Paper>
    </div>
  )
}
