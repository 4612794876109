/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core"
import { CloseIcon, CoolEmoji } from "../assets"
import { DCXButton } from "./reusables"

type Props = {
  open: boolean
  onClose: () => void
}

export const DemoAlertModal: React.FC<Props> = ({ open, onClose }) => {
  return (
    <Dialog className="demo-alert-modal" open={open} onClose={onClose}>
      <IconButton className="close-button" size="medium" onClick={onClose}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
      <DialogTitle>
        <img className="emoji" src={CoolEmoji} aria-label="cool-emoji" />
        <span className="text">Esto es una demo</span>
      </DialogTitle>
      <DialogContent>
        <p>
          Los precios de los autos pueden no corresponder a los valores actuales
        </p>
        <p>
          Si tienes dudas o quieres reservar un auto te recomendamos
          visitar&nbsp;
          <a href="https://dercocenter.cl">dercocenter.cl</a>
        </p>
      </DialogContent>
      <DialogActions>
        <DCXButton type="tertiary" text="OK" onClick={onClose} />
      </DialogActions>
    </Dialog>
  )
}
