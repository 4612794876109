import React from "react"
import { RecommendVideo } from "."

export function Recommends({ className, model }) {
  return (
    model.recommendVideo && (
      <div className={`model-recommends ${className || ""}`}>
        <div className="title">
          <div className="section-name">Conoce más del {model.name}</div>
        </div>
        <RecommendVideo
          className="video"
          videoLink={model.recommendVideo.url}
        ></RecommendVideo>
      </div>
    )
  )
}
