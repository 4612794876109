import React, { createContext, useCallback, useRef, useState } from "react"
import { CustomKeyboard } from "../components"
import { useSpecialDevice } from "../hooks"
import { SPECIAL_DEVICE } from "../types"

export const KeyboardContext = createContext()

export const KeyboardProvider = ({ children }) => {
  // setState function to update input field value
  const keyboardSetter = useRef(null)
  const keyboardRef = useRef(null)

  // keyboard state, options and custom callbacks
  const [kbState, setKbState] = useState({
    display: false,
    inputRef: null,
    options: {},
    callbacks: {},
  })
  // Decide if keyboard is needed based on env variable
  const [, storePage] = useSpecialDevice()
  const [useKeyboard] = useState(() => storePage === SPECIAL_DEVICE.screen)

  // Callback to open the keyboard. Receives setter, op
  const showKeyboard = useCallback(
    (setterFunc, inputRef, callbacks = {}, options = {}) => {
      if (useKeyboard) {
        keyboardSetter.current = setterFunc
        setKbState(prev => ({
          ...prev,
          display: true,
          inputRef,
          callbacks,
          options,
        }))
      }
    },
    [useKeyboard]
  )

  // Hide keyboard, reset internal state and blur if needed
  const hideKeyboard = useCallback(
    needBlur => {
      if (useKeyboard) {
        // Delete current setter
        keyboardSetter.current = null
        // Save elementId
        const inputElem = kbState.inputRef?.current
        // Reset keyboard state
        setKbState(prev => ({
          ...prev,
          display: false,
          inputRef: null,
          callbacks: {},
          options: {},
        }))
        // Manual blur if needed
        if (needBlur && inputElem) {
          inputElem.blur()
        }
      }
    },
    [useKeyboard, kbState]
  )

  const resetKeyboard = useCallback(() => {
    if (useKeyboard && keyboardRef?.current) keyboardRef.current.setInput("")
  }, [useKeyboard, keyboardRef])

  return (
    <KeyboardContext.Provider
      value={{
        isKeyboardOpen: useKeyboard && kbState.display,
        showKeyboard,
        hideKeyboard,
        resetKeyboard,
      }}
    >
      <>
        {children}
        {useKeyboard && kbState.display && (
          <CustomKeyboard
            keyboardRef={keyboardRef}
            inputRef={kbState.inputRef}
            options={kbState.options}
            callbacks={{
              ...kbState.callbacks,
              onChange: keyboardSetter.current,
            }}
          />
        )}
      </>
    </KeyboardContext.Provider>
  )
}
