import React from "react"
import { AppBar, Toolbar, Divider } from "@material-ui/core"
import { HeaderSortButton, HeaderFilterButton } from "."

export const FilterSortHeader = ({ topPosition, activeModelSearch }) => {
  return (
    <AppBar
      className={`filters-header ${topPosition} ${
        activeModelSearch ? "active-search" : ""
      }`}
      position="sticky"
    >
      <Toolbar variant="dense">
        <HeaderFilterButton />
        <Divider orientation="vertical" />
        <HeaderSortButton />
      </Toolbar>
    </AppBar>
  )
}
