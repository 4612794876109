import React from "react"
import { Loader } from "."

type Props = {
  isOpen: boolean
}

export const DrawerLoader: React.FC<Props> = ({ isOpen }) => {
  return (
    <Loader className={`drawer-loader-container ${!isOpen ? "hide" : ""}`} />
  )
}
