import React from "react"
import { parseNumber } from "../../utils"

export function OptionColor({ option, onClick, displayPriceSection }) {
  return (
    <div
      className={`option-color ${option.selected ? "active " : ""} ${
        option.disabled ? "disabled " : ""
      }`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={e => e.key === "Enter" && onClick()}
    >
      <div className="circle" style={option.style}></div>
      <div className="texts">
        <div className="title">{option.info.displayName}</div>
        {option.info.colorSurcharge && (
          <div className="subtitle">Color premium</div>
        )}
      </div>
      {displayPriceSection && (
        <div className="price">
          {option.disabled ? (
            <div className="subtitle">No disponible</div>
          ) : (
            getOptionPricing(option.minFin)
          )}
        </div>
      )}
    </div>
  )
}

const getOptionPricing = fin => (
  <>
    {[1, 4].includes(Math.floor(fin.variant)) && (
      <>
        <div className="title">${parseNumber(fin.monthlyPrice)} /mes</div>
        <div className="subtitle">
          Pie ${parseNumber(fin.firstPaymentPrice, 0)}
        </div>
      </>
    )}
    {[2, 5, 7].includes(Math.floor(fin.variant)) && (
      <>
        <div className="title">${parseNumber(fin.firstPaymentPrice)}</div>
        <div className="subtitle">al contado</div>
      </>
    )}
    {[3, 6, 8].includes(Math.floor(fin.variant)) && (
      <>
        <div className="subtitle">Te devolvemos</div>
        <div className="title">${parseNumber(fin.onFavor)}</div>
      </>
    )}
  </>
)
