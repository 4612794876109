import { useState, useEffect, useContext } from "react"
import { CarContext } from "../context"
import { getAccessoriesTotalPrice, checkUrl } from "../utils"
import { CheckoutAccessory } from "../types"

type Results = [number, CheckoutAccessory[], CheckoutAccessory[]]

export const useSelectedAccessoriesTotalPrice = (): Results => {
  const {
    checkoutData: { selectedAccessories },
  } = useContext(CarContext)

  const [accessories, setAccessories] = useState<CheckoutAccessory[]>([])
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(() => {
    if (!checkUrl(["home", "model", "config", "comparador"])) {
      setTotalPrice(getAccessoriesTotalPrice(selectedAccessories))
      setAccessories(selectedAccessories)
    }
  }, [selectedAccessories, setTotalPrice, setAccessories])

  // [precio total, filtrados, todos]
  return [totalPrice, accessories.filter(acc => acc.selected), accessories]
}
