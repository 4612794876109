export enum Payment {
  cash = "cash",
  credit = "credit",
  flexCredit = "flexCredit",
}

export enum CreditText {
  credit = "Crédito Convencional",
  flexCredit = "Crédito Flexible",
}
