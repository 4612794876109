import api from "./api"
import { Seller } from "../types"

export const loginSeller = async (
  sellerCredential: string
): Promise<Seller | null> => {
  try {
    const response = await api.get(`/sellers?rut=${sellerCredential}`)
    return (response.data as Seller) || null
  } catch (e) {
    return null
  }
}

export const checkSellerExistence = async (
  sellerCredential: string
): Promise<boolean> => {
  try {
    const response = await api.get(
      `/sellers?rut=${sellerCredential}&checkExistence=true`
    )
    return response?.data?.exists
  } catch (e) {
    return false
  }
}

export const getSellerData = async (
  sellerId: number
): Promise<Seller | null> => {
  try {
    const response = await api.get(`/sellers/${sellerId}`)
    return (response.data as Seller) || null
  } catch (e) {
    return null
  }
}
