import React from "react"
import { DCXButton } from "../../reusables/dcx-button"

type Props = {
  text: string
  onClick: () => void
  className?: string
  disabled: boolean
}

export const SimpleButton: React.FC<Props> = ({
  text,
  onClick,
  className,
  disabled,
}) => {
  return (
    <div className={`insurance-simple-button ${className || ""}`}>
      <DCXButton
        type="primary"
        text={text}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  )
}
