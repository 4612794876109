import React from "react"
import FormControl from "@material-ui/core/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import { CarInfo, StepProps } from "."
import { DCXButton } from ".."

export const AppraiseStep4: React.FC<StepProps> = ({ form, updateForm }) => {
  return (
    <>
      <CarInfo data={form} />
      <div className="title">¿Tienes las mantenciones de tu auto al día?</div>
      <div className="subtitle"></div>
      <div className="component">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Mantenciones"
            name="maintenance"
            value={form.maintenanceStatus}
            onChange={(_, newValue) =>
              updateForm(prev => ({
                ...prev,
                maintenanceStatus: Number(newValue),
              }))
            }
          >
            <FormControlLabel
              id="select-maintenance-1"
              value={1}
              control={<Radio />}
              label={TEXTS[1]}
            />
            <FormControlLabel
              id="select-maintenance-2"
              value={0}
              control={<Radio />}
              label={TEXTS[0]}
            />
            <FormControlLabel
              id="select-maintenance-3"
              value={-1}
              control={<Radio />}
              label={TEXTS[-1]}
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="buttons">
        <DCXButton
          className="button-back"
          id="back-step-4"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        />
        <DCXButton
          className="button-next select-car-maintenance"
          id="finish-step-4"
          type="primary"
          text="SIGUIENTE"
          onClick={form.actions.nextStep}
        />
      </div>
    </>
  )
}

const TEXTS = {
  1: "Sí, realizadas en un servicio de la marca",
  0: "Sí, todas las mantenciones, pero no en la marca",
  [-1]: "No, me faltan algunas",
}
