import React from "react"
import { DCXButton } from ".."
import { getStandardAnimationProps } from "../../constants"

export function HeaderModel({
  upperTitle,
  lowerTitle,
  buttonText,
  clickHandler,
}) {
  return (
    <div className="header-model">
      <div className="section-container">
        <div className="title">
          <div className="gray">{upperTitle}</div>
          <div className="dark-gray">{lowerTitle}</div>
        </div>
        <div {...getStandardAnimationProps()}>
          <DCXButton
            className={`title-action-button ${!buttonText ? "hide" : ""}`}
            type="tertiary"
            icon="comparePink"
            text={buttonText}
            arrow="right"
            onClick={clickHandler}
          />
        </div>
      </div>
    </div>
  )
}
