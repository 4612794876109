import React, { memo } from "react"
import { FrameTemplate } from "../templates"
import { UserSummaryStepImage } from "../../../assets"
import { DCXButton } from "../../reusables/dcx-button"
import { Owner } from "../../../types"

type Props = {
  owner: Owner
  rut: string
  rz?: string
  name?: string
  lastname?: string
  birthdate?: string
  address: string
  onChangeInfo: () => void
}

const TITLES = {
  [Owner.me]: "¿Están bien tus datos?",
  [Owner.other]: "¿Están bien sus datos?",
  [Owner.business]: "¿Están bien los datos?",
}

const UserSummaryStepComponent: React.FC<Props> = ({
  owner,
  rut,
  rz,
  name,
  lastname,
  birthdate,
  address,
  onChangeInfo,
}) => {
  return (
    <FrameTemplate
      title={TITLES[owner]}
      image={UserSummaryStepImage}
      desc="Revísalos y si están correctos continúa"
    >
      <div className="insurance-drawer-step-content insurance-user-summary-step">
        <div className="summary">
          <p>{rut}</p>
          <p className={owner !== Owner.business ? "hidden" : ""}>{rz}</p>
          <p className={owner === Owner.business ? "hidden" : ""}>
            {name} {lastname}
          </p>
          <p className={owner === Owner.business ? "hidden" : ""}>
            {birthdate}
          </p>
          <p>{address}</p>
        </div>
        <DCXButton
          type="tertiary"
          text="Cambiar Información"
          arrow="right"
          onClick={onChangeInfo}
        />
      </div>
    </FrameTemplate>
  )
}

export const UserSummaryStep = memo(UserSummaryStepComponent)
