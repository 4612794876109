import { useEffect, useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { CarContext } from "../context"

export const useInsuranceCompanyName = (): [string] => {
  const { checkoutData } = useContext(CarContext)
  const [companyName, setCompanyName] = useState<string>("")
  const data = useStaticQuery(graphql`
    query ALL_INSURANCE_COMPANY_NAMES {
      allInsurance {
        nodes {
          companies {
            id
            name
          }
        }
      }
    }
  `)

  const [allCompanies] = useState(() => data.allInsurance.nodes[0].companies)

  useEffect(() => {
    const company = allCompanies.find(
      com => com.id === checkoutData.insurance?.selectedPlan.companyId
    )
    company && setCompanyName(company.name)
  }, [checkoutData.insurance?.selectedPlan.companyId])

  return [companyName]
}
