import React from "react"
import { DCXButton } from ".."

export type WrapperProps = {
  className: string
  handleClick: () => void
}

type Props = {
  title: string
  text: string
  icon: string
  buttonText: string
  handleClick: Pick<WrapperProps, "handleClick">
  color?: string
}

export const ShortcutCard: React.FC<Props> = ({
  title,
  text,
  icon,
  buttonText,
  handleClick = () => {},
  color = "primary",
}) => {
  return (
    <div
      className={`extra-cards___container ${
        color === "secondary" ? "secondary" : ""
      }`}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyDown={e => e.key === "Enter" && handleClick()}
    >
      <div className="data-container">
        <div className="icon-container">
          <img src={icon} alt="ícono de la tarjeta" />
        </div>
        <div className="txt-container">
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </div>
      <DCXButton className="button" text={buttonText} type="secondary" />
    </div>
  )
}
