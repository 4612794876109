import { useEffect, useState, useContext } from "react"
import { CarContext } from "../context"
import { Model } from "../types"

type Results = [boolean]

type Params = {
  colorId: number
  selectedModel: Model | null
  selectedVersionId: number
}
export const usePremiumColor = ({
  colorId,
  selectedModel,
  selectedVersionId,
}: Params): Results => {
  const { modelsPricesStocks } = useContext(CarContext)

  const [isPremiumColorSelected, setPremiumColorSelected] = useState(false)

  useEffect(() => {
    if (!modelsPricesStocks || !colorId || !selectedModel || !selectedVersionId)
      return
    const selectedColorname = selectedModel.configOptions.colors.find(
      opt => opt.id === colorId
    )?.name
    const needSurcharge = Boolean(
      modelsPricesStocks[selectedVersionId]?.colorStocks.find(
        color => color.colorName === selectedColorname
      )?.colorSurcharge
    )
    setPremiumColorSelected(needSurcharge)
  }, [
    modelsPricesStocks,
    colorId,
    selectedModel,
    selectedVersionId,
    setPremiumColorSelected,
  ])

  return [isPremiumColorSelected]
}
