import React from "react"

/*
    c: initial position (c, c) or rotating circle. Cant be calculated from size so need to be passed on props
 */
export function Spinner({ className, size, c, color, strokes }) {
  return (
    <div className={className}>
      <svg
        version="1.1"
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        aria-label={""}
      >
        <circle
          fill="none"
          stroke={color}
          strokeWidth={strokes.line}
          cx={size / 2}
          cy={size / 2}
          r={size / 2 - 5}
          style={{ opacity: 0.5 }}
        />
        <circle
          fill={color}
          stroke={color}
          strokeWidth={strokes.circle}
          cx={c} //COULD NOT DEDUCE FROM SIZE PROP
          cy={c} //COULD NOT DEDUCE FROM SIZE PROP
          r={size / 40}
        >
          <animateTransform
            attributeName="transform"
            dur="2s"
            type="rotate"
            from={`0 ${size / 2} ${size / 2}`}
            to={`360 ${size / 2} ${size / 2}`}
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </div>
  )
}
