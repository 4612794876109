import React, { useCallback, useContext } from "react"
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import { PaymentHeader } from "."
import { UIContext } from "../context"
import { HelpIcon } from "../assets"
import { parseNumber, getDiscountPercentage } from "../utils"

export function ShowPrice({
  appContext,
  PaymentInfo,
  priceUnavailableText = "No disponible",
  data: otherData,
  center,
  big,
  from = true,
  discount = true,
  chips = true,
  fullClickeable,
}) {
  const data = { ...appContext, ...PaymentInfo, ...otherData }
  const { openFinancingDrawer, openPaymentDrawer } = useContext(UIContext)
  const discountValue = getDiscountPercentage(
    data.finalCarPrice,
    data.priceList
  )
  const loading = data.variant === 0
  const priceUnavailable = data.variant === -1

  const onClickPrice = useCallback(
    fullClickeable
      ? e => {
          e.preventDefault()
          e.stopPropagation()
          openPaymentDrawer()
        }
      : () => {},
    [fullClickeable]
  )

  const onClickHelp = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      openPaymentDrawer()
    },
    [openPaymentDrawer]
  )

  const onClickFinance = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      openFinancingDrawer()
    },
    [openFinancingDrawer]
  )

  return (
    <div
      className={`price-container ${center ? "center" : ""} ${
        big ? "big" : ""
      } ${fullClickeable ? "clickeable" : ""} ${
        priceUnavailable ? "price-unavailable" : ""
      }`}
      role="button"
      tabIndex={0}
      onClick={onClickPrice}
      onKeyPress={e => e.key === "Enter" && onClickPrice()}
    >
      {priceUnavailable && (
        <div className="price-unavailable">{priceUnavailableText}</div>
      )}
      {!priceUnavailable && from && (
        <div className="from">{!loading && "DESDE"}</div>
      )}
      {[1, 4].includes(Math.floor(PaymentInfo.variant)) && (
        <>
          <div className="price-detail">
            ${parseNumber(data.monthlyPrice)}
            {/*asterisk(data.terms)*/}
            <span className="superindex-container">
              <sup className="superindex">(1)</sup>
            </span>
            <span>&nbsp;/ mes</span>
            {moreInfo(data.setMoreInfo, onClickHelp)}
          </div>
          <div className="pay-upfront">
            <span>
              <span>Pie:</span> ${parseNumber(data.firstPaymentPrice, 0)} - CAE:{" "}
              {parseNumber(data.CAE, 2)}%
            </span>
          </div>
        </>
      )}
      {[2, 5, 7].includes(Math.floor(PaymentInfo.variant)) && (
        <>
          <div className="price-detail">
            ${parseNumber(PaymentInfo.firstPaymentPrice)}
            {moreInfo(data.setMoreInfo, onClickHelp)}
          </div>
          <div className="pay-upfront">
            <span>Precio Lista: </span>
            <span
              style={
                PaymentInfo.firstPaymentPrice === data.priceList
                  ? {}
                  : { textDecoration: "line-through" }
              }
            >
              ${parseNumber(data.priceList)}
            </span>
          </div>
        </>
      )}
      {[3, 6, 8].includes(Math.floor(PaymentInfo.variant)) && (
        <>
          <div className="price-detail on-favor">
            <span>Te devolvemos:&nbsp;</span>{" "}
            <div>${parseNumber(data.onFavor)}</div>
            {moreInfo(data.setMoreInfo, onClickHelp)}
          </div>
          <div className="pay-upfront">
            <span>Precio:</span> ${parseNumber(data.carPrice)}
          </div>
        </>
      )}
      {[1, 3].includes(Math.floor(PaymentInfo.variant)) && (
        <div className="pay-upfront">
          <span>Pago Final Opcional: ${parseNumber(data.VFMG)}</span>
        </div>
      )}
      {!priceUnavailable && appContext.carPaymentInfo && (
        <div className="pay-upfront">
          <span>Auto parte de pago:&nbsp;</span>$
          {parseNumber(appContext.carPaymentInfo.finalPrice)}
        </div>
      )}
      {!priceUnavailable && discount && (
        <div className="pay-upfront discount">
          {isNaN(discountValue) || !discountValue
            ? null
            : `${discountValue}% descuento`}
        </div>
      )}
      {!priceUnavailable && chips && (
        <PaymentHeader
          id="open-financing-drawer-button-pricecomponent"
          data={data}
          onClick={onClickFinance}
        />
      )}
    </div>
  )
}

const moreInfo = (setMoreInfo, onClickPrice) => {
  return (
    <Tooltip
      title="Ver detalles del precio"
      arrow={true}
      placement="bottom-start"
    >
      <IconButton
        aria-label="Ayuda"
        //size="small"
        onClick={e => {
          e.stopPropagation()
          onClickPrice(e)
          // Execute parent function if provided and always open payment details drawer
          setMoreInfo && setMoreInfo()
        }}
      >
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )
}
