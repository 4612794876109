import React from "react"
import { Link } from "gatsby"
import { ShowPrice, Image, Spinner } from "."
import { getStandardAnimationProps } from "../constants"
import {
  changanLogo,
  greatwallLogo,
  havalLogo,
  jacLogo,
  mazdaLogo,
  renaultLogo,
  suzukiLogo,
} from "../assets"
import {
  CalculatePriceFull,
  urlWithSession,
  getDiscountPercentage,
  getMinimunPrices,
  isModelAvailable,
} from "../utils"

export function CardModel({ model, appContext, terms, animate, idx }) {
  const { loading, modelsPricesStocks } = appContext
  const animationProps = animate ? getStandardAnimationProps(idx) : {}

  // Determine if this model is out of stock (no versions available)
  const outOfStock = !isModelAvailable(model, modelsPricesStocks)

  // Get chepeast version
  const chepeastVersion = getMinimunPrices(
    modelsPricesStocks,
    model?.versions.map(v => v.id),
    {
      // allowOutOfStock: !isModelAvailable(model, modelsPricesStocks),
      allowOutOfStock: true,
    }
  )

  // Calculate payment info. Pass cheapest version as "selected"
  const PaymentInfo = CalculatePriceFull({
    appContext: {
      ...appContext,
      selectedVersion: chepeastVersion,
    },
    prices: chepeastVersion,
  })

  const priceList = chepeastVersion?.priceList || 0
  // const outOfStock = PaymentInfo.variant === -1
  const discount = getDiscountPercentage(PaymentInfo.finalCarPrice, priceList)

  return (
    <Link to={urlWithSession(`/model/${model.slug}`)} {...animationProps}>
      <div
        className={`common-card model-card ${outOfStock ? "out-of-stock" : ""}`}
      >
        <div className="card-content">
          <div className="card-top">
            <img
              className="brand-icon"
              src={getBrandLogo(model.brand.toLowerCase())}
              alt="marca"
            />
            <div className="offer-names">{handleHyphen(model.name)}</div>
          </div>
          <Image
            className="photo"
            filename={model.gallery
              .find(g => g.category === "model")
              .images[0].url.split("/")
              .pop()}
            alt="Test"
          ></Image>
          {loading ? (
            <Spinner
              className="loader"
              size={60}
              c={12} //Depend indirectly of size
              color="#b20d27"
              strokes={{ line: 3, circle: 6 }}
            />
          ) : (
            <div className="price">
              <ShowPrice
                appContext={appContext}
                PaymentInfo={PaymentInfo}
                priceUnavailableText=""
                data={{
                  priceList,
                  terms,
                  setMoreInfo: () =>
                    appContext.setSelectedVersion(chepeastVersion),
                  slug: model.slug,
                }}
                center
                discount={false}
                chips={false}
              ></ShowPrice>
            </div>
          )}
          <div className={`out-of-stock-banner ${outOfStock ? "enabled" : ""}`}>
            {outOfStock ? "AGOTADO" : ""}
          </div>
        </div>

        <div className={`discount ${discount === 0 ? "hidden" : ""}`}>
          {getDiscountPercentage(PaymentInfo.finalCarPrice, priceList)}%
        </div>
      </div>
    </Link>
  )
}

const handleHyphen = modelName => {
  if (modelName.includes("-")) {
    const hyphenPos = modelName.indexOf("-")
    const words = []
    for (let m, reg = /\S+/g; (m = reg.exec(modelName)); ) {
      words.push({ word: m[0], startIndex: m.index })
    }
    const wordHyphen = words.reverse().find(obj => obj.startIndex < hyphenPos)
    const preText = modelName.slice(0, wordHyphen.startIndex)
    const postText = modelName.slice(
      wordHyphen.startIndex + wordHyphen.word.length
    )
    return (
      <>
        <span>{preText}</span>
        <span style={{ whiteSpace: "nowrap" /*width: "min-content"*/ }}>
          {wordHyphen.word}
        </span>
        <span>{postText}</span>
      </>
    )
  }
  return <span>{modelName}</span>
}

const getBrandLogo = brand => {
  switch (brand) {
    case "mazda":
      return mazdaLogo
    case "suzuki":
      return suzukiLogo
    case "renault":
      return renaultLogo
    case "changan":
      return changanLogo
    case "great wall":
      return greatwallLogo
    case "haval":
      return havalLogo
    case "jac":
      return jacLogo
    default:
      return null
  }
}
