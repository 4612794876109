import React from "react"
import { FormControl, InputLabel, Select } from "@material-ui/core"
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded"
import { getDuesOrPercentageOptions } from "../../utils"
import { useSpecialDevice, useUpdateStats, UpdateStatType } from "../../hooks"
import { SPECIAL_DEVICE, Payment } from "../../types"

type Props = {
  className?: string
  disableUnderline?: boolean
  payment: Payment
  dues: number
  setDues: (value: number) => void
  label?: ReactNode
  variant?: "filled" | "outlined" | "standard"
}

export const DuesSelector: React.FC<Props> = ({
  className,
  disableUnderline,
  payment,
  dues,
  setDues,
  label,
  variant,
}) => {
  const [updateStat] = useUpdateStats("", UpdateStatType.CALLBACK)
  const [, storePage] = useSpecialDevice()
  const Selector = (
    <Select
      native
      value={dues}
      disabled={payment === Payment.cash}
      {...(variant === "outlined" ? {} : { disableUnderline })}
      onChange={({ target: { value } }) => {
        value && setDues(Number(value))
        updateStat(`paymentConfig-dues-selectorTo${value}`)
      }}
      IconComponent={KeyboardArrowDownRoundedIcon}
      label={label}
      inputProps={{
        id: "outlined-age-native-simple",
      }}
    >
      {getDuesOrPercentageOptions(payment, "dues").map(e => (
        <option key={e.value} value={e.value}>
          {e.label}
        </option>
      ))}
    </Select>
  )

  return (
    <FormControl
      className={`selector dues-selector ${className || ""} ${
        storePage === SPECIAL_DEVICE.screen ? "screen" : ""
      }`}
      variant={variant || "standard"}
    >
      {variant === "outlined" && (
        <InputLabel
          className="outlined-label"
          htmlFor="outlined-age-native-simple"
        >
          {label}
        </InputLabel>
      )}
      {Selector}
    </FormControl>
  )
}
