import React, { useCallback, memo } from "react"
import { QuestionTemplate } from "../templates"
import { DatePicker } from "../subcomponents"
import { Insurance, OnChange, Owner } from "../../../types"
import { capitalize } from "../../../utils"
import moment from "moment"

type Props = {
  birthdate: string
  name: string
  onChange: OnChange
  owner: Owner
}

const TITLES = (name: string) => ({
  [Owner.me]: `¿Cuándo llegaste al mundo ${capitalize(name)}?`,
  [Owner.other]: `¿Cuándo llegó al mundo ${capitalize(name)}?`,
  [Owner.business]: "",
})

const BirthdateStepComponent: React.FC<Props> = ({
  name,
  birthdate,
  owner,
  onChange,
}) => {
  const _onChange = useCallback(date => {
    const hasError =
      moment(date).isValid() && moment().diff(date, "years", true) < 18
    onChange({ name: Insurance.birthdate, value: date, hasError })
  }, [])

  return (
    <QuestionTemplate title={TITLES(name)[owner]}>
      <div className="insurance-drawer-step-content insurance-birthdate-step">
        <DatePicker date={birthdate} setDate={_onChange} />
      </div>
    </QuestionTemplate>
  )
}

export const BirthdateStep = memo(BirthdateStepComponent)
