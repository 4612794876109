import React, { useContext, memo } from "react"
import moment from "moment"
import { QuestionTemplate } from "../templates"
import { InsuranceCompanyOption } from "../subcomponents"
import { InsuranceSimulationOption } from "../../../types"
import CheckIcon from "@material-ui/icons/CheckRounded"
import { CarContext } from "../../../context"
import { Owner } from "../../../types"

type StepProps = {
  option?: InsuranceSimulationOption
  brand: string
  model: string
  year: string
  name: string
  lastname: string
  owner: Owner
}

type SummaryCardProps = {
  option?: InsuranceSimulationOption
  attributes: string[]
  brand: string
  model: string
  year: string
  owner: string
  expirationDate: string
}

const SummaryCardComponent: React.FC<SummaryCardProps> = ({
  option,
  attributes,
  brand,
  model,
  year,
  owner,
  expirationDate,
}) => {
  const { financeData } = useContext(CarContext)
  const todayUF = financeData?.todayUF
  const modelFullName = model.toLowerCase().includes(brand.toLowerCase())
    ? model
    : brand + " " + model
  return (
    <div className="summary-card">
      <div className="summary-card-title">DERCO SEGUROS</div>
      <div className="summary-card-attributes">
        {attributes.map((att, i) => (
          <div key={i}>
            <CheckIcon fontSize="small" />
            {att}
          </div>
        ))}
      </div>
      <InsuranceCompanyOption
        content={{
          ...option,
          monthlyCost: (option?.monthlyCostUf || 0) * todayUF,
        }}
        selected={false}
        disabled
      />
      <div className="summary-line">{`Modelo: ${modelFullName} ${year}`}</div>
      <div className="summary-line">Dueño del auto: {owner}</div>
      <div className="summary-line special">
        Cotización válida hasta el {expirationDate}
      </div>
    </div>
  )
}

const SummaryCard = memo(SummaryCardComponent)

const TITLES = {
  [Owner.me]: "Asegura tu auto",
  [Owner.other]: "Asegura el auto",
  [Owner.business]: "Asegura el auto",
}

const InsuranceSummaryStepComponent: React.FC<StepProps> = ({
  option,
  brand,
  model,
  year,
  name,
  lastname,
  owner,
}) => {
  const validity = 15
  const expirationDate = moment().add(validity, "days").format("DD/MM/YYYY")
  const ownerName = name + " " + lastname
  return (
    <QuestionTemplate
      title={TITLES[owner]}
      desc="Protege tu auto de accidentes, robo o daños que puedan sufrir tú y tu auto "
    >
      <div className="insurance-drawer-step-content insurance-summary-step-content">
        <SummaryCard
          option={option}
          attributes={ATTRIBUTES}
          {...{ brand, model, year, expirationDate }}
          owner={ownerName}
        />
        <div className="step-disclaimer">
          * Los valores en pesos variarán de acuerdo al valor de la UF del día.
          Cotización válida hasta el {expirationDate}. Medio de pago Tarjeta de
          Crédito (puede no ser del dueño del auto).
        </div>
        <div className="step-disclaimer">Ver Términos y condiciones.</div>
      </div>
    </QuestionTemplate>
  )
}

export const InsuranceSummaryStep = memo(InsuranceSummaryStepComponent)

const ATTRIBUTES = [
  "Reposición 0km por 2 años",
  "Servicio Técnico de la marca",
  "Repuestos originales",
  "Asistencia todo el año",
]
