import React from "react"

export function RecommendVideo({ className, videoLink }) {
  return (
    <div className={className}>
      <iframe
        title="Video"
        src={videoLink}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      ></iframe>
    </div>
  )
}
