import React, { useState } from "react"
import { DCXButton } from ".."
import {
  OptionPriceSection,
  OptionColorsSection,
  OptionOutOfStockBanner,
} from "./subcomponents"

export function OptionEquipment({
  className,
  option,
  onClick,
  openDetailsDrawer,
  firstEquipOpened = false,
  alwaysExpanded = false,
  withoutPriceSection = false,
  ...props
}) {
  const [expanded, setExpanded] = useState(
    alwaysExpanded || (firstEquipOpened && option.isFirstPack)
  )
  return (
    <div
      className={`option-equipment ${option.selected ? "active " : ""} ${
        option.unavailable ? "unavailable " : ""
      } ${onClick ? "clickable" : ""} ${className || ""}`}
      id={`equipment-option-${option.info.name}`}
      role="button"
      tabIndex={option.unavailable ? -1 : 0}
      onClick={option.unavailable ? null : onClick}
      onKeyDown={e => e.key === "Enter" && onClick()}
      {...props}
    >
      <div className="top">
        <div className="top-title">{option.info.name}</div>
      </div>

      {option.info.origin && (
        <div className="origin">
          {option.info.origin.name}: {option.info.origin.value}
        </div>
      )}

      <div className="center">
        {!alwaysExpanded && !(firstEquipOpened && option.isFirstPack) && (
          <div className="button-container">
            <DCXButton
              className="more-datails-button"
              type="tertiary"
              // startIcon={expanded ? <RemoveRoundedIcon /> : <AddRoundedIcon />}
              text={expanded ? "Ocultar Detalle" : "Ver detalle completo"}
              onClick={e => {
                e.stopPropagation()
                const el = document.getElementById(
                  `equipment-option-${option.info.name}`
                )
                el.blur()
                setExpanded(!expanded)
              }}
            />
          </div>
        )}

        <div
          className={`include ${
            !option.isFirstPack && !expanded ? "show" : ""
          }`}
        >
          Incluye equipamiento {option.info.previousEquip} y añade:
        </div>

        {option.info.categories.map((cat, idx) => {
          const newFeatsLength = cat.features.filter(
            feat => expanded || feat.newFeat
          ).length
          return newFeatsLength ? (
            <div className="category" key={idx}>
              <div className="category-name">{cat.name}</div>
              {cat.features.map((feat, i) => (
                <div
                  className={`item ${
                    !(expanded || feat.newFeat) ? "hide" : ""
                  } ${feat.newFeat ? "new-feat" : ""} ${
                    expanded ? "expanded" : ""
                  }`}
                  key={i}
                >
                  {/* {feat.newFeat ? "+ " : ""} */}
                  {feat.name}
                  {feat.value && ": " + feat.value}
                </div>
              ))}
            </div>
          ) : null
        })}
      </div>

      <OptionColorsSection colors={option.colors} />

      <OptionPriceSection
        className={`${withoutPriceSection ? "hide" : ""}`}
        minFin={option.minFin}
      />

      <OptionOutOfStockBanner hide={!option.outOfStock} />
    </div>
  )
}
