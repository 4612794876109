import React, { useState, useCallback } from "react"
import { Dialog, Divider, IconButton } from "@material-ui/core"
import { DCXButton, InputForEmail } from "."
import CloseRoundedIcon from "@material-ui/icons/CloseRounded"
import { sendPageByMail } from "../../api"
import { ShareDialogImage1, ShareDialogImage2 } from "../../assets"
import QRCode from "qrcode.react"

export function ShareDialog({
  open,
  disableBackdrop,
  setState,
  emojiSrc,
  mainText,
  qrText,
  mailText,
  mailingTemplate, // "model" or "checkout"
  qrUrl,
  continueThisSiteText,
  continueThisSiteAction,
  children,
  PaperProps,
  ...props
}) {
  const [dialogSecondStep, setDialogSecondStep] = useState(false)
  const [email, setEmail] = useState("")
  const [emailHasError, setEmailError] = useState(false)

  const closeDialog = useCallback(() => {
    setState(false)
    setTimeout(() => setDialogSecondStep(false), 200)
  }, [setState, setDialogSecondStep])

  const handleMailChange = useCallback(
    ({ value, hasError }) => {
      setEmail(value)
      setEmailError(hasError)
    },
    [setEmail, setEmailError]
  )

  const sendEmail = useCallback(() => {
    setTimeout(() => setDialogSecondStep(true), 200)
    sendPageByMail(email, qrUrl, mailingTemplate)
  }, [mailingTemplate, qrUrl, email])

  return (
    <Dialog
      onClose={closeDialog}
      open={open}
      BackdropProps={
        disableBackdrop
          ? {
              style: {
                display: "none",
              },
            }
          : null
      }
      PaperProps={PaperProps}
      {...props}
    >
      <div
        className="share-dialog-content"
        style={{ minHeight: PaperProps.style.minHeight }}
      >
        <IconButton className="close-button" onClick={closeDialog}>
          <CloseRoundedIcon />
        </IconButton>
        <img
          className={`absolute-icon left ${!dialogSecondStep ? "hide" : ""}`}
          src={ShareDialogImage1}
          alt="Ícono soñado"
        />
        <img
          className={`absolute-icon right ${!dialogSecondStep ? "hide" : ""}`}
          src={ShareDialogImage1}
          alt="Ícono soñado"
        />
        <img
          className={`absolute-icon top ${!dialogSecondStep ? "hide" : ""}`}
          src={ShareDialogImage2}
          alt="Ícono soñado"
        />
        <div className="title-section">
          <span className="main-text">{mainText}</span>
          <img className="icon" src={emojiSrc} alt="Ícono soñado" />
        </div>
        <span
          className={`secondary-text smaller ${
            !dialogSecondStep ? "hide" : ""
          }`}
        >
          Revisa tu correo, este modelo te espera
        </span>
        {!dialogSecondStep && (
          <>
            <Divider />
            <div className="options-section">
              <div className="section qr-section">
                <span className="secondary-text">
                  {qrText || "Desde tu teléfono"}
                </span>
                {qrUrl && (
                  <QRCode className="qr-image" value={qrUrl} size={140} />
                )}
              </div>
              <Divider orientation="vertical" />
              <div className="section email-section">
                <span className="secondary-text">
                  {mailText || "o mándalo a tu correo"}
                </span>
                <div className="email-input-container">
                  <InputForEmail
                    name="email-input"
                    onChange={handleMailChange}
                    value={email}
                    helperText="Este email no está correcto"
                  />
                </div>
                <DCXButton
                  className="button-send"
                  type="primary"
                  text="ENVIAR"
                  onClick={sendEmail}
                  disabled={!email || emailHasError}
                />
              </div>
            </div>

            {continueThisSiteText && continueThisSiteAction && (
              <>
                <Divider />
                <div className="continue-section">
                  <span className="secondary-text">Sigue en este sitio</span>
                  <span className="secondary-text unbold">
                    {continueThisSiteText}
                  </span>
                  <div className="button-continue">
                    <DCXButton
                      type="secondary"
                      text="SIGUIENTE"
                      onClick={continueThisSiteAction}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  )
}
