import React, { useEffect, useContext, useRef } from "react"
import { Input } from "@inventures/react-lib/components"
import { InputStatus, useInput } from "@inventures/react-lib/hooks"
import { TextFieldProps } from "@material-ui/core/TextField"
import {
  RequiredValidator,
  LengthValidator,
} from "@inventures/react-lib/validators"
import { MaskFormatter } from "@inventures/react-lib/formatters"
import { KeyboardContext } from "../../context"
import { useSpecialDevice } from "../../hooks"
import { StandardOnChangeParams } from "../../types"

const debounceTime = 800

type Props = {
  elementId?: string
  name: string
  value: string
  label?: string
  onChange: ({ name, value, hasError }: StandardOnChangeParams) => void
  onEnterPress?: () => void
  onTabPress?: () => void
} & TextFieldProps

export const InputForPlate2: React.FC<Props> = ({
  elementId = "input-for-plate",
  label = "Patente",
  name,
  onChange,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new MaskFormatter([
        "A",
        "A",
        /[a-zA-Z0-9]/,
        /[a-zA-Z0-9]/,
        "9",
        "9",
      ]),
      validators: [
        new RequiredValidator("Patente requerida"),
        new LengthValidator("¡Ojo! Esta patente está incompleta 🤷‍♀️", 6),
      ],
      debounceTime,
    }
  )
  const hasError = status === InputStatus.ERROR
  // Update parent component when value or error changes
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])

  return (
    <Input
      id={elementId}
      variant="outlined"
      color="primary"
      margin="dense"
      {...props}
      inputRef={inputRef}
      value={value}
      label={label}
      onChange={e => setValue(String(e.target.value).toUpperCase())} // Update internal state
      onFocus={() =>
        showKeyboard(
          setValue,
          inputRef,
          {
            onEnterPress,
            onTabPress,
          },
          { capsAlwaysLocked: true, disableAccent: true }
        )
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      autoComplete={forStore ? "off" : "on"}
    />
  )
}
