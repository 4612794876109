import React, { useContext, useCallback, useState } from "react"
import Popover from "@material-ui/core/Popover"
import Dialog from "@material-ui/core/Dialog"
import Divider from "@material-ui/core/Divider"
import CircularProgress from "@material-ui/core/CircularProgress"
import { DCXButton, InputForRut } from "../reusables"
import { infoToSessionString } from "../../utils/session"

import { CarContext } from "../../context/AppContext"
import { loginSeller, checkSellerExistence, getSessionData } from "../../api"
import { AppContextType } from "../../types"

const RecoverSessionContainer: React.FC = () => {
  const [customerRut, setCustomerRut] = useState("")
  const [customerHasError, setCustomerHasError] = useState(true)

  const handleChangeCustomerRut = useCallback(({ value, hasError }) => {
    setCustomerRut(value)
    setCustomerHasError(hasError)
  }, [])
  const handleSubmitCustomer = useCallback(async () => {
    const customerSession = await getSessionData(customerRut)
    if (customerSession) {
      window.location.replace(customerSession)
    } else {
      window.alert("No se ha encontrado una sesion para ese RUT.")
    }
  }, [customerRut])

  return (
    <div className="sessionMenu__customer">
      <h3>Recupera tu auto guardado</h3>
      <InputForRut
        elementId="userRut"
        name="user"
        onChange={handleChangeCustomerRut}
        validateEmptyString={false}
      />
      <DCXButton
        className={`sessionMenu__button ${customerHasError ? "error" : ""}`}
        text="IR"
        onClick={handleSubmitCustomer}
        type="primary"
        disabled={customerHasError || customerRut === ""}
      />
    </div>
  )
}

type SellerContainerProps = {
  handleClose: () => void
}
const SellerContainer: React.FC<SellerContainerProps> = ({ handleClose }) => {
  const appContext = useContext<AppContextType>(CarContext)
  const { checkoutData, setCheckoutData } = appContext

  const [loading, setLoading] = useState(false)
  const [sellerCredential, setSellerCredential] = useState("")
  const [credentialHasError, setCredentialHasError] = useState(true)

  const handleChangeSellerCredential = useCallback(({ value, hasError }) => {
    setSellerCredential(value)
    setCredentialHasError(hasError)
  }, [])

  const handleLogin = useCallback(async () => {
    setLoading(true)
    const sellerData = await loginSeller(sellerCredential)
    if (sellerData) {
      setCheckoutData(prev => ({ ...prev, sellerData }))
      infoToSessionString({ sellerId: sellerData.id || null })
    } else {
      window.alert("No se ha encontrado el vendedor")
    }
    setLoading(false)
  }, [sellerCredential])

  const handleSignOut = useCallback(() => {
    handleClose()
    setCheckoutData(prev => ({ ...prev, sellerData: null }))
    infoToSessionString({ sellerId: null })
    setSellerCredential("")
  }, [])

  return (
    <>
      <div
        className={`sessionMenu__seller-login ${
          checkoutData.sellerData ? "hide" : ""
        }`}
      >
        <h3>Atendido por</h3>
        <InputForRut
          elementId="sellerId"
          name="seller"
          onChange={handleChangeSellerCredential}
          value={sellerCredential}
          validateEmptyString={false}
          label="RUT Asesor"
          asyncValidators={[
            {
              validate: async (newValue: string) => {
                const response = await checkSellerExistence(newValue)
                return response
              },
              errorMsg: "Este rut no está registrado 🤷‍♀️",
            },
          ]}
        />
        {!loading ? (
          <DCXButton
            className={`sessionMenu_login-button sessionMenu__button ${
              credentialHasError ? "error" : ""
            }`}
            text="INICIAR SESIÓN"
            onClick={handleLogin}
            type="primary"
            disabled={credentialHasError || sellerCredential === ""}
          />
        ) : (
          <CircularProgress />
        )}
      </div>
      <div
        className={`sessionMenu__seller-data ${
          !checkoutData.sellerData ? "hide" : ""
        }`}
      >
        <p>Atendido por</p>
        <h3>{checkoutData.sellerData?.name || ""}</h3>
        <DCXButton
          className="sessionMenu__button"
          text="CERRAR SESIÓN"
          onClick={handleSignOut}
          type="primary"
        />
      </div>
    </>
  )
}

type ContentProps = {
  onlySession?: boolean
  handleClose: () => void
}
const SessionMenuContent: React.FC<ContentProps> = ({
  handleClose,
  onlySession,
}) => {
  return (
    <div
      className={`sessionMenu__container ${onlySession ? "onlySession" : ""}`}
    >
      <RecoverSessionContainer />
      {!onlySession && (
        <>
          <Divider />
          <SellerContainer handleClose={handleClose} />
        </>
      )}
    </div>
  )
}

type SessionMenuProps = {
  open: boolean
  anchorEl: HTMLElement | null
  handleClose: () => void
}

export const SessionMenu: React.FC<SessionMenuProps> = ({
  open,
  anchorEl,
  handleClose,
}) => {
  return (
    <div>
      <Popover
        className="sessionMenu desktop"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <SessionMenuContent handleClose={handleClose} />
      </Popover>
      <Dialog className="sessionMenu mobile" open={open} onClose={handleClose}>
        <SessionMenuContent handleClose={handleClose} onlySession />
      </Dialog>
    </div>
  )
}

type SellerMenuProps = {
  open: boolean
  handleClose: () => void
}
export const MobileSellerMenu: React.FC<SellerMenuProps> = ({
  open,
  handleClose,
}) => {
  return (
    <div>
      <Dialog className="sessionMenu mobile" open={open} onClose={handleClose}>
        <div className={`sessionMenu__container onlySession`}>
          <SellerContainer handleClose={handleClose} />
        </div>
      </Dialog>
    </div>
  )
}
