import React, { useContext } from "react"
import { Button, Avatar } from "@material-ui/core"
import { FilterIcon } from "../../assets"
import { UIContext, SearchContext } from "../../context"

export function HeaderFilterButton() {
  const { openFiltersDrawer } = useContext(UIContext)
  const { areFiltersActive, filtersNumber } = useContext(SearchContext)

  return (
    <Button
      className="header-button filter-header-button"
      startIcon={
        areFiltersActive ? (
          <Avatar className="filters-number">{filtersNumber}</Avatar>
        ) : (
          <img className="filter-icon" src={FilterIcon} alt="Filtros" />
        )
      }
      onClick={openFiltersDrawer}
    >
      {areFiltersActive ? "Filtros" : "Filtrar"}
    </Button>
  )
}
