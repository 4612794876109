import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Model } from "../types/model"

export const useAllModels = (): [Model[]] => {
  const data = useStaticQuery<{ allModels: { nodes: Model[] } }>(graphql`
    query ALL_MODELS_HOOK {
      allModels {
        nodes {
          ...ModelBasic
          versions {
            ...VersionBasic
          }
          ...ConfigOptions
        }
      }
    }
  `)
  const [allModels] = useState(data.allModels.nodes)
  return [allModels]
}
