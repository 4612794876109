import React from "react"
import { getStandardAnimationProps } from "../../constants"

export function InfoCard({ idx, image, title, mainText, secondaryText }) {
  return (
    <div className="info-card" {...getStandardAnimationProps(idx)}>
      <div className="left">
        <img src={image} alt="" />
      </div>
      <div className="right">
        <div className="title">{title}</div>
        <div className="main-text">{mainText}</div>
        <div className="secondary-text">{secondaryText}</div>
      </div>
    </div>
  )
}
