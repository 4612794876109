import React from "react"
import { Link } from "@reach/router"
import { Image, DCXButton } from ".."
import {
  urlWithSession,
  getModelShortNameOrDefault,
  capitalize,
} from "../../utils"
import { getStandardAnimationProps } from "../../constants"

export function GalleryInterior({ model, outOfStock }) {
  const gallery = model.gallery.find(g => g.category === "interior").images
  if (![1, 2, 3, 4, 6, 7, 9].includes(gallery.length))
    throw new Error(
      `Cantidad de imágenes no permitida en Galería Interior. Modelo: ${model.name}`
    )
  const highestEquipment =
    model?.configOptions.packs[model.configOptions.packs.length - 1].name
  return (
    <div className="model-gallery-interior">
      <div className="message">
        <div className="title">
          <div>Interior</div>
          <div className="gray">{model.name}</div>
        </div>
        <div {...getStandardAnimationProps()}>
          <Link to={urlWithSession(`/config/${model.slug}`)}>
            <DCXButton
              className="title-action-button"
              type="tertiary"
              icon="comparePink"
              text={`CONFIGURA TU ${getModelShortNameOrDefault(model)}`}
              arrow="right"
            />
          </Link>
        </div>
      </div>

      <div className="gallery">
        {gallery.map((image, idx) => (
          <div
            className={`photo ${
              gallery.length !== 2 ? `photo${idx + 1}` : "photo1"
            }`}
            key={idx}
          >
            <Image filename={image.url.split("/").pop()}></Image>
          </div>
        ))}
        <div className="photo-disclaimer">
          Estas fotos corresponden al {model.name} con el equipamiento{" "}
          {highestEquipment ? capitalize(highestEquipment) : "más alto"}.
        </div>
      </div>
    </div>
  )
}
