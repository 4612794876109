import React from "react"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import ToggleButton from "@material-ui/lab/ToggleButton"
import { Payment } from "../../types"

export function PaymentToggle({ className, value, onChange }) {
  return (
    <ToggleButtonGroup
      className={`payment-toggle ${className || ""}`}
      color="primary"
      value={value}
      onChange={onChange}
      aria-label="Financiamiento"
      orientation="horizontal"
      exclusive
    >
      <ToggleButton
        className="first"
        id="select-cash"
        value={Payment.cash}
        aria-label="Pago al contado"
      >
        AL CONTADO
      </ToggleButton>
      <ToggleButton
        className="second"
        id="select-smart-credit"
        value={Payment.credit}
        aria-label="Pago en cuotas"
      >
        EN CUOTAS
      </ToggleButton>
    </ToggleButtonGroup>
  )
}
