export interface InsuranceRegion {
  id: string
  name: string
}
export interface InsuranceModel {
  brandId: string
  dcxModelIds?: number[]
  dcxVersionIds?: number[]
  id: string
  name: string
}
export interface InsuranceCommune {
  id: string
  name: string
  regionId: string
}
export interface InsuranceBrand {
  id: string
  name: string
}

export interface InsuranceData {
  regions: InsuranceRegion[]
  models: InsuranceModel[]
  communes: InsuranceCommune[]
  brands: InsuranceBrand[]
}

export interface InsuranceSimulationOption {
  companyId: string // 5 compañías
  deductible: number // 0, 3, 5, 10, 20
  dues: number // 12 or 24
  monthlyCostUf: number
}

export interface InsuranceSimulationData {
  simulationId: number
  options: InsuranceSimulationOption[]
}

export enum DataType {
  selectedPlan = "selectedPlan",
  contactData = "contactData",
}
