import React from "react"
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core"

type Row = {
  name: string
  value: string | number
}

export type DCXTableProps = {
  rows: Row[]
  windowWidth: number
}

export const DCXTable: React.FC<DCXTableProps> = ({ rows, windowWidth }) => {
  return (
    <Table size={windowWidth >= 810 ? "medium" : "small"} aria-label="table">
      <TableBody>
        {rows.map((row, idx) => (
          <TableRow key={`table-row-${idx}`}>
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">{row.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
