import React from "react"
import { HeaderModel, AccessoryCard } from ".."

export function Accessories({ accessories }) {
  return (
    <div className="model-accessories">
      <HeaderModel upperTitle="ACCESSORIOS" lowerTitle="AUTO" />
      <div className="accessories-section">
        {accessories.map((acc, idx) => (
          <AccessoryCard key={idx} data={acc} />
        ))}
      </div>
    </div>
  )
}
