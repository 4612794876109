import { getMinimunPrices } from "."
import { Payment } from "../types"

const getVersionName = (model, versionId) => {
  if (!model || !versionId) return ""
  const version = model.versions.find(v => v.id === versionId)
  if (!version) return ""
  return version.name
}

const getSortingPrice = (model, stocks, payment) =>
  getMinimunPrices(
    stocks,
    model?.versions.map(v => v.id),
    {
      allowOutOfStock: true,
    }
  )?.[payment === Payment.cash ? "priceCash" : "priceCredit"] || 0

const sortModelsHome = (models, payment, modelsPricesStocks, multiplier) =>
  models.sort((a, b) => {
    // Check if this models are available (with any stock)
    const av1 = isModelAvailable(a, modelsPricesStocks)
    const av2 = isModelAvailable(b, modelsPricesStocks)
    // Available models go first
    if (av1 !== av2) return av1 ? -1 : 1
    // If modelsPricesStocks is defined, compare priceCash or priceCredit to sort.
    // Else, sort based on position strapi attribute
    return (
      multiplier *
      (modelsPricesStocks !== null
        ? getSortingPrice(a, modelsPricesStocks, payment) -
          getSortingPrice(b, modelsPricesStocks, payment)
        : a.position - b.position)
    )
  })

const isModelAvailable = (model, modelsPricesStocks) => {
  if (!modelsPricesStocks) return null
  const versionIds = model.versions.map(v => v.id)
  return versionIds.some(
    id => modelsPricesStocks[id] && !modelsPricesStocks[id].outOfStock
  )
}

export { getVersionName, isModelAvailable, sortModelsHome }
