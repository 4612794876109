import React, { useState, useEffect, useRef } from "react"
import BaseCarousel, { ResponsiveType } from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

type CarouselProps = {
  children: React.ReactNode[]
  centerMode?: boolean
  nextPartialVisible?: boolean
  breakpoints: ResponsiveType
  showArrows?: boolean
  autoPlay?: number
  noCarouselAfter?: number
}
const Carousel2: React.FC<CarouselProps> = ({
  children,
  centerMode,
  nextPartialVisible,
  breakpoints,
  showArrows,
  autoPlay,
  noCarouselAfter,
}) => {
  const [initialized, setInitialized] = useState<boolean>(false)
  const carouselRef = useRef<BaseCarousel | null>(null)
  const [initialOffset, setInitialOffset] = useState<number>(0)
  const [offset, setOffset] = useState<number>(0)
  const [slide, setSlide] = useState<number>(0)
  const [shouldHide, setShouldHide] = useState(false)

  useEffect(() => {
    if (carouselRef.current === null || !centerMode) return
    setInitialOffset(carouselRef.current.state.itemWidth / 2)
    setOffset(carouselRef.current.state.itemWidth / 2)
  }, [initialized, setInitialOffset, setOffset, centerMode])

  useEffect(() => {
    if (!noCarouselAfter) return
    setShouldHide(window.innerWidth >= noCarouselAfter)

    const onResize = () => {
      setShouldHide(window.innerWidth >= noCarouselAfter)
    }
    window.addEventListener("resize", onResize)
    return () => window.removeEventListener("resize", onResize)
  }, [noCarouselAfter])

  return (
    <>
      <div className={`carousel ${shouldHide ? "hide" : ""}`}>
        <div
          className={`carousel__container ${!showArrows ? "no-arrows" : ""}`}
        >
          <BaseCarousel
            ref={ref => {
              carouselRef.current = ref
              setInitialized(true)
            }}
            responsive={breakpoints}
            centerMode={centerMode}
            partialVisible={!centerMode && nextPartialVisible}
            containerClass="carousel__items-container"
            additionalTransfrom={offset}
            showDots
            renderDotsOutside
            dotListClass="carousel__dots"
            beforeChange={nextSlide => {
              if (!centerMode) return
              if (nextSlide !== 0 && offset === initialOffset) {
                setOffset(0)
              } else if (nextSlide === 0 && offset !== initialOffset) {
                setOffset(initialOffset)
              }
            }}
            afterChange={(_, state) => {
              setSlide(state.currentSlide)
            }}
            arrows={false}
            autoPlay={(autoPlay || 0) > 0}
            autoPlaySpeed={(autoPlay || 0) * 1000}
            infinite={(autoPlay || 0) > 0}
            itemClass="carousel-item"
          >
            {children}
          </BaseCarousel>
        </div>
        <button
          className={`carousel__arrow left ${
            slide === 0 || !showArrows ? "hide" : ""
          }`}
          onClick={() => {
            carouselRef.current?.previous(1)
          }}
        ></button>
        <button
          className={`carousel__arrow right ${
            slide >=
              children.length -
                (carouselRef.current?.state.slidesToShow || 0) || !showArrows
              ? "hide"
              : ""
          }`}
          onClick={() => {
            carouselRef.current?.next(1)
          }}
        ></button>
      </div>
      <div className={`full-items ${!shouldHide ? "hide" : ""}`}>
        {children}
      </div>
    </>
  )
}

Carousel2.whyDidYouRender = true

export { Carousel2 }
