import React from "react"
import { DCXButton } from ".."
import { Frame } from "../landing/Frame"
import { appraiseFinalStepImage } from "../../assets"
import { StepProps } from "."

export const AppraiseThanks2: React.FC<StepProps> = ({ form }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const finishStep = () => {
    form.actions.closeAppraiseDrawer(false)
    form.actions.openDemoAlert()
  }
  return (
    <>
      <div className="component">
        <Frame
          title="¡Muchas gracias!"
          desc="Te contactaremos dentro de las próximas 24 horas para completar tu tasación"
          image={appraiseFinalStepImage}
        />
      </div>
      <div className="buttons">
        {/* <DCXButton
          className="button-back"
          id="back-step-8"
          type="secondary"
          text="ATRÁS"
          onClick={form.actions.prevStep}
        /> */}
        <DCXButton
          className="button-next no-margin close-appraise-drawer"
          id="close-appraise-drawer"
          type="primary"
          text="CERRAR"
          onClick={finishStep}
        />
      </div>
    </>
  )
}
