import { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Brand, InsuranceBrand, CarType, BrandOption } from "../../../types"
import { BRANDS_LOGOS } from "../../../constants"

type QueryResult = {
  allInsurance: {
    nodes: [
      {
        brands: InsuranceBrand[]
      }
    ]
  }
  allBrands: {
    nodes: Brand[]
  }
}

type Props = {
  carType: CarType
}

export const useBrandOptions = ({ carType }: Props): [BrandOption[]] => {
  // Fetch static data
  const data = useStaticQuery<QueryResult>(graphql`
    query BRANDS {
      allBrands {
        nodes {
          ID
          name
        }
      }
      allInsurance {
        nodes {
          brands {
            id
            name
          }
        }
      }
    }
  `)

  const [ourBrands] = useState<BrandOption[]>(() =>
    data.allBrands.nodes
      .map(brand => {
        const insuranceBrand = data.allInsurance.nodes[0].brands.find(
          ib => ib.name.toUpperCase() === brand.name.toUpperCase()
        )
        return {
          dcxId: brand.ID,
          dcxName: brand.name,
          logo: BRANDS_LOGOS[brand.ID],
          insuranceId: insuranceBrand?.id || "",
          insuranceName: insuranceBrand?.name || "",
        }
      })
      .sort((a, b) => a.dcxId - b.dcxId)
  )

  // Save data sorted
  const [allInsuranceBrands] = useState<BrandOption[]>(() =>
    data.allInsurance.nodes[0].brands
      .map(brand => {
        const ourBrand = ourBrands.find(ob => ob.insuranceName === brand.name)
        return {
          dcxId: ourBrand?.dcxId || 0,
          dcxName: ourBrand?.dcxName || "",
          insuranceId: brand.id,
          insuranceName: brand.name,
        }
      })
      .sort((a, b) =>
        new Intl.Collator("es").compare(a.insuranceName, b.insuranceName)
      )
  )

  return carType === CarType.new ? [ourBrands] : [allInsuranceBrands]
}
