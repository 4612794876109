import React, { useState } from "react"
import Helmet from "react-helmet"
import { seoImage } from "../assets"
import { useSpecialDevice } from "../hooks"

const activeEnv = (
  process.env.GATSBY_ACTIVE_ENV ||
  process.env.NODE_ENV ||
  "development"
).trim()

export function SEO({ lang, meta, description, title, url }) {
  const [forStore] = useSpecialDevice()
  const [disableIndexingMeta] = useState(() =>
    forStore || activeEnv !== "production"
      ? [{ name: "robots", content: "noindex" }]
      : []
  )

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={
        title ? title : "DercoCenter X | La forma más fácil de comprar tu auto"
      }
      description={
        description
          ? description
          : "Explora, crea, comparte y compra tu próximo auto con nosotros. Los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio."
      }
      titleTemplate={
        title ? title : "DercoCenter X | La forma más fácil de comprar tu auto"
      }
      meta={[
        {
          name: `description`,
          content: description
            ? description
            : "Explora, crea, comparte y compra tu próximo auto con nosotros. Los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.",
        },
        {
          property: `og:title`,
          content: title
            ? title
            : "DercoCenter X | La forma más fácil de comprar tu auto",
        },
        {
          property: `og:description`,
          content: description
            ? description
            : "Explora, crea, comparte y compra tu próximo auto con nosotros. Los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:url",
          content: url ? url : "https://dercocenterx.cl",
        },
        {
          property: "og:image",
          content: `https://dercocenterx.cl${seoImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: `https://dercocenterx.cl${seoImage}`,
        },
        {
          name: `twitter:site`,
          content: `https://dercocenterx.cl`,
        },
        {
          name: `twitter:title`,
          content: "DercoCenter X",
        },
        {
          name: `twitter:description`,
          content: description
            ? description
            : "Explora, crea, comparte y compra tu próximo auto con nosotros. Los mejores precios y opciones de financiamiento. Puedes entregar tu auto en parte de pago, reservar en línea y recibir en tu domicilio.",
        },
      ]
        .concat(meta)
        .concat(disableIndexingMeta)}
    >
      {process.env.GATSBY_ACTIVE_ENV === "production" && (
        <script>{`
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2162617,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')
      `}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `es`,
  meta: [],
  description: null,
  title: null,
  url: null,
}

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string,
// }
