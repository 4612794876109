import React from "react"
import {
  OptionPriceSection,
  OptionColorsSection,
  OptionOutOfStockBanner,
} from "./subcomponents"

export function OptionTriplet({
  option,
  onClick,
  openDetailsDrawer,
  ...props
}) {
  return (
    <div
      className={`option-triplet ${option.selected ? "active " : ""} ${
        option.unavailable ? "unavailable " : ""
      } ${onClick ? "clickable" : ""}`}
      role="button"
      tabIndex={option.unavailable ? -1 : 0}
      onClick={option.unavailable ? null : onClick}
      onKeyDown={e => e.key === "Enter" && onClick()}
      {...props}
    >
      <div className="top">
        <div className="main-text">{option.info.main}</div>
        <div className="secondary-text">{option.info.secondary}</div>
      </div>

      <OptionColorsSection colors={option.colors} />

      <OptionPriceSection minFin={option.minFin} />

      <OptionOutOfStockBanner hide={!option.outOfStock} />
    </div>
  )
}
