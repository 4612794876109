import React from "react"
import { ShortcutCard, WrapperProps } from "./ShortcutCard"
import { ShortcutCardCompareItem } from "../../assets"

export const CompareShortcutCard: React.FC<WrapperProps> = ({
  className,
  handleClick,
}) => {
  return (
    <div className={`shortcut-cards compare-card ${className}`}>
      <ShortcutCard
        icon={ShortcutCardCompareItem}
        title="¿Aún no te decides?"
        text="Si estás entre 2 o 3 modelos, usa nuestro comparador"
        buttonText="COMPARAR AUTOS"
        handleClick={handleClick}
      />
    </div>
  )
}
