import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { useSpecialDevice } from "../hooks"
import { InsuranceContext } from "./InsuranceContext"

type Props = {
  children: React.ReactNode
}
export const UIContext = createContext({})

export const UIProvider: React.FC<Props> = ({ children }) => {
  const { cleanData } = useContext(InsuranceContext)
  const [isStorePage] = useSpecialDevice()

  const [isFinancingDrawerOpen, setFinancingDrawerState] = useState<boolean>(
    false
  )
  const openFinancingDrawer = useCallback(() => setFinancingDrawerState(true), [
    setFinancingDrawerState,
  ])
  const closeFinancingDrawer = useCallback(
    () => setFinancingDrawerState(false),
    [setFinancingDrawerState]
  )

  const [isAppraiseDrawerOpen, setAppraiseDrawerState] = useState<boolean>(
    false
  )
  const openAppraiseDrawer = useCallback(() => setAppraiseDrawerState(true), [
    setAppraiseDrawerState,
  ])
  const closeAppraiseDrawer = useCallback(() => setAppraiseDrawerState(false), [
    setAppraiseDrawerState,
  ])

  const [isDetailsDrawerOpen, setDetailsDrawerState] = useState<boolean>(false)
  const openDetailsDrawer = useCallback(() => setDetailsDrawerState(true), [
    setDetailsDrawerState,
  ])
  const closeDetailsDrawer = useCallback(() => setDetailsDrawerState(false), [
    setDetailsDrawerState,
  ])

  const [isPaymentDrawerOpen, setPaymentDrawerState] = useState<boolean>(false)
  const openPaymentDrawer = useCallback(() => setPaymentDrawerState(true), [
    setPaymentDrawerState,
  ])
  const closePaymentDrawer = useCallback(() => setPaymentDrawerState(false), [
    setPaymentDrawerState,
  ])

  const [isMobileMenuOpen, setMobileMenuState] = useState<boolean>(false)
  const openMobileMenu = useCallback(() => setMobileMenuState(true), [
    setMobileMenuState,
  ])
  const closeMobileMenu = useCallback(() => setMobileMenuState(false), [
    setMobileMenuState,
  ])

  const [isFiltersDrawerOpen, setFilterDrawerState] = useState<boolean>(false)
  const openFiltersDrawer = useCallback(() => setFilterDrawerState(true), [
    setFilterDrawerState,
  ])
  const closeFiltersDrawer = useCallback(() => setFilterDrawerState(false), [
    setFilterDrawerState,
  ])

  const [isInsuranceDrawerOpen, setInsuranceDrawerState] = useState<boolean>(
    false
  )
  const openInsuranceDrawer = useCallback(() => setInsuranceDrawerState(true), [
    setInsuranceDrawerState,
  ])
  const closeInsuranceDrawer = useCallback(() => {
    if (isStorePage) cleanData()
    setInsuranceDrawerState(false)
  }, [isStorePage, cleanData, setInsuranceDrawerState])

  const [creditVideoOpen, setCreditVideoOpen] = useState<boolean>(false)
  const openCreditVideo = useCallback(() => setCreditVideoOpen(true), [
    setCreditVideoOpen,
  ])
  const closeCreditVideo = useCallback(() => setCreditVideoOpen(false), [
    setCreditVideoOpen,
  ])

  const [sessionMenuOpen, setSessionMenuOpen] = useState(false)
  const openSessionMenu = useCallback(() => {
    setSessionMenuOpen(true)
  }, [setSessionMenuOpen])
  const closeSessionMenu = useCallback(() => {
    setSessionMenuOpen(false)
  }, [setSessionMenuOpen])

  const [sellerMenuOpen, setSellerMenuOpen] = useState(false)
  const openSellerMenu = useCallback(() => {
    setSellerMenuOpen(true)
  }, [setSellerMenuOpen])
  const closeSellerMenu = useCallback(() => {
    setSellerMenuOpen(false)
  }, [setSellerMenuOpen])

  const [demoAlertOpen, setDemoAlertOpen] = useState(false)
  const openDemoAlert = useCallback(() => setDemoAlertOpen(true), [
    setDemoAlertOpen,
  ])
  const closeDemoAlert = useCallback(() => setDemoAlertOpen(false), [
    setDemoAlertOpen,
  ])

  const ctx = useMemo(
    () => ({
      isFinancingDrawerOpen,
      openFinancingDrawer,
      closeFinancingDrawer,
      isAppraiseDrawerOpen,
      openAppraiseDrawer,
      closeAppraiseDrawer,
      isDetailsDrawerOpen,
      openDetailsDrawer,
      closeDetailsDrawer,
      isPaymentDrawerOpen,
      openPaymentDrawer,
      closePaymentDrawer,
      isMobileMenuOpen,
      openMobileMenu,
      closeMobileMenu,
      isFiltersDrawerOpen,
      openFiltersDrawer,
      closeFiltersDrawer,
      isInsuranceDrawerOpen,
      openInsuranceDrawer,
      closeInsuranceDrawer,
      creditVideoOpen,
      openCreditVideo,
      closeCreditVideo,
      areDrawersOpened:
        isFinancingDrawerOpen ||
        isAppraiseDrawerOpen ||
        isDetailsDrawerOpen ||
        isPaymentDrawerOpen ||
        isFiltersDrawerOpen ||
        isInsuranceDrawerOpen,
      sessionMenuOpen,
      openSessionMenu,
      closeSessionMenu,
      sellerMenuOpen,
      openSellerMenu,
      closeSellerMenu,
      demoAlertOpen,
      openDemoAlert,
      closeDemoAlert,
    }),
    [
      isFinancingDrawerOpen,
      openFinancingDrawer,
      closeFinancingDrawer,
      isAppraiseDrawerOpen,
      openAppraiseDrawer,
      closeAppraiseDrawer,
      isDetailsDrawerOpen,
      openDetailsDrawer,
      closeDetailsDrawer,
      isPaymentDrawerOpen,
      openPaymentDrawer,
      closePaymentDrawer,
      isMobileMenuOpen,
      openMobileMenu,
      closeMobileMenu,
      isFiltersDrawerOpen,
      openFiltersDrawer,
      closeFiltersDrawer,
      isInsuranceDrawerOpen,
      openInsuranceDrawer,
      closeInsuranceDrawer,
      creditVideoOpen,
      openCreditVideo,
      closeCreditVideo,
      sessionMenuOpen,
      openSessionMenu,
      closeSessionMenu,
      sellerMenuOpen,
      openSellerMenu,
      closeSellerMenu,
      demoAlertOpen,
      openDemoAlert,
      closeDemoAlert,
    ]
  )

  return <UIContext.Provider value={ctx}>{children}</UIContext.Provider>
}
