import { FINANCING_MINS, FINANCING_MAXS } from "../constants"
import { Payment } from "../types"

export * from "./calculate"
export * from "./price"
export * from "./taxes"
export * from "./url"
export * from "./functions"
export * from "./sharing"
export * from "./colors"
export * from "./selections"
export * from "./parsing"
export * from "./session"
export * from "./filters"
export * from "./checkout"
export * from "./number"

export const getDuesOrPercentageOptions = (payment, type) => {
  if (!payment || payment === Payment.cash) return []
  const delta = type === "dues" ? 12 : 1
  const text = type === "dues" ? " meses" : "%"
  const minValue = FINANCING_MINS[type][payment]
  const n = 1 + (FINANCING_MAXS[type][payment] - minValue) / delta
  return Array(n)
    .fill(null)
    .map((_, idx) => ({
      value: minValue + idx * delta,
      label: minValue + idx * delta + text,
    }))
}
