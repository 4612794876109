import React, { useContext } from "react"
import Tooltip from "@material-ui/core/Tooltip"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import { HelpIcon } from "../assets"
import { CarContext } from "../context"
import { parseNumber, getVersionTaxes, CalculatePriceFull } from "../utils"

export function PlatePackTable({ dense, prices }) {
  const appContext = useContext(CarContext)
  const {
    windowWidth,
    selectedVersion,
    modelsPricesStocks,
    financeData,
    offers,
    selectedOffer,
  } = appContext

  // Check context to determine if offer was selected
  const offer = offers?.find(of => of.internalId === selectedOffer)

  // Early return if critical input is not defined
  if (
    !prices ||
    !offers ||
    !modelsPricesStocks ||
    !financeData ||
    !(selectedVersion || offer)
  )
    return null

  // Calculate price to obtain withCredit and carPrice variables
  const { withCredit, carPrice } = CalculatePriceFull({
    appContext,
    offer,
    prices,
  })

  // Calculate taxes on-the-fly with carPrice variable
  const greenTaxRate = offer?.greenTaxRate || selectedVersion?.greenTaxRate
  const taxes = getVersionTaxes({
    greenTaxRate,
    carPrice,
    financeData,
  })

  // Define table rows and total taxes based on taxes and withCredit variable
  const rows = !taxes
    ? []
    : [
        {
          name: "Inscripción",
          value: withCredit ? 0 : taxes.inscription,
          disabled: withCredit,
        },
        {
          name: "Impuesto verde",
          value: taxes.greenTax,
        },
        {
          name: "Permiso circulación",
          value: taxes.circulationPermit,
        },
        {
          name: "Seguro obligatorio (SOAP)",
          value: taxes.soap,
        },
      ]
  const total = rows.reduce(
    (acum, row) => acum + (row.disabled ? 0 : row.value),
    0
  )

  return (
    <div className="plate-pack-table">
      <Table
        size={!dense && windowWidth >= 810 ? "medium" : "small"}
        aria-label="a table"
      >
        <TableHead>
          <TableRow>
            <TableCell>PACK PATENTE</TableCell>
            <TableCell align="right">${parseNumber(total)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                {row.disabled ? (
                  <span style={{ fontStyle: "italic" }}>
                    no aplica
                    <Tooltip
                      title="Sólo aplica para Pago al Contado"
                      arrow={true}
                      placement="right"
                    >
                      <HelpIcon />
                    </Tooltip>
                  </span>
                ) : (
                  `$${parseNumber(row.value)}`
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <p>Los valores varían según la versión del auto.</p>
    </div>
  )
}
