import React, { memo } from "react"
import { QuestionTemplate } from "../templates"
import { InputForPhone } from "../../reusables/inputs.js"
import { Insurance, OnChange, Owner } from "../../../types"

type Props = {
  phone: string
  owner: Owner
  onChange: OnChange
}

const TITLES = {
  [Owner.me]: "¿Cuál es tu teléfono?",
  [Owner.other]: "¿Cuál es su teléfono?",
  [Owner.business]: "¿Cuál es tu teléfono?",
}

const PhoneStepComponent: React.FC<Props> = ({ phone, owner, onChange }) => {
  // Validation not needed. Handled by custom input.
  return (
    <QuestionTemplate title={TITLES[owner]}>
      <div className="insurance-drawer-step-content">
        <InputForPhone
          name={Insurance.phone}
          value={phone}
          onChange={onChange}
          fullWidth
          margin="normal"
        />
      </div>
    </QuestionTemplate>
  )
}

export const PhoneStep = memo(PhoneStepComponent)
