import Finance from "financejs"
import { Payment } from "../types"

const FinanceFunctions = new Finance()

function calculateCrf(terms, maxRate) {
  return ((1 + maxRate) ** terms * maxRate) / ((1 + maxRate) ** terms - 1)
}

function calculateMaxRate(carPrice, terms, amicarParams, smart = false) {
  const amount = carPrice
  try {
    return smart
      ? amicarParams.inteligentMaxRate
          .filter(
            value => value.min_amount <= amount && value.max_amount >= amount
          )
          .find(value => value.min_term <= terms && value.max_term >= terms)
          .max_rate
      : amicarParams.normalMaxRate
          .filter(
            value => value.min_amount <= amount && value.max_amount >= amount
          )
          .find(value => value.min_term <= terms && value.max_term >= terms)
          .max_rate
  } catch {
    return 0
  }
}

function calculateAdministrationCost(
  baseToFinance,
  terms,
  smart,
  administrationCostRates
) {
  terms = smart ? terms + 1 : terms

  try {
    return administrationCostRates.find(
      value =>
        value.min_amount <= baseToFinance &&
        value.max_amount >= baseToFinance &&
        value.min_term <= terms &&
        value.max_term >= terms
    ).value
  } catch {
    return 0
  }
}

function calculateInsuranceRate(terms, insuranceRates) {
  try {
    return insuranceRates.find(value => terms === value.term).value
  } catch {
    return 0
  }
}

function calculateVfmg(amount, terms) {
  return terms <= 24 ? amount * 0.5 : amount * 0.4
}

const DEFAULTS = {
  variant: 0,
  onFavor: 0,
  firstPaymentPrice: 0,
  monthlyPrice: 0,
  VFMG: 0,
  CTC: 0,
  CAE: 0,
  carPrice: 0,
  totalAdditionals: 0,
  totalDisocunts: 0,
  selectedCarPrice: 0,
  finalCarPrice: 0,
  baseAmount: 0,
  baseToFinance: 0,
}

export function CalculatePrice(
  paymentType,
  months,
  percentage,
  prices,
  carInPaymentData,
  financeData,
  additionals = []
) {
  if (!prices) return { ...DEFAULTS, variant: -1 }
  if (!paymentType || !months || !percentage || !financeData?.amicarParams)
    return DEFAULTS

  const priceList = prices.priceList
  const hasPaymentCar = !!carInPaymentData
  const carInPaymentPrice = carInPaymentData?.finalPrice || 0
  const totalAdditionals = additionals.reduce((a, b) => a + b, 0)

  // Calculate car in payment percentage
  const percentageCarInPayment = hasPaymentCar // PREGUNTA: este porcentaje es respecto al precio de lista o precio final?
    ? (carInPaymentPrice / priceList) * 100
    : 0
  // Define percentage of prepaid
  const prePaidPercentage = percentage + percentageCarInPayment

  // Determine if priceCash or priceCredit will be used
  let selectedCarPrice = 0
  if (paymentType === Payment.cash || prePaidPercentage > 40 || months < 24) {
    selectedCarPrice = prices.priceCash
  } else {
    selectedCarPrice = prices.priceCredit
  }

  // Calculate car final price after discounts // PREGUNTA, los descuentos se aplican sobre el precio de lista o el selectedPrice????
  const totalDiscounts =
    Math.round(
      Object.values(prices.discounts || {}).reduce(
        (acum, disc) => acum + (priceList * disc) / 100,
        0
      ) / 10000
    ) * 10000
  const finalCarPrice = selectedCarPrice - totalDiscounts
  /* Initialize variables */
  // base amount is defined as final carPrice plus additionals less carInPayment
  const baseAmount = finalCarPrice + totalAdditionals - carInPaymentPrice
  let onFavor = 0
  let firstPaymentPrice =
    paymentType !== Payment.cash ? (percentage * selectedCarPrice) / 100 : 0
  let monthlyPrice = 0
  let VFMG = 0
  let CTC = 0
  let CAE = 0
  let baseToFinance = 0

  // Check if base amount is negative
  if (baseAmount < 0) {
    onFavor = Math.abs(baseAmount)
    firstPaymentPrice = 0
  } else {
    // New calculate for CASH
    if (paymentType === Payment.cash) {
      // monthly price starts at base amount
      firstPaymentPrice = baseAmount
    } else {
      // base to finance is defined as base amount less firstPayment
      baseToFinance = baseAmount - firstPaymentPrice
      // New calculate for NOT-CASH
      const minimunFirstPaymentPrice =
        (20 * finalCarPrice) / 100 - carInPaymentPrice
      const maximumFirstPaymentPrice = finalCarPrice - carInPaymentPrice
      if (hasPaymentCar) {
        // limit the first payment price between extremes
        firstPaymentPrice = Math.max(
          minimunFirstPaymentPrice,
          Math.min(firstPaymentPrice, maximumFirstPaymentPrice)
        )
      }
      // Find the min finance amount
      const minimunFinanceAmount = Math.min(
        ...financeData.amicarParams.normalMaxRate.map(rate => rate.min_amount)
      )

      // Define terms, VMFG and monthly
      const isSmart = paymentType === Payment.flexCredit
      const terms = months
      VFMG = calculateVfmg(finalCarPrice, terms)
      let monthly = 0

      // Financial calculations only if financing is needed
      if (baseToFinance > 1000000) {
        // Cancel credit if baseFinance is less than minimunFinance or percentage to be financed is less than 40% when smart
        if (
          baseToFinance < minimunFinanceAmount ||
          (isSmart && prePaidPercentage > 60)
        ) {
          firstPaymentPrice = maximumFirstPaymentPrice
          monthly = 0
        } else {
          const taxRate = 0.008
          // calculate insurance rate & administration costs
          const insuranceRate = calculateInsuranceRate(
            terms,
            financeData.amicarParams.insuranceRates
          )
          const administrationCost = calculateAdministrationCost(
            baseToFinance,
            terms,
            isSmart,
            financeData.amicarParams.administrationCostRates
          )
          // define total amount to be financed
          const totalToFinance =
            (baseToFinance + administrationCost) / (1 - insuranceRate - taxRate)

          // calculate max rate
          const maxRate = calculateMaxRate(
            (baseToFinance + administrationCost) / (1 - insuranceRate),
            terms,
            financeData.amicarParams,
            isSmart
          )

          // calculate capital recovery factor
          const CRF = calculateCrf(terms, maxRate)

          // calculate monthly, CAE and firstPayment if credit is SMART
          if (isSmart) {
            let actualValue =
              totalToFinance - VFMG / (1 + maxRate) ** (terms + 1)
            // ActualValue < 580.000
            monthly = actualValue * CRF
            const flows = Array(terms).fill(monthly)
            // Calculate CAE
            flows.push(VFMG)
            try {
              CAE =
                Math.abs(FinanceFunctions.IRR(-baseToFinance, ...flows)) * 12
            } catch {
              CAE = 0
            }
            // Cancel credit if actualValue is less than minimunFinance or percentage to be financed is less than 40%
            if (
              actualValue < minimunFinanceAmount ||
              (isSmart && prePaidPercentage > 60)
            ) {
              firstPaymentPrice = maximumFirstPaymentPrice
              monthly = 0
            }
          } else {
            // calculate monthly, CAE and firstPayment if credit is TRADITIONAL
            monthly = CRF * totalToFinance
            // calculate CAE
            const flows = Array(terms).fill(monthly)
            try {
              CAE =
                Math.abs(FinanceFunctions.IRR(-baseToFinance, ...flows)) * 12
            } catch {
              CAE = 0
            }
          }

          // calculate credit total cost
          CTC = monthly * terms + (isSmart ? VFMG : 0)
        }
      }

      // Check calculation results
      if (monthly <= 0) {
        if (baseToFinance < 0) {
          onFavor = carInPaymentPrice - finalCarPrice
          monthlyPrice = firstPaymentPrice > 0 ? 0 : -1
        } else {
          monthlyPrice = 0
          firstPaymentPrice = baseAmount
        }
      } else {
        monthlyPrice = monthly
      }
    }
  }
  const output = {
    onFavor,
    firstPaymentPrice,
    monthlyPrice: Math.round(monthlyPrice),
    VFMG,
    CTC: Math.round(CTC),
    CAE,
    carPrice: finalCarPrice,
    totalDiscounts,
    totalAdditionals,
    selectedCarPrice,
    finalCarPrice,
    baseAmount,
    baseToFinance,
  }
  return {
    ...output,
    variant: getPaymentVariant(output, arguments),
    withCredit: getWithCredit(output, arguments),
  }
}
/*
    0: No hay precios. Actualmente se maneja con CAE = 100

    1: Crédito Flexible. Cuotas mensuales con Pié.
    2: Crédito Flexible.. Valor al contado. (incluye $0, que podría ocurrir cuando CIP = priceCar)
    3: Crédito Flexible.. Te devolvemos

    4: Crédito Convencional. Cuotas mensuales con Pié.
    5: Crédito Convencional. Valor al contado. (incluye $0, que podría ocurrir cuando CIP = priceCar)
    6: Crédito Convencional. Te devolvemos

    7: Pago al contado. Valor al contado. (incluye $0, que podría ocurrir cuando CIP = priceCar)
    8: Pago al contado. Te devolvemos

    1.1: variante 1 pero con Auto en parte de Pago.
    2.1: variante 2 pero con Auto en parte de Pago.
    3.1: variante 3 pero con Auto en parte de Pago.
    4.1: variante 4 pero con Auto en parte de Pago.
    5.1: variante 5 pero con Auto en parte de Pago.
    6.1: variante 6 pero con Auto en parte de Pago.
    7.1: variante 7 pero con Auto en parte de Pago.
    8.1: variante 8 pero con Auto en parte de Pago.
 */

const getWithCredit = ({ onFavor, monthlyPrice }, [paymentType]) =>
  paymentType !== Payment.cash && onFavor <= 0 && monthlyPrice !== 0

const getPaymentVariant = (
  { onFavor, firstPaymentPrice, monthlyPrice, VFMG, CTC, CAE, carPrice },
  [paymentType, months, percentage, prices, carInPaymentData, financeData]
) => {
  const hasPaymentCar = !!carInPaymentData
  if (paymentType === Payment.flexCredit) {
    if (monthlyPrice > 0 && firstPaymentPrice > 0) {
      return hasPaymentCar ? 1.1 : 1
    }
    if (monthlyPrice === 0 && firstPaymentPrice > 0) {
      return hasPaymentCar ? 2.1 : 2
    }
    if (onFavor > 0) {
      return hasPaymentCar ? 3.1 : 3
    }
  } else if (paymentType === Payment.credit) {
    if (monthlyPrice > 0 && firstPaymentPrice > 0) {
      return hasPaymentCar ? 4.1 : 4
    }
    if (monthlyPrice === 0 && firstPaymentPrice > 0) {
      return hasPaymentCar ? 5.1 : 5
    }
    if (onFavor > 0) {
      return hasPaymentCar ? 6.1 : 6
    }
  } else if (paymentType === Payment.cash) {
    if (firstPaymentPrice > 0) {
      return hasPaymentCar ? 7.1 : 7
    }
    if (onFavor > 0) {
      return hasPaymentCar ? 8.1 : 8
    }
  }
}

const getBudgetedPriceHelper = ({
  monthly,
  dues,
  firstPayment,
  maxRate,
  priceRange,
  insuranceRates,
}) => {
  /*  STEP 1
      Dividir monthly/CRF. 
      Para calcular CRF probar con la tasa del intervalo (5.7-100) y ver si baseToFinance queda dentro del intervalo. 
      Sino, probar con la tasa del intervalo (0-5.7)
  */
  let CRF = calculateCrf(dues, maxRate)
  const totalToFinance = monthly / CRF

  /*  STEP 2
      Revertir el (1 - insuranceRate - taxRate) y los costos de administración para tener baseToFinance. 
  */
  const taxRate = 0.008
  const insuranceRate = calculateInsuranceRate(dues, insuranceRates)
  const administrationCost = ADMINISTRATION_COSTS_AVG.find(
    entry =>
      entry.minDues <= dues &&
      dues <= entry.maxDues &&
      entry.priceRange === priceRange
  ).value
  const baseToFinance =
    totalToFinance * (1 - insuranceRate - taxRate) - administrationCost

  /*  STEP 3
      Sumar el pie y tenemos baseAmount
  */
  const baseAmount = baseToFinance + firstPayment

  /*  STEP 4
      Mostrar en negrita el mínimo entre baseAmount y pie/0.2
  */
  const firstPaymentBottleneck = firstPayment / 0.2 < baseAmount
  const budgetedPrice = Math.round(Math.min(baseAmount, firstPayment / 0.2))

  return { baseToFinance, firstPaymentBottleneck, budgetedPrice }
}

const testMaxRate = ({
  monthly,
  dues,
  firstPayment,
  supposedBaseToFinance,
  priceRange,
  amicarParams,
}) => {
  // Find max_rate within ranges
  const entry = amicarParams.normalMaxRate.find(
    value =>
      value.min_amount <= supposedBaseToFinance &&
      value.max_amount >= supposedBaseToFinance &&
      value.min_term <= dues &&
      value.max_term >= dues
  )
  const maxRate = entry.max_rate

  // Calculate relevant variables
  const {
    baseToFinance,
    budgetedPrice,
    firstPaymentBottleneck,
  } = getBudgetedPriceHelper({
    monthly,
    dues,
    firstPayment,
    maxRate,
    priceRange,
    insuranceRates: amicarParams.insuranceRates,
  })

  // Check if baseToFinance is inside the range
  if (entry.min_amount > baseToFinance || entry.max_amount < baseToFinance) {
    return null
  } else {
    return { budgetedPrice, firstPaymentBottleneck }
  }
}

export const getBudgetedPrice = ({
  monthly,
  dues,
  firstPayment,
  financeData,
}) => {
  if (!financeData?.amicarParams) return null

  let result = testMaxRate({
    monthly,
    dues,
    firstPayment,
    supposedBaseToFinance: 6000000, // inside the 5.8-15 range
    priceRange: 2,
    amicarParams: financeData.amicarParams,
  })
  if (!result) {
    result = testMaxRate({
      monthly,
      dues,
      firstPayment,
      supposedBaseToFinance: 5000000, // inside the 0-5.8 range
      priceRange: 1,
      amicarParams: financeData.amicarParams,
    })
  }
  if (!result) return null
  return {
    ...result,
    budgetedPrice: Math.round(result.budgetedPrice / 100000) * 100000,
  }
}

const ADMINISTRATION_COSTS_AVG = [
  {
    priceRange: 1,
    minDues: 6,
    maxDues: 12,
    value: 331000,
  },
  {
    priceRange: 1,
    minDues: 13,
    maxDues: 24,
    value: 376000,
  },
  {
    priceRange: 1,
    minDues: 25,
    maxDues: 36,
    value: 413000,
  },
  {
    priceRange: 1,
    minDues: 37,
    maxDues: 48,
    value: 411000,
  },
  {
    priceRange: 1,
    minDues: 48,
    maxDues: 60,
    value: 393000,
  },
  {
    priceRange: 2,
    minDues: 6,
    maxDues: 12,
    value: 235000,
  },
  {
    priceRange: 2,
    minDues: 13,
    maxDues: 24,
    value: 337000,
  },
  {
    priceRange: 2,
    minDues: 25,
    maxDues: 36,
    value: 427000,
  },
  {
    priceRange: 2,
    minDues: 37,
    maxDues: 48,
    value: 387000,
  },
  {
    priceRange: 2,
    minDues: 48,
    maxDues: 60,
    value: 367000,
  },
]
