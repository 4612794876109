import React from "react"

type Props = {
  title: string
  desc?: string
  children: React.ReactNode
}

export const QuestionTemplate: React.FC<Props> = ({
  title,
  desc,
  children,
}) => {
  return (
    <div className="question-template">
      <div className="title-container">
        <span className="title">{title}</span>
        <span className="desc">{desc}</span>
      </div>
      {children}
    </div>
  )
}
