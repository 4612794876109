import React from "react"
import { getIcon } from "../../assets"

const detail = details => {
  const stagger = 350
  return details.map((item, index) => (
    <div
      className="spec" //appear-animation"
      key={index}
      style={{ animationDuration: `${stagger * (index + 1)}ms` }}
    >
      <img className="icon" src={getIcon(item.icon.name, "white")} alt=""></img>
      <div className="description">{item.data || item.value}</div>
    </div>
  ))
}

export function VideoBackground({ model }) {
  return (
    <div
      className="hero-video"
      style={{
        backgroundImage: `url(${
          model.gallery.find(g => g.category === "exterior").images[0].url
        })`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="video">
        <video
          autoPlay="autoplay"
          loop="loop"
          muted
          id="MyVideo"
          className="fhd"
        >
          <source src={model.heroVideo?.url1080p} type="video/mp4" />
        </video>
        <video
          autoPlay="autoplay"
          loop="loop"
          muted
          id="MyVideo"
          className="hd"
        >
          <source src={model.heroVideo?.url720p} type="video/mp4" />
        </video>
        <video
          autoPlay="autoplay"
          loop="loop"
          muted
          id="MyVideo"
          className="sd"
        >
          <source src={model.heroVideo?.url480p} type="video/mp4" />
        </video>
      </div>
      <div className="main-specs">{detail(model.highlights)}</div>
    </div>
  )
}
