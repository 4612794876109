import React, { useContext, useEffect, useRef } from "react"
import { Input } from "@inventures/react-lib/components"
import { InputStatus, useInput } from "@inventures/react-lib/hooks"
import { RequiredValidator } from "@inventures/react-lib/validators"
import { Formatter } from "@inventures/react-lib/formatters"
import { TextFieldProps } from "@material-ui/core/TextField"
import { useSpecialDevice } from "../../hooks"
import { KeyboardContext } from "../../context"
import { StandardOnChangeParams } from "../../types"

const debounceTime = 800

class SpacesFormatter extends Formatter {
  format(input: string) {
    const words = input.split(" ").filter(Boolean).length
    const trimmedInput = words > 0 ? input.trimLeft() : input.trim()
    return trimmedInput.replace(/\s+/g, " ")
  }
}

class RemoveNumbersFormatter extends Formatter {
  format(input: string) {
    return input.replace(/[\d]/, "")
  }
}

type Props = {
  elementId: string
  name: string
  onChange: ({ name, value, hasError }: StandardOnChangeParams) => void
  onEnterPress?: () => void
  onTabPress?: () => void
  value: string
  requiredValidatorError: string
} & TextFieldProps

export const DCXInput: React.FC<Props> = ({
  elementId,
  name,
  onChange,
  requiredValidatorError,
  onEnterPress,
  onTabPress,
  ...props
}) => {
  const { showKeyboard, hideKeyboard } = useContext(KeyboardContext)
  const [value, setValue, status, errors, handleBlur] = useInput(
    props.value || "",
    {
      formatter: new SpacesFormatter(),
      validators: [new RequiredValidator(requiredValidatorError)],
      debounceTime,
    }
  )
  const inputRef = useRef(null)
  const [forStore] = useSpecialDevice()
  const hasError = status === InputStatus.ERROR
  useEffect(() => {
    onChange({ name, value, hasError })
  }, [value, hasError, onChange, name])
  useEffect(() => {
    if (props.value === "") setValue("")
  }, [props.value])

  return (
    <Input
      variant="outlined"
      color="primary"
      margin="dense"
      {...props}
      id={elementId}
      value={value}
      inputRef={inputRef}
      onChange={e => setValue(String(e.target.value))}
      onFocus={() =>
        showKeyboard(setValue, inputRef, {
          onEnterPress,
          onTabPress,
        })
      }
      onBlur={() => {
        hideKeyboard()
        handleBlur()
      }}
      error={hasError}
      helperText={errors[0]}
      autoComplete={forStore ? "off" : "on"}
    />
  )
}
