import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export const Image = props => {
  const data = useStaticQuery(query)
  const image = data.images.nodes.find(n =>
    n.relativePath.includes(props.filename)
  )
  if (!image?.childImageSharp?.fluid) {
    console.error(`ERROR: Image not found (${props.filename})`)
    return null
  }
  return (
    <Img
      alt={props.alt || "Dercocenter"}
      className={props.className}
      fluid={image.childImageSharp.fluid}
    />
  )
}

const query = graphql`
  query IMAGES_QUERY {
    images: allFile {
      nodes {
        relativePath
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
