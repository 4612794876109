import { CheckoutAccessory } from "../types"

export const getAccessoriesTotalPrice = (
  accessories: CheckoutAccessory[]
): number => {
  return accessories
    .filter(acc => acc.selected)
    .reduce((prev, actual) => prev + actual.price, 0)
}

export const getAccessoriesSelectionState = (
  selectedAccessories: number
): boolean[] => {
  const binaryAcc = (selectedAccessories >>> 0).toString(2)
  const binaryString = "0".repeat(5 - binaryAcc.length) + binaryAcc
  return binaryString
    .split("")
    .reverse()
    .map(v => Boolean(Number(v)))
}
