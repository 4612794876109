import React, { useContext } from "react"
import { HeaderModel, Frame } from ".."
import { Carousel2, DCXButton } from "../reusables"
import { UIContext } from "../../context/UIContext"
import { insuranceInfoFramesData } from "../../constants"

export const Insurance: React.FC = () => {
  const { openInsuranceDrawer } = useContext(UIContext)
  return (
    <div className="model-insurance">
      <HeaderModel upperTitle="DERCO" lowerTitle="SEGUROS" />
      <Carousel2
        breakpoints={{
          extraSmall: { breakpoint: { min: 0, max: 639 }, items: 1 },
          small: { breakpoint: { min: 640, max: 959 }, items: 2 },
          medium: { breakpoint: { min: 960, max: 1279 }, items: 3 },
        }}
        autoPlay={5}
        noCarouselAfter={1280}
      >
        {insuranceInfoFramesData.map((frame, idx) => (
          <Frame key={idx} {...frame} />
        ))}
      </Carousel2>
      <div className="model-insurance__cta">
        <DCXButton
          text="COTIZA TU SEGURO"
          onClick={openInsuranceDrawer}
          type="primary"
        />
      </div>
    </div>
  )
}
