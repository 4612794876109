import React from "react"
import { getStandardAnimationProps } from "../../constants"
import {
  big1Image,
  big2Image,
  big3Image,
  big4Image,
  big5Image,
  big6Image,
  big7Image,
  completedIcon,
  pendingIcon,
} from "../../assets"
import { useSpecialDevice } from "../../hooks"

const getImgSrc = (idx, step, completed, pending) =>
  completed.includes(idx)
    ? completedIcon
    : pending.includes(idx)
    ? pendingIcon
    : step.number

const getStepClass = (idx, completed, pending) =>
  "step " +
  (completed.includes(idx)
    ? "completed"
    : pending.includes(idx)
    ? "pending"
    : "")

export function Steps({ title, completed: _completed, pending: _pending }) {
  const completed = _completed || []
  const pending = _pending || []
  const [isStorePage] = useSpecialDevice()
  const standardAnimationProps = isStorePage ? {} : getStandardAnimationProps()
  return (
    <div className="steps">
      <div className="title" {...standardAnimationProps}>
        {title || "¿Cómo seguimos?"}
      </div>
      {STEPS.map((step, idx) => (
        <div
          key={idx}
          className={getStepClass(idx + 1, completed, pending)}
          {...(isStorePage ? {} : getStandardAnimationProps(idx, 0, 200, 250))}
        >
          <div className="left">
            <img src={getImgSrc(idx + 1, step, completed, pending)} alt="" />
          </div>
          <div className="right">
            <div className="step-title">{step.title}</div>
            {!completed.includes(idx + 1) && (
              <div className="desc">{step.desc}</div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const STEPS = [
  {
    number: big1Image,
    title: "Arma tu auto nuevo",
    desc: "Elige tu próximo auto cómo y desde donde quieras",
  },
  {
    number: big2Image,
    title: "Ingresa tus datos",
    desc: "Para poder reservar y/o pre-aprobar tu crédito en minutos",
  },
  {
    number: big3Image,
    title: "Reserva tu auto",
    desc: "Un pago de $200.000, si te arrepientes te lo devolvemos",
  },
  {
    number: big4Image,
    title: "Finaliza la compra",
    desc: "Un asesor te contactará para finalizar la compra",
  },
  {
    number: big5Image,
    title: "Delivery o retiro",
    desc: "Entregamos el auto en tu casa o en un local",
  },
  {
    number: big6Image,
    title: "Pruébalo con calma",
    desc: "Revísalo, pruébalo y si no te gusta ¡Lo devuelves!",
  },
  {
    number: big7Image,
    title: "Instalamos tu patente",
    desc: "Vamos a tu casa a instalarte la patente",
  },
]
