import React, { useContext } from "react"
import { Button } from "@material-ui/core"
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded"
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded"
import { CarContext, SearchContext } from "../../context"

export function HeaderSortButton({ text }) {
  const { priceOrder, setPriceOrder } = useContext(CarContext)
  const { activeModelSearch } = useContext(SearchContext)

  return (
    <Button
      className={`header-button sort-price-header-button ${
        priceOrder ? "asc" : "desc"
      }`}
      startIcon={
        activeModelSearch && priceOrder === null ? (
          <AttachMoneyRoundedIcon />
        ) : (
          <ArrowUpwardRoundedIcon />
        )
      }
      onClick={() => setPriceOrder(prev => !prev)}
    >
      {text || "Precio"}
    </Button>
  )
}
